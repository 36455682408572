import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Table} from '@devexpress/dx-react-grid-material-ui';
import DropdownResearchItem from '../../../DropdownResearch/DropdownResearchItem';
import TextField from '@material-ui/core/TextField';
import {InputAdornment, Select, MenuItem} from '@material-ui/core';
import {
  getItemSupplierRequest,
  getItemPosByPosRequest,
} from '../../../../config/actions.config';

class OrderItemEditCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: null,
      priceWithoutVat: null,
      vatRatesList: [],
      currentVat: null
    };
    this.params = {limit: 5, 'item.project.id': this.props.idProject};
    this.newItem = {};
  }

  componentDidMount() {
    if (this.props.vatRatesList) {
      this.setState({
        vatRatesList: this.props.vatRatesList.vatRates
      })
    }
  }

  componentDidUpdate(prevProps) {
    const {itemSuppliersList, cell} = this.props;

    if (prevProps.itemSuppliersList !== itemSuppliersList) {
      if (
        itemSuppliersList.itemSuppliers.length === 1 &&
        !itemSuppliersList.loading &&
        !itemSuppliersList.error
      ) {
        if (cell.column.name === 'quantity') {
          this.setState({
            quantity: itemSuppliersList.itemSuppliers[0].minimumOrderQuantity
              ? itemSuppliersList.itemSuppliers[0].minimumOrderQuantity
              : 1,
          });
        }
        if (cell.column.name === 'priceWithoutVat') {
          this.setState({
            priceWithoutVat: itemSuppliersList.itemSuppliers[0]
              .purchasePriceExclTax
              ? itemSuppliersList.itemSuppliers[0].purchasePriceExclTax
              : 0,
          });
        }
      }
    }

    if (this.props.vatRatesList !== prevProps.vatRatesList) {
      if (this.props.vatRatesList.vatRates !== null &&
        !this.props.vatRatesList.loading &&
        !this.props.vatRatesList.error) {
        this.setState({
          vatRatesList: this.props.vatRatesList.vatRates,
          currentVat: this.props.vatRatesList.vatRates[0].id
        })
      }
    }
  }

  onSearchItem = (productName) => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (productName !== '') {
        this.params['item.name'] = {};
        this.params['item.name'].operation = 'contain';
        this.params['item.name'].value = productName;
      } else {
        delete this.params['item.name'];
      }

      if (this.props.supplier) {
        this.props.getItemSupplierRequest(this.props.id, this.params);
      }
      if (this.props.intern) {
        this.props.getItemPosByPosRequest(this.props.id, this.params);
      }
    }, 200);
  };

  onHandleChange = (item) => {
    console.log(item, this.state.vatRatesList);
    if (item && item !== '') {
      this.props.setNewItem('itemSupplier', item.id);
      this.props.setNewItem('item', item.item.id);
      this.props.setNewItem('vatRate', item.vatRate ? item.vatRate.id : this.state.vatRatesList[0] ? this.state.vatRatesList[0].id : null);
      this.props.setNewItem(
        'quantity',
        item.minimumOrderQuantity ? item.minimumOrderQuantity : 1
      );
      this.props.setNewItem(
        'priceWithoutVat',
        item.purchasePriceExclTax ? item.purchasePriceExclTax : 0
      );
      this.setState({
        fullItem: item,
      });
    }
  };

  onHandleChangeSelect = (event) => {
    this.props.setNewItem('vatRate', event.target.value);
    this.setState({currentVat: event.target.value});
  }

  onHandleChangeItem = (name, event) => {
    this.setState({[name]: parseFloat(event.target.value)});
    this.props.setNewItem(name, parseFloat(event.target.value));
  };

  onHandleEnter = () => {
    this.props.onHandleEnter();
  }

  render() {
    const {cell, fullItem} = this.props;

    let content = cell.value;
    if (cell.column.name === 'item') {
      return (
        <Table.Cell style={cell.style}>
          <DropdownResearchItem
            onHandleChangeInput={this.onSearchItem}
            onHandleChange={(result) => this.onHandleChange(result)}
            onHandleEnter={this.onHandleEnter}
            value={fullItem ? fullItem.item.name : cell.value}
          />
        </Table.Cell>
      );
    } else if (cell.column.name === 'quantity') {
      return (
        <Table.Cell style={cell.style}>
          <TextField
            type='number'
            value={this.state.quantity ? this.state.quantity : cell.value}
            InputProps={{inputProps: {min: 0}}}
            onChange={(result) => this.onHandleChangeItem('quantity', result)}
          />
        </Table.Cell>
      );
    } else if (cell.column.name === 'priceWithoutVat') {
      return (
        <Table.Cell style={cell.style}>
          <TextField
            type='number'
            value={
              this.state.priceWithoutVat
                ? this.state.priceWithoutVat
                : cell.value
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  {cell.row.currency ? cell.row.currency.symbol : ''}
                </InputAdornment>
              ),
              inputProps: {min: 0},
            }}
            onChange={(result) =>
              this.onHandleChangeItem('priceWithoutVat', result)
            }
          />
        </Table.Cell>
      );
    } else if (cell.column.name === 'vatrate') {
      return (
        <Table.Cell style={cell.style}>
          {this.state.vatRatesList.length !== 0 && (
            <Select
              className={'vatRatesList-select'}
              value={this.state.currentVat ? this.state.currentVat : cell.value ? cell.value.id : this.state.vatRatesList[0].id}
              onChange={this.onHandleChangeSelect}
            >
              {this.state.vatRatesList.map((vat, index) => (
                <MenuItem key={index} value={vat.id}>{vat.rate + '% - ' + vat.description}</MenuItem>
              ))}
            </Select>)}
        </Table.Cell>
      );
    } else if (cell.column.name === 'totalWithoutVat') {
      return (
        <Table.Cell style={cell.style}>
          <TextField
            disabled
            value={cell.value}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  {cell.row.currency ? cell.row.currency.symbol : ''}
                </InputAdornment>
              ),
            }}
          />
        </Table.Cell>
      );
    } else if (cell.column.name === 'packUnitName') {
      return (
        <Table.Cell style={cell.style}>
          <div align='center'>{cell.value}</div>
        </Table.Cell>
      );
    }

    return <Table.Cell style={cell.style}>{content}</Table.Cell>;
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  itemSuppliersList: state.sdk.itemSuppliers.itemSuppliersList,
  vatRatesList: state.sdk.vatRates.vatRatesList,
});

export default connect(mapStateToProps, {
  getItemSupplierRequest,
  getItemPosByPosRequest,
})(OrderItemEditCell);
