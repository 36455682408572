import React, { Component } from 'react';
import { connect } from 'react-redux';
import IntlMessages from '../../../util/IntlMessages';
import {
  UnderlineTitle,
  Loading,
  ActionButton,
} from '../../../config/components.config';
import Select from 'react-select';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import {
  getAllUsersByProjectRequest,
  updateSupplierDeliveryDocumentRequest,
  putPosDeliverydocumentRequest,
  putAddressRequest,
} from '../../../config/actions.config';
import '../index.css';
const countries = require('i18n-iso-countries');

class DetailDeliveryRequestor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditInfo: false,
      usersList: [],
      responsibleUserProfile: null,
      billingAddress: {},
      shippingAddress: {},
    };
    this.updatedOrder = {};
    this.updatedOrderAdresses = {};
    this.allCountry = null;
  }

  componentDidMount() {
    this.props.getAllUsersByProjectRequest(this.props.idProject);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.responsibleUserProfile !== this.props.responsibleUserProfile
    ) {
      this.setState({
        responsibleUserProfile: {
          value: this.props.responsibleUserProfile.id,
          label: this.props.responsibleUserProfile.fullName,
        },
      });
    }

    if (prevProps.usersList !== this.props.usersList) {
      if (
        this.props.usersList.users !== '' &&
        !this.props.usersList.loading &&
        !this.props.usersList.error
      ) {
        this.setState({ usersList: this.props.usersList.users });
      }
    }
  }

  changeResponsible = (event) => {
    this.setState({
      responsibleUserProfile: event,
    });

    this.updatedOrder.responsibleUserProfile = event.value;
  };

  handleSubmit = () => {
    if (this.updatedOrder.responsibleUserProfile) {
      if(this.props.supplier){
        this.props.updateSupplierDeliveryDocumentRequest(
          this.props.idDelivery,
          this.updatedOrder
        );
      }
      if(this.props.intern){
        this.props.putPosDeliverydocumentRequest(
          this.props.idDelivery,
          this.updatedOrder
        );
      }
    }
    if (this.updatedOrderAdresses.billingAddress) {
      this.props.putAddressRequest(
        this.props.billingAddress.id,
        this.updatedOrderAdresses.billingAddress
      );
    }

    if (this.updatedOrderAdresses.shippingAddress) {
      this.props.putAddressRequest(
        this.props.shippingAddress.id,
        this.updatedOrderAdresses.shippingAddress
      );
    }

    this.setState({ showEditInfo: false });
  };

  handleEdit = () => {
    this.setState({
      showEditInfo: !this.state.showEditInfo,
    });
    if (this.props.responsibleUserProfile) {
      this.setState({
        responsibleUserProfile: {
          value: this.props.responsibleUserProfile.id,
          label: this.props.responsibleUserProfile.fullName,
        },
      });
    }
    if (this.props.billingAddress) {
      this.setState({
        billingAddress: {
          street: this.props.billingAddress.street,
          streetNumber: this.props.billingAddress.streetNumber,
          zipCode: this.props.billingAddress.zipCode,
          city: this.props.billingAddress.city,
          country: this.props.billingAddress.country,
        },
      });
    }
    if (this.props.shippingAddress) {
      this.setState({
        shippingAddress: {
          street: this.props.shippingAddress.street,
          streetNumber: this.props.shippingAddress.streetNumber,
          zipCode: this.props.shippingAddress.zipCode,
          city: this.props.shippingAddress.city,
          country: this.props.shippingAddress.country,
        },
      });
    }
  };

  handleChangeAddress = (adress, value) => (event) => {
    let objectAdress = this.state[adress];

    if (value === 'country') {
      var countryCode = countries.getAlpha2Code(event.value, this.props.locale);
      objectAdress[value] = countryCode;
    } else {
      objectAdress[value] = event.target.value;
    }

    this.setState({
      [adress]: objectAdress,
    });

    this.updatedOrderAdresses[adress] = objectAdress;
  };

  render() {
    const { billingAddress, shippingAddress } = this.state;
    countries.registerLocale(
      require(`i18n-iso-countries/langs/${
        this.props.locale === 'fr_CA' ? 'fr' : this.props.locale
      }.json`)
    );

    this.allCountry = countries.getNames(this.props.locale, {
      select: 'official',
    });

    return (
      <>
        <div className='jr-card fullWidth'>
          <UnderlineTitle
            title={<IntlMessages id='orders.detail.card.requestor.title' />}
          >
            <ActionButton icon={'edit'} onClick={this.handleEdit} />
          </UnderlineTitle>

          {this.props.requestorPos &&
            (!this.state.showEditInfo ? (
              <>
                {this.props.responsibleUserProfile && (
                  <div className={'information'}>
                    <div className='mr-2'>
                      <b>
                        <IntlMessages id='orders.detail.card.requestor.responsible' />
                        :
                      </b>
                    </div>
                    <div>{this.props.responsibleUserProfile.fullName}</div>
                  </div>
                )}
                <div className={'information'}>
                  <div className='mr-2'>
                    <b>
                      <IntlMessages id='itemPos.modal.pos' />:
                    </b>
                  </div>
                  <div>{this.props.requestorPos.name}</div>
                </div>
                <div className={'information'}>
                  <div className='mr-2'>
                    <b>
                      <IntlMessages id='pos.table.warehouse' />:
                    </b>
                  </div>
                  <div>
                    {this.props.requestorPos.warehouse ? (
                      <IntlMessages id='stock.warehouse' />
                    ) : (
                      <IntlMessages id='stock.store' />
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div className={'information editDetailCardContainer'}>
                <div className='col-3 mr-2 p-0'>
                  <b>
                    <IntlMessages id='orders.detail.card.requestor.responsible' />
                    :
                  </b>
                </div>

                <div className='col-8 p-0'>
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    }}
                    fullWidth
                    hideSelectedOptions={true}
                    value={this.state.responsibleUserProfile}
                    onChange={this.changeResponsible}
                    options={this.state.usersList.map((data) => {
                      return {
                        value: data.id,
                        label: data.fullName,
                      };
                    })}
                  />
                </div>
              </div>
            ))}

          {!this.state.showEditInfo ? (
            <div className='mt-3'>
              <div className='row'>
                {this.props.billingAddress && (
                  <div className='col-6'>
                    <div>
                      <b>
                        <IntlMessages id='billingAddress' /> :
                      </b>
                    </div>
                    <div>
                      <div>
                        {this.props.billingAddress.street}{' '}
                        {this.props.billingAddress.streetNumber}
                      </div>
                      <div>
                        {this.props.billingAddress.zipCode}{' '}
                        {this.props.billingAddress.city}
                        {', '}
                        {this.props.getCountry(
                          this.props.billingAddress.country
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {this.props.shippingAddress && (
                  <div className='col-6'>
                    <div>
                      <b>
                        <IntlMessages id='shippingAddress' />:
                      </b>
                    </div>
                    <div>
                      <div>
                        {this.props.shippingAddress.street}{' '}
                        {this.props.shippingAddress.streetNumber}
                      </div>
                      <div>
                        {this.props.shippingAddress.zipCode}{' '}
                        {this.props.shippingAddress.city}
                        {', '}
                        {this.props.getCountry(
                          this.props.shippingAddress.country
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <>
              <div className='mt-3'>
                <div>
                  <b>
                    <IntlMessages id='billingAddress' /> :
                  </b>
                </div>
                <div>
                  <div className='row'>
                    <div className='col-lg-8'>
                      <TextField
                        margin='normal'
                        size='small'
                        fullWidth
                        label={<IntlMessages id='appModule.adress' />}
                        variant='outlined'
                        value={billingAddress.street}
                        onChange={this.handleChangeAddress(
                          'billingAddress',
                          'street'
                        )}
                      />
                    </div>
                    <div className='col-lg-4 pl-0'>
                      <TextField
                        margin='normal'
                        size='small'
                        fullWidth
                        label={<IntlMessages id='appModule.houseNumber' />}
                        variant='outlined'
                        value={billingAddress.streetNumber}
                        onChange={this.handleChangeAddress(
                          'billingAddress',
                          'streetNumber'
                        )}
                      />
                    </div>
                    <div className='col-lg-8'>
                      <TextField
                        margin='normal'
                        size='small'
                        fullWidth
                        label={<IntlMessages id='appModule.city' />}
                        variant='outlined'
                        value={billingAddress.city}
                        onChange={this.handleChangeAddress(
                          'billingAddress',
                          'city'
                        )}
                      />
                    </div>
                    <div className='col-lg-4 pl-0'>
                      <TextField
                        margin='normal'
                        size='small'
                        fullWidth
                        label={<IntlMessages id='appModule.zipCode' />}
                        variant='outlined'
                        value={billingAddress.zipCode}
                        onChange={this.handleChangeAddress(
                          'billingAddress',
                          'zipCode'
                        )}
                      />
                    </div>
                    <div className='col-8'>
                      <FormControl className='formAdress'>
                        <Select
                          fullWidth
                          placeholder={<IntlMessages id='appModule.country' />}
                          value={
                            (billingAddress.country
                            ? billingAddress.country
                            : '')
                              ? {
                                  value: countries.getName(
                                    billingAddress.country
                                      ? billingAddress.country
                                      : '',
                                    this.props.locale,
                                    { select: 'official' }
                                  ),
                                  label: countries.getName(
                                    billingAddress.country
                                      ? billingAddress.country
                                      : '',
                                    this.props.locale,
                                    { select: 'official' }
                                  ),
                                }
                              : ''
                          }
                          onChange={this.handleChangeAddress(
                            'billingAddress',
                            'country'
                          )}
                          styles={{
                            control: (base) => ({
                              ...base,
                              height: 40,
                            }),
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 9999,
                            }),
                          }}
                          options={
                            this.allCountry &&
                            Object.keys(this.allCountry).map((data) => {
                              return {
                                value: this.allCountry[data],
                                label: this.allCountry[data],
                              };
                            })
                          }
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>

              <div className='mt-3'>
                <div>
                  <b>
                    <IntlMessages id='shippingAddress' /> :
                  </b>
                </div>
                <div>
                  <div className='row'>
                    <div className='col-lg-8'>
                      <TextField
                        margin='normal'
                        size='small'
                        fullWidth
                        label={<IntlMessages id='appModule.adress' />}
                        variant='outlined'
                        value={shippingAddress.street}
                        onChange={this.handleChangeAddress(
                          'shippingAddress',
                          'street'
                        )}
                      />
                    </div>
                    <div className='col-lg-4 pl-0'>
                      <TextField
                        margin='normal'
                        size='small'
                        fullWidth
                        label={<IntlMessages id='appModule.houseNumber' />}
                        variant='outlined'
                        value={shippingAddress.streetNumber}
                        onChange={this.handleChangeAddress(
                          'shippingAddress',
                          'streetNumber'
                        )}
                      />
                    </div>
                    <div className='col-lg-8'>
                      <TextField
                        margin='normal'
                        size='small'
                        fullWidth
                        label={<IntlMessages id='appModule.city' />}
                        variant='outlined'
                        value={shippingAddress.city}
                        onChange={this.handleChangeAddress(
                          'shippingAddress',
                          'city'
                        )}
                      />
                    </div>
                    <div className='col-lg-4 pl-0'>
                      <TextField
                        margin='normal'
                        size='small'
                        fullWidth
                        label={<IntlMessages id='appModule.zipCode' />}
                        variant='outlined'
                        value={shippingAddress.zipCode}
                        onChange={this.handleChangeAddress(
                          'shippingAddress',
                          'zipCode'
                        )}
                      />
                    </div>
                    <div className='col-8'>
                      <FormControl className='formAdress'>
                        <Select
                          fullWidth
                          placeholder={<IntlMessages id='appModule.country' />}
                          value={
                            (shippingAddress.country
                            ? shippingAddress.country
                            : '')
                              ? {
                                  value: countries.getName(
                                    shippingAddress.country
                                      ? shippingAddress.country
                                      : '',
                                    this.props.locale,
                                    { select: 'official' }
                                  ),
                                  label: countries.getName(
                                    shippingAddress.country
                                      ? shippingAddress.country
                                      : '',
                                    this.props.locale,
                                    { select: 'official' }
                                  ),
                                }
                              : ''
                          }
                          onChange={this.handleChangeAddress(
                            'shippingAddress',
                            'country'
                          )}
                          styles={{
                            control: (base) => ({
                              ...base,
                              height: 40,
                            }),
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 9999,
                            }),
                          }}
                          options={
                            this.allCountry &&
                            Object.keys(this.allCountry).map((data) => {
                              return {
                                value: this.allCountry[data],
                                label: this.allCountry[data],
                              };
                            })
                          }
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>

              <div className='d-flex justify-content-end my-3'>
                <Button
                  variant='outlined'
                  onClick={this.handleSubmit}
                  color='primary'
                >
                  <IntlMessages id='items.modal.button.save' />
                </Button>
              </div>
            </>
          )}

          {this.props.loading && <Loading />}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  usersList: state.sdk.user.usersList,
  locale: state.sdk.settings.locale.locale,
});

export default connect(mapStateToProps, {
  getAllUsersByProjectRequest,
  updateSupplierDeliveryDocumentRequest,
  putPosDeliverydocumentRequest,
  putAddressRequest,
})(DetailDeliveryRequestor);
