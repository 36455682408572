import React from 'react';
import { connect } from 'react-redux';
import OutOfOrderTable from './OutOfOrderTable';
import IntlMessages from '../../../util/IntlMessages';
import { MainButton } from '../../../config/components.config';
import ReOrderModal from './ReOrderModal/ReOrderModal';

class OutOfOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalReOrder: false,
      showButtonBool: false,
      selectedRows: [],
      showButtonToSelect: true,
      selectAll: false,
    };
  }

  showButton = (selectedRows) => {
    if (selectedRows.length > 0) {
      this.setState({
        showButtonBool: true,
        selectedRows: selectedRows,
        showButtonToSelect: false,
      });
    } else {
      this.setState({
        showButtonBool: false,
        selectedRows: [],
        showButtonToSelect: true,
      });
    }
  };

  render() {
    const {
      showModalReOrder,
      showButtonBool,
      selectedRows,
      showButtonToSelect,
      selectAll,
    } = this.state;
    return (
      <div className='app-wrapper div-wrapper-title'>
        <div className='row headerAlign'>
          <div className='col-lg-9 col-md-9 col-sm-7 page-title'>
            <IntlMessages id='outOfOrder.title' />
          </div>
          <div className='col-lg-3 col-md-3 col-sm-5 btn-add-container'>
            {showButtonBool && (
              <MainButton
                onClick={() => this.setState({ showModalReOrder: true })}
              >
                <IntlMessages id='outOfOrder.button.order' />
              </MainButton>
            )}
            {showButtonToSelect && (
              <MainButton
                onClick={() =>
                  this.setState({ selectAll: true, showButtonToSelect: false })
                }
              >
                <IntlMessages id='outOfOrder.button.select' />
              </MainButton>
            )}
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='jr-card'>
              <OutOfOrderTable
                showButton={this.showButton}
                selectAll={selectAll}
              />
            </div>
          </div>

          <ReOrderModal
            selectedRows={selectedRows}
            open={showModalReOrder}
            close={() => this.setState({ showModalReOrder: false })}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
});

export default connect(mapStateToProps)(OutOfOrder);
