import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getPosRequest } from '../../../config/actions.config';
import { Loading } from '../../../config/components.config';
import IntlMessages from '../../../util/IntlMessages';
import { NotificationManager } from 'react-notifications';
import PosInfo from './DetailCards/PosInfo';
import PosOrders from './DetailCards/PosOrders';
import PosDelivery from './DetailCards/PosDelivery';
import PosItems from './DetailCards/PosItems';
import '../index.css'

class PosDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.posDetail = null;
  }

  componentDidMount() {
    this.props.getPosRequest(this.props.match.params.idPos);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.posDetails !== this.props.posDetails) {
      if (this.props.posDetails.loading) {
        this.setState({
          loading: true,
        });
      }
      if (
        this.props.posDetails &&
        this.props.posDetails.pos !== '' &&
        !this.props.posDetails.loading &&
        !this.props.posDetails.error
      ) {
        this.setState({
          loading: false,
        });
        this.posDetail = this.props.posDetails.pos;
      }
    }

    if (prevProps.updatedPos !== this.props.updatedPos) {
      if (
        this.props.updatedPos.pos !== '' &&
        !this.props.updatedPos.loading &&
        !this.props.updatedPos.error
      ) {
        NotificationManager.success(
          <IntlMessages id='pos.success.message.updated' />
        );
        this.props.getPosRequest(this.props.match.params.idPos);
      }
    }
  }

  render() {
    const { loading } = this.state;

    return (
      <div className='app-wrapper div-wrapper-title'>
        <div className='row headerAlign'>
          <div className='col-12 page-title'>
            <IntlMessages id='pos.detail.title' />
          </div>
        </div>
        <div>
          <div className='row'>
            <div className='col-md-4 col-12 detailCard'>
              <PosInfo pos={this.posDetail} />
            </div>
            <div className='col-md-4 col-12 detailCard'>
              <PosOrders id={this.props.match.params.idPos} />
              {loading && <Loading />}
            </div>
            <div className='col-md-4 col-12 detailCard'>
              <PosDelivery id={this.props.match.params.idPos}/>
              {loading && <Loading />}
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
              <PosItems id={this.props.match.params.idPos} pos={this.posDetail}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  posDetails: state.sdk.pos.posDetails,
  updatedPos: state.sdk.pos.updatedPos,
});

export default connect(mapStateToProps, {
  getPosRequest,
})(PosDetail);
