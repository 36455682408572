import React from 'react';
import { connect } from 'react-redux';
import IntlMessages from '../../../util/IntlMessages';
import SupplierOrdersTable from './SupplierOrdersTable/index';
import { MainButton } from '../../../config/components.config';
import { NavLink } from 'react-router-dom';
import '../index.css';

class SupplierOrders extends React.Component {
  render() {
    return (
      <div className='app-wrapper div-wrapper-title'>
        <div className='row headerAlign'>
          <div className='col-lg-9 col-md-9 col-sm-7 page-title'>
            <IntlMessages id='orders.supplier.title' />
          </div>
          <div className='col-lg-3 col-md-3 col-sm-5 btn-add-container'>
            <NavLink to={'create-supplier-order'}>
              <MainButton>
                <IntlMessages id='orders.button.add' />
              </MainButton>
            </NavLink>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='jr-card'>
              <SupplierOrdersTable />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
});

export default connect(mapStateToProps)(SupplierOrders);
