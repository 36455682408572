import React, { Component } from 'react';
import { Modal } from '../../../../config/components.config';
import IntlMessages from '../../../../util/IntlMessages';
import ItemModalCreationContent from './ItemModalCreationContent';

class ItemModalCreation extends Component {
  handleClose = () => this.props.close();

  render() {
    return (
      <div>
        <Modal
          title={<IntlMessages id='items.modal.creation.title' />}
          width='md'
          open={this.props.open}
          close={this.handleClose}
        >
          <ItemModalCreationContent close={this.handleClose} />
        </Modal>
      </div>
    );
  }
}

export default ItemModalCreation;
