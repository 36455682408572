import saMessages from '../locales/nl/nl.json';
import nlMessages from '../locales/nl/global_nl.json';

const result = {};
let key;

for (key in saMessages) {
  if (Object.prototype.hasOwnProperty.call(saMessages, key)) {
    result[key] = saMessages[key];
  }
}

for (key in nlMessages) {
  if (Object.prototype.hasOwnProperty.call(nlMessages, key)) {
    result[key] = nlMessages[key];
  }
}

const nlLang = {
  messages: {
    ...result,
  },
  locale: 'nl',
};
export default nlLang;
