import React, { Component } from 'react';
import { connect } from 'react-redux';
import IntlMessages from '../../../../util/IntlMessages';
import { Grid, Table } from '@devexpress/dx-react-grid-material-ui';
import { UnderlineTitle } from '../../../../config/components.config';
import NoData from '../../../../components/Table/NoData/NoData';
import { getPosDeliveryDocumentsRequest } from '../../../../config/actions.config';
import PosDeliveryCell from '../../../../components/Table/Cells/Pos/PosDeliveryCell';

const columns = [{ name: 'delivery' }];

class PosDelivery extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.params = {
      limit: 10,
    };
  }

  componentDidMount() {
    this.props.getPosDeliveryDocumentsRequest(this.props.id, this.params);
  }

  render() {
    const { posDeliveryDocuments } = this.props;
    const row = [];
    if (posDeliveryDocuments && posDeliveryDocuments.deliveryDocuments !== '') {
      posDeliveryDocuments.deliveryDocuments.forEach((data, i) => {
        row[i] = {
          id: data.id,
          name: data.name,
        };
      });
    }
    return (
      <>
        <div className='jr-card'>
          <UnderlineTitle
            title={<IntlMessages id='pos.detail.card.delivery.title' />}
          />
          <div className='containerTable'>
            <Grid rows={row} columns={columns}>
              <Table
                noDataCellComponent={NoData}
                cellComponent={PosDeliveryCell}
              />
            </Grid>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  posDeliveryDocuments: state.sdk.posDocuments.posDeliveryDocuments,
});

export default connect(mapStateToProps, { getPosDeliveryDocumentsRequest })(
  PosDelivery,
);
