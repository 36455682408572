import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../../../util/IntlMessages';
import { capitalize } from '../../../../config/services.config';

class ItemModalCreationTranslations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
    };
  }

  handleChangeTranslation = (name) => (event) => {
    const { data } = this.props;
    this.setState({ name: capitalize(event.target.value) });
    this.props.handleChangeTranslation(
      name,
      capitalize(event.target.value),
      data,
    );
  };

  render() {
    const { name } = this.state;

    return (
      <div>
        <div className='row mt-3'>
          <div className='col-12 col-md-4 textFieldLabel mt-3'>
            <h4>
              <IntlMessages id='items.modal.creation.label.name' />
            </h4>
          </div>
          <div className='col-12 col-md-8 mt-4'>
            <div className='form-group text-field-stepper'>
              <TextField
                margin='normal'
                fullWidth
                value={name}
                variant='outlined'
                size='small'
                onChange={this.handleChangeTranslation('name')}
                className='inputTranslationItem'
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
});

export default connect(mapStateToProps)(ItemModalCreationTranslations);
