import React, { Component } from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import {
  formatDateTime,
  formatMoney,
} from '../../../../config/services.config';
import IntlMessages from '../../../../util/IntlMessages';
import { connect } from 'react-redux';
import {
  BadgeVat,
  StatusComponent,
} from '../../../../config/components.config';
import ButtonsOrders from '../../../Buttons/ButtonsOrders';
import { updateDocumentStatusRequest } from '../../../../config/actions.config';
import '../cell.css';

class OrdersCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showText: false,
    };
  }

  statusCreated = [
    {
      name: 'validate',
      text: <IntlMessages id='orders.table.type.status.validated' />,
    },
    {
      name: 'cancel',
      text: <IntlMessages id='orders.table.type.status.canceled' />,
    },
  ];

  statusValidated = [
    {
      name: 'send',
      text: <IntlMessages id='orders.table.type.status.sended' />,
    },
    {
      name: 'cancel',
      text: <IntlMessages id='orders.table.type.status.canceled' />,
    },
  ];

  statusSended = [
    {
      name: 'delivery',
      text: <IntlMessages id='orders.table.type.status.delivered' />,
    },
    {
      name: 'cancel',
      text: <IntlMessages id='orders.table.type.status.canceled' />,
    },
  ];

  handlePutStatus(newStatus) {
    this.props.updateDocumentStatusRequest(this.props.cell.row.id, newStatus);
  }

  render() {
    const { cell } = this.props;

    let content = cell.value;
    if (cell.column.name === 'createdAt') {
      content = formatDateTime(
        cell.row.createdAt,
        cell.row.locale,
        cell.row.timezone,
      );
    } else if (cell.column.name === 'reference') {
      if (content === null) {
        content = (
          <div className='noDataText'>
            <IntlMessages id='orders.supplier.no.reference' />
          </div>
        );
      }
    } else if (cell.column.name === 'responsibleUserProfile') {
      if (content === null || content === undefined) {
        content = (
          <div className='noDataText'>
            <IntlMessages id='orders.supplier.no.responsibleUserProfile' />
          </div>
        );
      }
    } else if (cell.column.name === 'status') {
      content = (
        <div align='center'>
          <StatusComponent
            status={cell.row.status}
            clickable
            statusList={
              cell.row.status === 'created'
                ? this.statusCreated
                : cell.row.status === 'validated'
                ? this.statusValidated
                : cell.row.status === 'sended'
                ? this.statusSended
                : []
            }
            updateStatus={(newStatus) => this.handlePutStatus(newStatus)}
            label={
              <IntlMessages
                id={`orders.table.type.status.${cell.row.status}`}
              />
            }
            errorLabel={<IntlMessages id='appModule.status.unknown' />}
          />
        </div>
      );
    } else if (cell.column.name === 'action') {
      content = (
        <div className={'btn-action-container'}>
          <ButtonsOrders idOrder={cell.row.id} orderType={cell.row.orderType} />
        </div>
      );
    } else if (cell.column.name === 'totalWithoutVat') {
      content = (
        <div align='center'>
          <BadgeVat price={cell.row.totalWithoutVat}>
            {formatMoney(
              cell.row.currency,
              cell.row.locale,
              cell.row.totalWithoutVat,
            )}
          </BadgeVat>
        </div>
      );
    }
    return <Table.Cell style={cell.style}>{content}</Table.Cell>;
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
});

export default connect(mapStateToProps, { updateDocumentStatusRequest })(
  OrdersCell,
);
