import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ActionButton } from '../../config/components.config';
import MovementModalView from '../../app/Stock/Movements/MovementModal/MovementModalView/MovementModalView';

class ButtonsMovements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalView: false,
    };
  }

  render() {
    const { openModalView } = this.state;
    return (
      <>
        <div className={'btn-action-container'}>
          <ActionButton
            icon={'view'}
            onClick={() => this.setState({ openModalView: true })}
          />
        </div>

        <MovementModalView
          open={openModalView}
          idMovement={this.props.idMovement}
          close={() => this.setState({ openModalView: false })}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
});

export default connect(mapStateToProps, {})(ButtonsMovements);
