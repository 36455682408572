import React, { Component } from 'react';
import Select from 'react-select';

class SearchSelect extends Component {
  handleInputChange = (value) => {
    this.props.handleChangeValue(value);
  }

  render() {
    const { options, changeSelect, inputValue, isLoading } = this.props;
    return (
      <div>
        <Select
          styles={{
            // Fixes the overlapping problem of the component
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
          fullWidth
          hideSelectedOptions
          inputValue={inputValue}
          onInputChange={this.handleInputChange}
          onChange={changeSelect}
          isLoading={isLoading}
          options={options}
        />
      </div>
    );
  }
}

export default SearchSelect;
