import React, { Component } from 'react';
import { connect } from 'react-redux';
import IntlMessages from '../../../util/IntlMessages';
import InventoryExportModal from './InventoryModal/InventoryExportModal/InventoryExportModal';
import InventoryImportModal from './InventoryModal/InventoryImportModal/InventoryImportModal';
// import { ButtonComponent } from '../../../config/components.config';
import { getInventoryListRequest } from '../../../config/actions.config';
import InventoryExportModalContent from './InventoryModal/InventoryExportModal/InventoryExportModalContent';
import InventoryImportModalContent from './InventoryModal/InventoryImportModal/InventoryImportModalContent';

class Inventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalExportInventory: false,
      openModalImportInventory: false,
    };
  }

  componentDidMount() {
    // La requete pour récupérer la liste des inventaires.
    // this.props.getInventoryListRequest(this.props.idProject, {limit: 50,offset: 0});
  }

  render() {
    const { openModalExportInventory, openModalImportInventory } = this.state;

    return (
      <div className="app-wrapper div-wrapper-title">
        <div className="row headerAlign">
          <div className="col-lg-8 col-md-8 col-sm-6 page-title">
            <IntlMessages id="inventory.title" />
          </div>
          {/* Bouton a afficher quand les tableau des inventaires est pret */}
          {/* <div className="col-lg-2 col-md-2 col-sm-3">
            <ButtonComponent
              id="exportInventory"
              onClick={() => this.setState({ openModalExportInventory: true })}
            >
              <IntlMessages id="inventory.button.export" />
            </ButtonComponent>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-3">
            <ButtonComponent
              id="importInventory"
              onClick={() => this.setState({ openModalImportInventory: true })}
            >
              <IntlMessages id="inventory.button.import" />
            </ButtonComponent>
          </div> */}
        </div>

        <div className="row">
          <div className="col-12">
            {/* La partie ci dessous est temporaire en attendant le tableau des inventaires */}
            <div className="jr-card">
              <InventoryExportModalContent
                posList={this.state.posList}
                itemList={this.state.itemList}
              />
            </div>
            <div className="jr-card">
              <InventoryImportModalContent />
            </div>
            {/*---------------------Jusque ici---------------------*/}

            {openModalExportInventory && (
              <InventoryExportModal
                open={openModalExportInventory}
                close={() => this.setState({ openModalExportInventory: false })}
              />
            )}
            {openModalImportInventory && (
              <InventoryImportModal
                open={openModalImportInventory}
                close={() => this.setState({ openModalImportInventory: false })}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  inventoryList: state.sdk.inventory.inventoryList,
});

export default connect(mapStateToProps, {
  getInventoryListRequest,
})(Inventory);
