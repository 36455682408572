import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Loading,
  UnderlineTitle,
  MainButton,
} from '../../../../config/components.config';
import { getItemPosByItemRequest } from '../../../../config/actions.config';
import IntlMessages from '../../../../util/IntlMessages';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  SelectionState,
  CustomPaging,
  SearchState,
  FilteringState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import ColumnCell from '../../../../components/Table/Columns/ColumnCell';
import { NotificationManager } from 'react-notifications';
import NoData from '../../../../components/Table/NoData/NoData';
import ItemPosCell from '../../../../components/Table/Cells/ItemPos/ItemPosCell';
import ItemPosModalCreation from '../../../Stock/ItemPos/ItemPosModal/ItemPosModalCreation/ItemPosModalCreation';
import { tableColumnExtensionsItemPos } from '../../../../components/Table/Columns/ShowColumns';
import FilterCell from '../../../../components/Table/Cells/ItemPos/FiltersCell';

const columns = [
  {
    name: 'pos',
    title: <IntlMessages id="itemPos.table.column.pos" />,
  },
  {
    name: 'reorderLevel',
    title: <IntlMessages id="itemPos.table.column.qtyAlert" />,
  },
  {
    name: 'reorderQuantity',
    title: <IntlMessages id="itemPos.table.column.qtyReorder" />,
  },
  {
    name: 'quantityInStock',
    title:  <IntlMessages id="itemPos.table.column.qtyNow" />,
  },
  {
    name: 'quantityInOrder',
    title:  <IntlMessages id="itemPos.table.column.qtyCommand" />,
  },
  {
    name: 'quantityInDelivery',
    title: <IntlMessages id="itemPos.table.column.qtyDelivery" />,
  },
  {
    name: 'buttons',
    title: <IntlMessages id="itemPos.table.column.actions" />,
  },
];

class ItemStock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pageSizes: [10, 20, 50, 100, 0],
      currentPage: 0,
      currentPageSize: 10,
      filters: [],
      openModalItemPos: false,
    };
    this.params = {
      limit: this.state.currentPageSize,
      page: this.state.currentPage + 1,
    };
  }

  componentDidMount() {
    this.props.getItemPosByItemRequest(this.props.id, this.params);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.itemPosByItemList !== this.props.itemPosByItemList) {
      if (this.props.itemPosByItemList.loading) {
        this.setState({ loading: true });
      }
      if (!this.props.itemPosByItemList.loading && !this.props.itemPosByItemList.error) {
        this.setState({
          loading: false,
          objectCount: this.props.itemPosByItemList.meta.object_count,
        });
      }
    }

    if (prevProps.updatedItemPos !== this.props.updatedItemPos) {
      if (
        this.props.updatedItemPos.itemPos !== '' &&
        !this.props.updatedItemPos.loading &&
        !this.props.updatedItemPos.error
      ) {
        NotificationManager.success(
          <IntlMessages id='itemPos.success.message.updated' />
        );
        this.props.getItemPosByItemRequest(this.props.id, this.params);
      }
    }

    if (prevProps.newItemPos !== this.props.newItemPos) {
      if (
        this.props.newItemPos.itemPos !== '' &&
        !this.props.newItemPos.loading &&
        !this.props.newItemPos.error
      ) {
        NotificationManager.success(
          <IntlMessages id='itemPos.success.message.item.added' />
        );
        this.props.getItemPosByItemRequest(this.props.id, this.params);
      }
    }

    if (prevProps.newItemPosStock !== this.props.newItemPosStock) {
      if (
        this.props.newItemPosStock.itemPos !== '' &&
        !this.props.newItemPosStock.loading &&
        !this.props.newItemPosStock.error
      ) {
        NotificationManager.success(
          <IntlMessages id='itemPos.success.message.updated' />
        );
        this.props.getItemPosByItemRequest(this.props.id, this.params);
      }
    }

    if (prevProps.newItemTransfert !== this.props.newItemTransfert) {
      if (
        this.props.newItemTransfert.itemTransfert !== '' &&
        !this.props.newItemTransfert.loading &&
        !this.props.newItemTransfert.error
      ) {
        NotificationManager.success(
          <IntlMessages id='itemPos.success.message.updated' />
        );
        this.props.getItemPosByItemRequest(this.props.id, this.params);
      }
    }

    if (prevProps.deletedItemPos !== this.props.deletedItemPos) {
      if (
        this.props.deletedItemPos.itemPos !== '' &&
        !this.props.deletedItemPos.loading &&
        !this.props.deletedItemPos.error
      ) {
        NotificationManager.success(
          <IntlMessages id='itemPos.success.message.deleted' />
        );
        this.props.getItemPosByItemRequest(this.props.id, this.params);
      }
    }
  }

  setCurrentPage = (page) => {
    this.setState({ currentPage: page }, () => {
      this.props.getItemPosByItemRequest(this.props.id, {
        limit: this.state.currentPageSize,
        page: page + 1,
      });
    });
  };

  setPageSize = (pageSize) => {
    this.setState({ currentPageSize: pageSize }, () => {
      this.props.getItemPosByItemRequest(this.props.id, {
        limit: pageSize,
        page: 1,
      });
    });
  };

  onFilter = (filter) => {
    this.setState({ filters: filter });
    filter.forEach((data) => {
      if (data.value && data.value !== '' && data.value !== 'all') {
        this.params['pos.name'] = {};
        this.params['pos.name'].operation = 'contain';
        this.params['pos.name'].value = data.value;
      } else {
        delete this.params['pos.name'];
      }
    });

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.getItemPosByItemRequest(this.props.id, this.params);
    }, 200);
  };

  render() {
    const {
      loading,
      currentPageSize,
      currentPage,
      pageSizes,
      objectCount,
      openModalItemPos,
      filters,
    } = this.state;
    const { itemPosByItemList } = this.props;

    const row = [];
    if (itemPosByItemList && itemPosByItemList.itemPos) {
      itemPosByItemList.itemPos.forEach((data, i) => {
        row[i] = {
          itemPos: data,
          item: this.props.item,
          pos: data.pos,
          reorderLevel: data.reorderLevel ? data.reorderLevel : 0,
          reorderQuantity: data.reorderQuantity ? data.reorderQuantity : 0,
          quantityInStock: data.quantityInStock ? data.quantityInStock : 0,
          quantityInOrder: data.quantityInOrder ? data.quantityInOrder : 0,
          quantityInDelivery: data.quantityInDelivery
            ? data.quantityInDelivery
            : 0,
        };
      });
    }

    return (
      <div>
        <div className='jr-card'>
          <UnderlineTitle
            title={<IntlMessages id='item.details.card.stock.title' />}
          >
            <div>
              <MainButton
                onClick={() => this.setState({ openModalItemPos: true })}
                id="addItemPos"
              >
                <IntlMessages id='item.details.button.add.itempos' />
              </MainButton>
            </div>
          </UnderlineTitle>
          <Grid rows={row} columns={columns}>
            <SelectionState />
            <PagingState
              pageSize={currentPageSize}
              currentPage={currentPage}
              onCurrentPageChange={this.setCurrentPage}
              onPageSizeChange={this.setPageSize}
            />
            <SortingState />
            <IntegratedSorting />
            <SearchState defaultValue='' />
            <FilteringState onFiltersChange={this.onFilter} filters={filters} />
            <Table
              columnExtensions={tableColumnExtensionsItemPos}
              cellComponent={ItemPosCell}
              noDataCellComponent={NoData}
            />
            <CustomPaging totalCount={objectCount} />
            <TableHeaderRow cellComponent={ColumnCell} />
            <TableFilterRow cellComponent={FilterCell} />
            <PagingPanel pageSizes={pageSizes} />
          </Grid>

          {loading && <Loading />}

          <ItemPosModalCreation
            item
            id={this.props.id}
            open={openModalItemPos}
            close={() => this.setState({ openModalItemPos: false })}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  itemPosByItemList: state.sdk.itemPos.itemPosByItemList,
  updatedItemPos: state.sdk.itemPos.updatedItemPos,
  newItemPos: state.sdk.items.newItemPos,
  newItemPosStock: state.sdk.itemPos.newItemPosStock,
  newItemTransfert: state.sdk.items.newItemTransfert,
  deletedItemPos: state.sdk.itemPos.deletedItemPos,
});

export default connect(mapStateToProps, { getItemPosByItemRequest })(ItemStock);
