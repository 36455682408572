import React, { Component } from 'react';
import { connect } from 'react-redux';
import ColumnCell from '../../../components/Table/Columns/ColumnCell';
import NoData from '../../../components/Table/NoData/NoData';
import PosCell from '../../../components/Table/Cells/Pos/PosCell';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  SelectionState,
  CustomPaging,
  SearchState,
  FilteringState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableColumnVisibility,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import { tableColumnExtensionsPos } from '../../../components/Table/Columns/ShowColumns';
import { Loading } from '../../../config/components.config';
import { NotificationManager } from 'react-notifications';
import IntlMessages from '../../../util/IntlMessages';
import {
  getPosByProjectRequest,
  getCategoriesByProjectRequest,
  setGlobalError,
} from '../../../config/actions.config';
import FilterCell from '../../../components/Table/Cells/Pos/FiltersCell';

class PosTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pageSizes: [10, 20, 50, 100, 0],
      filters: [],
    };
    this.parameters = {
      withStock: true,
    };
  }

  componentDidMount() {
    if (!localStorage.getItem('paramsPos')) {
      localStorage.setItem(
        'paramsPos',
        JSON.stringify({
          limit: 20,
          page: 1,
          withStock: true,
        })
      );
    }
    this.parameters = JSON.parse(localStorage.getItem('paramsPos'));

    this.props.getPosByProjectRequest(this.props.idProject, this.parameters);
    this.props.getCategoriesByProjectRequest(this.props.idProject, {
      type: 'pos',
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.posList !== this.props.posList) {
      if (this.props.posList.loading) {
        this.setState({ loading: true });
      }
      if (
        this.props.posList.pos !== '' &&
        !this.props.posList.loading &&
        !this.props.posList.error
      ) {
        this.setState({
          loading: false,
          objectCount: this.props.posList.meta.object_count,
        });
      }
    }

    if (prevProps.newPos !== this.props.newPos) {
      if (
        this.props.newPos.pos !== '' &&
        !this.props.newPos.loading &&
        !this.props.newPos.error
      ) {
        NotificationManager.success(
          <IntlMessages id='pos.success.message.added' />
        );
        this.props.getPosByProjectRequest(
          this.props.idProject,
          JSON.parse(localStorage.getItem('paramsPos'))
        );
      }
    }

    if (prevProps.updatedPos !== this.props.updatedPos) {
      if (
        this.props.updatedPos.pos !== '' &&
        !this.props.updatedPos.loading &&
        !this.props.updatedPos.error
      ) {
        NotificationManager.success(
          <IntlMessages id='pos.success.message.updated' />
        );
        this.props.getPosByProjectRequest(
          this.props.idProject,
          JSON.parse(localStorage.getItem('paramsPos'))
        );
      }
    }

    if (prevProps.deletedPos !== this.props.deletedPos) {
      if (
        this.props.deletedPos.pos !== '' &&
        !this.props.deletedPos.loading &&
        !this.props.deletedPos.error
      ) {
        NotificationManager.success(
          <IntlMessages id='pos.success.message.deleted' />
        );
        this.props.getPosByProjectRequest(
          this.props.idProject,
          JSON.parse(localStorage.getItem('paramsPos'))
        );
      }
      if (this.props.deletedPos.error) {
        NotificationManager.error(
          <IntlMessages id='pos.error.message.delete' />
        );
      }
    }

    if (prevProps.globalError !== this.props.globalError) {
      if (this.props.globalError === true) {
        NotificationManager.error(
          <IntlMessages id='appModule.message.error' />
        );
        this.props.setGlobalError(false);
      }
    }
  }

  setCurrentPage = (page) => {
    this.parameters = JSON.parse(localStorage.getItem('paramsPos'));
    this.parameters.page = page + 1;

    this.props.getPosByProjectRequest(this.props.idProject, this.parameters);
    localStorage.setItem('paramsPos', JSON.stringify(this.parameters));
  };

  setPageSize = (pageSize) => {
    this.parameters = JSON.parse(localStorage.getItem('paramsPos'));
    this.parameters.limit = pageSize;

    this.props.getPosByProjectRequest(this.props.idProject, this.parameters);
    localStorage.setItem('paramsPos', JSON.stringify(this.parameters));
  };

  onFilter = (filter) => {
    this.parameters = JSON.parse(localStorage.getItem('paramsPos'));
    this.parameters.page = 1;
    this.setState({ filters: filter });
    filter.forEach((data) => {
      if (data.value && data.value !== '' && data.value !== 'all') {
        if (data.columnName === 'warehouse') {
          this.parameters[data.columnName] = {};
          this.parameters[data.columnName].operation = 'eq';
          this.parameters[data.columnName].value = data.value;
        } else if (data.columnName === 'posCategory') {
          this.parameters['posCategory.category.id'] = {};
          this.parameters['posCategory.category.id'].operation = 'contain';
          this.parameters['posCategory.category.id'].value = data.value;
        } else {
          this.parameters[data.columnName] = {};
          this.parameters[data.columnName].operation = 'contain';
          this.parameters[data.columnName].value = data.value;
        }
      } else {
        if (data.columnName === 'posCategory') {
          delete this.parameters['posCategory.category.id'];
        } else {
          delete this.parameters[data.columnName];
        }
      }
    });

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.getPosByProjectRequest(this.props.idProject, this.parameters);
    }, 200);

    localStorage.setItem('paramsPos', JSON.stringify(this.parameters));
  };

  render() {
    const {
      loading,
      pageSizes,
      objectCount,
      filters,
    } = this.state;
    const { posList } = this.props;

    const row = [];
    if (posList && posList.pos) {
      posList.pos.forEach((data, i) => {
        row[i] = {
          pos: data,
          name: data.name,
          posCategory: data.posCategory,
          warehouse: data.warehouse,
        };
      });
    }

    const columnsPos = [
      { name: 'name', title: <IntlMessages id='pos.table.name' /> },
      {
        name: 'posCategory',
        title: <IntlMessages id='pos.table.category' />,
        categories: this.props.categoriesList.categories
          ? this.props.categoriesList.categories
          : [],
      },
      { name: 'warehouse', title: <IntlMessages id='pos.table.warehouse' /> },
      { name: 'buttons', title: <IntlMessages id='pos.table.actions' /> },
    ];

    return (
      <div>
        <Grid rows={row} columns={columnsPos}>
          <SelectionState />
          <PagingState
            pageSize={
              JSON.parse(localStorage.getItem('paramsPos')) &&
              JSON.parse(localStorage.getItem('paramsPos'))['limit']
                ? JSON.parse(localStorage.getItem('paramsPos'))['limit']
                : 20
            }
            currentPage={
              JSON.parse(localStorage.getItem('paramsPos')) &&
              JSON.parse(localStorage.getItem('paramsPos'))['page']
                ? JSON.parse(localStorage.getItem('paramsPos'))['page'] - 1
                : 1
            }
            onCurrentPageChange={this.setCurrentPage}
            onPageSizeChange={this.setPageSize}
          />
          <SortingState />
          <IntegratedSorting />
          <SearchState defaultValue='' />
          <FilteringState onFiltersChange={this.onFilter} filters={filters} />
          <Table
            columnExtensions={tableColumnExtensionsPos}
            cellComponent={PosCell}
            noDataCellComponent={NoData}
          />
          <CustomPaging totalCount={objectCount} />
          <TableColumnVisibility />
          <TableHeaderRow cellComponent={ColumnCell} />
          <TableFilterRow cellComponent={FilterCell} />
          <PagingPanel pageSizes={pageSizes} />
        </Grid>
        {loading && <Loading />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  posList: state.sdk.pos.posList,
  newPos: state.sdk.pos.newPos,
  updatedPos: state.sdk.pos.updatedPos,
  deletedPos: state.sdk.pos.deletedPos,
  categoriesList: state.sdk.categories.categoriesList,
  globalError: state.sdk.auth.globalError,
});

export default connect(mapStateToProps, {
  getPosByProjectRequest,
  getCategoriesByProjectRequest,
  setGlobalError,
})(PosTable);
