// Example
import { all } from 'redux-saga/effects';
import rootSaga from '../../config/sagas.config';
// import accommodationUnitsSaga from './AccommodationUnits';

export default function* appSaga() {
  yield all([
    // accommodationUnitsSaga(),
    rootSaga(),
  ]);
}
