import saMessages from '../locales/en/en.json';
import enMessages from '../locales/en/global_en.json';

const result = {};
let key;

for (key in enMessages) {
  if (Object.prototype.hasOwnProperty.call(enMessages, key)) {
    result[key] = enMessages[key];
  }
}

for (key in saMessages) {
  if (Object.prototype.hasOwnProperty.call(saMessages, key)) {
    result[key] = saMessages[key];
  }
}

const enLang = {
  messages: {
    ...result,
  },
  locale: 'en',
};
export default enLang;
