import React from 'react';
import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../../../util/IntlMessages';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import '../cell.css';

const style = {
  paddingBottom: 15,
  paddingRight: 15,
  height: 65,
  width: '100%',
  paddingLeft: 0,
};

const stockStatus = [
  { value: 'all', label: <IntlMessages id="appModule.all" /> },
  {
    value: 'stock',
    label: <IntlMessages id="movements.table.stock.status.stock" />,
  },
  {
    value: 'booked',
    label: <IntlMessages id="movements.table.stock.status.booked" />,
  },
  {
    value: 'consigned',
    label: <IntlMessages id="movements.table.stock.status.consigned" />,
  },
  {
    value: 'returned',
    label: <IntlMessages id="movements.table.stock.status.returned" />,
  },
  {
    value: 'repair',
    label: <IntlMessages id="movements.table.stock.status.repair" />,
  },
  {
    value: 'broken',
    label: <IntlMessages id="movements.table.stock.status.broken" />,
  },
  {
    value: 'lost',
    label: <IntlMessages id="movements.table.stock.status.lost" />,
  },
  {
    value: 'stolen',
    label: <IntlMessages id="movements.table.stock.status.stolen" />,
  },
  {
    value: 'quarantine',
    label: <IntlMessages id="movements.table.stock.status.quarantine" />,
  },
];

const typeStatus = [
  { value: 'all', label: <IntlMessages id="appModule.all" /> },
  {
    value: 'manual',
    label: <IntlMessages id="movements.table.type.status.manual" />,
  },
  {
    value: 'sale',
    label: <IntlMessages id="movements.table.type.status.sale" />,
  },
  {
    value: 'supplier_delivery',
    label: <IntlMessages id="movements.table.type.status.supplier_delivery" />,
  },
  {
    value: 'internal_delivery',
    label: <IntlMessages id="movements.table.type.status.internal_delivery" />,
  },
  {
    value: 'inventory',
    label: <IntlMessages id="movements.table.type.status.inventory" />,
  },
  {
    value: 'adjustment',
    label: <IntlMessages id="movements.table.type.status.adjustment" />,
  },
];

const FilterCell = ({ column, filter, onFilter, ...restProps }) => {
  const filterValue = filter && filter.value;
  const onChange = (e) =>
    onFilter({ columnName: column.name, value: e.target.value });

  const handleReset = () => {
    onFilter({ columnName: column.name, value: '' });
  };

  // const onChangeDate = (e) => {
  //   if (e === null) {
  //     onFilter({ columnName: column.name, value: null });
  //   } else {
  //     let dateStart = new Date(e);
  //     dateStart = new Date(
  //       dateStart.getFullYear(),
  //       dateStart.getMonth(),
  //       dateStart.getDate(),
  //       0,
  //       0,
  //       0
  //     );
  //     let dateEnd = new Date(e);
  //     dateEnd = new Date(
  //       dateEnd.getFullYear(),
  //       dateEnd.getMonth(),
  //       dateEnd.getDate(),
  //       23,
  //       59,
  //       59
  //     );
  //     const dateFormated = formatDatePicker(new Date(e));
  //     onFilter({
  //       columnName: column.name,
  //       value: dateFormated,
  //       values: {
  //         dateStart: formatDatePicker(dateStart),
  //         dateEnd: formatDatePicker(dateEnd),
  //       },
  //     });
  //   }
  // };

  let content;
  if (column.name === 'item' || column.name === 'pos') {
    content = (
      <TableFilterRow.Cell
        {...restProps}
        className="tableFilterRow"
        column={column}
      >
        <TextField
          label={<IntlMessages id="appModule.filter" />}
          value={filterValue || ''}
          onChange={onChange}
          InputProps={{
            endAdornment: filterValue ? (
              <IconButton onClick={handleReset} className="p-0 m-0">
                <Close />
              </IconButton>
            ) : (
              <IconButton />
            ),
          }}
        />
      </TableFilterRow.Cell>
    );
  } else if (column.name === 'stockStatus') {
    content = (
      <TableFilterRow.Cell {...restProps} style={style} column={column}>
        <FormControl className="fullWidth">
          <InputLabel id="demo-simple-select-label" className="fullWidth">
            <IntlMessages id="appModule.filter" />
          </InputLabel>
          <Select
            value={filterValue ? filterValue : 'all'}
            autoWidth
            onChange={onChange}
            className="fullWidth"
          >
            {stockStatus.map((element, i) => {
              return (
                <MenuItem key={i} value={element.value}>
                  {element.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </TableFilterRow.Cell>
    );
  } else if (column.name === 'type') {
    content = (
      <TableFilterRow.Cell {...restProps} style={style} column={column}>
        <FormControl className="fullWidth">
          <InputLabel id="demo-simple-select-label" className="fullWidth">
            <IntlMessages id="appModule.filter" />
          </InputLabel>
          <Select
            value={filterValue ? filterValue : 'all'}
            autoWidth
            onChange={onChange}
            className="fullWidth"
          >
            {typeStatus.map((element, i) => {
              return (
                <MenuItem key={i} value={element.value}>
                  {element.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </TableFilterRow.Cell>
    );
    // } else if (column.name === 'datetime') {
    //   content = (
    //     <TableFilterRow.Cell
    //       {...restProps}
    //       className='tableFilterRow'
    //       column={column}
    //     >
    //       <DatePicker
    //         label={<IntlMessages id='appModule.date' />}
    //         value={filterValue}
    //         className='fullWidth'
    //         clearable
    //         onChange={onChangeDate}
    //         format='dd/MM/yyyy'
    //         views={['year', 'month', 'day']}
    //         openTo='year'
    //         mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
    //       />
    //       {filterValue && (
    //         <IconButton
    //           style={{ marginBottom: '-25px' }}
    //           edge='end'
    //           size='small'
    //           onClick={() => onChangeDate(null)}
    //         >
    //           <Close />
    //         </IconButton>
    //       )}
    //     </TableFilterRow.Cell>
    //   );
  } else {
    content = (
      <TableFilterRow.Cell
        {...restProps}
        className="tableFilterRow"
        column={column}
      />
    );
  }
  return content;
};

export default FilterCell;
