import React from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import {
  setUserCurrentProjectRequest,
  getProjectDetailsRequest,
} from '../../../config/actions.config';
import CustomScrollbars from '../../../util/CustomScrollbars';
import IntlMessages from '../../../util/IntlMessages';

class DropdownMenuProject extends React.Component {
  constructor() {
    super();
    this.state = {
      inputValue: ' ',
    };
  }

  selectProject = (id, name) => {
    this.props.setUserCurrentProjectRequest(id, name);
    this.props.getProjectDetailsRequest(id);
    localStorage.setItem('id_project', id);
    localStorage.setItem('name_project', name);
    this.props.selected();
  };

  handleInputChange = (newValue) => {
    const inputValue = newValue;
    this.setState({ inputValue });
    return inputValue;
  };

  chooseProject = (project) => {
    this.props.setUserCurrentProjectRequest(project.id, project.label);
    this.props.getProjectDetailsRequest(project.id);
    localStorage.setItem('id_project', project.id);
    localStorage.setItem('name_project', project.label);
    this.props.selected();
  };

  render() {
    const filterColors = (inputValue) =>
      this.props.listProject.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    const loadOptions = (inputValue, callback) => {
      setTimeout(() => {
        callback(filterColors(inputValue));
      }, 1000);
    };

    let projects = '';
    if (this.props.listProject.length > 0) {
      projects = this.props.listProject.map((data, i) => (
        <li className="media" key={i}>
          <div className="media-body">
            <div className="d-flex justify-content-between align-items-center">
              <Button onClick={() => this.selectProject(data.id, data.label)}>
                <h5
                  className="text-capitalize user-name mb-0"
                  style={{ color: '#1694e3' }}
                >
                  {data.label}
                </h5>
              </Button>
            </div>
          </div>
        </li>
      ));
    }

    const nbProject = this.props.listProject
      ? this.props.listProject.length
      : 0;

    return (
      <div>
        {nbProject > 4 && (
          <AsyncSelect
            cacheOptions
            placeholder={<IntlMessages id="appModule.project.search" />}
            loadOptions={loadOptions}
            defaultOptions
            onChange={this.chooseProject}
            onInputChange={this.handleInputChange}
          />
        )}
        <CustomScrollbars
          className="messages-list scrollbar"
          style={{ height: 280 }}
        >
          <div className="d-flex h-100 justify-content-center">
            <ul className="list-unstyled fullWidth">{projects}</ul>
          </div>
        </CustomScrollbars>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user,
});

export default connect(mapStateToProps, {
  setUserCurrentProjectRequest,
  getProjectDetailsRequest,
})(DropdownMenuProject);
