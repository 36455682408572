import React, { Component } from 'react';
import { connect } from 'react-redux';
import IntlMessages from '../../../../util/IntlMessages';
import SupplierOrderSupplier from './DetailCards/SupplierOrderSupplier';
import SupplierOrderRequestor from './DetailCards/SupplierOrderRequestor';
import { createOrderDocumentSupplierRequest } from '../../../../config/actions.config';
import Button from '@material-ui/core/Button';
import { Redirect } from 'react-router';
import '../../index.css';

class SupplierOrderCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      orderDocumentId: null,
      orderdocument: {
        requestorPos: null,
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.newOrderDocumentSupplier !== this.props.newOrderDocumentSupplier
    ) {
      if (
        this.props.newOrderDocumentSupplier.orderDocument !== '' &&
        !this.props.newOrderDocumentSupplier.loading &&
        !this.props.newOrderDocumentSupplier.error
      ) {
        this.setState({
          redirect: true,
          orderDocumentId: this.props.newOrderDocumentSupplier.orderDocument.id,
        });
      }
    }
  }

  handeChange = (name, value) => {
    console.log(name, value);
    if (name === 'supplier') {
      this.setState({ [name]: value });
    } else {
      let orderdocument = this.state.orderdocument;
      orderdocument[name] = value;

      this.setState({ orderdocument: orderdocument });
    }
  };

  handleSubmit = () => {
    this.props.createOrderDocumentSupplierRequest(
      this.state.supplier,
      this.state.orderdocument
    );
  };

  render() {
    if (this.state.redirect) {
      return (
        <Redirect to={'show-supplier-order/' + this.state.orderDocumentId} />
      );
    } else {
      return (
        <div className='app-wrapper div-wrapper-title'>
          <div className='row headerAlign'>
            <div className='col-12 page-title'>
              <IntlMessages id='orders.supplier.create.title' />
            </div>
          </div>
          <div>
            <div className='row'>
              <div className='col-12 col-md-6 detailCard'>
                <SupplierOrderSupplier handeChange={this.handeChange} />
              </div>
              <div className='col-12 col-md-6 detailCard'>
                <SupplierOrderRequestor handeChange={this.handeChange} />
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <div className='d-flex justify-content-end'>
                  <Button
                    disabled={
                      !(
                        this.state.supplier &&
                        this.state.orderdocument.requestorPos
                      )
                    }
                    variant='outlined'
                    onClick={this.handleSubmit}
                    color='primary'
                  >
                    <IntlMessages id='items.modal.button.save' />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  newOrderDocumentSupplier: state.sdk.suppliers.newOrderDocumentSupplier,
});

export default connect(mapStateToProps, {
  createOrderDocumentSupplierRequest,
})(SupplierOrderCreate);
