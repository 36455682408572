import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { formatMoney } from '../../../../config/services.config';
import '../cell.css';

const OrderItemCell = (cell) => {
  let content = cell.value;

  if (
    cell.column.name === 'priceWithoutVat' ||
    cell.column.name === 'totalWithoutVat'
  ) {
    content = (
      <div align='center'>
        {formatMoney(cell.row.currency?.isoCode, cell.row.locale, cell.value)}
      </div>
    );
  } else if (cell.column.name === 'vatrate') {
    content = (
      <div align='center'>
        {cell.row.vatrate ? cell.row.vatrate.rate  + '%' : '0%'}
      </div>
    );
  } else if (cell.column.name === 'item') {
    content = <div align='left'>{cell.row.item}</div>;
  } else if (cell.column.name === 'quantityAlreadyDelivered') {
    content = <div align='center'>{cell.row.quantityAlreadyDelivered}</div>;
  } else if (
    cell.column.name === 'quantity' ||
    cell.column.name === 'packUnitName'
  ) {
    content = <div align='center'>{cell.value}</div>;
  }

  return <Table.Cell style={cell.style}>{content}</Table.Cell>;
};

export default OrderItemCell;
