import React from 'react';
import { FaTruck, FaHandPaper } from 'react-icons/fa';
import { HiAdjustments } from 'react-icons/hi';
import { ShoppingBasket } from '@material-ui/icons';
import { ListAlt } from '@material-ui/icons';

export const movementIcon = (movement) => {
  switch (movement) {
    case 'manual': {
      return <FaHandPaper />;
    }

    case 'sale': {
      return <ShoppingBasket />;
    }

    case 'supplier_delivery': {
      return <FaTruck />;
    }

    case 'internal_delivery': {
      return <FaTruck />;
    }

    case 'inventory': {
      return <ListAlt />;
    }

    case 'adjustment': {
      return <HiAdjustments />;
    }

    default: {
      return '';
    }
  }
};
