import React, { Component } from 'react';
import { connect } from 'react-redux';
import IntlMessages from '../../../../util/IntlMessages';
import { Grid, Table } from '@devexpress/dx-react-grid-material-ui';
import { UnderlineTitle } from '../../../../config/components.config';
import NoData from '../../../../components/Table/NoData/NoData';
import { getPosOrderDocumentsRequest } from '../../../../config/actions.config';
import PosOrdersCell from '../../../../components/Table/Cells/Pos/PosOrdersCell';

const columns = [{ name: 'reference' }];

class PosOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.params = {
      limit: 10,
    };
  }

  componentDidMount() {
    this.props.getPosOrderDocumentsRequest(this.props.id, this.params);
  }

  render() {
    const { posOrderDocuments } = this.props;
    const row = [];
    if (posOrderDocuments && posOrderDocuments.orderDocuments !== '') {
      posOrderDocuments.orderDocuments.forEach((data, i) => {
        row[i] = {
          id: data.id,
          reference: data.reference,
        };
      });
    }
    return (
      <>
        <div className='jr-card'>
          <UnderlineTitle
            title={<IntlMessages id='pos.detail.card.orders.title' />}
          />
          <div className='containerTable'>
            <Grid rows={row} columns={columns}>
              <Table
                noDataCellComponent={NoData}
                cellComponent={PosOrdersCell}
              />
            </Grid>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  // orderDocumentsSupplierList: state.sdk.suppliers.orderDocumentsSupplierList,
  posOrderDocuments: state.sdk.posDocuments.posOrderDocuments,
});

export default connect(mapStateToProps, { getPosOrderDocumentsRequest })(
  PosOrders,
);
