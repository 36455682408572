import React, { Component } from 'react';
import { connect } from 'react-redux';
import IntlMessages from '../../../util/IntlMessages';
import { UnderlineTitle, Loading } from '../../../config/components.config';
import '../index.css';

class DetailOrderVendor extends Component {
  render() {
    return (
      <>
        <div className='jr-card fullWidth'>
          <UnderlineTitle
            title={<IntlMessages id='orders.detail.card.vendor.title' />}
          />

          {this.props.vendorPos && (
            <>
              <div className={'information'}>
                <div className='mr-2'>
                  <b>
                    <IntlMessages id='appModule.last.name' />:
                  </b>
                </div>
                <div>{this.props.vendorPos.name}</div>
              </div>

              <div className={'information'}>
                <div className='mr-2'>
                  <b>
                    <IntlMessages id='pos.table.warehouse' />:
                  </b>
                </div>
                {this.props.vendorPos.warehouse ? (
                  <div>
                    <IntlMessages id='stock.warehouse' />
                  </div>
                ) : (
                  <div>
                    <IntlMessages id='stock.store' />
                  </div>
                )}
              </div>
            </>
          )}

          {this.props.loading && <Loading />}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
});

export default connect(mapStateToProps)(DetailOrderVendor);
