import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import '../cell.css';
import { FaArrowRight } from 'react-icons/fa';
import MovementModalView from '../../../../app/Stock/Movements/MovementModal/MovementModalView/MovementModalView';
import { ImArrowDownRight2, ImArrowUpRight2 } from 'react-icons/im';
import { movementIcon } from '../MovementsIcon';

export const ItemMovementsCell = (cell) => <ItemMovementsConst cell={cell} />;

class ItemMovementsConst extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalView: false,
    };
  }

  render() {
    const { cell } = this.props;

    let content = cell.value;

    if (cell.column.name === 'movements') {
      content = (
        <>
          <div>
            {movementIcon(cell.row.type)} {cell.row.pos}
          </div>
        </>
      );
    } else if (cell.column.name === 'quantity') {
      content = (
        <div>
          {content}
          {cell.row.quantity < 0 ? (
            <ImArrowDownRight2 style={{ color: 'red' }} />
          ) : (
            <ImArrowUpRight2 style={{ color: 'green' }} />
          )}
        </div>
      );
    } else if (cell.column.name === 'icon') {
      content = (
        <>
          <div onClick={() => this.setState({ openModalView: true })}>
            <FaArrowRight className='arrowRightMovements' />
          </div>

          <MovementModalView
            open={this.state.openModalView}
            idMovement={cell.row.id}
            close={() => this.setState({ openModalView: false })}
          />
        </>
      );
    }
    return <Table.Cell style={cell.style}>{content}</Table.Cell>;
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
});

export default connect(mapStateToProps)(ItemMovementsCell);
