import React, { Component } from 'react';
import { connect } from 'react-redux';
import IntlMessages from '../../../../../util/IntlMessages';
import {
  UnderlineTitle,
  Loading,
} from '../../../../../config/components.config';
import { getSuppliersByOrganizationRequest } from '../../../../../config/actions.config';
import SearchSelect from '../../../../../components/Select/SearchSelect/SearchSelect';
import '../../../index.css';

class SupplierOrderSupplier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      supplierList: null,
      supplier: null,
      valueSupplierReseach: '',
    };
  }

  componentDidMount() {
    if (this.props.organisation) {
      this.props.getSuppliersByOrganizationRequest(this.props.organisation.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.organisation !== this.props.organisation) {
      if (this.props.organisation) {
        this.props.getSuppliersByOrganizationRequest(
          this.props.organisation.id);
      }
    }

    if (
      prevProps.suppliersByOrganizationList !==
      this.props.suppliersByOrganizationList
    ) {
      if (this.props.suppliersByOrganizationList.loading) {
        this.setState({ loading: true });
      }
      if (
        this.props.suppliersByOrganizationList.suppliers !== '' &&
        !this.props.suppliersByOrganizationList.loading &&
        !this.props.suppliersByOrganizationList.error
      ) {
        this.setState({
          loading: false,
          supplierList: this.props.suppliersByOrganizationList.suppliers,
        });
      }
    }
  }

  changeSupplier = (event) => {
    console.log(event);
    this.setState({ supplier: event });
    this.props.handeChange('supplier', event.value);
  };

  handleChangeSupplierValue = (value) => {
    console.log(value);
    this.props.getSuppliersByOrganizationRequest(this.props.organisation.id, {
      'company.name|contain': value,
    });
    this.setState({ valueSupplierReseach: value });
  };

  render() {
    const { valueSupplierReseach, loading, supplierList } = this.state;

    return (
      <>
        <div className="jr-card fullWidth">
          <UnderlineTitle
            title={<IntlMessages id="orders.detail.card.supplier.title" />}
          />

          <div
            className={'information'}
            style={{ justifyContent: 'space-between' }}
          >
            <div className="col-3 mr-2 p-0">
              <b>
                <IntlMessages id="orders.detail.card.supplier.title" />:
              </b>
            </div>

            <div className="col-8 p-0">
              <SearchSelect
                handleChangeValue={this.handleChangeSupplierValue}
                changeSelect={this.changeSupplier}
                inputValue={valueSupplierReseach}
                isLoading={loading}
                options={
                  supplierList &&
                  supplierList.map((data) => ({
                    value: data.id,
                    label: data.company.name + ' - ' + data.supplierReference,
                  }))
                }
              />
            </div>
          </div>
          {this.state.loading && <Loading />}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  organisation: state.sdk.project.projectDetails.organisation,
  suppliersByOrganizationList: state.sdk.suppliers.suppliersByOrganizationList,
});

export default connect(mapStateToProps, {
  getSuppliersByOrganizationRequest,
})(SupplierOrderSupplier);
