import saMessages from '../locales/en_CA/en_CA.json';
import encaMessages from "../locales/en_CA/global_en_CA.json";

const result = {};
let key;

for (key in encaMessages) {
  if (Object.prototype.hasOwnProperty.call(encaMessages, key)) {
    result[key] = encaMessages[key];
  }
}

for (key in saMessages) {
  if (Object.prototype.hasOwnProperty.call(saMessages, key)) {
    result[key] = saMessages[key];
  }
}

const enLang = {
  messages: {
    ...result,
  },
  locale: "en-CA",
};
export default enLang;
