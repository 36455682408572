import React, { Component } from "react";
import { connect } from "react-redux";
import AddSelect from "./SelectComponents/AddSelect";

class SelectHelper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
    };
  }

  componentDidMount() {
    if (this.props.items) {
      this.setState({ selectedItems: this.props.items });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.items !== prevProps.items) {
      this.setState({ selectedItems: this.props.items });
    }
  }

  handleSelectedItems = (valueList) => {
    let array = [];

    valueList && valueList.forEach((element) => {
      array.push(element.value);
    });

    this.setState({ selectedItems: array },
      () => {
        this.handleChange(this.state.selectedItems);
      },
    );
  };

  handleChange = (selectedItems) => {
    this.props.updateItem(selectedItems);
  };

  render() {
    const { selectedItems } = this.state;
    return (
      <div>
        <AddSelect
          creatable={this.props.creatable}
          onClickAction={(text) => this.props.onClickAction(text)}
          listItemsSelected={selectedItems}
          handleSelectedItems={this.handleSelectedItems}
          listItems={this.props.listItems ? this.props.listItems : []}
          hideItem={this.props.hideItem}
          labelSelect={this.props.labelSelect}
          id={this.props.id ? this.props.id : ''}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
});

export default connect(mapStateToProps)(SelectHelper);
