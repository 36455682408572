import React, { Component } from 'react';
import { Modal } from '../../../../config/components.config';
import IntlMessages from '../../../../util/IntlMessages';
import ItemModalEditionContent from './ItemModalEditionContent';

class ItemModalEdition extends Component {
  handleClose = () => this.props.close();

  render() {
    const { open, item } = this.props;

    return (
      <div>
        <Modal
          title={<IntlMessages id="items.modal.edition.title" />}
          width="md"
          open={open}
          close={this.handleClose}
        >
          <ItemModalEditionContent
            close={this.handleClose}
            item={item}
          />
        </Modal>
      </div>
    );
  }
}

export default ItemModalEdition;
