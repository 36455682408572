import React, { Component } from 'react';
import { connect } from 'react-redux';
import IntlMessages from '../../../../util/IntlMessages';
import { Grid, Table } from '@devexpress/dx-react-grid-material-ui';
import { UnderlineTitle } from '../../../../config/components.config';
import NoData from '../../../../components/Table/NoData/NoData';
import { getOrderDocumentsSupplierRequest } from '../../../../config/actions.config';
import SupplierOrdersCell from '../../../../components/Table/Cells/Suppliers/SupplierOrdersCell';

const columns = [{ name: 'reference' }];

class SuppliersOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.params = {
      limit: 10,
    };
  }

  componentDidMount() {
    this.props.getOrderDocumentsSupplierRequest(this.props.id, this.params);
  }

  render() {
    const { orderDocumentsSupplierList } = this.props;
    const row = [];
    if (orderDocumentsSupplierList && orderDocumentsSupplierList !== '') {
      orderDocumentsSupplierList.orderDocuments.forEach((data, i) => {
        row[i] = {
          id: data.id,
          reference: data.reference,
        };
      });
    }
    return (
      <>
        <div className='jr-card'>
          <UnderlineTitle
            title={<IntlMessages id='supplier.detail.card.orders.title' />}
          />
          <div className='containerTable'>
            <Grid rows={row} columns={columns}>
              <Table
                noDataCellComponent={NoData}
                cellComponent={SupplierOrdersCell}
              />
            </Grid>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  orderDocumentsSupplierList: state.sdk.suppliers.orderDocumentsSupplierList,
});

export default connect(mapStateToProps, { getOrderDocumentsSupplierRequest })(
  SuppliersOrders
);
