import React, { Component } from 'react';
import { connect } from 'react-redux';
import IntlMessages from '../../../../util/IntlMessages';
import { Grid, Table } from '@devexpress/dx-react-grid-material-ui';
import { UnderlineTitle } from '../../../../config/components.config';
import NoData from '../../../../components/Table/NoData/NoData';
import ItemMovementsCell from '../../../../components/Table/Cells/ItemMovements/ItemMovementsCell';
import '../../index.css';

const columns = [{ name: 'movements' }, { name: 'quantity' }, { name: 'icon' }];

const columnExtensions = [
  { columnName: 'movements' },
  { columnName: 'quantity', width: 60 },
  { columnName: 'icon', width: 60 },
];

class ItemMovements extends Component {
  render() {
    const { itemStockMovements } = this.props;
    const row = [];
    if (itemStockMovements && itemStockMovements !== '') {
      itemStockMovements.forEach((data, i) => {
        row[i] = {
          id: data.id,
          type: data.type,
          quantity: data.quantity,
          pos: data.itemStock?.itemPos?.pos?.name,
          movements: data.datetime,
          locale: this.props.locale,
          timezone: this.props.timezone,
        };
      });
    }
    return (
      <>
        <div className='jr-card'>
          <UnderlineTitle
            title={<IntlMessages id='item.details.card.mouvements.title' />}
          />
          <div className='containerTable'>
            <Grid rows={row} columns={columns}>
              <Table
                columnExtensions={columnExtensions}
                noDataCellComponent={NoData}
                cellComponent={ItemMovementsCell}
              />
            </Grid>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  locale: state.sdk.settings.locale.locale,
  timezone: state.sdk.project.projectDetails.timezone,
});

export default connect(mapStateToProps, {})(ItemMovements);
