import React, { Component } from 'react';
import { Modal } from '../../../../../config/components.config';
import IntlMessages from '../../../../../util/IntlMessages';
import ItemPosModalEditContent from './ItemPosModalEditContent';

class ItemPosModalEdit extends Component {
  handleClose = () => this.props.close();

  render() {
    return (
      <Modal
        title={<IntlMessages id={'itemPos.modal.creation.title'} />}
        width='md'
        open={this.props.open}
        close={this.handleClose}
      >
        <ItemPosModalEditContent
          handleClose={this.handleClose}
          itemPos={this.props.itemPos}
        />
      </Modal>
    );
  }
}

export default ItemPosModalEdit;
