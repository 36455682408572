import React from 'react';
import IntlMessages from '../../util/IntlMessages';
import { HomeCard } from '../../config/components.config';
import { FaList, FaTruck, FaBoxOpen, FaUsers } from 'react-icons/fa';
import { Place, ShoppingBasket } from '@material-ui/icons'

class SamplePage extends React.Component {
  handleClick = (param) => {
    this.props.history.push(`/app/${param}`);
  };

  render() {
    return (
      <div className='app-wrapper div-wrapper-title'>
        <div className={'page-title'}>
          <IntlMessages id='mainMenu.title' />
        </div>
        <div className='row'>
          <div className='col-lg-6 col-sm-12'>
            <HomeCard
              icon={<FaList />}
              title={<IntlMessages id='mainMenu.homeCard.items.title' />}
              subtitle={<IntlMessages id='mainMenu.homeCard.items.subtitle' />}
              onClick={() => this.handleClick('items')}
              isComplete={false}
            />
          </div>
          <div className='col-lg-6 col-sm-12'>
            <HomeCard
              icon={<Place />}
              title={<IntlMessages id='mainMenu.homeCard.pos.title' />}
              subtitle={<IntlMessages id='mainMenu.homeCard.pos.subtitle' />}
              onClick={() => this.handleClick('pos')}
              isComplete={false}
            />
          </div>
          <div className='col-lg-6 col-sm-12'>
            <HomeCard
              icon={<FaUsers />}
              title={<IntlMessages id='mainMenu.homeCard.supplier.title' />}
              subtitle={
                <IntlMessages id='mainMenu.homeCard.supplier.subtitle' />
              }
              onClick={() => this.handleClick('suppliers')}
              isComplete={false}
            />
          </div>
          <div className='col-lg-6 col-sm-12'>
            <HomeCard
              icon={<FaBoxOpen />}
              title={<IntlMessages id='mainMenu.homeCard.stock.title' />}
              subtitle={<IntlMessages id='mainMenu.homeCard.stock.subtitle' />}
              onClick={() => this.handleClick('stock')}
              isComplete={false}
            />
          </div>
          <div className='col-lg-6 col-sm-12'>
            <HomeCard
              icon={<ShoppingBasket />}
              title={<IntlMessages id='mainMenu.homeCard.orders.title' />}
              subtitle={<IntlMessages id='mainMenu.homeCard.orders.subtitle' />}
              onClick={() => this.handleClick('orders-suppliers')}
              isComplete={false}
            />
          </div>
          <div className='col-lg-6 col-sm-12'>
            <HomeCard
              icon={<FaTruck />}
              title={<IntlMessages id='mainMenu.homeCard.delivery.title' />}
              subtitle={
                <IntlMessages id='mainMenu.homeCard.delivery.subtitle' />
              }
              onClick={() => this.handleClick('delivery-suppliers')}
              isComplete={false}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SamplePage;
