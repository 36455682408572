import React, { Component } from 'react';
import { connect } from 'react-redux';
import { styles } from '../../../../components/AccordionStyle';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Loading } from '../../../../config/components.config';
import { NotificationManager } from 'react-notifications';
import {
  postItemRequest,
  getSuppliersByOrganizationRequest,
  postItemTranslationsRequest,
  getItemsByProjectRequest,
  postSupplierItemRequest,
} from '../../../../config/actions.config';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IntlMessages from '../../../../util/IntlMessages';
import '../../index.css';
import ItemModalCreationTranslations from './ItemModalCreationTranslations';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';

class ItemModalCreationContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultSupplier: '',
      loadingSend: false,
    };
    this.items = {
      name: '',
      defaultSupplier: '',
      loadingSend: false,
    };
    this.translationItems = {
      translations: [],
    };
  }

  componentDidMount() {
    const { allowedLanguages } = this.props;
    if (allowedLanguages) {
      allowedLanguages.forEach((element) => {
        let array = {
          locale: element,
          name: '',
        };
        this.translationItems.translations.push(array);
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.newItem !== prevProps.newItem) {
      if (
        this.props.newItem &&
        this.props.newItem.item !== '' &&
        !this.props.newItem.loading &&
        !this.props.newItem.error
      ) {
        this.props.postItemTranslationsRequest(
          this.props.newItem.item.id,
          this.translationItems,
        );
        /*
        En attente de l'API
        if (this.items.defaultSupplier && this.items.defaultSupplier !== "") {
          this.props.postSupplierItemRequest(this.items.defaultSupplier, this.props.newItem.item.id, {default: true})
        }
         */
      }
    }
    if (this.props.newItemTranslations !== prevProps.newItemTranslations) {
      if (
        this.props.newItemTranslations &&
        this.props.newItemTranslations.itemTranslations !== '' &&
        !this.props.newItemTranslations.loading &&
        !this.props.newItemTranslations.error
      ) {
        this.handleClose();
      }
    }
  }

  handleChangeName = (event) => {
    this.setState({ name: event.target.value });
    this.items['name'] = event.target.value;
    this.translationItems.translations[0].name = event.target.value;
  };

  handleChangeDefaultSupplier = (event) => {
    this.setState({ defaultSupplier: event });
    this.items['defaultSupplier'] = event ? event.value : null;
  };

  handleChangeTranslation = (name, data, locale) => {
    let objIndex = this.translationItems.translations.findIndex(
      (item) => item.locale === locale,
    );
    this.translationItems.translations[objIndex][name] = data;
  };

  handleClose = () => this.props.close();

  handleSubmit = () => {
    if (this.items.name === '') {
      NotificationManager.warning(<IntlMessages id='message.items.create.title.missing' />);
    } else {
      this.setState({ loadingSend: true });
      this.items['stockManagement'] = true;
      this.props.postItemRequest(this.items, this.props.idProject);
    }
  };

  render() {
    const { loadingSend, name } = this.state;
    const { allowedLanguages, classes } = this.props;
    return (
      <div className='col-12' style={{ padding: 0, overflow: 'hidden' }}>
        <div className='row mt-3'>
          <div className='col-12 col-md-4 textFieldLabel mt-3'>
            <h4>
              <IntlMessages id='items.modal.creation.label.name' />
            </h4>
          </div>
          <div className='col-12 col-md-8 mt-4'>
            <div className='form-group text-field-stepper'>
              <TextField
                margin='normal'
                fullWidth
                value={name}
                variant='outlined'
                size='small'
                onChange={this.handleChangeName}
                className='inputName'
              />
            </div>
          </div>
        </div>
        {allowedLanguages ? (
          allowedLanguages.map((data, i) => {
            return (
              <Accordion
                key={i}
                className={classes.rootAccordion}
                defaultExpanded={data === this.props.locale}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                  className={clsx(classes.rootSummary, classes.contentSummary)}
                >
                  <Typography>
                    <i
                      style={{ marginRight: '5px' }}
                      className={`flag flag-24 flag-${
                        data === 'en_US' || data === 'en' || data === 'en_CA'
                          ? 'gb'
                          : (data === 'fr_CA')
                          ? 'ca'
                          : data
                      }`}
                    />
                  </Typography>
                  <Typography>
                    <IntlMessages id={data} />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.rootDetails}>
                  <ItemModalCreationTranslations
                    handleClose={this.handleClose}
                    handleChangeTranslation={this.handleChangeTranslation}
                    data={data}
                  />
                </AccordionDetails>
              </Accordion>
            );
          })
        ) : (
          <Loading />
        )}
        <div className='row mt-4'>
          {/* En attente de l'API
            <div className='col-12 col-lg-4 mt-4'>
              <h4 className='ml-2'>
                <IntlMessages id='items.modal.defaultSupplier' />
              </h4>
            </div>
            <div className='col-12 col-lg-8 mt-3'>
              <Select
                  fullWidth
                  isClearable
                  value={defaultSupplier}
                  onChange={this.handleChangeDefaultSupplier}
                  options={
                    suppliersByOrganizationList && suppliersByOrganizationList.suppliers !== "" &&
                    suppliersByOrganizationList.suppliers.map((data, i) => {
                      return {
                        value: data.id,
                        label: data.company.name,
                      };
                    })
                  }
              />
            </div>
            */}
        </div>
        <div className='d-flex justify-content-end my-3'>
          <Button
            onClick={this.handleClose}
            color='primary'
            disabled={loadingSend}
          >
            <IntlMessages id='items.modal.button.cancel' />
          </Button>
          <Button
            variant='outlined'
            onClick={this.handleSubmit}
            color='primary'
            disabled={loadingSend}
            id='save'
          >
            <IntlMessages id='items.modal.button.save' />
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  suppliersByOrganizationList: state.sdk.suppliers.suppliersByOrganizationList,
  allowedLanguages: state.sdk.project.projectDetails.allowedLanguages,
  locale: state.sdk.settings.locale.locale,
  newItem: state.sdk.items.newItem,
  newItemTranslations: state.sdk.items.newItemTranslations,
});

export default connect(mapStateToProps, {
  postItemRequest,
  getSuppliersByOrganizationRequest,
  postItemTranslationsRequest,
  postSupplierItemRequest,
  getItemsByProjectRequest,
})(withStyles(styles)(ItemModalCreationContent));
