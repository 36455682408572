import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MainButton } from '../../config/components.config';
import IntlMessages from '../../util/IntlMessages';
import ItemModalCreation from "./ItemModal/ItemModalCreation/ItemModalCreation";
import ItemsTable from "./ItemsTable/ItemsTable";

class Items extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalCreation: false,
    };
  }
  handleClose = () => {
    this.setState({ openModalCreation: false });
  };
  render() {
    const { openModalCreation } = this.state;

    return (
      <div>
        <div className='app-wrapper div-wrapper-title'>
          <div className='row headerAlign'>
            <div className='col-7 col-lg-5 page-title'>
              <IntlMessages id='items.title' />
            </div>
            <div className='col-5 col-lg-4' />
            <div className='col-12 col-lg-3 btn-add-container'>
              <MainButton
                id='addItem'
                onClick={() => this.setState({ openModalCreation: true })}
              >
                <IntlMessages id='items.button.add' />
              </MainButton>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='jr-card'>
                <ItemsTable />
                <ItemModalCreation
                  open={openModalCreation}
                  close={this.handleClose}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  defaultCountry: state.sdk.project.projectDetails.defaultCountry,
  deletedItem: state.sdk.items.deletedItem,
});

export default connect(mapStateToProps, {
})(Items);
