import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import IntlMessages from '../../../../util/IntlMessages';
import ButtonsItemSupplier from '../../../Buttons/ButtonsItemSupplier';
import { formatMoney } from '../../../../config/services.config';
import '../cell.css';

const ItemsCell = (cell) => {
  let content = cell.value;

  if (cell.column.name === 'name') {
    if (content) {
      content = <div align='left'>{content}</div>;
    } else {
      content = (
        <div align='left' className='noDataText'>
          <IntlMessages id='pos.table.no.name' />
        </div>
      );
    }
  } else if (cell.column.name === 'supplierProductReference') {
    if (content) {
      content = <div align='left'>{cell.value}</div>;
    } else {
      content = (
        <div align='left' className='noDataText'>
          <IntlMessages id='orders.supplier.no.reference' />
        </div>
      );
    }
  } else if (cell.column.name === 'packUnitName') {
    if (content) {
      content = <div align='center'>{cell.value}</div>;
    } else {
      content = (
        <div align='center' className='noDataText'>
          <IntlMessages id='appModule.NA' />
        </div>
      );
    }
  } else if (cell.column.name === 'purchasePriceExclTax') {
    content = (
      <div align='center'>
        {formatMoney(cell.row.currency?.isoCode, cell.row.locale, cell.value)}
      </div>
    );
  } else if (cell.column.name === 'maximumOrderQuantity') {
    if (content) {
      content = <div align='center'>{cell.value}</div>;
    } else {
      content = (
        <div align='center' className='noDataText'>
          <IntlMessages id='itemSupplier.table.no.maximumOrderQuantity' />
        </div>
      );
    }
  } else if (cell.column.name === 'quantityPerPack') {
    if (content) {
      content = <div align='center'>{cell.value}</div>;
    } else {
      content = (
        <div align='center' className='noDataText'>
          <IntlMessages id='appModule.NA' />
        </div>
      );
    }
  } else if (cell.column.name === 'button') {
    content = (
      <div className={'btn-action-container'}>
        <ButtonsItemSupplier itemSupplier={cell.row.itemSupplier} />
      </div>
    );
  } else {
    content = <div align='center'>{content}</div>;
  }

  return <Table.Cell style={cell.style}>{content}</Table.Cell>;
};

export default ItemsCell;
