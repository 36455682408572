import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import './ModalChoiceCompanies.css';
import { Button } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class ModalChoiceCompanies extends Component {
  handleClose = () => {
    this.props.close();
  };

  render() {
    const { open } = this.props;

    // TODO add translation on this page
    return (
      <Dialog
        maxWidth='md'
        onClose={this.handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <DialogTitle id='customized-dialog-title' onClose={this.handleClose}>
          Votre événement a bien été créé
        </DialogTitle>
        <DialogContent dividers>
          <Typography className='text-align-center' gutterBottom>
            <h1>Félicitation !</h1>
          </Typography>
          <Typography className='text-align-center' gutterBottom>
            Votre conférence est maintenant encodée
            <br />
            Vous pouvez maintenant commencer à vendre vos tickets, ou compléter
            les différentes données :
          </Typography>
          <div className='row'>
            <div className='col-6 mt-5 mb-3 text-align-center'>
              <NavLink to='/app/speaker'>
                <Button
                  className='btn-finish'
                  variant='outlined'
                  color='primary'
                >
                  Ajouter des Intervenants
                </Button>
              </NavLink>
            </div>
            <div className='col-6 mt-5 mb-3 text-align-center'>
              <NavLink to='/app/speaker'>
                <Button
                  className='btn-finish'
                  variant='outlined'
                  color='primary'
                >
                  Ajouter des Séances
                </Button>
              </NavLink>
            </div>
            <div className='col-6 mt-3 mb-3 text-align-center'>
              <NavLink to='/app/list/room'>
                <Button
                  className='btn-finish'
                  variant='outlined'
                  color='primary'
                >
                  Configurer vos Salles
                </Button>
              </NavLink>
            </div>
            <div className='col-6 mt-3 mb-3 text-align-center'>
              <NavLink to='/app/tickets'>
                <Button
                  className='btn-finish'
                  variant='outlined'
                  color='primary'
                >
                  Ajouter des Tickets
                </Button>
              </NavLink>
            </div>
            <div className='col-6 mt-3 mb-5 text-align-center'>
              <NavLink to='/app/stands'>
                <Button
                  className='btn-finish'
                  variant='outlined'
                  color='primary'
                >
                  Configurer vos Stands
                </Button>
              </NavLink>
            </div>
            <div className='col-6 mt-3 mb-5 text-align-center'>
              <NavLink to='/app/pos'>
                <Button
                  className='btn-finish'
                  variant='outlined'
                  color='primary'
                >
                  Ajouter des Points de Vente
                </Button>
              </NavLink>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className='row mt-2 mb-3 ml-3 mr-3'>
            <div className='col-12'>
              <Typography className='text-align-center' gutterBottom>
                Besoin d&apos;aide ? N&apos;Hésitez pas à consulter notre{' '}
                <a href='https://www.emisys.com/'>support en ligne</a>, vous y
                trouverez beaucoup d&apos;informations, ou{' '}
                <a href='https://www.emisys.com/#contact-info-2'>
                  contacter Emisys
                </a>{' '}
                pour demander une assistance.
              </Typography>
            </div>
          </div>
          <Typography gutterBottom />
        </DialogActions>
      </Dialog>
    );
  }
}

export default ModalChoiceCompanies;
