import React from 'react';
import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import { Close } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../../../util/IntlMessages';
import '../cell.css';

const FilterCell = ({ column, filter, onFilter, ...restProps }) => {
  const filterValue = filter && filter.value;
  const onChange = (e) =>
    onFilter({ columnName: column.name, value: e.target.value });

  const handleReset = () => {
    onFilter({ columnName: column.name, value: '' });
  };

  let content;
  if (column.name === 'item' || column.name === 'pos') {
    content = (
      <TableFilterRow.Cell
        {...restProps}
        className="tableFilterRow"
        column={column}
      >
        <TextField
          label={<IntlMessages id="appModule.filter" />}
          value={filterValue ? filterValue : ''}
          onChange={onChange}
          InputProps={{
            endAdornment: filterValue ? (
              <IconButton onClick={handleReset} className={'p-0 m-0'}>
                <Close />
              </IconButton>
            ) : (
              <IconButton />
            ),
          }}
        />
      </TableFilterRow.Cell>
    );
  } else {
    content = (
      <TableFilterRow.Cell
        {...restProps}
        className="tableFilterRow"
        column={column}
      ></TableFilterRow.Cell>
    );
  }
  return content;
};

export default FilterCell;
