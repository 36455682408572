import React, { Component } from 'react';
import { connect } from 'react-redux';
import IntlMessages from '../../../../../util/IntlMessages';
import {
  UnderlineTitle,
  Loading,
} from '../../../../../config/components.config';
import { getSuppliersByOrganizationRequest, getPosByProjectRequest } from '../../../../../config/actions.config';
import Select from 'react-select';
import '../../../index.css';

class InternOrderVendor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      posList: null,
      vendorPos: null,
    };
  }

  componentDidMount() {
    this.props.getPosByProjectRequest(this.props.idProject);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.posList !== this.props.posList) {
      if (this.props.posList.loading) {
        this.setState({ loading: true });
      }
      if (
        this.props.posList.pos !== '' &&
        !this.props.posList.loading &&
        !this.props.posList.error
      ) {
        this.setState({
          loading: false,
          posList: this.props.posList.pos
        });
      }
    }
  }

  changeVendorPos = (event) => {
    this.setState({ vendorPos: event });
    this.props.handeChange('vendorPos', event.value);
  };

  render() {
    return (
      <>
        <div className='jr-card fullWidth'>
          <UnderlineTitle
            title={<IntlMessages id='orders.detail.card.vendor.title' />}
          ></UnderlineTitle>

          <div
            className={'information'}
            style={{ justifyContent: 'space-between' }}
          >
            <div className='col-3 mr-2 p-0'>
              <b>
                <IntlMessages id='orders.detail.card.vendor.title' />:
              </b>
            </div>

            <div className='col-8 p-0'>
              <Select
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
                fullWidth
                hideSelectedOptions={true}
                value={this.state.vendorPos}
                onChange={this.changeVendorPos}
                options={
                  this.state.posList &&
                  this.state.posList.map((data) => {
                    return {
                      value: data.id,
                      label: data.name,
                    };
                  })
                }
              />
            </div>
          </div>
          {this.state.loading && <Loading />}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  posList: state.sdk.pos.posList,
});

export default connect(mapStateToProps, {
  getSuppliersByOrganizationRequest,
  getPosByProjectRequest
})(InternOrderVendor);
