import React, { Component } from 'react';
import { Modal } from '../../../../../config/components.config';
import IntlMessages from '../../../../../util/IntlMessages';
import ItemSupplierModalCreationContent from './ItemSupplierModalCreationContent';

class ItemSupplierModalCreation extends Component {
  handleClose = () => this.props.close();

  render() {
    return (
      <Modal
        title={<IntlMessages id={'itemSupplier.modal.creation.title'} />}
        width='md'
        open={this.props.open}
        close={this.handleClose}
      >
        <ItemSupplierModalCreationContent
          handleClose={this.handleClose}
          idSupplier={this.props.idSupplier}
        />
      </Modal>
    );
  }
}

export default ItemSupplierModalCreation;
