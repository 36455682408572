import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  ButtonDropdown,
} from 'reactstrap';
import {
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
  INSIDE_THE_HEADER,
} from '../../config/constants.config';
import {
  switchLanguage,
  toggleCollapsedNav,
  getUserSelfProjectsRequest,
} from '../../config/actions.config';
import CardHeader from './CardHeader/index';
import Menu from '../TopNav/Menu';
import UserInfoPopup from '../UserInfo/UserInfoPopup';
import LanguageSwitcher from '../LanguageSwitcher/index';
import DropdownMenuProject from '../DropdownMenu/DropdownMenuProject';
import { FiFlag } from 'react-icons/fi';
import { checkReduxResponse } from '../../config/services.config';
import { Loading } from '../../config/components.config';

class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      anchorEl: undefined,
      searchBox: false,
      mailNotification: false,
      userInfo: false,
      langSwitcher: false,
      appNotification: false,
      dropDownOpen: false,
      projectLoading: false,
      allProject: [],
    };
  }

  componentDidMount() {
    this.props.getUserSelfProjectsRequest();
    if (
      this.props.selfListProjects &&
      this.props.selfListProjects.projects.length > 0
    ) {
      this.getSelfListProjects(this.props.selfListProjects.projects);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selfListProjects !== prevProps.selfListProjects) {
      this.setState({ projectLoading: this.props.selfListProjects.loading });
      if (checkReduxResponse(this.props.selfListProjects, 'projects')) {
        this.getSelfListProjects(this.props.selfListProjects.projects);
      }
    }
  }

  getSelfListProjects = (selfListProjects) => {
    const allProject = [];

    if (selfListProjects !== undefined && selfListProjects !== '') {
      selfListProjects.forEach((element) => {
        const isDuplicate = allProject.some(
          (project) => project.id === element.id
        );
        if (!isDuplicate) {
          allProject.push({
            id: element.id,
            label: element.name,
            value: element.name,
          });
        }
      });
    }

    this.setState({ allProject: allProject });
  };

  isMenuOpen = (isOpen) => {
    this.setState({ dropDownOpen: isOpen });
  };

  onToggleCollapsedNav = () => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  redirect = () => {
    this.isMenuOpen(false);
    window.location.reload();
  };

  onLangSwitcherSelect = (event) => {
    this.setState({
      langSwitcher: !this.state.langSwitcher,
      anchorEl: event.currentTarget,
    });
  };

  onUserInfoSelect = () => {
    this.setState({ userInfo: !this.state.userInfo });
  };

  handleRequestClose = () => {
    this.setState({
      langSwitcher: false,
      userInfo: false,
      mailNotification: false,
      appNotification: false,
      searchBox: false,
    });
  };

  toggle = () => {
    this.setState({ dropDownOpen: !this.state.dropDownOpen });
  };

  render() {
    const { allProject, dropDownOpen, userInfo, projectLoading } = this.state;
    const { drawerType, locale, navigationStyle, horizontalNavPosition } =
      this.props;

    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? 'd-block d-xl-none'
      : drawerType.includes(COLLAPSED_DRAWER)
      ? 'd-block'
      : 'd-none';

    return (
      <AppBar
        className={`app-main-header ${
          navigationStyle === HORIZONTAL_NAVIGATION &&
          horizontalNavPosition === BELOW_THE_HEADER
            ? 'app-main-header-top'
            : ''
        }`}
      >
        <Toolbar className="app-toolbar" disableGutters={false}>
          {navigationStyle === HORIZONTAL_NAVIGATION ? (
            <div
              className="d-block d-md-none pointer mr-3"
              onClick={this.onToggleCollapsedNav}
            >
              <span className="jr-menu-icon">
                <span className="menu-icon" />
              </span>
            </div>
          ) : (
            <IconButton
              className={`jr-menu-icon mr-3 ${drawerStyle}`}
              aria-label="Menu"
              onClick={this.onToggleCollapsedNav}
            >
              <span className="menu-icon color-menu" />
            </IconButton>
          )}
          {navigationStyle === HORIZONTAL_NAVIGATION &&
            horizontalNavPosition === INSIDE_THE_HEADER && <Menu />}

          <ul className="header-notifications list-inline ml-auto">
            <li className="list-inline-item">
              <ButtonDropdown
                isOpen={dropDownOpen}
                className="quick-menu app-notification"
                toggle={this.toggle}
              >
                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown"
                >
                  <span className="app-notification-menu">
                    <span className="pl-1">{this.props.nameProject}</span>
                  </span>
                </DropdownToggle>

                <DropdownMenu style={{ width: 340 }} right>
                  <CardHeader
                    styleName="align-items-center"
                    heading="Projects"
                  />
                  <DropdownMenuProject
                    listProject={allProject}
                    isMenuOpen={this.isMenuOpen}
                    selected={this.redirect}
                  />
                  {projectLoading && <Loading />}
                </DropdownMenu>
              </ButtonDropdown>
            </li>
            <li className="list-inline-item">
              <Dropdown
                className="quick-menu app-notification"
                isOpen={this.state.langSwitcher}
                toggle={this.onLangSwitcherSelect.bind(this)}
              >
                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown"
                >
                  <div
                    className="d-flex align-items-center pointer pt-1"
                    style={{ color: '#424242', fontSize: 15 }}
                  >
                    <FiFlag style={{ marginRight: 5 }} />{' '}
                    {locale.locale.toUpperCase()}
                  </div>
                </DropdownToggle>

                <DropdownMenu right style={{ width: 'max-content' }}>
                  <LanguageSwitcher
                    switchLanguage={this.props.switchLanguage}
                    handleRequestClose={this.handleRequestClose}
                  />
                </DropdownMenu>
              </Dropdown>
            </li>

            {navigationStyle === HORIZONTAL_NAVIGATION && (
              <li className="list-inline-item user-nav">
                <Dropdown
                  className="quick-menu"
                  isOpen={userInfo}
                  toggle={this.onUserInfoSelect.bind(this)}
                >
                  <DropdownToggle
                    className="d-inline-block"
                    tag="span"
                    data-toggle="dropdown"
                  >
                    <IconButton className="icon-btn size-30">
                      <Avatar
                        alt="..."
                        src="http://via.placeholder.com/150x150"
                        className="size-30"
                      />
                    </IconButton>
                  </DropdownToggle>

                  <DropdownMenu right>
                    <UserInfoPopup />
                  </DropdownMenu>
                </Dropdown>
              </li>
            )}
          </ul>
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = (state) => ({
  drawerType: state.sdk.settings.drawerType,
  locale: state.sdk.settings.locale,
  navigationStyle: state.sdk.settings.navigationStyle,
  horizontalNavPosition: state.sdk.settings.horizontalNavPosition,
  selfListProjects: state.sdk.user.userSelfProjects,
  nameProject: state.sdk.user.currentProject.nameCurrentProject,
  currentProject: state.sdk.user.currentProject,
});

export default withRouter(
  connect(mapStateToProps, {
    toggleCollapsedNav,
    switchLanguage,
    getUserSelfProjectsRequest,
  })(Header)
);
