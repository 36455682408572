import React, { Component } from 'react';
import { Modal } from '../../../../config/components.config';
import IntlMessages from '../../../../util/IntlMessages';
import SuppliersModalEditContent from './SuppliersModalEditContent';

class SuppliersModalEdit extends Component {
  handleClose = () => this.props.close();

  render() {
    return (
      <Modal
        title={<IntlMessages id={'suppliers.modal.edit.title'} />}
        width='md'
        open={this.props.open}
        close={this.handleClose}
      >
        <SuppliersModalEditContent
          handleClose={this.handleClose}
          supplier={this.props.supplier}
        />
      </Modal>
    );
  }
}

export default SuppliersModalEdit;
