import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IntlMessages from '../../../util/IntlMessages';

class CardMenu extends React.Component {
  render() {
    const options = [
      <IntlMessages key={'updateData'} id="popup.updateData" />,
      <IntlMessages key={'detailedLog'} id="popup.detailedLog" />,
      <IntlMessages key={'statistics'} id="popup.statistics" />,
      <IntlMessages key={'clearData'} id="popup.clearData" />,
    ];
    const { menuState, anchorEl, handleRequestClose } = this.props;
    return (
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={menuState}
        onClose={handleRequestClose}

        MenuListProps={{
          style: {
            width: 150,
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem key={index} onClick={handleRequestClose}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    );
  }
}

export default CardMenu;
