import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import IntlMessages from '../../util/IntlMessages';
import PosModalEdit from '../../app/Pos/PosModal/PosModalEdit/PosModalEdit';
import { NavLink } from 'react-router-dom';
import { deletePosRequest } from '../../config/actions.config';
import { ActionButton } from '../../config/components.config';

class ButtonsPos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalPos: false,
      isClosed: false,
    };
  }

  handleOpenDelete = () => {
    this.setState({
      isClosed: true,
    });
  };

  handleDelete = () => {
    this.props.deletePosRequest(this.props.pos.id);
    this.setState({
      isClosed: false,
    });
  };

  render() {
    const { openModalPos } = this.state;
    const { pos } = this.props;
    return (
      <div className={'btn-action-container'}>
        <NavLink to={'show-pos/' + this.props.pos.id}>
          <ActionButton icon={'view'} />
        </NavLink>

        <ActionButton
          icon={'edit'}
          className='editPos'
          id={pos.id}
          onClick={() => this.setState({ openModalPos: true })}
        />
        <ActionButton
          icon={'delete'}
          id={pos.id}
          onClick={this.handleOpenDelete}
          className='deletePos'
        />

        <PosModalEdit
          pos={pos}
          open={openModalPos}
          close={() => this.setState({ openModalPos: false })}
        />

        <SweetAlert
          show={this.state.isClosed}
          style={{ whiteSpace: 'normal' }}
          warning
          showCancel
          confirmBtnText={<IntlMessages id='appModule.yes' />}
          cancelBtnText={<IntlMessages id='appModule.cancel' />}
          confirmBtnBsStyle='danger'
          title={<IntlMessages id='appModule.modal.delete.confirm.message' />}
          titleBsStyle='default'
          onConfirm={() => this.handleDelete()}
          confirmBtnCssClass='confirmDeletePos'
          onCancel={() => {
            this.setState({ isClosed: false });
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  posDetails: state.sdk.pos.posDetails,
});

export default connect(mapStateToProps, { deletePosRequest })(ButtonsPos);
