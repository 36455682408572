import React, { Component } from 'react';
import { connect } from 'react-redux';
import { styles } from '../../../../components/AccordionStyle';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Loading } from '../../../../config/components.config';
import { NotificationManager } from 'react-notifications';
import {
  putItemRequest,
  getSuppliersByOrganizationRequest,
  putItemTranslationsRequest,
  getItemsByProjectRequest,
} from '../../../../config/actions.config';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IntlMessages from '../../../../util/IntlMessages';
import clsx from 'clsx';
import ItemModalEditionTranslations from './ItemModalEditionTranslations';
import TextField from '@material-ui/core/TextField';
import '../../index.css';

class ItemModalEditionContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      defaultSupplier: '',
      loadingSend: false,
    };
    this.items = {
      name: '',
      loadingSend: false,
    };
    this.translationItems = {
      translations: [],
    };
  }

  componentDidMount() {
    const { allowedLanguages } = this.props;
    if (this.translationItems.translations.length === 0) {
      if (allowedLanguages) {
        allowedLanguages.forEach((element) => {
          let array = {
            locale: element,
            name: '',
          };
          this.translationItems.translations.push(array);
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.itemDetails !== prevProps.itemDetails) {
      if (
        this.props.itemDetails &&
        this.props.itemDetails.item !== '' &&
        !this.props.itemDetails.loading &&
        !this.props.itemDetails.error
      ) {
        this.translationItems.translations.forEach((element, indexT) => {
          this.props.itemDetails.item.translations.forEach(
            (elementB, indexL) => {
              if (element.locale === elementB.locale) {
                this.translationItems.translations[
                  indexT
                ] = this.props.itemDetails.item.translations[indexL];
              }
            },
          );
        });
        this.setState({
          name: this.props.itemDetails.item.name,
        });
        this.items = this.props.itemDetails.item;
      }
    }
    if (this.props.updatedItem !== prevProps.updatedItem) {
      if (
        this.props.updatedItem &&
        this.props.updatedItem.item !== '' &&
        !this.props.updatedItem.loading &&
        !this.props.updatedItem.error
      ) {
        this.props.putItemTranslationsRequest(
          this.props.updatedItem.item.id,
          this.translationItems,
        );
      }
    }
    if (
      this.props.updatedItemTranslations !== prevProps.updatedItemTranslations
    ) {
      if (
        this.props.updatedItemTranslations &&
        this.props.updatedItemTranslations.itemTranslations !== '' &&
        !this.props.updatedItemTranslations.loading &&
        !this.props.updatedItemTranslations.error
      ) {
        this.handleClose();
      }
    }
  }

  handleChangeDefaultSupplier = (event) => {
    this.setState({ defaultSupplier: event });
    this.items['defaultSupplier'] = event ? event.value : null;
  };

  handleChangeName = (event) => {
    this.setState({ name: event.target.value });
    this.items['name'] = event.target.value;
  };

  handleChangeTranslation = (name, data, locale) => {
    if (this.props.locale === locale) {
      this.items[name] = data;
    }
    let objIndex = this.translationItems.translations.findIndex(
      (item) => item.locale === locale,
    );
    this.translationItems.translations[objIndex][name] = data;
  };

  handleClose = () => this.props.close();

  handleSubmit = () => {
    if (this.items.name === '' || this.items.name === undefined) {
      NotificationManager.warning(<IntlMessages id='message.title.missing' />);
    } else {
      this.setState({ loadingSend: true });
      this.props.putItemRequest(this.items);
    }
  };

  render() {
    const { loadingSend, name } = this.state;
    const { allowedLanguages, classes } = this.props;
    return (
      <div className='col-12' style={{ padding: 0, overflow: 'hidden' }}>
        <div className='row mt-3'>
          <div className='col-12 col-md-4 textFieldLabel mt-3'>
            <h4>
              <IntlMessages id='items.modal.creation.label.name' />
            </h4>
          </div>
          <div className='col-12 col-md-8 mt-4'>
            <div className='form-group text-field-stepper'>
              <TextField
                margin='normal'
                fullWidth
                value={name}
                variant='outlined'
                size='small'
                onChange={this.handleChangeName}
                className='inputName'
              />
            </div>
          </div>
        </div>
        {allowedLanguages ? (
          allowedLanguages.map((data, i) => {
            let translation = this.translationItems.translations.find(
              (element) => element.locale === data,
            );
            return (
              <Accordion
                key={i}
                className={classes.rootAccordion}
                defaultExpanded={data === this.props.locale}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                  className={clsx(classes.rootSummary, classes.contentSummary)}
                >
                  <Typography>
                    <i
                      style={{ marginRight: '5px' }}
                      className={`flag flag-24 flag-${
                        data === 'en_US' || data === 'en' || data === 'en_CA'
                          ? 'gb'
                          : data === 'fr_CA'
                          ? 'ca'
                          : data
                      }`}
                    />
                  </Typography>
                  <Typography>
                    <IntlMessages id={data} />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.rootDetails}>
                  <ItemModalEditionTranslations
                    handleClose={this.handleClose}
                    translation={translation}
                    handleChangeTranslation={this.handleChangeTranslation}
                    data={data}
                  />
                </AccordionDetails>
              </Accordion>
            );
          })
        ) : (
          <Loading />
        )}
        <div className='row mt-4'>
          {/* En attente de l'API
            <div className='col-12 col-lg-4 mt-4'>
              <h4 className='ml-2'>
                <IntlMessages id='items.modal.defaultSupplier' />
              </h4>
            </div>
            <div className='col-12 col-lg-8 mt-3'>
              <Select
                  fullWidth
                  isClearable
                  value={defaultSupplier}
                  onChange={this.handleChangeDefaultSupplier}
                  options={
                    suppliersByOrganizationList && suppliersByOrganizationList.suppliers !== "" &&
                    suppliersByOrganizationList.suppliers.map((data, i) => {
                      return {
                        value: data.id,
                        label: data.company.name,
                      };
                    })
                  }
              />
            </div>
            */}
        </div>
        <div className='d-flex justify-content-end my-3'>
          <Button
            onClick={this.handleClose}
            color='primary'
            disabled={loadingSend}
          >
            <IntlMessages id='items.modal.button.cancel' />
          </Button>
          <Button
            variant='outlined'
            onClick={this.handleSubmit}
            color='primary'
            disabled={loadingSend}
            id='save'
          >
            <IntlMessages id='items.modal.button.save' />
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  suppliersByOrganizationList: state.sdk.suppliers.suppliersByOrganizationList,
  idOrganization: state.sdk.project.projectDetails.organization.id,
  allowedLanguages: state.sdk.project.projectDetails.allowedLanguages,
  locale: state.sdk.settings.locale.locale,
  updatedItem: state.sdk.items.updatedItem,
  updatedItemTranslations: state.sdk.items.updatedItemTranslations,
  itemDetails: state.sdk.items.item,
});

export default connect(mapStateToProps, {
  putItemRequest,
  getSuppliersByOrganizationRequest,
  putItemTranslationsRequest,
  getItemsByProjectRequest,
})(withStyles(styles)(ItemModalEditionContent));
