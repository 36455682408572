import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import IntlMessages from '../../../../../util/IntlMessages';
import { uploadInventoryRequest } from '../../../../../config/actions.config';
import { NotificationManager } from 'react-notifications';
import '../../../index.css';

class InventoryImportModalContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadLoading: false,
      inputFile: null,
    };
    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.uploadedInventory !== this.props.uploadedInventory) {
      this.setState({ uploadLoading: this.props.uploadedInventory.loading });
      if (
        !this.props.uploadedInventory.loading &&
        !this.props.uploadedInventory.error
      ) {
        if (this.props.uploadedInventory.inventory.result) {
          NotificationManager.success(
            <IntlMessages
              id="inventory.message.success.imported"
              values={{
                quantity: this.props.uploadedInventory.inventory.updates,
              }}
            />
          );
          this.inputRef.current.value = "";
          this.setState({ inputFile: null });
        }
      }

      if (this.props.uploadedInventory.error) {
        NotificationManager.error(
          this.props.uploadedInventory.errorMessage.error
        );
        this.inputRef.current.value = "";
        this.setState({ inputFile: null });
      }
    }
  }

  handleClose = () => this.props.handleClose();

  handLeSubmit = () => {
    const formData = new FormData();
    formData.append('file', this.state.inputFile);

    this.props.uploadInventoryRequest(this.props.idProject, formData);
  };

  handleFileSelected = (event) => {
    const file = event.target.files[0];
    if (file !== undefined) {
      this.setState({ inputFile: file });
    } else {
      this.setState({ inputFile: null });
    }
  };

  render() {
    const { uploadLoading, inputFile } = this.state;

    return (
      <div>
        <div className="col-12">
          <div className="information">
            <div className="col-12 col-md-4">
              <h4>
                <IntlMessages id="inventory.modal.label.file.csv" />
              </h4>
            </div>
            <div className="col-12 col-md-6">
              <input
                type="file"
                id="file"
                accept=".csv"
                ref={this.inputRef}
                onChange={this.handleFileSelected}
              />
            </div>
          </div>

          <div className="d-flex justify-content-end mt-4">
            {/* Bouton a montrer quand ce sera en modal */}
            {/* <Button onClick={this.handleClose} color="primary">
              <IntlMessages id="inventory.modal.button.cancel" />
            </Button> */}
            <Button
              id="saveImportCSVFile"
              variant="outlined"
              onClick={this.handLeSubmit}
              color="primary"
              endIcon={uploadLoading && <CircularProgress size={20} />}
              disabled={uploadLoading || inputFile === null}
            >
              <IntlMessages id="inventory.modal.button.import" />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  uploadedInventory: state.sdk.inventory.uploadedInventory,
});

export default connect(mapStateToProps, {
  uploadInventoryRequest,
})(InventoryImportModalContent);
