import React from 'react';
import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Close } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../../../util/IntlMessages';
import { formatDatePicker } from '../../../../config/services.config';
import { DatePicker } from 'material-ui-pickers';
import '../cell.css';

const style = {
  paddingBottom: 15,
  paddingRight: 15,
  height: 65,
  width: '100%',
  paddingLeft: 0,
};

const orderStatus = [
  { value: 'all', label: <IntlMessages id="appModule.all" /> },
  {
    value: 'created',
    label: <IntlMessages id="orders.table.type.status.created" />,
  },
];

const FilterCell = ({ column, filter, onFilter, ...restProps }) => {
  const filterValue = filter && filter.value;
  const onChange = (e) =>
    onFilter({ columnName: column.name, value: e.target.value });

  const handleReset = () => {
    onFilter({ columnName: column.name, value: '' });
  };

  const onChangeDate = (e) => {
    if (e === null) {
      onFilter({ columnName: column.name, value: null });
    } else {
      let dateStart = new Date(e);
      dateStart = new Date(
        dateStart.getFullYear(),
        dateStart.getMonth(),
        dateStart.getDate(),
        0,
        0,
        0
      );
      let dateEnd = new Date(e);
      dateEnd = new Date(
        dateEnd.getFullYear(),
        dateEnd.getMonth(),
        dateEnd.getDate(),
        23,
        59,
        59
      );
      const dateFormated = formatDatePicker(new Date(e));
      onFilter({
        columnName: column.name,
        value: dateFormated,
        values: {
          dateStart: formatDatePicker(dateStart),
          dateEnd: formatDatePicker(dateEnd),
        },
      });
    }
  };

  let content;
  if (column.name === 'reference') {
    const defaultValue = JSON.parse(localStorage.getItem('paramsOrdersIntern'))
      ? JSON.parse(localStorage.getItem('paramsOrdersIntern'))['reference']
        ? JSON.parse(localStorage.getItem('paramsOrdersIntern'))['reference']
            .value
        : ''
      : '';
    content = (
      <TableFilterRow.Cell
        {...restProps}
        className="tableFilterRow"
        column={column}
      >
        <TextField
          label={<IntlMessages id="appModule.filter" />}
          value={defaultValue}
          onChange={onChange}
          InputProps={{
            endAdornment:
              filterValue || defaultValue ? (
                <IconButton onClick={handleReset} className={'p-0 m-0'}>
                  <Close />
                </IconButton>
              ) : (
                <IconButton />
              ),
          }}
        />
      </TableFilterRow.Cell>
    );
  } else if (column.name === 'requestorPos') {
    const defaultValue = JSON.parse(localStorage.getItem('paramsOrdersIntern'))
      ? JSON.parse(localStorage.getItem('paramsOrdersIntern'))[
          'requestorPos.name'
        ]
        ? JSON.parse(localStorage.getItem('paramsOrdersIntern'))[
            'requestorPos.name'
          ].value
        : ''
      : '';
    content = (
      <TableFilterRow.Cell
        {...restProps}
        className="tableFilterRow"
        column={column}
      >
        <TextField
          label={<IntlMessages id="appModule.filter" />}
          value={defaultValue}
          onChange={onChange}
          InputProps={{
            endAdornment:
              filterValue || defaultValue ? (
                <IconButton onClick={handleReset} className={'p-0 m-0'}>
                  <Close />
                </IconButton>
              ) : (
                <IconButton />
              ),
          }}
        />
      </TableFilterRow.Cell>
    );
  } else if (column.name === 'vendorPos') {
    const defaultValue = JSON.parse(localStorage.getItem('paramsOrdersIntern'))
      ? JSON.parse(localStorage.getItem('paramsOrdersIntern'))['vendorPos.name']
        ? JSON.parse(localStorage.getItem('paramsOrdersIntern'))[
            'vendorPos.name'
          ].value
        : ''
      : '';
    content = (
      <TableFilterRow.Cell
        {...restProps}
        className="tableFilterRow"
        column={column}
      >
        <TextField
          label={<IntlMessages id="appModule.filter" />}
          value={defaultValue}
          onChange={onChange}
          InputProps={{
            endAdornment:
              filterValue || defaultValue ? (
                <IconButton onClick={handleReset} className={'p-0 m-0'}>
                  <Close />
                </IconButton>
              ) : (
                <IconButton />
              ),
          }}
        />
      </TableFilterRow.Cell>
    );
  } else if (column.name === 'responsibleUserProfile') {
    const defaultValue = JSON.parse(localStorage.getItem('paramsOrdersIntern'))
      ? JSON.parse(localStorage.getItem('paramsOrdersIntern'))[
          'responsibleUserProfile.fullName'
        ]
        ? JSON.parse(localStorage.getItem('paramsOrdersIntern'))[
            'responsibleUserProfile.fullName'
          ].value
        : ''
      : '';
    content = (
      <TableFilterRow.Cell
        {...restProps}
        className="tableFilterRow"
        column={column}
      >
        <TextField
          label={<IntlMessages id="appModule.filter" />}
          value={defaultValue}
          onChange={onChange}
          InputProps={{
            endAdornment:
              filterValue || defaultValue ? (
                <IconButton onClick={handleReset} className={'p-0 m-0'}>
                  <Close />
                </IconButton>
              ) : (
                <IconButton />
              ),
          }}
        />
      </TableFilterRow.Cell>
    );
  } else if (column.name === 'status') {
    content = (
      <TableFilterRow.Cell {...restProps} style={style} column={column}>
        <FormControl className="fullWidth">
          <InputLabel id="demo-simple-select-label" className="fullWidth">
            <IntlMessages id="appModule.filter" />
          </InputLabel>
          <Select
            value={filterValue ? filterValue : 'all'}
            autoWidth
            onChange={onChange}
            className="fullWidth"
          >
            {orderStatus.map((element, i) => {
              return (
                <MenuItem key={i} value={element.value}>
                  {element.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </TableFilterRow.Cell>
    );
  } else if (column.name === 'createdAt') {
    const defaultValue = JSON.parse(localStorage.getItem('paramsOrdersIntern'))
      ? JSON.parse(localStorage.getItem('paramsOrdersIntern'))['createdAt']
        ? JSON.parse(localStorage.getItem('paramsOrdersIntern'))['createdAt'][0]
            .value
        : null
      : null;
    content = (
      <TableFilterRow.Cell
        {...restProps}
        className="tableFilterRow"
        column={column}
      >
        <DatePicker
          label={<IntlMessages id="appModule.date" />}
          value={defaultValue}
          className="fullWidth"
          clearable
          onChange={onChangeDate}
          format="dd/MM/yyyy"
          views={['year', 'month', 'day']}
          openTo="year"
          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        />
        {defaultValue && (
          <IconButton
            style={{ marginBottom: '-25px' }}
            edge="end"
            size="small"
            onClick={() => onChangeDate(null)}
          >
            <Close />
          </IconButton>
        )}
      </TableFilterRow.Cell>
    );
  } else {
    content = (
      <TableFilterRow.Cell
        {...restProps}
        className="tableFilterRow"
        column={column}
      ></TableFilterRow.Cell>
    );
  }
  return content;
};

export default FilterCell;
