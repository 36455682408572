import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import ButtonsPos from '../../../Buttons/ButtonsPos';
import IntlMessages from '../../../../util/IntlMessages';
import '../cell.css';

const PosCell = (cell) => {
  let content = cell.value;
  if (cell.column.name === 'buttons') {
    content = <ButtonsPos pos={cell.row.pos} />;
  } else if (cell.column.name === 'name') {
    content = <div className='nameCell'>{cell.row.name}</div>;
  } else if (cell.column.name === 'warehouse') {
    if (content) {
      content = <IntlMessages id='stock.warehouse' />;
    } else {
      content = <IntlMessages id='stock.store' />;
    }
  } else if (cell.column.name === 'posCategory') {
    if (content.length !== 0) {
      content = content.map((data, index) => {
        return (
          <div key={index}>
            {data.category.name}
            {content.length !== index + 1 && `, `}
          </div>
        );
      });
    } else {
      content = (
        <div className='noDataText'>
          <IntlMessages id='pos.no.category' />
        </div>
      );
    }
  }
  return <Table.Cell style={cell.style}>{content}</Table.Cell>;
};

export default PosCell;
