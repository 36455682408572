import React from 'react';
import { connect } from 'react-redux';
import Downshift from 'downshift';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import IntlMessages from '../../util/IntlMessages';

class DropdownResearchItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productSuggestions: [],
      loading: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.itemSuppliersList !== this.props.itemSuppliersList) {
      if (this.props.itemSuppliersList && this.props.itemSuppliersList !== '') {
        if (this.props.itemSuppliersList.loading) {
          this.loading = true;
          this.setState({ productSuggestions: [] });
        }
        if (
          this.props.itemSuppliersList.itemSuppliers &&
          !this.props.itemSuppliersList.loading &&
          !this.props.itemSuppliersList.error
        ) {
          this.setState({
            productSuggestions: this.props.itemSuppliersList.itemSuppliers,
            loading: false,
          });
        }
      }
    }

    if (prevProps.itemPosByPosList !== this.props.itemPosByPosList) {
      if (this.props.itemPosByPosList && this.props.itemPosByPosList !== '') {
        if (this.props.itemPosByPosList.loading) {
          this.loading = true;
          this.setState({ productSuggestions: [] });
        }
        if (
          this.props.itemPosByPosList.itemPos &&
          !this.props.itemPosByPosList.loading &&
          !this.props.itemPosByPosList.error
        ) {
          this.setState({
            productSuggestions: this.props.itemPosByPosList.itemPos,
            loading: false,
          });
        }
      }
    }
  }

  handleChange = (item) => {
    this.props.onHandleChange(item);
  };

  keyPress = (e) => {
    if (e.key === 'Enter') {
      this.props.onHandleEnter();
    }
  };

  render() {
    return (
      <div>
        <Downshift
          onChange={this.handleChange}
          itemToString={(item) => (item ? item.item.name : '')}
          onInputValueChange={(value) => this.props.onHandleChangeInput(value)}
          initialInputValue={this.props.value}
        >
          {({ getInputProps, getItemProps, isOpen, clearSelection }) => (
            <div>
              <div>
                <TextField
                  {...getInputProps({
                    placeholder: 'Item',
                    fullWidth: true,
                    autoComplete: 'off',
                    onKeyDown: this.keyPress,
                    onChange: () => clearSelection(),
                    autoFocus: true,
                  })}
                />
              </div>
              <Paper
                square
                style={{
                  position: 'absolute',
                  width: '20%',
                }}
              >
                {isOpen ? (
                  this.state.loading ? (
                    <MenuItem
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <i color="grey">
                        <IntlMessages id="appModule.charging" />{' '}
                      </i>
                      <CircularProgress size={20} />
                    </MenuItem>
                  ) : this.state.productSuggestions.length === 0 ? (
                    <MenuItem>
                      <i>
                        <IntlMessages id="appModule.no.result" />{' '}
                      </i>
                    </MenuItem>
                  ) : (
                    this.state.productSuggestions.map((item, index) => (
                      <MenuItem
                        key={index}
                        {...getItemProps({
                          index,
                          item,
                        })}
                        style={{ paddingTop: 10, paddingBottom: 10 }}
                      >
                        <div className="row fullWidth">
                          <div className="col-9">
                            <ListItemText primary={item.item.name} />
                          </div>
                        </div>
                      </MenuItem>
                    ))
                  )
                ) : null}
              </Paper>
            </div>
          )}
        </Downshift>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  itemSuppliersList: state.sdk.itemSuppliers.itemSuppliersList,
  itemPosByPosList: state.sdk.itemPos.itemPosByPosList,
});
export default connect(mapStateToProps)(DropdownResearchItem);
