import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getItemRequest,
  getItemSuppliersByItemRequest,
  getStockMovementsByItemRequest,
  setGlobalError,
} from '../../../config/actions.config';
import { Loading } from '../../../config/components.config';
import IntlMessages from '../../../util/IntlMessages';
import ItemInfo from './DetailsCards/ItemInfo';
import ItemMouvements from './DetailsCards/ItemMovements';
import ItemStock from './DetailsCards/ItemStock';
import ItemSuppliers from './DetailsCards/ItemSuppliers';
import { NotificationManager } from 'react-notifications';

class ItemDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemSuppliers: null,
      itemDetails: null,
      itemStockMovements: null,
      loadingSuppliers: true,
      loadingMovements: true,
    };
  }

  componentDidMount() {
    this.props.getItemRequest(this.props.match.params.idItem);
    this.props.getItemSuppliersByItemRequest(this.props.match.params.idItem);
    this.props.getStockMovementsByItemRequest(this.props.match.params.idItem, {
      limit: 10,
      order: 'id:DESC',
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.itemDetails !== this.props.itemDetails) {
      if (
        this.props.itemDetails &&
        this.props.itemDetails.item !== '' &&
        !this.props.itemDetails.loading &&
        !this.props.itemDetails.error
      ) {
        this.setState({ itemDetails: this.props.itemDetails.item });
      }
    }

    if (prevProps.newSupplierItem !== this.props.newSupplierItem) {
      if (
          this.props.newSupplierItem.supplierItem !== '' &&
          !this.props.newSupplierItem.loading &&
          !this.props.newSupplierItem.error
      ) {
        this.props.getItemRequest(this.props.match.params.idItem);
      }
    }

    if (prevProps.itemSuppliersListByItem !== this.props.itemSuppliersListByItem) {
      if (
        this.props.itemSuppliersListByItem &&
        this.props.itemSuppliersListByItem.itemSuppliers !== '' &&
        !this.props.itemSuppliersListByItem.loading &&
        !this.props.itemSuppliersListByItem.error
      ) {
        this.setState({
          itemSuppliers: this.props.itemSuppliersListByItem.itemSuppliers,
          loadingSuppliers: false,
        });
      }
    }

    if (prevProps.updatedItemSupplier !== this.props.updatedItemSupplier) {
      if (
        this.props.updatedItemSupplier.item !== '' &&
        !this.props.updatedItemSupplier.loading &&
        !this.props.updatedItemSupplier.error
      ) {
        NotificationManager.success(
          <IntlMessages id='supplierItem.success.message.updated' />
        );
        this.props.getItemSuppliersByItemRequest(
          this.props.match.params.idItem
        );
      }
    }

    if (
      prevProps.itemStockMovementsList !== this.props.itemStockMovementsList
    ) {
      if (
        this.props.itemStockMovementsList &&
        this.props.itemStockMovementsList.stockMovements !== '' &&
        !this.props.itemStockMovementsList.loading &&
        !this.props.itemStockMovementsList.error
      ) {
        this.setState({
          itemStockMovements: this.props.itemStockMovementsList.stockMovements,
          loadingMovements: false,
        });
      }
    }

    if (prevProps.newItemPosStock !== this.props.newItemPosStock) {
      if (
        this.props.newItemPosStock.itemPos !== '' &&
        !this.props.newItemPosStock.loading &&
        !this.props.newItemPosStock.error
      ) {
        this.props.getStockMovementsByItemRequest(
          this.props.match.params.idItem,
          {
            limit: 10,
            order: 'id:DESC',
          }
        );
      }
    }

    if (
      this.props.updatedItemTranslations !== prevProps.updatedItemTranslations
    ) {
      if (
        this.props.updatedItemTranslations &&
        this.props.updatedItemTranslations.itemTranslations !== '' &&
        !this.props.updatedItemTranslations.loading &&
        !this.props.updatedItemTranslations.error
      ) {
        NotificationManager.success(
          <IntlMessages id='item.success.message.updated' />
        );
      }
    }

    if (prevProps.globalError !== this.props.globalError) {
      if (this.props.globalError === true) {
        NotificationManager.error(
          <IntlMessages id='appModule.message.error' />
        );
        this.props.setGlobalError(false);
      }
    }
  }

  render() {
    const {
      itemSuppliers,
      itemDetails,
      itemStockMovements,
      loadingMovements,
      loadingSuppliers,
    } = this.state;

    console.log(itemSuppliers);

    return (
      <div className='app-wrapper div-wrapper-title'>
        <div className='row headerAlign'>
          <div className='col-12 page-title'>
            <IntlMessages id='item.details.title' />
          </div>
        </div>
        <div>
          <div className='row'>
            <div className='col-md-4 col-12 detailCard'>
              <ItemInfo item={itemDetails} />
            </div>
            <div className='col-md-4 col-12 detailCard'>
              <ItemSuppliers idItem={this.props.match.params.idItem} itemSuppliers={itemSuppliers} itemDetails={itemDetails} />
              {loadingSuppliers && <Loading />}
            </div>
            <div className='col-md-4 col-12 detailCard'>
              <ItemMouvements itemStockMovements={itemStockMovements} />
              {loadingMovements && <Loading />}
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              {itemDetails && (
                <ItemStock
                  id={this.props.match.params.idItem}
                  item={itemDetails}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  itemDetails: state.sdk.items.item,
  itemSuppliersListByItem: state.sdk.itemSuppliers.itemSuppliersListByItem,
  itemStockMovementsList: state.sdk.stockMovements.stockMovementsByItemList,
  newItemPosStock: state.sdk.itemPos.newItemPosStock,
  updatedItemTranslations: state.sdk.items.updatedItemTranslations,
  updatedItemSupplier: state.sdk.itemSuppliers.updatedItemSupplier,
  globalError: state.sdk.auth.globalError,
  newSupplierItem: state.sdk.suppliers.newSupplierItem,
});

export default connect(mapStateToProps, {
  getItemRequest,
  getItemSuppliersByItemRequest,
  getStockMovementsByItemRequest,
  setGlobalError,
})(ItemDetails);
