import React from 'react';
import { connect } from 'react-redux';
import PosTable from './PosTable';
import PosModalCreation from './PosModal/PosModalCreation/PosModalCreation';
import IntlMessages from '../../util/IntlMessages';
import { MainButton } from '../../config/components.config';

class Pos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalPos: false,
    };
  }

  render() {
    const { openModalPos } = this.state;

    return (
      <div className='app-wrapper div-wrapper-title'>
        <div className='row headerAlign'>
          <div className='col-lg-9 col-md-9 col-sm-7 page-title'>
            <IntlMessages id='pos.title' />
          </div>
          <div className='col-lg-3 col-md-3 col-sm-5 btn-add-container'>
            <MainButton
              id='addPos'
              onClick={() => this.setState({ openModalPos: true })}
            >
              <IntlMessages id='pos.button.add' />
            </MainButton>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='jr-card'>
              <PosTable />
            </div>
          </div>
          <PosModalCreation
            open={openModalPos}
            close={() => this.setState({ openModalPos: false })}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.sdk.auth,
  idProject: state.sdk.user.currentProject.idCurrentProject,
});

export default connect(mapStateToProps)(Pos);
