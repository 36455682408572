import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getSupplierByIdRequest } from '../../../config/actions.config';
import { Loading } from '../../../config/components.config';
import IntlMessages from '../../../util/IntlMessages';
import SuppliersDelivery from './DetailCards/SuppliersDelivery';
import SuppliersInfo from './DetailCards/SuppliersInfo';
import SuppliersItems from './DetailCards/SuppliersItems';
import SuppliersOrders from './DetailCards/SuppliersOrders';
import { NotificationManager } from 'react-notifications';

class SuppliersDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.supplierDetail = null;
  }

  componentDidMount() {
    this.props.getSupplierByIdRequest(this.props.match.params.idSupplier);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.supplierDetail !== this.props.supplierDetail) {
      if (this.props.supplierDetail.loading) {
        this.setState({
          loading: true,
        });
      }
      if (
        this.props.supplierDetail &&
        this.props.supplierDetail.supplier !== '' &&
        !this.props.supplierDetail.loading &&
        !this.props.supplierDetail.error
      ) {
        this.setState({
          loading: false,
        });
        this.supplierDetail = this.props.supplierDetail.supplier;
      }
    }
    if (prevProps.updatedSupplier !== this.props.updatedSupplier) {
      if (
        this.props.updatedSupplier.supplier !== '' &&
        !this.props.updatedSupplier.loading &&
        !this.props.updatedSupplier.error
      ) {
        NotificationManager.success(
          <IntlMessages id='supplier.success.message.updated' />,
        );
        this.props.getSupplierByIdRequest(this.props.match.params.idSupplier);
      }
    }
  }

  render() {
    const { loading } = this.state;

    return (
      <div className='app-wrapper div-wrapper-title'>
        <div className='row headerAlign'>
          <div className='col-12 page-title'>
            <IntlMessages id='supplier.detail.title' />
          </div>
        </div>
        <div>
          <div className='row'>
            <div className='col-md-4 col-12 detailCard'>
              <SuppliersInfo supplier={this.supplierDetail} />
            </div>
            <div className='col-md-4 col-12 detailCard'>
              <SuppliersOrders id={this.props.match.params.idSupplier} />
              {loading && <Loading />}
            </div>
            <div className='col-md-4 col-12 detailCard'>
              <SuppliersDelivery id={this.props.match.params.idSupplier} />
              {loading && <Loading />}
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
              <SuppliersItems id={this.props.match.params.idSupplier} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  supplierDetail: state.sdk.suppliers.supplierDetail,
  updatedSupplier: state.sdk.suppliers.updatedSupplier,
});

export default connect(mapStateToProps, { getSupplierByIdRequest })(
  SuppliersDetail,
);
