import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ActionButton } from '../../config/components.config';

class ButtonsOrders extends Component {
  render() {
    return (
      <>
        {this.props.orderType === 'supplierOrder' && (
          <div className={'btn-action-container'}>
            <NavLink to={'show-supplier-order/' + this.props.idOrder}>
              <ActionButton icon={'view'} />
            </NavLink>
          </div>
        )}

        {this.props.orderType === 'internOrder' && (
          <div className={'btn-action-container'}>
            <NavLink to={'show-intern-order/' + this.props.idOrder}>
              <ActionButton icon={'view'} />
            </NavLink>
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
});

export default connect(mapStateToProps, {})(ButtonsOrders);
