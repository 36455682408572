import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getItemStockMovementDetailRequest } from '../../../../../config/actions.config';
import { Loading } from '../../../../../config/components.config';
import { formatDateTime } from '../../../../../config/services.config';
import IntlMessages from '../../../../../util/IntlMessages';
import '../../../index.css';

class MovementModalViewContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datetime: '',
      status: '',
      item: '',
      pos: '',
      type: '',
      comment: '',
      quantityBefore: '',
      quantity: '',
      quantityAfter: '',
      loading: false,
    };
  }

  componentDidMount() {
    this.props.getItemStockMovementDetailRequest(this.props.idMovement);
  }

  componentDidUpdate(prevPros) {
    const { itemStockMovementsDetail } = this.props;
    if (prevPros.itemStockMovementsDetail !== itemStockMovementsDetail) {
      if (itemStockMovementsDetail.loading) {
        this.setState({ loading: true });
      }
      if (
        itemStockMovementsDetail.movementDetail !== '' &&
        !itemStockMovementsDetail.error &&
        !itemStockMovementsDetail.loading
      ) {
        console.log(itemStockMovementsDetail.movementDetail);
        this.setState({
          datetime: itemStockMovementsDetail.movementDetail.datetime,
          status: itemStockMovementsDetail.movementDetail.itemStock?.status,
          item: itemStockMovementsDetail.movementDetail.itemStock?.itemPos?.item?.name,
          pos: itemStockMovementsDetail.movementDetail.itemStock?.itemPos?.pos?.name,
          type: itemStockMovementsDetail.movementDetail.type,
          comment: itemStockMovementsDetail.movementDetail.comment,
          quantityBefore: itemStockMovementsDetail.movementDetail.quantityBefore,
          quantity: itemStockMovementsDetail.movementDetail.quantity,
          quantityAfter: itemStockMovementsDetail.movementDetail.quantityAfter,
          loading: false,
        });
      }
    }
  }

  handleClose = () => this.props.handleClose();

  render() {
    const {
      datetime,
      status,
      item,
      pos,
      type,
      comment,
      quantityBefore,
      quantity,
      quantityAfter,
      loading,
    } = this.state;

    const { locale, timezone } = this.props;

    return (
      <div>
        <div className='col-12'>

        <div>
          <h3 className='modalSubTitle'>{pos}</h3>
          <div>{datetime !== '' ? formatDateTime(datetime, locale, timezone) : 'dd/MM/yyyy'}</div>
        </div>

        <hr />

        <div className='row'>
          <div className='col-6'>
            <div className='movementInfo'>
              <span className='modalSubTitle'><IntlMessages id='movements.table.stockStatus' />:</span>
              <div>
                <IntlMessages id={`movements.table.stock.status.${status}`} />
              </div>
            </div>
            <div className='movementInfo'>
              <span className='modalSubTitle'><IntlMessages id='movements.table.type' />:</span>
              <div>
                {type}
              </div>
            </div>
            <div className='movementInfo'>
              <span className='modalSubTitle'><IntlMessages id='movements.table.item' />:</span>
              <div>
                {item}
              </div>
            </div>
            {comment && <div>
              <span className='modalSubTitle'><IntlMessages id='movements.modal.comment' /></span>
              <div className='textfieldContainer'>{comment}</div>
            </div>}
          </div>

          <div className='col-6'>
            <div className='row'>
            <div className='col'>
                <div className='modalSubTitle'><IntlMessages id='movements.modal.quantity' /></div>
              </div>
              <div className='col borderRight' align='center'>
                <div><IntlMessages id='movements.table.quantityBefore' /></div>
                <div className='modalSubTitle'>{quantityBefore}</div>
              </div>
              <div className='col borderRight' align='center'>
                <div><IntlMessages id='movements.table.transferred' /></div>
                <div className='modalSubTitle'>{quantity}</div>
              </div>
              <div className='col' align='center'>
                <div><IntlMessages id='movements.table.quantityAfter' /></div>
                <div className='modalSubTitle'>{quantityAfter}</div>
              </div>
            </div>
          </div>
        </div>

          {loading && <Loading />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  itemStockMovementsDetail: state.sdk.itemStockMovements.itemStockMovementsDetail,
  locale: state.sdk.settings.locale.locale,
  timezone: state.sdk.project.projectDetails.timezone,
});

export default connect(mapStateToProps, {
  getItemStockMovementDetailRequest,
})(MovementModalViewContent);
