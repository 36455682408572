import React, { Component } from 'react';
import { connect } from 'react-redux';
import IntlMessages from '../../../../util/IntlMessages';
import { createPosOrderdocumentRequest } from '../../../../config/actions.config';
import Button from '@material-ui/core/Button';
import { Redirect } from 'react-router';
import '../../index.css';
import InternOrderVendor from './DetailCards/InternOrderVendor';
import InternOrderRequestor from './DetailCards/InternOrderRequestor';

class InternOrderCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      orderDocumentId: null,
      orderdocument: {
        requestorPos: null,
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.newPosOrderdocument !== this.props.newPosOrderdocument
    ) {
      if (
        this.props.newPosOrderdocument.orderDocument !== '' &&
        !this.props.newPosOrderdocument.loading &&
        !this.props.newPosOrderdocument.error
      ) {
        this.setState({
          redirect: true,
          orderDocumentId: this.props.newPosOrderdocument.orderDocument.id,
        });
      }
    }
  }

  handeChange = (name, value) => {
    if (name === 'vendorPos') {
      this.setState({ [name]: value });
    } else {
      let orderdocument = this.state.orderdocument;
      orderdocument[name] = value;

      this.setState({ orderdocument: orderdocument });
    }
  };

  handleSubmit = () => {
    this.props.createPosOrderdocumentRequest(
      this.state.vendorPos,
      this.state.orderdocument
    );
  };

  render() {
    if (this.state.redirect) {
      return (
        <Redirect to={'show-intern-order/' + this.state.orderDocumentId} />
      );
    } else {
      return (
        <div className='app-wrapper div-wrapper-title'>
          <div className='row headerAlign'>
            <div className='col-12 page-title'>
              <IntlMessages id='orders.intern.create.title' />
            </div>
          </div>
          <div>
            <div className='row'>
              <div className='col-12 col-md-6 detailCard'>
                <InternOrderVendor handeChange={this.handeChange} />
              </div>
              <div className='col-12 col-md-6 detailCard'>
                <InternOrderRequestor handeChange={this.handeChange} />
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <div className='d-flex justify-content-end'>
                  <Button
                    disabled={
                      !(
                        this.state.vendorPos &&
                        this.state.orderdocument.requestorPos
                      )
                    }
                    variant='outlined'
                    onClick={this.handleSubmit}
                    color='primary'
                  >
                    <IntlMessages id='items.modal.button.save' />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  newPosOrderdocument: state.sdk.posDocuments.newPosOrderdocument
});

export default connect(mapStateToProps, {
  createPosOrderdocumentRequest,
})(InternOrderCreate);
