const languageData = [
  {
    languageId: 'French',
    locale: 'fr',
    icon: 'fr',
  },
  // {show English when translations are complete
  //   languageId: 'English',
  //   locale: 'en',
  //   icon: 'us',
  // },
  // {show English canadian when translations are complete
  //   languageId: 'English canadian',
  //   locale: 'en-CA',
  //   icon: 'ca',
  // },
  // {show Nederland when translations are complete
  //   languageId: 'Nederland',
  //   locale: 'nl',
  //   icon: 'nl',
  // },
  {
    languageId: 'French canadian',
    locale: 'fr-CA',
    icon: 'ca',
  },
  // { show German when translations are complete
  //   languageId: 'German',
  //   locale: 'de',
  //   icon: 'de',
  // },
];
export default languageData;
