import React, { Component } from 'react';
import { connect } from 'react-redux';
import IntlMessages from '../../../../../util/IntlMessages';
import {
  UnderlineTitle,
  Loading,
} from '../../../../../config/components.config';
import { getSuppliersByOrganizationRequest } from '../../../../../config/actions.config';
import Select from 'react-select';
import '../../../index.css';

class SupplierDeliverySupplier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      supplierList: null,
      supplier: null,
    };
  }

  componentDidMount() {
    if (this.props.organisation) {
      this.props.getSuppliersByOrganizationRequest(this.props.organisation.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.organisation !== this.props.organisation) {
      this.props.getSuppliersByOrganizationRequest(this.props.organisation.id);
    }

    if (
      prevProps.suppliersByOrganizationList !==
      this.props.suppliersByOrganizationList
    ) {
      if (
        this.props.suppliersByOrganizationList.suppliers !== '' &&
        !this.props.suppliersByOrganizationList.loading &&
        !this.props.suppliersByOrganizationList.error
      ) {
        this.setState({
          loading: false,
          supplierList: this.props.suppliersByOrganizationList.suppliers,
        });
      }
    }
  }

  changeSupplier = (event) => {
    this.setState({ supplier: event });
    this.props.handeChange('supplier', event.value);
  };

  render() {
    return (
      <>
        <div className='jr-card fullWidth'>
          <UnderlineTitle
            title={<IntlMessages id='orders.detail.card.supplier.title' />}
          ></UnderlineTitle>

          <div
            className={'information'}
            style={{ justifyContent: 'space-between' }}
          >
            <div className='col-3 mr-2 p-0'>
              <b>
                <IntlMessages id='orders.detail.card.supplier.title' />:
              </b>
            </div>

            <div className='col-8 p-0'>
              <Select
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
                fullWidth
                hideSelectedOptions={true}
                value={this.state.supplier}
                onChange={this.changeSupplier}
                options={
                  this.state.supplierList &&
                  this.state.supplierList.map((data) => {
                    return {
                      value: data.id,
                      label: data.company.name,
                    };
                  })
                }
              />
            </div>
          </div>
          {this.state.loading && <Loading />}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  organisation: state.sdk.project.projectDetails.organisation,
  suppliersByOrganizationList: state.sdk.suppliers.suppliersByOrganizationList,
});

export default connect(mapStateToProps, {
  getSuppliersByOrganizationRequest,
})(SupplierDeliverySupplier);
