import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  UnderlineTitle,
  Loading,
  ActionButton,
} from '../../../../config/components.config';
import '../../index.css';
import IntlMessages from '../../../../util/IntlMessages';
import SuppliersModalEdit from '../../SuppliersModal/SuppliersModalEdit/SuppliersModalEdit';

const countries = require('i18n-iso-countries');

class SuppliersInfo extends Component {
  constructor() {
    super();
    this.state = {
      openModalSuppliers: false,
      supplier: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.supplier) {
        this.setState({
          supplier: this.props.supplier,
        });
      }
    }
  }

  render() {
    const { openModalSuppliers, supplier } = this.state;
    countries.registerLocale(
      require(`i18n-iso-countries/langs/${
        this.props.locale === 'fr_CA' ? 'fr' : this.props.locale
      }.json`),
    );

    return (
      <div className='jr-card fullWidth'>
        <UnderlineTitle
          title={<IntlMessages id='supplier.detail.card.info.title' />}
        >
          <ActionButton
            icon={'edit'}
            onClick={() => this.setState({ openModalSuppliers: true })}
          />
        </UnderlineTitle>
        {supplier ? (
          <>
            <div className={'information'}>
              <div className='mr-2'>
                <b>
                  <IntlMessages id='suppliers.table.name' />
                </b>
              </div>
              <div>{supplier.company.name}</div>
            </div>
            <div className={'information'}>
              <div className='mr-2'>
                <b>
                  <IntlMessages id='suppliers.table.vat' />
                </b>
              </div>
              <div>{supplier.company.vatNumber}</div>
            </div>
            <div className={'information'}>
              <div className='mr-2'>
                <b>
                  <IntlMessages id='suppliers.table.reference' />
                </b>
              </div>
              <div>{supplier.supplierReference}</div>
            </div>
            <div className={'information'}>
              <div className='mr-2'>
                <b>
                  <IntlMessages id='suppliers.table.phone' />
                </b>
              </div>
              <div>
                {supplier.company.address.length > 0
                  ? supplier.company.address[0].phoneNumber
                  : ''}
              </div>
            </div>
            <div className={'information'}>
              <div className='mr-2'>
                <b>
                  <IntlMessages id='suppliers.table.email' />
                </b>
              </div>
              <div>{supplier.company.email}</div>
            </div>
            <div className={'information'}>
              <div className='mr-2'>
                <b>
                  <IntlMessages id='suppliers.table.address' />
                </b>
              </div>
              <div>
                <div>
                  {supplier.company.address.length > 0
                    ? supplier.company.address[0].streetNumber
                    : ''}{' '}
                  {supplier.company.address.length > 0
                    ? supplier.company.address[0].street
                    : ''}
                </div>
                <div>
                  {supplier.company.address.length > 0
                    ? supplier.company.address[0].zipCode
                    : ''}{' '}
                  {supplier.company.address.length > 0
                    ? supplier.company.address[0].city
                    : ''}{' '}
                  {supplier.company.address.length > 0
                    ? countries.getName(
                        supplier.company.address[0].country
                          ? supplier.company.address[0].country
                          : '',
                        this.props.locale,
                        { select: 'official' },
                      )
                    : ''}
                </div>
              </div>
            </div>
          </>
        ) : (
          <Loading />
        )}
        <SuppliersModalEdit
          supplier={supplier}
          open={openModalSuppliers}
          close={() => this.setState({ openModalSuppliers: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  locale: state.sdk.settings.locale.locale,
});

export default connect(mapStateToProps)(SuppliersInfo);
