import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from '../../../../util/IntlMessages';
import { NotificationManager } from 'react-notifications';
import {
  getCategoriesByProjectRequest,
  putPosRequest,
  putPosCategoryRequest,
} from '../../../../config/actions.config';
import Select from 'react-select';
import SelectHelper from '../../../../components/Select';
import '../../index.css';

class PosModalEditContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      description: '',
      posCategory: [],
      warehouse: false,
      loadingSend: false,
    };
    this.pos = {};
    this.posCategory = {
      categories: [],
    };
  }

  componentDidMount() {
    const { pos } = this.props;
    this.props.getCategoriesByProjectRequest(this.props.idProject, {
      type: 'pos',
    });

    if (pos.posCategory.length !== 0) {
      let listArray = [];
      let listObject = [];
      pos.posCategory.forEach((data) => {
        listArray.push(data.category.id);
        listObject.push({ category: data.category.id });
      });
      this.setState({ posCategory: listArray });
      this.posCategory.categories = listObject;
    }

    this.setState({
      name: pos.name,
      id: pos.id,
      description: pos.description ? pos.description : '',
      warehouse: {
        value: pos.warehouse,
        label: pos.warehouse ? (
          <IntlMessages id='stock.warehouse' />
        ) : (
          <IntlMessages id='stock.store' />
        ),
      },
    });
    this.pos.id = pos.id;
  }

  handleChange = (name) => (event) => {
    if (name === 'warehouse') {
      this.setState({ [name]: event });
      this.pos[name] = event.value;
    } else {
      this.setState({ [name]: event.target.value });
      this.pos[name] = event.target.value;
    }
  };

  handleChangePos = (selectedPos) => {
    this.setState({ posCategory: selectedPos });

    let posCategory = [];

    selectedPos.forEach((data) => {
      posCategory.push({ category: data });
    });

    this.posCategory = {
      categories: posCategory,
    };
  };

  handleClose = () => {
    this.props.handleClose();
  };

  handLeSubmit = () => {
    if (this.pos.name !== '') {
      this.setState({ loadingSend: true });
      this.props.putPosRequest(this.pos);
      if (this.posCategory.length !== 0) {
        this.props.putPosCategoryRequest(this.props.pos.id, this.posCategory);
      }
      this.props.handleClose();
    } else {
      NotificationManager.warning(
        <IntlMessages id='pos.warning.message.name' />,
      );
    }
  };

  render() {
    const {
      name,
      description,
      posCategory,
      warehouse,
      loadingSend,
    } = this.state;
    const { categoriesList } = this.props;

    return (
      <div>
        <div className='col-12'>
          <div className='information'>
            <div className='col-12 col-md-4'>
              <h4>
                <IntlMessages id={'pos.modal.name'} />
              </h4>
            </div>
            <div className='col-12 col-md-6'>
              <TextField
                id='name'
                variant='outlined'
                size='small'
                fullWidth
                value={name}
                onChange={this.handleChange('name')}
              />
            </div>
          </div>

          <div className='information'>
            <div className='col-12 col-md-4 mt-3'>
              <h4>
                <IntlMessages id={'pos.modal.description'} />
              </h4>
            </div>
            <div className='col-12 col-md-6'>
              <TextField
                id='description'
                multiline
                rows='4'
                fullWidth
                value={description}
                onChange={this.handleChange('description')}
                variant='outlined'
              />
            </div>
          </div>

          <div className='information'>
            <div className='col-12 col-md-4 mt-3'>
              <h4>
                <IntlMessages id={'pos.modal.category'} />
              </h4>
            </div>
            <div className='col-12 col-md-6'>
              <SelectHelper
                creatable={false}
                labelSelect={'name'}
                listItems={categoriesList}
                items={posCategory}
                updateItem={this.handleChangePos}
              />
            </div>
          </div>

          <div className='information'>
            <div className='col-12 col-md-4 mt-3'>
              <h4>
                <IntlMessages id={'pos.modal.type'} />
              </h4>
            </div>
            <div className='col-12 col-md-6'>
              <Select
                fullWidth
                isClearable
                value={warehouse}
                onChange={this.handleChange('warehouse')}
                options={[
                  {
                    value: true,
                    label: <IntlMessages id='stock.warehouse' />,
                  },
                  {
                    value: false,
                    label: <IntlMessages id='stock.store' />,
                  },
                ]}
              />
            </div>
          </div>

          <div className='d-flex justify-content-end my-4'>
            <Button
              onClick={this.handleClose}
              color='primary'
              disabled={loadingSend}
            >
              <IntlMessages id='appModule.cancel' />
            </Button>
            <Button
              id='save'
              variant='outlined'
              onClick={this.handLeSubmit}
              color='primary'
              disabled={loadingSend}
            >
              <IntlMessages id='appModule.save' />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  updatedPos: state.sdk.pos.updatedPos,
  categoriesList: state.sdk.categories.categoriesList.categories,
});

export default connect(mapStateToProps, {
  getCategoriesByProjectRequest,
  putPosRequest,
  putPosCategoryRequest,
})(PosModalEditContent);
