import React from 'react';
import { capitalizeFirstLetter } from '../../util/capitalizeFirstLetter';

const LanguageItem = ({ language, switchLanguage, handleRequestClose }) => {
  const { icon, locale } = language;
  let languageNames = new Intl.DisplayNames([locale], {
    type: 'language',
  });

  return (
    <li
      className="pointer"
      onClick={() => {
        handleRequestClose();
        switchLanguage(language);
      }}
    >
      <div className="d-flex align-items-center">
        <i className={`flag flag-24 flag-${icon}`} />
        <h4 className="mb-0 ml-2">
          {capitalizeFirstLetter(languageNames.of(locale))}
        </h4>
      </div>
    </li>
  );
};

export default LanguageItem;
