import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { formatDateTime } from '../../../../config/services.config';
import { StatusComponent } from '../../../../config/components.config';
import '../cell.css';
import IntlMessages from '../../../../util/IntlMessages';
import ButtonsMovements from '../../../Buttons/ButtonsMovements';
import { ImArrowDownRight2, ImArrowUpRight2 } from 'react-icons/im';
import { movementIcon } from '../MovementsIcon';

const MovementsCell = (cell) => {
  let content = cell.value;
  if (cell.column.name === 'datetime') {
    content = formatDateTime(
      cell.row.datetime,
      cell.row.locale,
      cell.row.timezone
    );
  } else if (
    cell.column.name === 'quantityBefore' ||
    cell.column.name === 'quantityAfter'
  ) {
    content = <div align='center'>{content}</div>;
  } else if (cell.column.name === 'quantity') {
    content = (
      <div className='centerContainer'>
        {content}
        {cell.row.quantity < 0 ? (
          <ImArrowDownRight2 style={{ color: 'red' }} />
        ) : (
          <ImArrowUpRight2 style={{ color: 'green' }} />
        )}
      </div>
    );
  } else if (cell.column.name === 'type') {
    content = (
      <div align='center'>
        {movementIcon(cell.row.type)}{' '}
        <IntlMessages id={`movements.table.type.status.${cell.row.type}`} />
      </div>
    );
  } else if (cell.column.name === 'buttons') {
    content = (
      <div className={'btn-action-container'}>
        <ButtonsMovements idMovement={cell.row.stockMovements.id} />
      </div>
    );
  } else if (cell.column.name === 'stockStatus') {
    content = (
      <div align='center'>
        <StatusComponent
          status={cell.row.stockStatus}
          label={
            <IntlMessages
              id={`movements.table.stock.status.${cell.row.stockStatus}`}
            />
          }
          errorLabel={<IntlMessages id='appModule.status.unknown' />}
        />
      </div>
    );
  }
  return <Table.Cell style={cell.style}>{content}</Table.Cell>;
};

export default MovementsCell;
