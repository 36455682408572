import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import { DatePicker } from 'material-ui-pickers';
import { putUserRequest } from '../../config/actions.config';
import { formatDatePicker } from '../../config/services.config';
import { UnderlineTitle, Loading } from '../../config/components.config';
import IntlMessages from '../../util/IntlMessages';
import Select from 'react-select';
import { genreList, SelectedGenre } from '../../components/Genre/Genre';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

class PersonalContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      firstName: '',
      lastName: '',
      gender: '',
      email: '',
      birthday: null,
      phonenumber: '',
      defaultLanguage: '',
      loading: false,
    };
    this.user = {};
  }

  componentDidMount() {
    const { userSelfDetails } = this.props;
    this.setState(
      {
        id: userSelfDetails.details.id,
        firstName: userSelfDetails.details?.firstName,
        lastName: userSelfDetails.details?.lastName,
        email: userSelfDetails.details?.email,
        gender: userSelfDetails.details?.gender,
        phonenumber: userSelfDetails.details?.phonenumber,
        password: userSelfDetails.details?.password,
        birthday: userSelfDetails.details?.birthday,
        defaultLanguage: userSelfDetails.details?.defaultLanguage,
      },
      () => {
        this.user.id = userSelfDetails.details.id;
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userSelfDetails !== this.props.userSelfDetails) {
      if (this.props.userSelfDetails.loading) {
        this.setState({ loading: true });
      }
      if (
        this.props.userSelfDetails.details !== '' &&
        !this.props.userSelfDetails.loading &&
        !this.props.userSelfDetails.error
      ) {
        this.setState({ loading: false });
      }
    }
  }

  handleChange = (name) => (event) => {
    if (name === 'phonenumber') {
      if (event !== '') {
        this.user[name] = '+' + event;
      } else {
        this.user[name] = null;
      }
    } else {
      this.setState({ [name]: event.target.value });
      this.user[name] = event.target.value;
    }
  };

  handleChangeSelect(name, selectedItem) {
    this.setState({ [name]: selectedItem });
    this.user[name] = selectedItem ? selectedItem.value : '';
  }

  handleChangeDate = (date) => {
    this.setState({ birthday: formatDatePicker(date) });
    this.user['birthday'] = formatDatePicker(date);
  };

  handleChangeDateInput = (event) => {
    if (event.target.value === '') {
      this.setState({ birthday: null });
      this.user['birthday'] = null;
    }
  };

  handleSubmit = () => {
    this.props.putUserRequest(this.user);
  };

  render() {
    const {
      birthday,
      firstName,
      lastName,
      gender,
      email,
      phonenumber,
      defaultLanguage,
      loading,
    } = this.state;
    const { allowedLanguages } = this.props;

    return (
      <div>
        <div className='col-12'>
          <UnderlineTitle
            title={<IntlMessages id='appModule.general.info' />}
          />
          <div className='information'>
            <div className='col-12 col-lg-4 p-0'>
              <h4>
                <IntlMessages id='appModule.email' />
              </h4>
            </div>
            <div className='col-12 col-lg-8 p-0'>
              <TextField
                id='mail'
                variant='outlined'
                size='small'
                disabled
                fullWidth
                value={email}
                onChange={this.handleChange('email')}
              />
            </div>
          </div>
          <div className='information'>
            <div className='col-12 col-lg-4 p-0'>
              <h4>
                <IntlMessages id='appModule.first.name' />
              </h4>
            </div>
            <div className='col-12 col-lg-8 p-0'>
              <TextField
                id='firstName'
                variant='outlined'
                size='small'
                fullWidth
                value={firstName}
                onChange={this.handleChange('firstName')}
              />
            </div>
          </div>
          <div className='information'>
            <div className='col-12 col-lg-4 p-0'>
              <h4>
                <IntlMessages id='appModule.last.name' />
              </h4>
            </div>
            <div className='col-12 col-lg-8 p-0'>
              <TextField
                id='name'
                variant='outlined'
                size='small'
                fullWidth
                value={lastName}
                onChange={this.handleChange('lastName')}
              />
            </div>
          </div>
          <div className='information'>
            <div className='col-12 col-lg-4 p-0'>
              <h4>
                <IntlMessages id='appModule.gender' />
              </h4>
            </div>
            <div className='col-12 col-lg-8 p-0'>
              <Select
                fullWidth
                isClearable
                labelId='demo-simple-select-label'
                value={
                  gender
                    ? {
                        value: gender.value ? gender.value : gender,
                        label: SelectedGenre(
                          gender.value ? gender.value : gender
                        ),
                      }
                    : ''
                }
                onChange={(text) => this.handleChangeSelect('gender', text)}
                options={genreList}
              />
            </div>
          </div>
          <div className='information'>
            <div className='col-12 col-lg-4 p-0'>
              <h4>
                <IntlMessages id='appModule.languages' />
              </h4>
            </div>
            <div className='col-12 col-lg-8 p-0'>
              <Select
                fullWidth
                isLoading={this.props.allowedLanguages ? false : true}
                isClearable
                labelId='demo-simple-select-label'
                value={
                  defaultLanguage
                    ? {
                        value: defaultLanguage.value
                          ? defaultLanguage.value
                          : defaultLanguage,
                        label: defaultLanguage.value ? (
                          <IntlMessages id={defaultLanguage.value} />
                        ) : (
                          <IntlMessages id={defaultLanguage} />
                        ),
                      }
                    : ''
                }
                onChange={(text) =>
                  this.handleChangeSelect('defaultLanguage', text)
                }
                options={
                  allowedLanguages &&
                  allowedLanguages.map((data) => {
                    return {
                      value: data,
                      label: <IntlMessages id={data} />,
                    };
                  })
                }
              />
            </div>
          </div>
          <div className='information'>
            <div className='col-12 col-lg-4 p-0'>
              <h4>
                <IntlMessages id='appModule.birthday' />
              </h4>
            </div>
            <div className='col-12 col-lg-8 p-0'>
              <DatePicker
                keyboard
                disableFuture
                fullWidth
                placeholder={'DD/MM/YYYY'}
                variant='outlined'
                size='small'
                openTo='year'
                format='dd/MM/yyyy'
                views={['date', 'month', 'year', 'day']}
                value={birthday}
                onChange={(date) => this.handleChangeDate(date)}
                onInputChange={(text) => this.handleChangeDateInput(text)}
                mask={[
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
              />
            </div>
          </div>
          <div className='information'>
            <div className='col-12 col-lg-4 mt-2  p-0'>
              <h4>
                <IntlMessages id='appModule.phone' />
              </h4>
            </div>
            <div className='col-12 col-lg-8  p-0'>
              <PhoneInput
                specialLabel=''
                inputStyle={{ padding: '7px 10px 7px 58px', width: '100%' }}
                country={
                  phonenumber === null
                    ? this.props.projectDetails.defaultCountry &&
                      this.props.projectDetails.defaultCountry.toLowerCase()
                    : ''
                }
                value={phonenumber}
                size='small'
                onChange={this.handleChange('phonenumber')}
              />
            </div>
          </div>
        </div>
        <div className='col-12 d-flex justify-content-end mt-3'>
          <Button
            variant='outlined'
            onClick={this.handleSubmit}
            color='primary'
          >
            <IntlMessages id='appModule.save' />
          </Button>
        </div>
        {loading && <Loading />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  userSelfDetails: state.sdk.user.userSelfDetails,
  projectDetails: state.sdk.project.projectDetails,
  allowedLanguages: state.sdk.project.projectDetails.allowedLanguages,
  locale: state.sdk.settings.locale.locale,
});

export default connect(mapStateToProps, { putUserRequest })(PersonalContent);
