import React, { Component } from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { connect } from 'react-redux';
import ButtonsSuppliers from '../../../Buttons/ButtonsSuppliers';
import { Place } from '@material-ui/icons';
import IntlMessages from '../../../../util/IntlMessages';
import { updateDocumentStatusRequest } from '../../../../config/actions.config';
import { StatusComponent } from '../../../../config/components.config';
import '../cell.css';

class SuppliersCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showText: false,
    };
  }

  statusList = [
    {
      name: 'validate',
      text: <IntlMessages id='orders.table.type.status.validated' />,
    },
    {
      name: 'cancel',
      text: <IntlMessages id='orders.table.type.status.canceled' />,
    },
    {
      name: 'send',
      text: <IntlMessages id='orders.table.type.status.sended' />,
    },
    {
      name: 'delivery',
      text: <IntlMessages id='orders.table.type.status.delivered' />,
    },
  ];

  handlePutStatus(newStatus) {
    this.props.updateDocumentStatusRequest(this.props.cell.row.id, newStatus);
  }

  render() {
    const { cell } = this.props;

    let content = cell.value;
    if (cell.column.name === 'buttons') {
      content = <ButtonsSuppliers supplier={cell.row.supplier} />;
    } else if (cell.column.name === 'name') {
      content = <div className='nameCell'>{cell.row.name}</div>;
    } else if (cell.column.name === 'email') {
      if (content === null) {
        content = (
          <div className='noDataText'>
            <IntlMessages id='suppliers.no.mail' />
          </div>
        );
      }
    } else if (cell.column.name === 'address') {
      content = (
        <div className='alignItemsCenter'>
          <div>
            <Place />
          </div>
          <div style={{ marginLeft: '10px' }}>
            <div>
              {cell.row.supplier.company.address[0]
                ? cell.row.supplier.company.address[0].street
                  ? cell.row.supplier.company.address[0].street
                  : ''
                : ''}{' '}
              {cell.row.supplier.company.address[0]
                ? cell.row.supplier.company.address[0].streetNumber
                : ''}
            </div>
            <div>
              {cell.row.supplier.company.address[0]
                ? cell.row.supplier.company.address[0].zipCode
                : ''}{' '}
              {cell.row.supplier.company.address[0]
                ? cell.row.supplier.company.address[0].city
                : ''}
            </div>
          </div>
        </div>
      );
    } else if (cell.column.name === 'phone') {
      if (content === null || content === '') {
        content = (
          <div className='noDataText'>
            <IntlMessages id='suppliers.no.phone' />
          </div>
        );
      }
    } else if (cell.column.name === 'vat') {
      if (!content) {
        content = (
          <div className='noDataText'>
            <IntlMessages id='appModule.NA' />
          </div>
        );
      }
    } else if (cell.column.name === 'status') {
      content = (
        <div align='center'>
          <StatusComponent
            status={cell.row.status}
            clickable
            statusList={this.statusList}
            updateStatus={(newStatus) => this.handlePutStatus(newStatus)}
            label={
              <IntlMessages
                id={`orders.table.type.status.${cell.row.status}`}
              />
            }
            errorLabel={<IntlMessages id='appModule.status.unknown' />}
          />
        </div>
      );
    }
    return <Table.Cell style={cell.style}>{content}</Table.Cell>;
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
});

export default connect(mapStateToProps, {
  updateDocumentStatusRequest,
})(SuppliersCell);
