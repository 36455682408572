import React, { Component } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import '../assets/vendors/style';
import MainApp from '../app/index';
import RTL from '../util/RTL';
import frLocale from 'date-fns/locale/fr';
import defaultTheme from './themes/defaultTheme';
import AppLocale from '../lngProvider';
import SignIn from './SignIn';
import { setInitUrl } from '../config/actions.config';
import Error404 from '../components/Error404'

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/signin',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

class App extends Component {
  componentDidMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
  }

  render() {
    const {
      match,
      location,
      locale,
      authUser,
      initURL,
      isDirectionRTL,
    } = this.props;
    if (location.pathname === '/') {
      if (authUser === null) {
        return <Redirect to='/signin' />;
      }
      if (initURL === '' || initURL === '/' || initURL === '/signin') {
        return <Redirect to='/app/index' />;
      }
      return <Redirect to={initURL} />;
    }
    const applyTheme = createMuiTheme(defaultTheme);

    if (isDirectionRTL) {
      applyTheme.direction = 'rtl';
      document.body.classList.add('rtl');
    } else {
      document.body.classList.remove('rtl');
      applyTheme.direction = 'ltr';
    }

    const currentAppLocale = AppLocale[locale.locale];

    return (
      <MuiThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            <RTL>
              <div className={'app-main'}>
                <Switch>
                  <RestrictedRoute
                    path={`${match.url}app`}
                    authUser={authUser}
                    component={MainApp}
                  />
                  <Route path='/signin' component={SignIn} />

                  <Route component={Error404} />
                </Switch>
              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  const { sideNavColor, locale, isDirectionRTL } = state.sdk.settings;
  const { authUser, initURL } = state.sdk.auth;
  return {
    sideNavColor,
    locale,
    isDirectionRTL,
    authUser,
    initURL,
  };
};

export default connect(mapStateToProps, {
  setInitUrl
})(App);
