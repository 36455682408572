import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '../../../../../config/components.config';
import IntlMessages from '../../../../../util/IntlMessages';
import InventoryExportModalContent from './InventoryExportModalContent';

class InventoryExportModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posList: [],
      itemList: [],
    };
  }

  handleClose = () => this.props.close();

  render() {
    return (
      <Modal
        id="export-inventory-modal"
        title={<IntlMessages id="inventory.modal.export.title" />}
        width="md"
        open={this.props.open}
        close={this.handleClose}
      >
        <InventoryExportModalContent
          handleClose={this.handleClose}
        />
      </Modal>
    );
  }
}

InventoryExportModal.propTypes = {
  posList: PropTypes.array,
  itemList: PropTypes.array,
};

export default InventoryExportModal;
