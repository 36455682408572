import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  UnderlineTitle,
  Loading,
  ActionButton,
} from '../../../../config/components.config';
import IntlMessages from '../../../../util/IntlMessages';
import PosModalEdit from '../../PosModal/PosModalEdit/PosModalEdit';
import '../../index.css';

class PosInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalPos: false,
    };
  }

  render() {
    const { pos } = this.props;

    return (
      <>
        <div className='jr-card fullWidth'>
          <UnderlineTitle
            title={<IntlMessages id='pos.detail.card.info.title' />}
          >
            <ActionButton
              icon={'edit'}
              onClick={() => this.setState({ openModalPos: true })}
            />
          </UnderlineTitle>
          {pos ? (
            <>
              <div className={'information'}>
                <div className='mr-2'>
                  <b>
                    <IntlMessages id='pos.table.name' />:
                  </b>
                </div>
                <div>{pos.name}</div>
              </div>

              <div className={'information'}>
                <div className='mr-2'>
                  <b>
                    <IntlMessages id='pos.table.category' />:
                  </b>
                </div>
                {pos.posCategory.map((element, index) => {
                  return (
                    <div key={index} className='mr-1'>
                      {element.category.name}
                      {pos.posCategory.length !== index + 1 && `,`}
                    </div>
                  );
                })}
              </div>

              <div className={'information'}>
                <div className='mr-2'>
                  <b>
                    <IntlMessages id='pos.table.warehouse' />:
                  </b>
                </div>
                {pos.warehouse ? (
                  <div>
                    <IntlMessages id='stock.warehouse' />
                  </div>
                ) : (
                  <div>
                    <IntlMessages id='stock.store' />
                  </div>
                )}
              </div>
              <PosModalEdit
                pos={pos}
                open={this.state.openModalPos}
                close={() => this.setState({ openModalPos: false })}
              />
            </>
          ) : (
            <Loading />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
});

export default connect(mapStateToProps)(PosInfo);
