import React from 'react';
import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import { Close } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IntlMessages from '../../../../util/IntlMessages';
import '../cell.css';

const style = {
  paddingBottom: 15,
  paddingRight: 15,
  height: 65,
  width: '100%',
  paddingLeft: 0,
};

const FilterCell = ({ column, filter, onFilter, ...restProps }) => {
  const filterValue = filter && filter.value;
  const onChange = (e) =>
    onFilter({ columnName: column.name, value: e.target.value });

  const handleReset = () => {
    onFilter({ columnName: column.name, value: '' });
  };

  let content;
  if (column.name === 'name') {
    const defaultValue = JSON.parse(localStorage.getItem('paramsPos'))
      ? JSON.parse(localStorage.getItem('paramsPos'))[column.name]
        ? JSON.parse(localStorage.getItem('paramsPos'))[column.name].value
        : ''
      : '';
    content = (
      <TableFilterRow.Cell
        {...restProps}
        className="tableFilterRow"
        column={column}
      >
        <TextField
          label={<IntlMessages id="appModule.filter" />}
          value={defaultValue}
          onChange={onChange}
          InputProps={{
            endAdornment:
              filterValue || defaultValue ? (
                <IconButton onClick={handleReset} className={'p-0 m-0'}>
                  <Close />
                </IconButton>
              ) : (
                <IconButton />
              ),
          }}
        />
      </TableFilterRow.Cell>
    );
  } else if (column.name === 'warehouse') {
    const defaultValue = JSON.parse(localStorage.getItem('paramsPos'))
      ? JSON.parse(localStorage.getItem('paramsPos'))[column.name]
        ? JSON.parse(localStorage.getItem('paramsPos'))[column.name].value
        : 'all'
      : 'all';
    content = (
      <TableFilterRow.Cell {...restProps} style={style} column={column}>
        <FormControl style={{ width: '80%' }}>
          <InputLabel id="demo-simple-select-label">
            <IntlMessages id="appModule.filter" />
          </InputLabel>
          <Select
            value={defaultValue}
            autoWidth
            onChange={onChange}
            className="fullWidth"
          >
            <MenuItem value="all">
              <IntlMessages id="appModule.all" />
            </MenuItem>
            <MenuItem value="1">
              <IntlMessages id="stock.warehouse" />
            </MenuItem>
            <MenuItem value="0">
              <IntlMessages id="stock.store" />
            </MenuItem>
          </Select>
        </FormControl>
      </TableFilterRow.Cell>
    );
  } else if (column.name === 'posCategory') {
    const defaultValue = JSON.parse(localStorage.getItem('paramsPos'))
      ? JSON.parse(localStorage.getItem('paramsPos'))['posCategory.category.id']
        ? JSON.parse(localStorage.getItem('paramsPos'))[
            'posCategory.category.id'
          ].value
        : 'all'
      : 'all';
    let categories = column.categories;
    content = (
      <TableFilterRow.Cell {...restProps} style={style} column={column}>
        <FormControl style={{ width: '80%' }}>
          <InputLabel id="demo-simple-select-label">
            <IntlMessages id="appModule.filter" />
          </InputLabel>
          <Select
            value={defaultValue}
            autoWidth
            onChange={onChange}
            className="fullWidth"
          >
            <MenuItem key={0} value="all">
              <IntlMessages id="appModule.all" />
            </MenuItem>
            {categories &&
              categories.map((element, i) => {
                return (
                  <MenuItem key={i + 1} value={element.id}>
                    {element.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </TableFilterRow.Cell>
    );
  } else {
    content = (
      <TableFilterRow.Cell
        {...restProps}
        className="tableFilterRow"
        column={column}
      ></TableFilterRow.Cell>
    );
  }
  return content;
};

export default FilterCell;
