import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { createLogger } from 'redux-logger';
import reducers from '../config/reducers.config';
import Config from '../config/config.config';
import appReducers from '../ReduxSaga/reducers';
import appSaga from '../ReduxSaga/saga';
Config.setApiUrl(process.env.REACT_APP_URL_API);

const history = createBrowserHistory({ basename: '/stock/' });
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();
const loggerMiddleware = createLogger();
let middlewares = [sagaMiddleware, routeMiddleware];
if (process.env.REACT_APP_ENABLE_LOG === 'true') {
  middlewares.push(loggerMiddleware);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState) {
  const newRootReducer = combineReducers({
    sdk: reducers(history),
    app: appReducers(history),
  });

  const store = createStore(
    newRootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(appSaga);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../config/reducers.config', () => {
      const nextRootReducer = require('../config/reducers.config');
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}
export { history };
