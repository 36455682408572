import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from '../../../../../util/IntlMessages';
import { UncontrolledPopover } from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';
import {
  getItemsByProjectRequest,
  getPosByProjectRequest,
  LinkPosToItemRequest,
  LinkItemToPosRequest,
} from '../../../../../config/actions.config';
import '../../../index.css';

class ItemPosModalCreationContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pos: '',
      item: '',
      reorderLevel: '',
      reorderQuantity: '',
      targetStockLevel: '',
      maximumStockLevel: '',
      posList: [],
      itemList: [],
    };
    this.newItemPos = {
      reorderLevel: 0,
      reorderQuantity: 0,
      targetStockLevel: 0,
      maximumStockLevel: 0,
    };
    this.params = { limit: 5 };
  }

  componentDidMount() {
    if (this.props.pos) {
      this.props.getItemsByProjectRequest(this.props.idProject, this.params);
    }

    if (this.props.item) {
      this.props.getPosByProjectRequest(this.props.idProject, this.params);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.posList !== this.props.posList) {
      if (
        this.props.posList.pos !== '' &&
        !this.props.posList.error &&
        !this.props.posList.loading
      ) {
        this.setState({ posList: this.props.posList.pos });
      }
    }

    if (prevProps.itemsList !== this.props.itemsList) {
      if (
        this.props.itemsList.items !== '' &&
        !this.props.itemsList.error &&
        !this.props.itemsList.loading
      ) {
        this.setState({ itemList: this.props.itemsList.items });
      }
    }
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
    this.newItemPos[name] = parseInt(event.target.value, 10);
  };

  handleChangeSelect = (name) => (event) => {
    this.setState({ [name]: event.value });
  };

  handleChangePos = (text) => {
    if (text.length !== 0) {
      this.params['name'] = {};
      this.params['name'].operation = 'contain';
      this.params['name'].value = text;
    } else {
      delete this.params['name'];
      this.setState({ posList: [] });
    }

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.getPosByProjectRequest(this.props.idProject, this.params);
    }, 200);
  };

  handleChangeItem = (text) => {
    if (text.length !== 0) {
      this.params['name'] = {};
      this.params['name'].operation = 'contain';
      this.params['name'].value = text;
    } else {
      delete this.params['name'];
      this.setState({ itemList: [] });
    }

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.getItemsByProjectRequest(this.props.idProject, this.params);
    }, 100);
  };

  handleClose = () => this.props.handleClose();

  handLeSubmit = () => {
    if (Object.keys(this.newItemPos).length !== 0) {
      let arrayValue = Object.values(this.newItemPos);
      if (!arrayValue.some((v) => v < 0)) {
        if (this.props.pos) {
          this.props.LinkItemToPosRequest(
            this.props.id,
            this.state.item,
            this.newItemPos
          );
        }

        if (this.props.item) {
          this.props.LinkPosToItemRequest(
            this.props.id,
            this.state.pos,
            this.newItemPos
          );
        }
        this.props.handleClose();
      } else {
        NotificationManager.warning(
          <IntlMessages id='itemPos.warning.message.negative.add' />
        );
      }
    } else {
      this.props.handleClose();
    }
  };

  render() {
    const {
      reorderLevel,
      reorderQuantity,
      targetStockLevel,
      maximumStockLevel,
    } = this.state;
    const { pos, item } = this.props;

    return (
      <div>
        <div className='col-12'>
          {!pos && (
            <div className='information'>
              <div className='col-12 col-md-4'>
                <h4>
                  <IntlMessages id={'itemPos.modal.pos'} />
                </h4>
              </div>
              <div className='col-12 col-md-6'>
                <Select
                  id='type'
                  fullWidth
                  isClearable
                  value={pos}
                  onChange={this.handleChangeSelect('pos')}
                  onInputChange={(text) => this.handleChangePos(text)}
                  options={
                    this.state.posList &&
                    this.state.posList.map((data) => {
                      return {
                        value: data.id,
                        label: data.name,
                      };
                    })
                  }
                />
              </div>
            </div>
          )}

          {!item && (
            <div className='information'>
              <div className='col-12 col-md-4'>
                <h4>
                  <IntlMessages id={'itemPos.modal.item'} />
                </h4>
              </div>
              <div className='col-12 col-md-6'>
                <Select
                  fullWidth
                  isClearable
                  value={item}
                  onChange={this.handleChangeSelect('item')}
                  onInputChange={(text) => this.handleChangeItem(text)}
                  options={
                    this.state.itemList &&
                    this.state.itemList.map((data) => {
                      return {
                        value: data.id,
                        label: data.name,
                      };
                    })
                  }
                />
              </div>
            </div>
          )}

          <div className='information'>
            <div className='col-12 col-md-4'>
              <h4>
                <IntlMessages id={'itemPos.modal.reorderLevel'} />
                <i
                  className='zmdi zmdi-info infoIcon'
                  id={'Popoverbutton-reorderLevel'}
                  onMouseEnter={() =>
                    this.setState({ popoverReorderLevel: true })
                  }
                  onMouseLeave={() =>
                    this.setState({ popoverReorderLevel: false })
                  }
                />

                <UncontrolledPopover
                  placement='top'
                  isOpen={this.state.popoverReorderLevel}
                  target={'Popoverbutton-reorderLevel'}
                  className='UncontrolledPopover'
                >
                  <IntlMessages id={'itemPos.modal.reorderLevel.popover'} />
                </UncontrolledPopover>
              </h4>
            </div>
            <div className='col-12 col-md-6'>
              <TextField
                type='number'
                InputProps={{ inputProps: { min: 0 } }}
                variant='outlined'
                size='small'
                fullWidth
                value={reorderLevel}
                onChange={this.handleChange('reorderLevel')}
              />
            </div>
          </div>

          <div className='information'>
            <div className='col-12 col-md-4'>
              <h4>
                <IntlMessages id={'itemPos.modal.reorderQuantity'} />
                <i
                  className='zmdi zmdi-info infoIcon'
                  id={'Popoverbutton-reorderQuantity'}
                  onMouseEnter={() =>
                    this.setState({ popoverReorderQuantity: true })
                  }
                  onMouseLeave={() =>
                    this.setState({ popoverReorderQuantity: false })
                  }
                />

                <UncontrolledPopover
                  placement='top'
                  isOpen={this.state.popoverReorderQuantity}
                  target={'Popoverbutton-reorderQuantity'}
                  className='UncontrolledPopover'
                >
                  <IntlMessages id={'itemPos.modal.reorderQuantity.popover'} />
                </UncontrolledPopover>
              </h4>
            </div>
            <div className='col-12 col-md-6'>
              <TextField
                type='number'
                InputProps={{ inputProps: { min: 0 } }}
                variant='outlined'
                size='small'
                fullWidth
                value={reorderQuantity}
                onChange={this.handleChange('reorderQuantity')}
              />
            </div>
          </div>

          <div className='information'>
            <div className='col-12 col-md-4'>
              <h4>
                <IntlMessages id={'itemPos.modal.targetStockLevel'} />
                <i
                  className='zmdi zmdi-info infoIcon'
                  id={'Popoverbutton-targetStockLevel'}
                  onMouseEnter={() =>
                    this.setState({ popoverTargetStockLevel: true })
                  }
                  onMouseLeave={() =>
                    this.setState({ popoverTargetStockLevel: false })
                  }
                />

                <UncontrolledPopover
                  placement='top'
                  isOpen={this.state.popoverTargetStockLevel}
                  target={'Popoverbutton-targetStockLevel'}
                  className='UncontrolledPopover'
                >
                  <IntlMessages id={'itemPos.modal.targetStockLevel.popover'} />
                </UncontrolledPopover>
              </h4>
            </div>
            <div className='col-12 col-md-6'>
              <TextField
                type='number'
                InputProps={{ inputProps: { min: 0 } }}
                variant='outlined'
                size='small'
                fullWidth
                value={targetStockLevel}
                onChange={this.handleChange('targetStockLevel')}
              />
            </div>
          </div>

          <div className='information'>
            <div className='col-12 col-md-4'>
              <h4>
                <IntlMessages id={'itemPos.modal.maximumStockLevel'} />
                <i
                  className='zmdi zmdi-info infoIcon'
                  id={'Popoverbutton-maximumStockLevel'}
                  onMouseEnter={() =>
                    this.setState({ popoverMaximumStockLevel: true })
                  }
                  onMouseLeave={() =>
                    this.setState({ popoverMaximumStockLevel: false })
                  }
                />

                <UncontrolledPopover
                  placement='top'
                  isOpen={this.state.popoverMaximumStockLevel}
                  target={'Popoverbutton-maximumStockLevel'}
                  className='UncontrolledPopover'
                >
                  <IntlMessages
                    id={'itemPos.modal.maximumStockLevel.popover'}
                  />
                </UncontrolledPopover>
              </h4>
            </div>
            <div className='col-12 col-md-6'>
              <TextField
                type='number'
                InputProps={{ inputProps: { min: 0 } }}
                variant='outlined'
                size='small'
                fullWidth
                value={maximumStockLevel}
                onChange={this.handleChange('maximumStockLevel')}
              />
            </div>
          </div>

          <div className='d-flex justify-content-end my-4'>
            <Button onClick={this.handleClose} color='primary'>
              <IntlMessages id='appModule.cancel' />
            </Button>
            <Button
              id='save'
              variant='outlined'
              onClick={this.handLeSubmit}
              color='primary'
            >
              <IntlMessages id='appModule.save' />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  posList: state.sdk.pos.posList,
  itemsList: state.sdk.items.itemsList,
});

export default connect(mapStateToProps, {
  getItemsByProjectRequest,
  getPosByProjectRequest,
  LinkPosToItemRequest,
  LinkItemToPosRequest,
})(ItemPosModalCreationContent);
