import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from '../../../../util/IntlMessages';
import {
  getCategoriesByProjectRequest,
  postPosRequest,
  putPosCategoryRequest,
} from '../../../../config/actions.config';
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';
import SelectHelper from '../../../../components/Select';
import '../../index.css';

class PosModalCreationContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      description: '',
      warehouse: false,
      loadingSend: false,
    };
    this.pos = {
      name: '',
      description: '',
      warehouse: false,
    };
    this.posCategory = {
      categories: [],
    };
  }

  componentDidMount() {
    this.props.getCategoriesByProjectRequest(this.props.idProject, {
      type: 'pos',
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.newPos !== this.props.newPos) {
      if (
        this.props.newPos.pos !== '' &&
        !this.props.newPos.loading &&
        !this.props.newPos.error
      ) {
        this.props.putPosCategoryRequest(
          this.props.newPos.pos.id,
          this.posCategory
        );
      }
    }
  }

  handleChange = (name) => (event) => {
    if (name === 'warehouse') {
      this.setState({ [name]: event });
      this.pos[name] = event.value;
    } else {
      this.setState({ [name]: event.target.value });
      this.pos[name] = event.target.value;
    }
  };

  handleChangePos = (selectedPos) => {
    this.setState({ posCategory: selectedPos });

    let posCategory = [];

    selectedPos.forEach((data) => {
      posCategory.push({ category: data });
    });

    this.posCategory = {
      categories: posCategory,
    };
  };

  handleClose = () => {
    this.props.handleClose();
  };

  handLeSubmit = () => {
    if (this.pos.name !== '') {
      this.setState({ loadingSend: true });
      this.props.postPosRequest(this.props.idProject, this.pos);
      this.props.handleClose();
    } else {
      NotificationManager.warning(
        <IntlMessages id='pos.warning.message.name' />
      );
    }
  };

  render() {
    const {
      name,
      description,
      posCategory,
      warehouse,
      loadingSend,
    } = this.state;
    const { categoriesList } = this.props;

    return (
      <div>
        <div className='col-12'>
          <div className='information'>
            <div className='col-12 col-md-4'>
              <h4>
                <IntlMessages id={'pos.modal.name'} />
              </h4>
            </div>
            <div className='col-12 col-md-6'>
              <TextField
                id='name'
                variant='outlined'
                size='small'
                fullWidth
                value={name}
                onChange={this.handleChange('name')}
              />
            </div>
          </div>

          <div className='information'>
            <div className='col-12 col-md-4 mt-3'>
              <h4>
                <IntlMessages id={'pos.modal.description'} />
              </h4>
            </div>
            <div className='col-12 col-md-6'>
              <TextField
                id='description'
                multiline
                rows='4'
                fullWidth
                value={description}
                onChange={this.handleChange('description')}
                variant='outlined'
              />
            </div>
          </div>

          <div className='information'>
            <div className='col-12 col-md-4 mt-3'>
              <h4>
                <IntlMessages id={'pos.modal.category'} />
              </h4>
            </div>
            <div className='col-12 col-md-6'>
              <SelectHelper
                id='categoryList'
                creatable={false}
                labelSelect={'name'}
                listItems={categoriesList}
                items={posCategory}
                updateItem={this.handleChangePos}
              />
            </div>
          </div>

          <div className='information'>
            <div className='col-12 col-md-4 mt-3'>
              <h4>
                <IntlMessages id={'pos.modal.type'} />
              </h4>
            </div>
            <div className='col-12 col-md-6'>
              <Select
                id='type'
                fullWidth
                value={warehouse}
                onChange={this.handleChange('warehouse')}
                options={[
                  {
                    value: true,
                    label: <IntlMessages id='stock.warehouse' />,
                  },
                  {
                    value: false,
                    label: <IntlMessages id='stock.store' />,
                  },
                ]}
              />
            </div>
          </div>

          <div className='d-flex justify-content-end my-4'>
            <Button
              onClick={this.handleClose}
              color='primary'
              disabled={loadingSend}
            >
              <IntlMessages id='appModule.cancel' />
            </Button>
            <Button
              id="save"
              variant='outlined'
              onClick={this.handLeSubmit}
              color='primary'
              disabled={loadingSend}
            >
              <IntlMessages id='appModule.save' />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  newPos: state.sdk.pos.newPos,
  categoriesList: state.sdk.categories.categoriesList.categories,
});

export default connect(mapStateToProps, {
  getCategoriesByProjectRequest,
  postPosRequest,
  putPosCategoryRequest,
})(PosModalCreationContent);
