import enLang from './entries/en';
import en_caLang from './entries/en-CA';
import frLang from './entries/fr';
import nlLang from './entries/nl';
import fr_caLang from './entries/fr-CA';
import deLang from './entries/de';

const AppLocale = {
    'en': enLang,
    'en-CA': en_caLang,
    'nl': nlLang,
    'fr': frLang,
    'fr-CA': fr_caLang,
    'de': deLang,
};

export default AppLocale;
