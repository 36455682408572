import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { ActionButton } from '../../config/components.config';

class ButtonsDeliveries extends Component {
  render() {
    return (
      <>
        {this.props.deliveryType === 'supplierDelivery' && (
          <div className={'btn-action-container'}>
            <NavLink to={'show-supplier-delivery/' + this.props.idDelivery}>
              <ActionButton icon={'view'} />
            </NavLink>
          </div>
        )}

        {this.props.deliveryType === 'internDelivery' && (
          <div className={'btn-action-container'}>
            <NavLink to={'show-intern-delivery/' + this.props.idDelivery}>
              <ActionButton icon={'view'} />
            </NavLink>
          </div>
        )}
      </>
    );
  }
}


export default ButtonsDeliveries;
