import React, { Component } from 'react';
import { Modal } from '../../../../config/components.config';
import IntlMessages from '../../../../util/IntlMessages';
import PosModalCreationContent from './PosModalCreationContent';

class PosModalCreation extends Component {
  handleClose = () => this.props.close();

  render() {
    return (
      <Modal
        title={<IntlMessages id={'pos.modal.creation.title'} />}
        width='md'
        open={this.props.open}
        close={this.handleClose}
      >
        <PosModalCreationContent handleClose={this.handleClose} />
      </Modal>
    );
  }
}

export default PosModalCreation;
