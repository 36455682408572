import React from 'react';
import { connect } from 'react-redux';
import ItemPosTable from './ItemPosTable';
import IntlMessages from '../../../util/IntlMessages';
import { getItemPosByProjectRequest } from '../../../config/actions.config';

class ItemPos extends React.Component {

  render() {

    return (
        <div className='app-wrapper div-wrapper-title'>
          <div className='row headerAlign'>
            <div className='col-lg-9 col-md-9 col-sm-7 page-title'>
              <IntlMessages id='itemPos.title' />
            </div>
            <div className='col-lg-3 col-md-3 col-sm-5' />
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='jr-card'>
                <ItemPosTable />
              </div>
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
});

export default connect(mapStateToProps, {
  getItemPosByProjectRequest,
})(ItemPos);
