import React, { Component } from 'react';
import { connect } from 'react-redux';
import ItemPosModalEdit from '../../app/Stock/ItemPos/ItemPosModal/ItemPosModalEdit/ItemPosModalEdit';
import SweetAlert from 'react-bootstrap-sweetalert';
import IntlMessages from '../../util/IntlMessages';
import { ActionButton } from '../../config/components.config';
import { deleteItemPosRequest } from '../../config/actions.config';
import { BiTransfer } from 'react-icons/bi';
import ItemPosModalQuantity from '../../app/Stock/ItemPos/ItemPosModal/ItemPosModalQuantity/ItemPosModalQuantity';

class ButtonsItemPos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalEditionItemPos: false,
      openModalEditQuantity: false,
      isModalForAdd: false,
      isClosed: false,
    };
  }
  handleDelete = () => {
    this.setState({
      isClosed: true,
    });
  };

  render() {
    const {
      openModalEditionItemPos,
      openModalEditQuantity,
    } = this.state;
    return (
      <div className={'btn-action-container'}>
        <ActionButton
          onClick={() =>
            this.setState({ openModalEditQuantity: true })
          }
        >
          <BiTransfer />
        </ActionButton>
        <ActionButton
          icon={'edit'}
          onClick={() => this.setState({ openModalEditionItemPos: true })}
        />
        <ActionButton icon={'delete'} onClick={this.handleDelete} />
        <ItemPosModalEdit
          itemPos={this.props.itemPos}
          open={openModalEditionItemPos}
          close={() => this.setState({ openModalEditionItemPos: false })}
        />

        <ItemPosModalQuantity
          item={this.props.item}
          pos={this.props.pos}
          itemPosId={this.props.itemPos.id}
          open={openModalEditQuantity}
          close={() => this.setState({ openModalEditQuantity: false })}
        />

        <SweetAlert
          show={this.state.isClosed}
          style={{ whiteSpace: 'normal' }}
          warning
          showCancel
          confirmBtnText={<IntlMessages id='appModule.yes' />}
          cancelBtnText={<IntlMessages id='appModule.cancel' />}
          confirmBtnBsStyle='danger'
          title={<IntlMessages id='appModule.modal.delete.confirm.message' />}
          titleBsStyle='default'
          onConfirm={() => {
            this.props.deleteItemPosRequest(this.props.itemPos.id);
            this.setState({ isClosed: false });
          }}
          onCancel={() => {
            this.setState({ isClosed: false });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
});

export default connect(mapStateToProps, {
  deleteItemPosRequest,
})(ButtonsItemPos);
