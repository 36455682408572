import React from 'react';
import { connect } from 'react-redux';
import SuppliersTable from './SuppliersTable';
import SuppliersModalCreation from './SuppliersModal/SuppliersModalCreation/SuppliersModalCreation';
import IntlMessages from '../../util/IntlMessages';
import { MainButton } from '../../config/components.config';

class Suppliers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalSupplier: false,
    };
  }

  render() {
    const { openModalSupplier } = this.state;

    return (
      <div className='app-wrapper div-wrapper-title'>
        <div className='row headerAlign'>
          <div className='col-lg-9 col-md-9 col-sm-7 page-title'>
            <IntlMessages id='suppliers.title' />
          </div>
          <div className='col-lg-3 col-md-3 col-sm-5 btn-add-container'>
            <MainButton
              id='addSupplier'
              onClick={() => this.setState({ openModalSupplier: true })}
            >
              <IntlMessages id='suppliers.button.add' />
            </MainButton>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='jr-card' style={{ paddingTop: 7 }}>
              <SuppliersTable />
            </div>
          </div>
          <SuppliersModalCreation
            open={openModalSupplier}
            close={() => this.setState({ openModalSupplier: false })}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.sdk.auth,
  idProject: state.sdk.user.currentProject.idCurrentProject,
});

export default connect(mapStateToProps)(Suppliers);
