import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import IntlMessages from '../../util/IntlMessages';
import SuppliersModalEdit from '../../app/Suppliers/SuppliersModal/SuppliersModalEdit/SuppliersModalEdit';
import { NavLink } from 'react-router-dom';
import { deleteSupplierRequest } from '../../config/actions.config';
import { ActionButton } from '../../config/components.config';

class ButtonsSuppliers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalSuppliers: false,
      isClosed: false,
    };
  }

  handleOpenDelete = () => {
    this.setState({
      isClosed: true,
    });
  };

  handleDelete = () => {
    this.props.deleteSupplierRequest(this.props.supplier.id);
    this.setState({
      isClosed: false,
    });
  };

  render() {
    const { openModalSuppliers } = this.state;
    const { supplier } = this.props;
    return (
      <div className={'btn-action-container'}>
        <NavLink to={'show-supplier/' + supplier.id}>
          <ActionButton icon={'view'} />
        </NavLink>
        <ActionButton
          icon={'edit'}
          id={supplier.id}
          className='editSupplier'
          onClick={() => this.setState({ openModalSuppliers: true })}
        />
        <ActionButton
          icon={'delete'}
          className='deleteSupplier'
          id={supplier.id}
          onClick={this.handleOpenDelete}
        />

        <SuppliersModalEdit
          supplier={supplier}
          open={openModalSuppliers}
          close={() => this.setState({ openModalSuppliers: false })}
        />

        <SweetAlert
          show={this.state.isClosed}
          style={{ whiteSpace: 'normal' }}
          warning
          showCancel
          confirmBtnText={<IntlMessages id='appModule.yes' />}
          cancelBtnText={<IntlMessages id='appModule.cancel' />}
          confirmBtnBsStyle='danger'
          title={<IntlMessages id='appModule.modal.delete.confirm.message' />}
          titleBsStyle='default'
          onConfirm={() => this.handleDelete()}
          confirmBtnCssClass='confirmDeleteSupplier'
          onCancel={() => {
            this.setState({ isClosed: false });
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  posDetails: state.sdk.pos.posDetails,
});

export default connect(mapStateToProps, { deleteSupplierRequest })(
  ButtonsSuppliers,
);
