import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import CustomScrollbars from '../../util/CustomScrollbars';
import IntlMessages from '../../util/IntlMessages';

function TabContainer({ children, dir }) {
  return (
    <div dir={dir}>
      <ul className="nav-menu">{children}</ul>
    </div>
  );
}

TabContainer.propTypes = {
  dir: PropTypes.string.isRequired,
};

class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `#${history.location.pathname}`; // get current path

    const menuLi = document.getElementsByClassName('menu');
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function () {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, 'li');
          if (
            menuLi[j] !== this &&
            (parentLi === null || !parentLi.classList.contains('open'))
          ) {
            menuLi[j].classList.remove('open');
          }
        }
        this.classList.toggle('open');
      };
    }

    const activeLi = document.querySelector(`a[href="${pathname}"]`); // select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav) {
        if (activeNav.classList.contains('sub-menu')) {
          this.closest(activeNav, 'li').classList.add('open');
        } else {
          this.closest(activeLi, 'li').classList.add('open');
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  componentDidUpdate(prevProps) {
    const { history } = prevProps;
    const pathname = `${history.location.pathname}`; // get current path

    const activeLi = document.querySelector(`a[href="${pathname}"]`); // select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav) {
        if (activeNav.classList.contains('sub-menu')) {
          this.closest(activeNav, 'li').classList.add('open');
        } else {
          this.closest(activeLi, 'li').classList.add('open');
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        'matches',
        'webkitMatchesSelector',
        'mozMatchesSelector',
        'msMatchesSelector',
        'oMatchesSelector',
      ].some((fn) => {
        if (typeof document.body[fn] === 'function') {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {
      console.error(e);
    }

    return null;
  }

  render() {
    const { history } = this.props;
    const that = this;
    const pathname = `#${history.location.pathname}`; // get current path

    const menuLi = document.getElementsByClassName('menu');
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function () {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, 'li');
          if (
            menuLi[j] !== this &&
            (parentLi === null || !parentLi.classList.contains('open'))
          ) {
            menuLi[j].classList.remove('open');
          }
        }
        this.classList.toggle('open');
      };
    }

    const activeLi = document.querySelector(`a[href="${pathname}"]`); // select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav) {
        if (activeNav.classList.contains('sub-menu')) {
          this.closest(activeNav, 'li').classList.add('open');
        } else {
          this.closest(activeLi, 'li').classList.add('open');
        }
      }
    } catch (error) {
      console.error(error);
    }

    return (
      <CustomScrollbars className=" scrollbar">
        <TabContainer dir="menu">
          {/* En attendant L'api
          <li className='ui_tooltip menu'>
            <Button className='void title-sidebar'>
              <i className='zmdi zmdi-view-dashboard zmdi-hc-fw' />
              <span className='nav-text'>
                <IntlMessages id='sidenav.dashboard' />
              </span>
            </Button>
            <ul className='sub-menu'>
              <li>
                <NavLink className='prepend-icon' to='/app/dashboardOne'>
                  <span className='nav-text'>
                    change id intlMessage
                    <IntlMessages id='sidenav.dashboard.one' />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink className='prepend-icon' to='/app/dashboardTwo'>
                  <span className='nav-text'>
                    change id intlMessage
                    <IntlMessages id='sidenav.dashboard.two' />
                  </span>
                </NavLink>
              </li>
            </ul>
          </li> */}

          <li className="menu no-arrow">
            <NavLink className="prepend-icon title-sidebar" to="/app/items">
              <i className="zmdi zmdi-storage zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidenav.items" />
              </span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink className="prepend-icon title-sidebar" to="/app/pos">
              <i className="zmdi zmdi-store zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidenav.pos" />
              </span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink className="prepend-icon title-sidebar" to="/app/suppliers">
              <i className="zmdi zmdi-accounts zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidenav.suppliers" />
              </span>
            </NavLink>
          </li>

          <li className="ui_tooltip menu">
            <Button className="void title-sidebar">
              <i className="zmdi zmdi-layers zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidenav.stock.management" />
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/stock">
                  <span className="nav-text">
                    <IntlMessages id="sidenav.stock" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/movements">
                  <span className="nav-text">
                    <IntlMessages id="sidenav.movements" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/out-of-order">
                  <span className="nav-text">
                    <IntlMessages id="sidenav.outOfOrder" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/inventory">
                  <span className="nav-text">
                    <IntlMessages id="sidenav.inventory" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="ui_tooltip menu">
            <Button className="void title-sidebar">
              <i className="zmdi zmdi-shopping-basket zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidenav.order" />
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/orders-suppliers">
                  <span className="nav-text">
                    <IntlMessages id="sidenav.order.suppliers" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/orders-intern">
                  <span className="nav-text">
                    <IntlMessages id="sidenav.order.internal" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>
          {/* En attendant L'api
          <li className="ui_tooltip menu">
            <Button className="void title-sidebar">
              <i className="zmdi zmdi-truck zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidenav.delivery" />
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/delivery-suppliers">
                  <span className="nav-text">
                    <IntlMessages id="sidenav.delivery.suppliers" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/delivery-internal">
                  <span className="nav-text">
                    <IntlMessages id="sidenav.delivery.internal" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>
          */}
        </TabContainer>
      </CustomScrollbars>
    );
  }
}

export default withRouter(SidenavContent);
