import React, { Component } from 'react';
import { connect } from 'react-redux';
import IntlMessages from '../../../util/IntlMessages';
import {
  UnderlineTitle,
  Loading,
  ActionButton,
} from '../../../config/components.config';
import ColumnCell from '../../../components/Table/Columns/ColumnCell';
import {
  columnsOrdersDetail,
  tableColumnExtensionsOrdersDetail,
} from '../../../components/Table/Columns/ShowColumns';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditColumn,
} from '@devexpress/dx-react-grid-material-ui';
import {
  getDocumentsLinesRequest,
  postDocumentsLinesRequest,
  deleteDocumentsLineRequest,
  editDocumentsLineRequest,
  setGlobalError,
} from '../../../config/actions.config';
import NoData from '../../../components/Table/NoData/NoData';
import OrderItemCell from '../../../components/Table/Cells/OrderItem/OrderItemCell';
import { Save, Cancel } from '@material-ui/icons';
import { Getter } from '@devexpress/dx-react-core';
import { NotificationManager } from 'react-notifications';
import '../index.css';

const getRowId = (row) => row.id;

class DetailDeliveryItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      calculatePrice: null,
      disabled: false,
    };
    this.newItemId = {};
    this.newItem = {};
  }

  EditButton = ({ onExecute, disableButton }) => (
    <ActionButton
      icon={'edit'}
      onClick={() => {
        disableButton();
        onExecute();
      }}
      disabled={this.state.disabled}
    />
  );

  DeleteButton = ({ onExecute }) => (
    <ActionButton
      icon={'delete'}
      onClick={onExecute}
      disabled={this.state.disabled}
    />
  );

  CommitButton = ({ onExecute, disableButton }) => (
    <ActionButton
      onClick={() => {
        onExecute();
        disableButton();
      }}
    >
      <Save style={{ fontSize: 17 }} />
    </ActionButton>
  );

  CancelButton = ({ onExecute, resetNewItem, disableButton }) => (
    <ActionButton
      onClick={() => {
        resetNewItem();
        onExecute();
        disableButton();
      }}
    >
      <Cancel style={{ fontSize: 17, color: 'red' }} />
    </ActionButton>
  );

  AddButton = ({ onExecute, disableButton }) => (
    <ActionButton
      icon={'add'}
      onClick={() => {
        disableButton();
        onExecute();
      }}
      disabled={this.state.disabled}
    />
  );

  commandComponents = {
    add: this.AddButton,
    edit: this.EditButton,
    delete: this.DeleteButton,
    commit: this.CommitButton,
    cancel: this.CancelButton,
  };

  Command = ({ id, onExecute }) => {
    const CommandButton = this.commandComponents[id];
    return (
      <CommandButton
        onExecute={onExecute}
        resetNewItem={this.resetNewItem}
        disableButton={() => this.disableButton(id)}
      />
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      if (!this.props.loading) {
        this.props.getDocumentsLinesRequest(this.props.id);
      }
    }

    if (prevProps.documentsLinesList !== this.props.documentsLinesList) {
      if (this.props.documentsLinesList.loading) {
        this.setState({ loading: true });
      }
      if (
        !this.props.documentsLinesList.loading &&
        !this.props.documentsLinesList.error
      ) {
        this.setState({
          loading: false,
          object_count: this.props.documentsLinesList.meta.object_count,
        });
      }
    }

    if (prevProps.newDocumentsLines !== this.props.newDocumentsLines) {
      if (
        !this.props.newDocumentsLines.loading &&
        !this.props.newDocumentsLines.error
      ) {
        this.props.refresh();
        this.props.getDocumentsLinesRequest(this.props.id);
        NotificationManager.success(
          <IntlMessages id="orders.detail.item.message.success.added" />
        );
      }
    }

    if (prevProps.updatedDocumentsLine !== this.props.updatedDocumentsLine) {
      if (
        !this.props.updatedDocumentsLine.loading &&
        !this.props.updatedDocumentsLine.error
      ) {
        this.props.refresh();
        this.props.getDocumentsLinesRequest(this.props.id);
        NotificationManager.success(
          <IntlMessages id="orders.detail.item.message.success.updated" />
        );
      }
    }

    if (prevProps.deletedDocumentsLine !== this.props.deletedDocumentsLine) {
      if (
        !this.props.deletedDocumentsLine.loading &&
        !this.props.deletedDocumentsLine.error
      ) {
        this.props.refresh();
        this.props.getDocumentsLinesRequest(this.props.id);
        NotificationManager.success(
          <IntlMessages id="orders.detail.item.message.success.deleted" />
        );
      }
    }

    if (prevProps.globalError !== this.props.globalError) {
      if (this.props.globalError === true) {
        NotificationManager.error(
          <IntlMessages id="appModule.message.error" />
        );
        this.props.setGlobalError(false);
      }
    }
  }

  resetNewItem = () => (this.newItem = {});

  disableButton = (id) => {
    if (id === 'edit' || id === 'add') {
      this.setState({ disabled: true });
    }
    if (id === 'cancel' || id === 'commit') {
      this.setState({ disabled: false });
    }
  };

  setNewItem = (name, event) => {
    this.newItem[name] = event;
  };

  render() {
    const { documentsLinesList } = this.props;
    const rows = [];
    if (documentsLinesList && documentsLinesList.documentsLines) {
      documentsLinesList.documentsLines.forEach((data, i) => {
        rows[i] = {
          id: data.id,
          item: data.item?.name,
          priceWithoutVat: data.priceWithoutVat,
          quantity: data.quantity,
          totalWithoutVat: data.totalWithoutVat,
          packUnitName: data.packUnitName,
          vatrate: data.vatrate,
          locale: this.props.locale,
          currency:
            this.props.projectDetails && this.props.projectDetails !== ''
              ? this.props.projectDetails.projectFinance.defaultCurrency
              : null,
        };
      });
    }

    return (
      <>
        <div className="jr-card fullWidth">
          <UnderlineTitle
            title={<IntlMessages id="orders.detail.card.items.title" />}
          />

          <Grid rows={rows} columns={columnsOrdersDetail} getRowId={getRowId}>
            <Table
              columnExtensions={tableColumnExtensionsOrdersDetail}
              cellComponent={OrderItemCell}
              noDataCellComponent={NoData}
            />
            <TableHeaderRow cellComponent={ColumnCell} />
            <Getter
              name="tableColumns"
              computed={({ tableColumns }) => {
                const result = [
                  ...tableColumns.filter(
                    (c) => c.type !== TableEditColumn.COLUMN_TYPE
                  ),
                  {
                    key: 'editCommand',
                    type: TableEditColumn.COLUMN_TYPE,
                    width: 120,
                  },
                ];
                return result;
              }}
            />
          </Grid>
          {/* *********** REMOVE FOR THE MOMENT **************
          {!this.state.loading && this.state.object_count !== 0 && (
            <div className='priceContainer'>
              <div style={{ width: '40%' }}>
                <div className='priceItem'>
                  <span>
                    <b>
                      <IntlMessages id='delivery.detail.item.price.subtotal' />
                    </b>
                  </span>
                  <div>
                    {formatMoney(
                      this.props.projectDetails &&
                        this.props.projectDetails.projectFinance.defaultCurrency
                          .isoCode,
                      this.props.locale,
                      this.props.totalWithoutVat,
                    )}
                  </div>
                </div>
                <div className='priceItem'>
                  <span>
                    <b>
                      <IntlMessages id='delivery.detail.item.price.vat' />
                    </b>
                  </span>
                  <div>
                    {formatMoney(
                      this.props.projectDetails &&
                        this.props.projectDetails.projectFinance.defaultCurrency
                          .isoCode,
                      this.props.locale,
                      this.props.totalWithVat - this.props.totalWithoutVat,
                    )}
                  </div>
                </div>
                <div className='priceItem'>
                  <span>
                    <b>
                      <IntlMessages id='delivery.detail.item.price.total' />
                    </b>
                  </span>
                  <div>
                    {formatMoney(
                      this.props.projectDetails &&
                        this.props.projectDetails.projectFinance.defaultCurrency
                          .isoCode,
                      this.props.locale,
                      this.props.totalWithVat,
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          */}
          {this.state.loading && <Loading />}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  documentsLinesList: state.sdk.documents.documentsLinesList,
  newDocumentsLines: state.sdk.documents.newDocumentsLines,
  updatedDocumentsLine: state.sdk.documents.updatedDocumentsLine,
  deletedDocumentsLine: state.sdk.documents.deletedDocumentsLine,
  locale: state.sdk.settings.locale.locale,
  projectDetails: state.sdk.project.projectDetails,
  globalError: state.sdk.auth.globalError,
});

export default connect(mapStateToProps, {
  getDocumentsLinesRequest,
  postDocumentsLinesRequest,
  deleteDocumentsLineRequest,
  editDocumentsLineRequest,
  setGlobalError,
})(DetailDeliveryItems);
