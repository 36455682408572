import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from '../../../../../util/IntlMessages';
import { UncontrolledPopover } from 'reactstrap';
import Select from 'react-select';
import {
  getItemsByProjectRequest,
  postSupplierItemRequest,
} from '../../../../../config/actions.config';
import Switch from '@material-ui/core/Switch';

class ItemSupplierModalCreationContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: '',
      defaultItem: false,
      supplierProductName: '',
      supplierProductReference: '',
      minimumOrderQuantity: 0,
      maximumOrderQuantity: 0,
      quantityPerPack: 0,
      purchasePriceExclTax: 0,
      packUnitName: '',
      popoverReference: false,
      popoverSupplierProductName: false,
      popoverSupplierDefaultItem: false,
      popoverPurchasePrice: false,
      popoverMinOrderQtty: false,
      popoverMaxOrderQtty: false,
      popoverQttyPack: false,
      popoverPackUnit: false,
      posList: [],
      itemList: [],
    };
    this.newItemSupplier = {
      supplierProductName: '',
      supplierProductReference: '',
      minimumOrderQuantity: null,
      maximumOrderQuantity: null,
      quantityPerPack: null,
      packUnitName: '',
      default: false,
    };
    this.params = { limit: 5 };
  }

  componentDidMount() {
    this.props.getItemsByProjectRequest(this.props.idProject, this.params);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.itemsList !== this.props.itemsList) {
      if (
        this.props.itemsList.items !== '' &&
        !this.props.itemsList.error &&
        !this.props.itemsList.loading
      ) {
        this.setState({ itemList: this.props.itemsList.items });
      }
    }
  }

  handleChangeDefault = (event) => {
    this.setState({ defaultItem: event.target.checked });
    this.newItemSupplier['default'] = event.target.checked;
  };

  handleChange = (name) => (event) => {
    if (
      name === 'supplierProductName' ||
      name === 'supplierProductReference' ||
      name === 'packUnitName'
    ) {
      this.setState({ [name]: event.target.value });
      this.newItemSupplier[name] = event.target.value;
    } else if (name === 'purchasePriceExclTax') {
      this.setState({ [name]: event.target.value });
      this.newItemSupplier[name] = event.target.value;
    } else {
      this.setState({ [name]: event.target.value });
      this.newItemSupplier[name] = parseInt(event.target.value, 10);
    }
  };

  handleChangeSelect = (name) => (event) => {
    this.setState({ [name]: event.value, supplierProductName: event.label });
    this.newItemSupplier['supplierProductName'] = event.label;
  };

  handleChangeItem = (text) => {
    if (text.length !== 0) {
      this.params['name'] = {};
      this.params['name'].operation = 'contain';
      this.params['name'].value = text;
    } else {
      delete this.params['name'];
      this.setState({ itemList: [] });
    }

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.getItemsByProjectRequest(this.props.idProject, this.params);
    }, 200);
  };

  handleClose = () => this.props.handleClose();

  handLeSubmit = () => {
    this.props.postSupplierItemRequest(
      this.props.idSupplier,
      this.state.item,
      this.newItemSupplier,
    );
    this.props.handleClose();
  };

  render() {
    const {
      supplierProductName,
      supplierProductReference,
      minimumOrderQuantity,
      maximumOrderQuantity,
      quantityPerPack,
      packUnitName,
      purchasePriceExclTax,
      defaultItem,
    } = this.state;

    return (
      <div>
        <div className='col-12'>
          <div className='information'>
            <div className='col-12 col-md-4'>
              <h4>
                <IntlMessages id={'itemSupplier.modal.item'} />
              </h4>
            </div>
            <div className='col-12 col-md-6'>
              <Select
                fullWidth
                isClearable
                onChange={this.handleChangeSelect('item')}
                onInputChange={(text) => this.handleChangeItem(text)}
                options={
                  this.state.itemList &&
                  this.state.itemList.map((data) => {
                    return {
                      value: data.id,
                      label: data.name,
                    };
                  })
                }
              />
            </div>
          </div>

          <div className='information'>
            <div className='col-12 col-md-4'>
              <h4>
                <IntlMessages id={'itemSupplier.modal.supplierProductName'} />
                <i
                  className='zmdi zmdi-info infoIcon'
                  id={'Popoverbutton-popoverSupplierProductName'}
                  onMouseEnter={() =>
                    this.setState({ popoverSupplierProductName: true })
                  }
                  onMouseLeave={() =>
                    this.setState({ popoverSupplierProductName: false })
                  }
                />

                <UncontrolledPopover
                  placement='top'
                  isOpen={this.state.popoverSupplierProductName}
                  target={'Popoverbutton-popoverSupplierProductName'}
                  className='UncontrolledPopover'
                >
                  <IntlMessages
                    id={'itemSupplier.modal.supplierProductName.popover'}
                  />
                </UncontrolledPopover>
              </h4>
            </div>
            <div className='col-12 col-md-6'>
              <TextField
                type='text'
                variant='outlined'
                size='small'
                fullWidth
                value={supplierProductName}
                onChange={this.handleChange('supplierProductName')}
              />
            </div>
          </div>

          <div className='information'>
            <div className='col-12 col-md-4'>
              <h4>
                <IntlMessages id={'itemSupplier.modal.default'} />
                <i
                  className='zmdi zmdi-info infoIcon'
                  id={'Popoverbutton-popoverSupplierDefaultItem'}
                  onMouseEnter={() =>
                    this.setState({ popoverSupplierDefaultItem: true })
                  }
                  onMouseLeave={() =>
                    this.setState({ popoverSupplierDefaultItem: false })
                  }
                />

                <UncontrolledPopover
                  placement='top'
                  isOpen={this.state.popoverSupplierDefaultItem}
                  target={'Popoverbutton-popoverSupplierDefaultItem'}
                  className='UncontrolledPopover'
                >
                  <IntlMessages id={'itemSupplier.modal.default'} />
                </UncontrolledPopover>
              </h4>
            </div>
            <div className='col-12 col-lg-8 mt-3'>
              <Switch
                checked={defaultItem}
                onChange={this.handleChangeDefault}
                color='primary'
                name='checkedB'
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
          </div>

          <div className='information'>
            <div className='col-12 col-md-4'>
              <h4>
                <IntlMessages id={'itemSupplier.modal.supplierItemReference'} />
                <i
                  className='zmdi zmdi-info infoIcon'
                  id={'Popoverbutton-popoverReference'}
                  onMouseEnter={() => this.setState({ popoverReference: true })}
                  onMouseLeave={() =>
                    this.setState({ popoverReference: false })
                  }
                />

                <UncontrolledPopover
                  placement='top'
                  isOpen={this.state.popoverReference}
                  target={'Popoverbutton-popoverReference'}
                  className='UncontrolledPopover'
                >
                  <IntlMessages
                    id={'itemSupplier.modal.supplierItemReference.popover'}
                  />
                </UncontrolledPopover>
              </h4>
            </div>
            <div className='col-12 col-md-6'>
              <TextField
                type='text'
                variant='outlined'
                size='small'
                fullWidth
                value={supplierProductReference}
                onChange={this.handleChange('supplierProductReference')}
              />
            </div>
          </div>

          <div className='information'>
            <div className='col-12 col-md-4'>
              <h4>
                <IntlMessages id={'itemSupplier.modal.purchase.price'} />
                <i
                  className='zmdi zmdi-info infoIcon'
                  id={'Popoverbutton-popoverPurchasePrice'}
                  onMouseEnter={() =>
                    this.setState({ popoverPurchasePrice: true })
                  }
                  onMouseLeave={() =>
                    this.setState({ popoverPurchasePrice: false })
                  }
                />

                <UncontrolledPopover
                  placement='top'
                  isOpen={this.state.popoverPurchasePrice}
                  target={'Popoverbutton-popoverPurchasePrice'}
                  className='UncontrolledPopover'
                >
                  <IntlMessages
                    id={'itemSupplier.modal.purchase.price.popover'}
                  />
                </UncontrolledPopover>
              </h4>
            </div>
            <div className='col-12 col-md-6'>
              <TextField
                type='number'
                InputProps={{ inputProps: { min: 0 } }}
                variant='outlined'
                size='small'
                fullWidth
                value={purchasePriceExclTax}
                onChange={this.handleChange('purchasePriceExclTax')}
              />
            </div>
          </div>

          <div className='information'>
            <div className='col-12 col-md-4'>
              <h4>
                <IntlMessages id={'itemSupplier.modal.minOrder.qtty'} />
                <i
                  className='zmdi zmdi-info infoIcon'
                  id={'Popoverbutton-popoverMinOrderQtty'}
                  onMouseEnter={() =>
                    this.setState({ popoverMinOrderQtty: true })
                  }
                  onMouseLeave={() =>
                    this.setState({ popoverMinOrderQtty: false })
                  }
                />

                <UncontrolledPopover
                  placement='top'
                  isOpen={this.state.popoverMinOrderQtty}
                  target={'Popoverbutton-popoverMinOrderQtty'}
                  className='UncontrolledPopover'
                >
                  <IntlMessages
                    id={'itemSupplier.modal.minOrder.qtty.popover'}
                  />
                </UncontrolledPopover>
              </h4>
            </div>
            <div className='col-12 col-md-6'>
              <TextField
                type='number'
                InputProps={{ inputProps: { min: 0 } }}
                variant='outlined'
                size='small'
                fullWidth
                value={minimumOrderQuantity}
                onChange={this.handleChange('minimumOrderQuantity')}
              />
            </div>
          </div>

          <div className='information'>
            <div className='col-12 col-md-4'>
              <h4>
                <IntlMessages id={'itemSupplier.modal.maxOrderQtty'} />
                <i
                  className='zmdi zmdi-info infoIcon'
                  id={'Popoverbutton-popoverMaxOrderQtty'}
                  onMouseEnter={() =>
                    this.setState({ popoverMaxOrderQtty: true })
                  }
                  onMouseLeave={() =>
                    this.setState({ popoverMaxOrderQtty: false })
                  }
                />

                <UncontrolledPopover
                  placement='top'
                  isOpen={this.state.popoverMaxOrderQtty}
                  target={'Popoverbutton-popoverMaxOrderQtty'}
                  className='UncontrolledPopover'
                >
                  <IntlMessages
                    id={'itemSupplier.modal.maxOrderQtty.popover'}
                  />
                </UncontrolledPopover>
              </h4>
            </div>
            <div className='col-12 col-md-6'>
              <TextField
                type='number'
                InputProps={{ inputProps: { min: 0 } }}
                variant='outlined'
                size='small'
                fullWidth
                value={maximumOrderQuantity}
                onChange={this.handleChange('maximumOrderQuantity')}
              />
            </div>
          </div>

          <div className='information'>
            <div className='col-12 col-md-4'>
              <h4>
                <IntlMessages id={'itemSupplier.modal.qtty.pack'} />
                <i
                  className='zmdi zmdi-info infoIcon'
                  id={'Popoverbutton-popoverQttyPack'}
                  onMouseEnter={() => this.setState({ popoverQttyPack: true })}
                  onMouseLeave={() => this.setState({ popoverQttyPack: false })}
                />

                <UncontrolledPopover
                  placement='top'
                  isOpen={this.state.popoverQttyPack}
                  target={'Popoverbutton-popoverQttyPack'}
                  className='UncontrolledPopover'
                >
                  <IntlMessages id={'itemSupplier.modal.qtty.pack.popover'} />
                </UncontrolledPopover>
              </h4>
            </div>
            <div className='col-12 col-md-6'>
              <TextField
                type='number'
                InputProps={{ inputProps: { min: 0 } }}
                variant='outlined'
                size='small'
                fullWidth
                value={quantityPerPack}
                onChange={this.handleChange('quantityPerPack')}
              />
            </div>
          </div>

          <div className='information'>
            <div className='col-12 col-md-4'>
              <h4>
                <IntlMessages id={'itemSupplier.modal.pack.unit'} />
                <i
                  className='zmdi zmdi-info infoIcon'
                  id={'Popoverbutton-popoverPackUnit'}
                  onMouseEnter={() => this.setState({ popoverPackUnit: true })}
                  onMouseLeave={() => this.setState({ popoverPackUnit: false })}
                />

                <UncontrolledPopover
                  placement='top'
                  isOpen={this.state.popoverPackUnit}
                  target={'Popoverbutton-popoverPackUnit'}
                  className='UncontrolledPopover'
                >
                  <IntlMessages id={'itemSupplier.modal.pack.unit'} />
                </UncontrolledPopover>
              </h4>
            </div>
            <div className='col-12 col-md-6'>
              <TextField
                type='text'
                variant='outlined'
                size='small'
                fullWidth
                value={packUnitName}
                onChange={this.handleChange('packUnitName')}
              />
            </div>
          </div>

          <div className='d-flex justify-content-end my-4'>
            <Button onClick={this.handleClose} color='primary'>
              <IntlMessages id='appModule.cancel' />
            </Button>
            <Button
              variant='outlined'
              onClick={this.handLeSubmit}
              color='primary'
            >
              <IntlMessages id='appModule.save' />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  itemsList: state.sdk.items.itemsList,
});

export default connect(mapStateToProps, {
  getItemsByProjectRequest,
  postSupplierItemRequest,
})(ItemSupplierModalCreationContent);
