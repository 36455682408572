import React, { Component } from 'react';
import { Modal } from '../../../../../config/components.config';
import IntlMessages from '../../../../../util/IntlMessages';
import ItemSupplierModalEditContent from './ItemSupplierModalEditContent';

class ItemSupplierModalEdit extends Component {
  handleClose = () => this.props.close();

  render() {
    const { open, itemSupplier } = this.props;

    return (
      <div>
        <Modal
          title={<IntlMessages id='items.modal.edition.title' />}
          width='md'
          open={open}
          close={this.handleClose}
        >
          <ItemSupplierModalEditContent
            handleClose={this.handleClose}
            itemSupplier={itemSupplier}
          />
        </Modal>
      </div>
    );
  }
}

export default ItemSupplierModalEdit;
