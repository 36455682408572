import React from 'react';
import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import { Close } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../../../util/IntlMessages';
import '../cell.css';

const FilterCell = ({ column, filter, onFilter, ...restProps }) => {
  const filterValue = filter && filter.value;
  const onChange = (e) =>
    onFilter({ columnName: column.name, value: e.target.value });

  const handleReset = () => {
    onFilter({ columnName: column.name, value: '' });
  };

  let content;
  if (column.name === 'name') {
    const defaultValue = JSON.parse(localStorage.getItem('paramsSupplier'))
      ? JSON.parse(localStorage.getItem('paramsSupplier'))['company.name']
        ? JSON.parse(localStorage.getItem('paramsSupplier'))['company.name']
            .value
        : ''
      : '';
    content = (
      <TableFilterRow.Cell
        {...restProps}
        className="tableFilterRow"
        column={column}
      >
        <TextField
          label={<IntlMessages id="appModule.filter" />}
          value={defaultValue}
          onChange={onChange}
          InputProps={{
            endAdornment:
              filterValue || defaultValue ? (
                <IconButton onClick={handleReset} className={'p-0 m-0'}>
                  <Close />
                </IconButton>
              ) : (
                <IconButton />
              ),
          }}
        />
      </TableFilterRow.Cell>
    );
  } else if (column.name === 'supplierReference') {
    const defaultValue = JSON.parse(localStorage.getItem('paramsSupplier'))
      ? JSON.parse(localStorage.getItem('paramsSupplier'))['supplierReference']
        ? JSON.parse(localStorage.getItem('paramsSupplier'))[
            'supplierReference'
          ].value
        : ''
      : '';
    content = (
      <TableFilterRow.Cell
        {...restProps}
        className="tableFilterRow"
        column={column}
      >
        <TextField
          label={<IntlMessages id="appModule.filter" />}
          value={defaultValue}
          onChange={onChange}
          InputProps={{
            endAdornment:
              filterValue || defaultValue ? (
                <IconButton onClick={handleReset} className={'p-0 m-0'}>
                  <Close />
                </IconButton>
              ) : (
                <IconButton />
              ),
          }}
        />
      </TableFilterRow.Cell>
    );
  } else if (column.name === 'email') {
    const defaultValue = JSON.parse(localStorage.getItem('paramsSupplier'))
      ? JSON.parse(localStorage.getItem('paramsSupplier'))['company.email']
        ? JSON.parse(localStorage.getItem('paramsSupplier'))['company.email']
            .value
        : ''
      : '';
    content = (
      <TableFilterRow.Cell
        {...restProps}
        className="tableFilterRow"
        column={column}
      >
        <TextField
          label={<IntlMessages id="appModule.filter" />}
          value={defaultValue}
          onChange={onChange}
          InputProps={{
            endAdornment:
              filterValue || defaultValue ? (
                <IconButton onClick={handleReset} className={'p-0 m-0'}>
                  <Close />
                </IconButton>
              ) : (
                <IconButton />
              ),
          }}
        />
      </TableFilterRow.Cell>
    );
  } else {
    content = (
      <TableFilterRow.Cell
        {...restProps}
        className="tableFilterRow"
        column={column}
      ></TableFilterRow.Cell>
    );
  }
  return content;
};

export default FilterCell;
