import React, { Component } from 'react';
import { connect } from 'react-redux';
import ColumnCell from '../../../components/Table/Columns/ColumnCell';
import NoData from '../../../components/Table/NoData/NoData';
import SuppliersCell from '../../../components/Table/Cells/Suppliers/SuppliersCell';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  SelectionState,
  CustomPaging,
  SearchState,
  FilteringState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableColumnVisibility,
  ColumnChooser,
  Toolbar,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import { tableColumnExtensionsSuppliers } from '../../../components/Table/Columns/ShowColumns';
import { Loading } from '../../../config/components.config';
import { NotificationManager } from 'react-notifications';
import IntlMessages from '../../../util/IntlMessages';
import {
  getSuppliersByOrganizationRequest,
  setGlobalError,
} from '../../../config/actions.config';
import FilterCell from '../../../components/Table/Cells/Suppliers/FiltersCell';

class SuppliersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pageSizes: [10, 20, 50, 100, 0],
      filters: [],
    };
    this.parameters = {};
  }

  componentDidMount() {
    if (!localStorage.getItem('paramsSupplier')) {
      localStorage.setItem(
        'paramsSupplier',
        JSON.stringify({
          limit: 20,
          page: 1,
        })
      );
    }
    this.parameters = JSON.parse(localStorage.getItem('paramsSupplier'));

    if (this.props.idOrganization) {
      this.props.getSuppliersByOrganizationRequest(
        this.props.idOrganization.id,
        this.parameters
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.idOrganization !== this.props.idOrganization) {
      this.props.getSuppliersByOrganizationRequest(
        this.props.idOrganization.id,
        this.parameters
      );
    }

    if (
      prevProps.suppliersByOrganizationList !==
      this.props.suppliersByOrganizationList
    ) {
      if (this.props.suppliersByOrganizationList.loading) {
        this.setState({ loading: true });
      }
      if (
        this.props.suppliersByOrganizationList.suppliers !== '' &&
        !this.props.suppliersByOrganizationList.loading &&
        !this.props.suppliersByOrganizationList.error
      ) {
        this.setState({
          loading: false,
          objectCount: this.props.suppliersByOrganizationList.meta.object_count,
        });
      }
    }

    if (prevProps.newSupplier !== this.props.newSupplier) {
      if (
        this.props.newSupplier.supplier !== '' &&
        !this.props.newSupplier.loading &&
        !this.props.newSupplier.error
      ) {
        NotificationManager.success(
          <IntlMessages id='supplier.success.message.added' />
        );
        this.props.getSuppliersByOrganizationRequest(
          this.props.idOrganization.id,
          JSON.parse(localStorage.getItem('paramsSupplier'))
        );
      }
    }

    if (prevProps.updatedSupplier !== this.props.updatedSupplier) {
      if (
        this.props.updatedSupplier.supplier !== '' &&
        !this.props.updatedSupplier.loading &&
        !this.props.updatedSupplier.error
      ) {
        NotificationManager.success(
          <IntlMessages id='supplier.success.message.updated' />
        );
        this.props.getSuppliersByOrganizationRequest(
          this.props.idOrganization.id,
          JSON.parse(localStorage.getItem('paramsSupplier'))
        );
      }
    }

    if (prevProps.deletedSupplier !== this.props.deletedSupplier) {
      if (
        this.props.deletedSupplier.supplier !== '' &&
        !this.props.deletedSupplier.loading &&
        !this.props.deletedSupplier.error
      ) {
        NotificationManager.success(
          <IntlMessages id='supplier.success.message.deleted' />
        );
        this.props.getSuppliersByOrganizationRequest(
          this.props.idOrganization.id,
          JSON.parse(localStorage.getItem('paramsSupplier'))
        );
      }

      if (this.props.deletedSupplier.error) {
        NotificationManager.error(
          <IntlMessages id='pos.error.message.delete' />
        );
      }
    }

    if (prevProps.globalError !== this.props.globalError) {
      if (this.props.globalError === true) {
        NotificationManager.error(
          <IntlMessages id='appModule.message.error' />
        );
        this.props.setGlobalError(false);
      }
    }
  }

  setCurrentPage = (page) => {
    this.parameters = JSON.parse(localStorage.getItem('paramsSupplier'));
    this.parameters.page = page + 1;

    this.props.getSuppliersByOrganizationRequest(
      this.props.idOrganization.id,
      this.parameters
    );
    localStorage.setItem('paramsSupplier', JSON.stringify(this.parameters));
  };

  setPageSize = (pageSize) => {
    this.parameters = JSON.parse(localStorage.getItem('paramsSupplier'));
    this.parameters.limit = pageSize;

    this.props.getSuppliersByOrganizationRequest(
      this.props.idOrganization.id,
      this.parameters
    );
    localStorage.setItem('paramsSupplier', JSON.stringify(this.parameters));
  };

  onFilter = (filter) => {
    this.parameters = JSON.parse(localStorage.getItem('paramsSupplier'));
    this.parameters.page = 1;
    this.setState({ filters: filter });
    filter.forEach((data) => {
      if (data.columnName === 'name' && data.value !== '') {
        this.parameters['company.name'] = {};
        this.parameters['company.name'].operation = 'contain';
        this.parameters['company.name'].value = data.value;
      } else if (data.columnName === 'email' && data.value !== '') {
        this.parameters['company.email'] = {};
        this.parameters['company.email'].operation = 'contain';
        this.parameters['company.email'].value = data.value;
      } else if (data.value && data.value !== '' && data.value !== 'all') {
        this.parameters[data.columnName] = {};
        this.parameters[data.columnName].operation = 'contain';
        this.parameters[data.columnName].value = data.value;
      } else {
        if (data.columnName === 'name') {
          delete this.parameters['company.name'];
        } else if (data.columnName === 'email') {
          delete this.parameters['company.email'];
        } else {
          delete this.parameters[data.columnName];
        }
      }
    });

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.getSuppliersByOrganizationRequest(
        this.props.idOrganization.id,
        this.parameters
      );
    }, 200);

    localStorage.setItem('paramsSupplier', JSON.stringify(this.parameters));
  };

  render() {
    const { loading, pageSizes, objectCount, filters } = this.state;
    const { suppliersByOrganizationList } = this.props;

    const row = [];
    if (suppliersByOrganizationList && suppliersByOrganizationList.suppliers) {
      suppliersByOrganizationList.suppliers.forEach((data, i) => {
        row[i] = {
          supplier: data,
          name: data.company.name,
          supplierReference: data.supplierReference,
          email: data.company.email,
          phone: data.company.address[0] && data.company.address[0].phoneNumber
            ? data.company.address[0].phoneNumber
            : '',
          vat: data.company && data.company.vatNumber ? data.company.vatNumber : '',
        };
      });
    }

    const columnsSuppliers = [
      { name: 'name', title: <IntlMessages id='suppliers.table.name' /> },
      {
        name: 'supplierReference',
        title: <IntlMessages id='suppliers.table.reference' />,
      },
      { name: 'email', title: <IntlMessages id='suppliers.table.email' /> },
      { name: 'phone', title: <IntlMessages id='suppliers.table.phone' /> },
      { name: 'address', title: <IntlMessages id='suppliers.table.address' /> },
      { name: 'vat', title: <IntlMessages id='suppliers.table.vat' /> },
      { name: 'buttons', title: <IntlMessages id='pos.table.actions' /> },
    ];

    return (
      <div>
        <Grid rows={row} columns={columnsSuppliers}>
          <SelectionState />
          <PagingState
            pageSize={
              JSON.parse(localStorage.getItem('paramsSupplier')) &&
              JSON.parse(localStorage.getItem('paramsSupplier'))['limit']
                ? JSON.parse(localStorage.getItem('paramsSupplier'))['limit']
                : 20
            }
            currentPage={
              JSON.parse(localStorage.getItem('paramsSupplier')) &&
              JSON.parse(localStorage.getItem('paramsSupplier'))['page']
                ? JSON.parse(localStorage.getItem('paramsSupplier'))['page'] - 1
                : 1
            }
            onCurrentPageChange={this.setCurrentPage}
            onPageSizeChange={this.setPageSize}
          />
          <SortingState />
          <IntegratedSorting />
          <SearchState defaultValue='' />
          <FilteringState onFiltersChange={this.onFilter} filters={filters} />
          <Table
            columnExtensions={tableColumnExtensionsSuppliers}
            cellComponent={(props) => <SuppliersCell cell={props} />}
            noDataCellComponent={NoData}
          />
          <CustomPaging totalCount={objectCount} />
          <TableColumnVisibility defaultHiddenColumnNames={['vat']} />
          <Toolbar />
          <ColumnChooser />
          <TableHeaderRow cellComponent={ColumnCell} />
          <TableFilterRow cellComponent={FilterCell} />
          <PagingPanel pageSizes={pageSizes} />
        </Grid>
        {loading && <Loading />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  idOrganization: state.sdk.project.projectDetails.organisation,
  suppliersByOrganizationList: state.sdk.suppliers.suppliersByOrganizationList,
  newSupplier: state.sdk.suppliers.newSupplier,
  updatedSupplier: state.sdk.suppliers.updatedSupplier,
  deletedSupplier: state.sdk.suppliers.deletedSupplier,
  categoriesList: state.sdk.categories.categoriesList,
  globalError: state.sdk.auth.globalError,
});

export default connect(mapStateToProps, {
  getSuppliersByOrganizationRequest,
  setGlobalError,
})(SuppliersTable);
