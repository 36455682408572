import React, { Component } from 'react';
import { connect } from 'react-redux';
import IntlMessages from '../../../../util/IntlMessages';
import SupplierDeliverySupplier from './DetailCards/SupplierDeliverySupplier';
import SupplierDeliveryRequestor from './DetailCards/SupplierDeliveryRequestor';
import { postSupplierDeliveryDocumentRequest } from '../../../../config/actions.config';
import Button from '@material-ui/core/Button';
import { Redirect } from 'react-router';
import '../../index.css';

class SupplierDeliveryCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      deliveryDocumentId: null,
      deliverydocument: {
        requestorPos: null,
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.newDeliverySupplierdocument !== this.props.newDeliverySupplierdocument
    ) {
      if (
        this.props.newDeliverySupplierdocument.document !== '' &&
        !this.props.newDeliverySupplierdocument.loading &&
        !this.props.newDeliverySupplierdocument.error
      ) {
        this.setState({
          redirect: true,
          deliveryDocumentId: this.props.newDeliverySupplierdocument.document.id,
        });
      }
    }
  }

  handeChange = (name, value) => {
    if (name === 'supplier') {
      this.setState({ [name]: value });
    } else {
      let deliverydocument = this.state.deliverydocument;
      deliverydocument[name] = value;

      this.setState({ deliverydocument: deliverydocument });
    }
  };

  handleSubmit = () => {
    this.props.postSupplierDeliveryDocumentRequest(
      this.state.supplier,
      this.state.deliverydocument
    );
  };

  render() {
    if (this.state.redirect) {
      return (
        <Redirect to={'show-supplier-delivery/' + this.state.deliveryDocumentId} />
      );
    } else {
      return (
        <div className='app-wrapper div-wrapper-title'>
          <div className='row headerAlign'>
            <div className='col-12 page-title'>
              <IntlMessages id='delivery.supplier.create.title' />
            </div>
          </div>
          <div>
            <div className='row'>
              <div className='col-12 col-md-6 detailCard'>
                <SupplierDeliverySupplier handeChange={this.handeChange} />
              </div>
              <div className='col-12 col-md-6 detailCard'>
                <SupplierDeliveryRequestor handeChange={this.handeChange} />
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <div className='d-flex justify-content-end'>
                  <Button
                    disabled={
                      !(
                        this.state.supplier &&
                        this.state.deliverydocument.requestorPos
                      )
                    }
                    variant='outlined'
                    onClick={this.handleSubmit}
                    color='primary'
                  >
                    <IntlMessages id='items.modal.button.save' />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  newDeliverySupplierdocument: state.sdk.suppliers.newDeliverySupplierdocument,
});

export default connect(mapStateToProps, {
  postSupplierDeliveryDocumentRequest,
})(SupplierDeliveryCreate);
