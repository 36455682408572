import React, { Component } from 'react';
import { connect } from 'react-redux';
import ColumnCell from '../../../../components/Table/Columns/ColumnCell';
import NoData from '../../../../components/Table/NoData/NoData';
import DeliveryCell from '../../../../components/Table/Cells/Deliveries/DeliveryCell';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  SelectionState,
  CustomPaging,
  SearchState,
  FilteringState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableColumnVisibility,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import { tableColumnExtensionsSuppliers } from '../../../../components/Table/Columns/ShowColumns';
import { Loading } from '../../../../config/components.config';
import IntlMessages from '../../../../util/IntlMessages';
import {
  setGlobalError,
  getAllPosDeliveryDocumentsRequest,
} from '../../../../config/actions.config';
import FilterCell from '../../../../components/Table/Cells/Suppliers/FiltersCell';
import { NotificationManager } from 'react-notifications';

class InternDeliveryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pageSizes: [10, 20, 50, 100, 0],
      currentPage: 0,
      currentPageSize: 20,
      filters: [],
    };
    this.params = {
      limit: this.state.currentPageSize,
      page: this.state.currentPage + 1,
    };
  }

  componentDidMount() {
    if (!localStorage.getItem('paramsDeliveriesIntern')) {
      localStorage.setItem(
        'paramsOrdersIntern',
        JSON.stringify({
          limit: 20,
          page: 1,
          order: 'id:DESC',
        }),
      );
    }
    this.parameters = JSON.parse(
      localStorage.getItem('paramsDeliveriesIntern'),
    );

    this.props.getAllPosDeliveryDocumentsRequest(
      this.props.idProject,
      this.parameters,
    );
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.posDeliveryDocumentList !== this.props.posDeliveryDocumentList
    ) {
      if (this.props.posDeliveryDocumentList.loading) {
        this.setState({ loading: true });
      }
      if (
        this.props.posDeliveryDocumentList.posDeliverydocuments !== '' &&
        !this.props.posDeliveryDocumentList.loading &&
        !this.props.posDeliveryDocumentList.error
      ) {
        this.setState({
          loading: false,
          objectCount: this.props.posDeliveryDocumentList.meta.object_count,
        });
      }
    }
    if (prevProps.updatedDocumentStatus !== this.props.updatedDocumentStatus) {
      if (
        !this.props.updatedDocumentStatus.loading &&
        !this.props.updatedDocumentStatus.error
      ) {
        NotificationManager.success(
          <IntlMessages id='delivery.status.message.success.updated' />,
        );
        this.props.getAllPosDeliveryDocumentsRequest(
          this.props.idProject,
          this.parameters,
        );
      }
    }
    if (prevProps.globalError !== this.props.globalError) {
      if (this.props.globalError === true) {
        NotificationManager.error(
          <IntlMessages id='appModule.message.error' />,
        );
        this.setState({ loading: false });
        this.props.setGlobalError(false);
      }
    }
  }

  render() {
    const {
      loading,
      currentPageSize,
      currentPage,
      pageSizes,
      objectCount,
      filters,
    } = this.state;

    const { posDeliveryDocumentList } = this.props;

    const row = [];
    if (
      posDeliveryDocumentList &&
      posDeliveryDocumentList.posDeliverydocuments
    ) {
      posDeliveryDocumentList.posDeliverydocuments.forEach((data, i) => {
        row[i] = {
          id: data.id,
          deliveryType: 'internDelivery',
          createdAt: data.createdAt,
          reference: data.reference,
          requestorPos: data.requestorPos?.name,
          vendorPos: data.vendorPos?.name,
          responsibleUserProfile: data.responsibleUserProfile?.fullName,
          status: data.status,
          totalWithoutVat: data.totalWithoutVat,
          locale: this.props.locale,
          timezone: this.props.timezone,
          currency:
            this.props.projectDetails && this.props.projectDetails !== ''
              ? this.props.projectDetails.projectFinance.defaultCurrency.isoCode
              : null,
        };
      });
    }

    const columnsSuppliers = [
      { name: 'createdAt', title: <IntlMessages id='appModule.date' /> },
      {
        name: 'reference',
        title: <IntlMessages id='orders.supplier.table.reference' />,
      },
      {
        name: 'requestorPos',
        title: <IntlMessages id='orders.supplier.table.requestorPos' />,
      },
      {
        name: 'vendorPos',
        title: <IntlMessages id='orders.supplier.table.vendorPos' />,
      },
      {
        name: 'status',
        title: <IntlMessages id='orders.supplier.table.status' />,
      },
      {
        name: 'responsibleUserProfile',
        title: (
          <IntlMessages id='orders.supplier.table.responsibleUserProfile' />
        ),
      },
      {
        name: 'totalWithoutVat',
        title: <IntlMessages id='orders.supplier.table.priceWithoutVat' />,
      },
      {
        name: 'action',
        title: <IntlMessages id='orders.supplier.table.action' />,
      },
    ];

    return (
      <div>
        <Grid rows={row} columns={columnsSuppliers}>
          <SelectionState />
          <PagingState
            pageSize={currentPageSize}
            currentPage={currentPage}
            onCurrentPageChange={this.setCurrentPage}
            onPageSizeChange={this.setPageSize}
          />
          <SortingState />
          <IntegratedSorting />
          <SearchState defaultValue='' />
          <FilteringState onFiltersChange={this.onFilter} filters={filters} />
          <Table
            columnExtensions={tableColumnExtensionsSuppliers}
            cellComponent={(props) => (
              <DeliveryCell
                cell={props}
                changeStatus={() => this.setState({ loading: true })}
              />
            )}
            noDataCellComponent={NoData}
          />
          <CustomPaging totalCount={objectCount} />
          <TableColumnVisibility />
          <TableHeaderRow cellComponent={ColumnCell} />
          <TableFilterRow cellComponent={FilterCell} />
          <PagingPanel pageSizes={pageSizes} />
        </Grid>
        {loading && <Loading />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  idOrganization: state.sdk.project.projectDetails.organisation,
  locale: state.sdk.settings.locale.locale,
  timezone: state.sdk.project.projectDetails.timezone,
  projectDetails: state.sdk.project.projectDetails,
  globalError: state.sdk.auth.globalError,
  posDeliveryDocumentList: state.sdk.posDocuments.posDeliveryDocumentList,
  updatedDocumentStatus: state.sdk.documents.updatedDocumentStatus,
});

export default connect(mapStateToProps, {
  setGlobalError,
  getAllPosDeliveryDocumentsRequest,
})(InternDeliveryTable);
