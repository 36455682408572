import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomScrollbars from '../../util/CustomScrollbars';
import LanguageItem from './LanguageItem';
import languageData from './data';

class LanguageSwitcher extends Component {
  render() {
    const { switchLanguage, handleRequestClose } = this.props;

    return (
      <CustomScrollbars
        className="messages-list language-list scrollbar"
        autoHeight
      >
        <ul className="list-unstyled">
          {languageData.map((language, index) => (
            <LanguageItem
              key={index}
              language={language}
              handleRequestClose={handleRequestClose}
              switchLanguage={switchLanguage}
            />
          ))}
        </ul>
      </CustomScrollbars>
    );
  }
}

const mapStateToProps = (state) => ({
  allowedLanguages: state.sdk.project.projectDetails.allowedLanguages,
});

export default connect(mapStateToProps)(LanguageSwitcher);
