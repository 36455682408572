import React from 'react';
import IntlMessages from '../../util/IntlMessages';

export const genreList = [
  {
    value: 'M',
    label: <IntlMessages id='appModule.gender.man' />,
  },
  {
    value: 'F',
    label: <IntlMessages id='appModule.gender.woman' />,
  },
  {
    value: 'O',
    label: <IntlMessages id='appModule.gender.other' />,
  },
  {
    value: 'NA',
    label: <IntlMessages id='appModule.gender.NA' />,
  },
];

export const SelectedGenre = (genre) => {
  if (genre === 'M') {
    return <IntlMessages id='appModule.gender.man' />;
  } else if (genre === 'F') {
    return <IntlMessages id='appModule.gender.woman' />;
  } else if (genre === 'O') {
    return <IntlMessages id='appModule.gender.other' />;
  } else {
    return <IntlMessages id='appModule.gender.NA' />;
  }
};