import saMessages from '../locales/fr_CA/fr_CA.json';
import frcaMessages from '../locales/fr_CA/global_fr_CA.json';

const result = {};
let key;

for (key in saMessages) {
  if (Object.prototype.hasOwnProperty.call(saMessages, key)) {
    result[key] = saMessages[key];
  }
}

for (key in frcaMessages) {
  if (Object.prototype.hasOwnProperty.call(frcaMessages, key)) {
    result[key] = frcaMessages[key];
  }
}

const fr_caLang = {
  messages: {
    ...result,
  },
  locale: 'fr-CA',
};
export default fr_caLang;
