import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from '../../../../util/IntlMessages';
import { postOrderDocumentRequest } from '../../../../config/actions.config';
import Select from 'react-select';
import { NotificationManager } from 'react-notifications';
import '../../index.css';

class ReOrderModalContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.toOrder = {
      supplierOrderModel: [],
    };
    this.suppliers = { suppliers: [] };
  }

  componentDidMount() {
    this.props.selectedRows.forEach((data) => {
      const difference = data.reorderLevel - data.quantityInStock;

      this.toOrder.supplierOrderModel.push({
        itemPos: data.itemPos.id,
        quantity: difference,
        itemSupplier: data.item.defaultItemSupplier
          ? data.item.defaultItemSupplier.default === true
            ? data.item.defaultItemSupplier.id
            : null
          : null,
      });
    });
  }

  handleChange = (itemPosId) => (event) => {
    this.setState({ [itemPosId]: parseInt(event.target.value, 10) });
    for (var item in this.toOrder.supplierOrderModel) {
      if (this.toOrder.supplierOrderModel[item].itemPos === itemPosId) {
        this.toOrder.supplierOrderModel[item].quantity = parseInt(
          event.target.value,
          10,
        );
        break; //Stop this loop, we found it!
      }
    }
  };

  changeSelect = (itemPosId) => (event) => {
    for (var item in this.toOrder.supplierOrderModel) {
      if (this.toOrder.supplierOrderModel[item].itemPos === itemPosId) {
        this.toOrder.supplierOrderModel[item].itemSupplier = event.value;
        break;
      }
    }
  };

  handleClose = () => {
    this.props.handleClose();
  };

  handLeSubmit = () => {
    let noConfig = false;
    for (var item in this.toOrder.supplierOrderModel) {
      if (this.toOrder.supplierOrderModel[item].supplier === null) {
        noConfig = true;
        break;
      }
    }
    if (noConfig) {
      NotificationManager.warning(
        <IntlMessages id='outOfOrder.reorder.modal.missing.config' />,
      );
    } else {
      this.props.postOrderDocumentRequest(this.props.idProject, this.toOrder);
      this.props.handleClose();
    }
  };

  render() {
    const { loadingSend } = this.state;

    return (
      <div>
        <div className='col-12'>
          <div className='information'>
            <div className='col-6 col-md-3 p-0'>
              <div className='modalSubTitle'>
                <IntlMessages id='outOfOrder.reorder.modal.pos' />
              </div>
            </div>
            <div className='col-6 col-md-3 p-0'>
              <div className='modalSubTitle'>
                <IntlMessages id='outOfOrder.reorder.modal.item' />
              </div>
            </div>
            <div className='col-12 col-md-3 p-0'>
              <div className='modalSubTitle'>
                <IntlMessages id='outOfOrder.reorder.modal.quantity' />
              </div>
            </div>
            <div className='col-12 col-md-3 p-0'>
              <div className='modalSubTitle'>
                <IntlMessages id='outOfOrder.reorder.modal.supplier' />
              </div>
            </div>
          </div>
          <hr />

          {this.props.selectedRows.map((data, i) => {
            const difference = data.reorderLevel - data.quantityInStock;
            return (
              <div className='information' key={i}>
                <div className='col-6 col-md-3 p-0'>
                  <h4>{data.pos.name}</h4>
                </div>
                <div className='col-6 col-md-3 p-0'>
                  <h4>{data.item.name}</h4>
                </div>
                <div className='col-12 col-md-3 p-0'>
                  <TextField
                    variant='outlined'
                    size='small'
                    type='number'
                    disabled={data.item.defaultItemSupplier ? false : true}
                    value={
                      this.state[data.itemPos.id]
                        ? this.state[data.itemPos.id]
                        : difference
                    }
                    onChange={this.handleChange(data.itemPos.id)}
                  />
                </div>
                <div className='col-12 col-md-3 p-0'>
                  {data.item.itemSupplier.length > 0 ? (
                    <Select
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                      fullWidth
                      hideSelectedOptions={true}
                      defaultValue={
                        data.item.defaultItemSupplier
                          ? data.item.defaultItemSupplier.default === true
                            ? {
                                label:
                                  data.item.defaultItemSupplier.supplier.name,
                                value:
                                  data.item.defaultItemSupplier.id,
                              }
                            : ''
                          : ''
                      }
                      onChange={this.changeSelect(
                        data.itemPos.id,
                      )}
                      options={
                        data.item.itemSupplier &&
                        data.item.itemSupplier.map((element) => {
                          return {
                            value: element.id,
                            label: element.supplier.name,
                          };
                        })
                      }
                    />
                  ) : (
                    <span className='reorderWarning'>
                      <IntlMessages id='outOfOrder.reorder.modal.supplier.missing.itemSupplier' />
                    </span>
                  )}
                </div>
              </div>
            );
          })}

          <div className='d-flex justify-content-end my-4'>
            <Button
              onClick={this.handleClose}
              color='primary'
              disabled={loadingSend}
            >
              <IntlMessages id='appModule.cancel' />
            </Button>
            <Button
              variant='outlined'
              onClick={this.handLeSubmit}
              color='primary'
              disabled={loadingSend}
            >
              <IntlMessages id='stock.order' />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  idOrganization: state.sdk.project.projectDetails.organisation,
});

export default connect(mapStateToProps, {
  postOrderDocumentRequest,
})(ReOrderModalContent);
