import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Table } from '@devexpress/dx-react-grid-material-ui';
import IntlMessages from '../../../../util/IntlMessages';
import { ActionButton, UnderlineTitle } from '../../../../config/components.config';
import NoData from '../../../../components/Table/NoData/NoData';
import ItemSuppliersCell from '../../../../components/Table/Cells/ItemSuppliers/ItemSuppliersCell';
import '../../index.css';
import SuppliersModalCreation from '../../../Suppliers/SuppliersModal/SuppliersModalCreation/SuppliersModalCreation';
import { postSupplierItemRequest, getItemSuppliersByItemRequest } from '../../../../config/actions.config';
import { NotificationManager } from 'react-notifications';

const columns = [{ name: 'supplier' }];

class ItemSuppliers extends Component {
  constructor() {
    super();
    this.state = {
      openModalAddSupplier: false,
    };
    this.paramsItemSupplier = {
      default: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.newSupplier !== this.props.newSupplier) {
      if (
          this.props.newSupplier.supplier !== '' &&
          !this.props.newSupplier.loading &&
          !this.props.newSupplier.error
      ) {
        if (this.props.itemDetails && !this.props.itemDetails.defaultSupplier) {
          this.paramsItemSupplier.default = true;
        }
        this.props.postSupplierItemRequest(this.props.newSupplier.supplier.id, this.props.idItem, this.paramsItemSupplier);
      }
    }
    if (prevProps.newSupplierItem !== this.props.newSupplierItem) {
      if (
          this.props.newSupplierItem.supplierItem !== '' &&
          !this.props.newSupplierItem.loading &&
          !this.props.newSupplierItem.error
      ) {
        NotificationManager.success(
            <IntlMessages id='supplier.success.message.added' />
        );
        this.props.getItemSuppliersByItemRequest(this.props.idItem);
      }
    }
  }

  handleOpenModal = () => {
    this.setState({ openModalAddSupplier: true });
  }

  render() {
    const { itemSuppliers } = this.props;
    const { openModalAddSupplier } = this.state;
    const row = [];
    if (itemSuppliers && itemSuppliers !== '') {
      itemSuppliers.forEach((data, i) => {
        row[i] = {
          data,
          default: data.default,
          supplier: data.supplier.company.name,
          purchasePriceExclTax: data.purchasePriceExclTax,
          packUnitName: data.packUnitName,
          locale: this.props.locale,
          currency:
            this.props.projectDetails && this.props.projectDetails !== ''
              ? this.props.projectDetails.projectFinance.defaultCurrency
              : null,
        };
      });
    }

    return (
      <>
        <div className="jr-card">
          <UnderlineTitle
            title={<IntlMessages id="item.details.card.suppliers.title" />}
          >
            <ActionButton
              icon="add"
              onClick={this.handleOpenModal}
              className="addSupplierItem"
            />
          </UnderlineTitle>
          <div className="containerTable">
            <Grid rows={row} columns={columns}>
              <Table
                noDataCellComponent={NoData}
                cellComponent={(props) => <ItemSuppliersCell cell={props} />}
              />
            </Grid>
          </div>
        </div>
        <SuppliersModalCreation
            open={openModalAddSupplier}
            close={() => this.setState({ openModalAddSupplier: false })}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  locale: state.sdk.settings.locale.locale,
  projectDetails: state.sdk.project.projectDetails,
  newSupplier: state.sdk.suppliers.newSupplier,
  newSupplierItem: state.sdk.suppliers.newSupplierItem,
});

export default connect(mapStateToProps, {postSupplierItemRequest, getItemSuppliersByItemRequest})(ItemSuppliers);
