import React, { Component } from 'react';
import { connect } from 'react-redux';
import ColumnCell from '../../../../components/Table/Columns/ColumnCell';
import NoData from '../../../../components/Table/NoData/NoData';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  SelectionState,
  CustomPaging,
  SearchState,
  FilteringState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableColumnVisibility,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  columnsOrders,
  tableColumnExtensionsOrders,
} from '../../../../components/Table/Columns/ShowColumns';
import { Loading } from '../../../../config/components.config';
import {
  getAllPosOrderdocumentsRequest,
  setGlobalError,
} from '../../../../config/actions.config';
import { NotificationManager } from 'react-notifications';
import IntlMessages from '../../../../util/IntlMessages';
import FilterCell from '../../../../components/Table/Cells/OrderIntern/FiltersCell';
import OrdersCell from '../../../../components/Table/Cells/Orders/OrdersCell';

class InternOrdersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pageSizes: [10, 20, 50, 100, 0],
      filters: [],
    };
    this.parameters = {};
  }

  componentDidMount() {
    if (!localStorage.getItem('paramsOrdersIntern')) {
      localStorage.setItem(
        'paramsOrdersIntern',
        JSON.stringify({
          limit: 20,
          page: 1,
          order: 'id:DESC',
        }),
      );
    }
    this.parameters = JSON.parse(localStorage.getItem('paramsOrdersIntern'));

    this.props.getAllPosOrderdocumentsRequest(
      this.props.idProject,
      this.parameters,
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.posOrderdocumentList !== this.props.posOrderdocumentList) {
      if (this.props.posOrderdocumentList.loading) {
        this.setState({ loading: true });
      }
      if (
        this.props.posOrderdocumentList.posOrderdocuments !== '' &&
        !this.props.posOrderdocumentList.loading &&
        !this.props.posOrderdocumentList.error
      ) {
        this.setState({
          loading: false,
          objectCount: this.props.posOrderdocumentList.meta.object_count,
        });
      }
    }
    if (prevProps.updatedDocumentStatus !== this.props.updatedDocumentStatus) {
      if (this.props.updatedDocumentStatus.loading) {
        this.setState({ loading: true });
      }
      if (
        this.props.updatedDocumentStatus.document !== '' &&
        !this.props.updatedDocumentStatus.loading &&
        !this.props.updatedDocumentStatus.error
      ) {
        this.props.getAllPosOrderdocumentsRequest(
          this.props.idProject,
          this.parameters,
        );
      }
    }
    if (prevProps.globalError !== this.props.globalError) {
      if (this.props.globalError === true) {
        NotificationManager.error(
          <IntlMessages id='appModule.message.error' />,
        );
        this.setState({ loading: false });
        this.props.setGlobalError(false);
      }
    }
  }

  setCurrentPage = (page) => {
    this.parameters = JSON.parse(localStorage.getItem('paramsOrdersIntern'));
    this.parameters.page = page + 1;

    this.props.getAllPosOrderdocumentsRequest(
      this.props.idProject,
      this.parameters,
    );
    localStorage.setItem('paramsOrdersIntern', JSON.stringify(this.parameters));
  };

  setPageSize = (pageSize) => {
    this.parameters = JSON.parse(localStorage.getItem('paramsOrdersIntern'));
    this.parameters.limit = pageSize;

    this.props.getAllPosOrderdocumentsRequest(
      this.props.idProject,
      this.parameters,
    );
    localStorage.setItem('paramsOrdersIntern', JSON.stringify(this.parameters));
  };

  onFilter = (filter) => {
    this.parameters = JSON.parse(localStorage.getItem('paramsOrders'));
    this.parameters.page = 1;
    this.setState({ filters: filter });
    filter.forEach((data) => {
      if (
        data.value &&
        data.value !== '' &&
        data.value !== 'all' &&
        data.value !== null
      ) {
        if (data.columnName === 'vendorPos') {
          this.parameters['vendorPos.name'] = {};
          this.parameters['vendorPos.name'].operation = 'contain';
          this.parameters['vendorPos.name'].value = data.value;
        } else if (data.columnName === 'responsibleUserProfile') {
          this.parameters['responsibleUserProfile.fullName'] = {};
          this.parameters['responsibleUserProfile.fullName'].operation =
            'contain';
          this.parameters['responsibleUserProfile.fullName'].value = data.value;
        } else if (data.columnName === 'requestorPos') {
          this.parameters['requestorPos.name'] = {};
          this.parameters['requestorPos.name'].operation = 'contain';
          this.parameters['requestorPos.name'].value = data.value;
        } else if (data.columnName === 'createdAt') {
          this.parameters[data.columnName] = [];
          this.parameters[data.columnName].push({
            operation: 'gt',
            value: data.values.dateStart,
          });
          this.parameters[data.columnName].push({
            operation: 'lt',
            value: data.values.dateEnd,
          });
        } else {
          this.parameters[data.columnName] = {};
          this.parameters[data.columnName].operation = 'contain';
          this.parameters[data.columnName].value = data.value;
        }
      } else {
        if (data.columnName === 'vendorPos') {
          delete this.parameters['vendorPos.name'];
        } else if (data.columnName === 'responsibleUserProfile') {
          delete this.parameters['responsibleUserProfile.fullName'];
        } else if (data.columnName === 'requestorPos') {
          delete this.parameters['requestorPos.name'];
        } else {
          delete this.parameters[data.columnName];
        }
      }
    });

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.getAllPosOrderdocumentsRequest(
        this.props.idProject,
        this.parameters,
      );
    }, 200);

    localStorage.setItem('paramsOrdersIntern', JSON.stringify(this.parameters));
  };

  render() {
    const {
      loading,
      currentPageSize,
      currentPage,
      pageSizes,
      objectCount,
      filters,
    } = this.state;

    const { posOrderdocumentList } = this.props;

    const row = [];
    if (posOrderdocumentList && posOrderdocumentList.posOrderdocuments) {
      posOrderdocumentList.posOrderdocuments.forEach((data, i) => {
        row[i] = {
          id: data.id,
          orderType: 'internOrder',
          createdAt: data.createdAt,
          reference: data.reference,
          requestorPos: data.requestorPos?.name,
          vendorPos: data.vendorPos?.name,
          responsibleUserProfile: data.responsibleUserProfile?.fullName,
          status: data.status,
          totalWithoutVat: data.totalWithoutVat,
          locale: this.props.locale,
          timezone: this.props.timezone,
          currency:
            this.props.projectDetails && this.props.projectDetails !== ''
              ? this.props.projectDetails.projectFinance.defaultCurrency.isoCode
              : null,
        };
      });
    }

    return (
      <div>
        <Grid rows={row} columns={columnsOrders}>
          <SelectionState />
          <PagingState
            pageSize={currentPageSize}
            currentPage={currentPage}
            onCurrentPageChange={this.setCurrentPage}
            onPageSizeChange={this.setPageSize}
          />
          <SortingState />
          <IntegratedSorting />
          <SearchState defaultValue='' />
          <FilteringState onFiltersChange={this.onFilter} filters={filters} />
          <Table
            columnExtensions={tableColumnExtensionsOrders}
            cellComponent={(props) => <OrdersCell cell={props} />}
            noDataCellComponent={NoData}
          />
          <CustomPaging totalCount={objectCount} />
          <TableColumnVisibility hiddenColumnNames={['supplier']} />
          <TableHeaderRow cellComponent={ColumnCell} />
          <TableFilterRow cellComponent={FilterCell} />
          <PagingPanel pageSizes={pageSizes} />
        </Grid>
        {loading && <Loading />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  posOrderdocumentList: state.sdk.posDocuments.posOrderdocumentList,
  locale: state.sdk.settings.locale.locale,
  timezone: state.sdk.project.projectDetails.timezone,
  projectDetails: state.sdk.project.projectDetails,
  globalError: state.sdk.auth.globalError,
  updatedDocumentStatus: state.sdk.documents.updatedDocumentStatus,
});

export default connect(mapStateToProps, {
  getAllPosOrderdocumentsRequest,
  setGlobalError,
})(InternOrdersTable);
