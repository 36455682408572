import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loading, UnderlineTitle } from '../../../../config/components.config';
import { getItemSupplierRequest } from '../../../../config/actions.config';
import IntlMessages from '../../../../util/IntlMessages';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  SelectionState,
  CustomPaging,
  FilteringState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  TableColumnVisibility,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import ColumnCell from '../../../../components/Table/Columns/ColumnCell';
import NoData from '../../../../components/Table/NoData/NoData';
import ItemsCell from '../../../../components/Table/Cells/Suppliers/ItemsCell';
import { NotificationManager } from 'react-notifications';
import { MainButton } from '../../../../config/components.config';
import ItemSupplierModalCreation from '../../ItemSupplier/ItemSupplierModal/ItemSupplierModalCreation/ItemSupplierModalCreation';
import FilterCell from '../../../../components/Table/Cells/ItemSuppliers/FiltersCell';

const columns = [
  {
    name: 'name',
    title: <IntlMessages id='itemSupplier.table.name' />,
  },
  {
    name: 'supplierProductReference',
    title: <IntlMessages id='itemSupplier.table.supplierProductReference' />,
  },
  {
    name: 'purchasePriceExclTax',
    title: <IntlMessages id='itemSupplier.table.purchasePriceExclTax' />,
  },
  {
    name: 'minimumOrderQuantity',
    title: <IntlMessages id='itemSupplier.table.minimumOrderQuantity' />,
  },
  {
    name: 'maximumOrderQuantity',
    title: <IntlMessages id='itemSupplier.table.maximumOrderQuantity' />,
  },
  {
    name: 'quantityPerPack',
    title: <IntlMessages id='itemSupplier.table.quantityPerPack' />,
  },
  {
    name: 'packUnitName',
    title: <IntlMessages id='itemSupplier.table.packUnitName' />,
  },
  {
    name: 'button',
    title: <IntlMessages id='itemSupplier.table.button' />,
  },
];

const tableColumnExtensions = [
  { columnName: 'quantityPerPack', align: 'center' },
  { columnName: 'packUnitName', align: 'center' },
  { columnName: 'button', align: 'right' },
];

class SuppliersItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pageSizes: [10, 20, 50, 100, 0],
      currentPage: 0,
      currentPageSize: 10,
      openModalItemSupplier: false,
      filters: [],
    };
    this.params = {
      limit: this.state.currentPageSize,
      page: this.state.currentPage + 1,
      'project.id': this.props.idProject,
    };
  }

  componentDidMount() {
    this.props.getItemSupplierRequest(this.props.id, this.params);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.itemSuppliersList !== this.props.itemSuppliersList) {
      if (this.props.itemSuppliersList.loading) {
        this.setState({ loading: true });
      }
      if (
        !this.props.itemSuppliersList.loading &&
        !this.props.itemSuppliersList.error
      ) {
        this.setState({
          loading: false,
          objectCount: this.props.itemSuppliersList.meta.object_count,
        });
      }
    }
    if (prevProps.newSupplierItem !== this.props.newSupplierItem) {
      if (
        this.props.newSupplierItem.supplierItem !== '' &&
        !this.props.newSupplierItem.loading &&
        !this.props.newSupplierItem.error
      ) {
        NotificationManager.success(
          <IntlMessages id='supplierItem.success.message.added' />
        );
        this.props.getItemSupplierRequest(this.props.id, this.params);
      }
    }
    if (prevProps.deletedItemSupplier !== this.props.deletedItemSupplier) {
      if (
        this.props.deletedItemSupplier.itemSupplier !== '' &&
        !this.props.deletedItemSupplier.loading &&
        !this.props.deletedItemSupplier.error
      ) {
        NotificationManager.success(
          <IntlMessages id='supplierItem.success.message.deleted' />
        );
        this.props.getItemSupplierRequest(this.props.id, this.params);
      }
    }
    if (prevProps.updatedItemSupplier !== this.props.updatedItemSupplier) {
      if (
        this.props.updatedItemSupplier.item !== '' &&
        !this.props.updatedItemSupplier.loading &&
        !this.props.updatedItemSupplier.error
      ) {
        NotificationManager.success(
          <IntlMessages id='supplierItem.success.message.updated' />
        );
        this.props.getItemSupplierRequest(this.props.id, this.params);
      }
    }
  }

  setCurrentPage = (page) => {
    this.setState({ currentPage: page }, () => {
      this.props.getItemSupplierRequest(this.props.id, {
        limit: this.state.currentPageSize,
        page: page + 1,
        'project.id': this.props.idProject,
      });
    });
  };

  setPageSize = (pageSize) => {
    this.setState({ currentPageSize: pageSize }, () => {
      this.props.getItemSupplierRequest(this.props.id, {
        limit: pageSize,
        page: 1,
        'project.id': this.props.idProject,
      });
    });
  };

  onFilter = (filter) => {
    this.setState({ filters: filter });
    filter.forEach((data) => {
      if (data.columnName === 'name') {
        this.params['item.name'] = {};
        this.params['item.name'].operation = 'contain';
        this.params['item.name'].value = data.value;
      } else {
        delete this.params[data.columnName];
      }
    });

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.getItemSupplierRequest(this.props.id, this.params);
    }, 200);
  };

  render() {
    const {
      loading,
      currentPageSize,
      currentPage,
      pageSizes,
      objectCount,
      openModalItemSupplier,
      filters,
    } = this.state;
    const { itemSuppliersList } = this.props;

    const row = [];
    if (itemSuppliersList && itemSuppliersList.itemSuppliers) {
      itemSuppliersList.itemSuppliers.forEach((data, i) => {
        row[i] = {
          itemSupplier: data,
          name: data.item ? data.item.name : '',
          supplierProductReference: data.supplierProductReference,
          purchasePriceExclTax: data.purchasePriceExclTax,
          minimumOrderQuantity: data.minimumOrderQuantity
            ? data.minimumOrderQuantity
            : 0,
          quantityPerPack: data.quantityPerPack,
          packUnitName: data.packUnitName,
          maximumOrderQuantity: data.maximumOrderQuantity,
          locale: this.props.locale,
          currency:
            this.props.projectDetails && this.props.projectDetails !== ''
              ? this.props.projectDetails.projectFinance.defaultCurrency
              : null,
        };
      });
    }

    return (
      <div>
        <div className='jr-card'>
          <UnderlineTitle
            title={<IntlMessages id='supplier.detail.card.items.title' />}
          >
            <div>
              <MainButton
                onClick={() => this.setState({ openModalItemSupplier: true })}
              >
                <IntlMessages id='suppliers.product.button.add' />
              </MainButton>
            </div>
          </UnderlineTitle>
          <Grid rows={row} columns={columns}>
            <SelectionState />
            <PagingState
              pageSize={currentPageSize}
              currentPage={currentPage}
              onCurrentPageChange={this.setCurrentPage}
              onPageSizeChange={this.setPageSize}
            />
            <SortingState />
            <IntegratedSorting />
            <FilteringState onFiltersChange={this.onFilter} filters={filters} />
            <Table
              columnExtensions={tableColumnExtensions}
              cellComponent={ItemsCell}
              noDataCellComponent={NoData}
            />
            <TableColumnVisibility />
            <CustomPaging totalCount={objectCount} />
            <TableHeaderRow cellComponent={ColumnCell} />
            <TableFilterRow cellComponent={FilterCell} />
            <PagingPanel pageSizes={pageSizes} />
          </Grid>

          {loading && <Loading />}
          <ItemSupplierModalCreation
            idSupplier={this.props.id}
            open={openModalItemSupplier}
            close={() => this.setState({ openModalItemSupplier: false })}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  itemSuppliersList: state.sdk.itemSuppliers.itemSuppliersList,
  newSupplierItem: state.sdk.suppliers.newSupplierItem,
  deletedItemSupplier: state.sdk.itemSuppliers.deletedItemSupplier,
  updatedItemSupplier: state.sdk.itemSuppliers.updatedItemSupplier,
  locale: state.sdk.settings.locale.locale,
  projectDetails: state.sdk.project.projectDetails,
});

export default connect(mapStateToProps, { getItemSupplierRequest })(
  SuppliersItems
);
