import React, { Component } from 'react';
import { Modal } from '../../../../config/components.config';
import IntlMessages from '../../../../util/IntlMessages';
import PosModalEditContent from './PosModalEditContent';

class PosModalEdit extends Component {
  handleClose = () => this.props.close();

  render() {
    return (
      <Modal
        title={<IntlMessages id={'pos.modal.edit.title'} />}
        width='md'
        open={this.props.open}
        close={this.handleClose}
      >
        <PosModalEditContent
          handleClose={this.handleClose}
          pos={this.props.pos}
        />
      </Modal>
    );
  }
}

export default PosModalEdit;
