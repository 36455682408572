import React from 'react';
import IntlMessages from '../util/IntlMessages';

const stockStatus = [
  { value: 'stock', label: <IntlMessages id='movements.table.stock.status.stock' /> },
  { value: 'booked', label: <IntlMessages id='movements.table.stock.status.booked' /> },
  { value: 'consigned', label: <IntlMessages id='movements.table.stock.status.consigned' /> },
  { value: 'returned', label: <IntlMessages id='movements.table.stock.status.returned' /> },
  { value: 'repair', label: <IntlMessages id='movements.table.stock.status.repair' /> },
  { value: 'broken', label: <IntlMessages id='movements.table.stock.status.broken' /> },
  { value: 'lost', label: <IntlMessages id='movements.table.stock.status.lost' /> },
  { value: 'stolen', label: <IntlMessages id='movements.table.stock.status.stolen' /> },
  { value: 'quarantine', label: <IntlMessages id='movements.table.stock.status.quarantine' /> },
];

export default stockStatus;