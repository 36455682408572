import React, { Component } from 'react';
import { connect } from 'react-redux';
import ColumnCell from '../../../../components/Table/Columns/ColumnCell';
import NoData from '../../../../components/Table/NoData/NoData';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  SelectionState,
  CustomPaging,
  SearchState,
  FilteringState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  tableColumnExtensionsMovements,
  columnsMovements,
} from '../../../../components/Table/Columns/ShowColumns';
import { Loading } from '../../../../config/components.config';
import { NotificationManager } from 'react-notifications';
import IntlMessages from '../../../../util/IntlMessages';
import {
  getStockMovementsRequest,
  setGlobalError,
} from '../../../../config/actions.config';
import MovementsCell from '../../../../components/Table/Cells/Movements/MovementsCell';
import FilterCell from '../../../../components/Table/Cells/Movements/FiltersCell';

class MovementsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pageSizes: [10, 20, 50, 100, 0],
      currentPage: 0,
      currentPageSize: 20,
      filters: [],
      selectedDateStart: null,
      selectedDateEnd: null,
    };
    this.params = {
      limit: this.state.currentPageSize,
      page: this.state.currentPage + 1,
      order: 'id:DESC',
    };
  }

  componentDidMount() {
    this.props.getStockMovementsRequest(this.props.idProject, this.params);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.stockMovementsList !== this.props.stockMovementsList) {
      if (this.props.stockMovementsList.loading) {
        this.setState({ loading: true });
      }
      if (
        this.props.stockMovementsList.stockMovements !== '' &&
        !this.props.stockMovementsList.loading &&
        !this.props.stockMovementsList.error
      ) {
        this.setState({
          loading: false,
          objectCount: this.props.stockMovementsList.meta.object_count,
        });
      }
    }

    if (prevProps.globalError !== this.props.globalError) {
      if (this.props.globalError === true) {
        NotificationManager.error(
          <IntlMessages id='appModule.message.error' />
        );
        this.props.setGlobalError(false);
      }
    }

    if (
      this.props.selectedDateStart !== prevProps.selectedDateStart ||
      this.props.selectedDateEnd !== prevProps.selectedDateEnd
    ) {
      if (this.props.selectedDateStart && this.props.selectedDateEnd) {
        delete this.params['datetime'];
        this.params['datetime'] = [];
        this.params['datetime'].push({
          operation: 'gt',
          value: this.props.selectedDateStart,
        });
        this.params['datetime'].push({
          operation: 'lt',
          value: this.props.selectedDateEnd,
        });

        this.props.getStockMovementsRequest(this.props.idProject, this.params);
      }
    }
  }

  setCurrentPage = (page) => {
    this.params.page = page + 1;
    this.setState({ currentPage: page }, () => {
      this.props.getStockMovementsRequest(this.props.idProject, this.params);
    });
  };

  setPageSize = (pageSize) => {
    this.params.limit = pageSize;
    this.setState({ currentPageSize: pageSize }, () => {
      this.props.getStockMovementsRequest(this.props.idProject, this.params);
    });
  };

  onFilter = (filter) => {
    this.setState({ filters: filter });
    filter.forEach((data) => {
      if (data.value && data.value !== '' && data.value !== 'all') {
        if (data.columnName === 'item') {
          this.params['itemStock.itemPos.item.name'] = {};
          this.params['itemStock.itemPos.item.name'].operation = 'contain';
          this.params['itemStock.itemPos.item.name'].value = data.value;
        } else if (data.columnName === 'pos') {
          this.params['itemStock.itemPos.pos.name'] = {};
          this.params['itemStock.itemPos.pos.name'].operation = 'contain';
          this.params['itemStock.itemPos.pos.name'].value = data.value;
        } else if (data.columnName === 'stockStatus') {
          this.params['itemStock.status'] = {};
          this.params['itemStock.status'].operation = 'eq';
          this.params['itemStock.status'].value = data.value;
        } else if (data.columnName === 'type') {
          this.params[data.columnName] = {};
          this.params[data.columnName].operation = 'eq';
          this.params[data.columnName].value = data.value;
        } else if (data.columnName === 'datetime') {
          delete this.params['datetime'];
          this.params[data.columnName] = [];
          this.params[data.columnName].push({
            operation: 'gt',
            value: data.values.dateStart,
          });
          this.params[data.columnName].push({
            operation: 'lt',
            value: data.values.dateEnd,
          });
        }
      } else {
        if (data.columnName === 'item') {
          delete this.params['itemStock.itemPos.item.name'];
        } else if (data.columnName === 'pos') {
          delete this.params['itemStock.itemPos.pos.name'];
        } else if (data.columnName === 'stockStatus') {
          delete this.params['itemStock.status'];
        } else {
          delete this.params[data.columnName];
        }
      }
    });

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.getStockMovementsRequest(this.props.idProject, this.params);
    }, 200);
  };

  render() {
    const {
      loading,
      currentPageSize,
      currentPage,
      pageSizes,
      objectCount,
      filters,
    } = this.state;
    const { stockMovementsList } = this.props;

    const row = [];
    if (stockMovementsList && stockMovementsList.stockMovements) {
      stockMovementsList.stockMovements.forEach((data, i) => {
        console.log(data);
        row[i] = {
          stockMovements: data,
          datetime: data.datetime,
          item: data?.itemStock?.itemPos?.item.name,
          pos: data?.itemStock?.itemPos?.pos.name,
          stockStatus: data?.itemStock?.status,
          type: data.type,
          quantityBefore: data.quantityBefore ? data.quantityBefore : 0,
          quantity: data.quantity ? data.quantity : 0,
          quantityAfter: data.quantityAfter ? data.quantityAfter : 0,
          locale: this.props.locale,
          timezone: this.props.timezone,
        };
      });
    }

    return (
      <div>
        <Grid rows={row} columns={columnsMovements}>
          <SelectionState />
          <PagingState
            pageSize={currentPageSize}
            currentPage={currentPage}
            onCurrentPageChange={this.setCurrentPage}
            onPageSizeChange={this.setPageSize}
          />
          <SortingState />
          <IntegratedSorting />
          <SearchState defaultValue='' />
          <FilteringState onFiltersChange={this.onFilter} filters={filters} />
          <Table
            columnExtensions={tableColumnExtensionsMovements}
            cellComponent={MovementsCell}
            noDataCellComponent={NoData}
          />
          <CustomPaging totalCount={objectCount} />
          <TableHeaderRow cellComponent={ColumnCell} />
          <TableFilterRow cellComponent={FilterCell} />
          <PagingPanel pageSizes={pageSizes} />
        </Grid>
        {loading && <Loading />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  stockMovementsList: state.sdk.stockMovements.stockMovementsList,
  globalError: state.sdk.auth.globalError,
  locale: state.sdk.settings.locale.locale,
  timezone: state.sdk.project.projectDetails.timezone,
});

export default connect(mapStateToProps, {
  getStockMovementsRequest,
  setGlobalError,
})(MovementsTable);
