import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import IntlMessages from '../../../../../util/IntlMessages';
import Select from 'react-select';
import {
  getPosByProjectRequest,
  getItemsByProjectRequest,
  exportInventoryRequest,
  getSuppliersByOrganizationRequest,
} from '../../../../../config/actions.config';
import stockStatus from '../../../../../services/stockStatus';
import '../../../index.css';
import SearchSelect from '../../../../../components/Select/SearchSelect/SearchSelect';

class InventoryExportModalContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posLoading: true,
      itemLoading: true,
      exportLoading: false,
      status: '',
      sku: '',
      posId: '',
      productId: '',
      supplierId: '',
      suppliersList: null,
      posList: null,
      itemList: null,
    };
    this.args = {};
  }

  componentDidMount() {
    this.props.getPosByProjectRequest(this.props.idProject, {});
    this.props.getItemsByProjectRequest(this.props.idProject, {});
    if (this.props.idOrganization) {
      this.props.getSuppliersByOrganizationRequest(
        this.props.idOrganization.id
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.posList !== this.props.posList) {
      this.setState({ posLoading: this.props.posList.loading });
      if (
        this.props.posList.pos !== '' &&
        !this.props.posList.error &&
        !this.props.posList.loading
      ) {
        this.setState({ posList: this.props.posList.pos });
      }
    }

    if (prevProps.itemsList !== this.props.itemsList) {
      this.setState({ itemLoading: this.props.itemsList.loading });
      if (
        this.props.itemsList.items !== '' &&
        !this.props.itemsList.error &&
        !this.props.itemsList.loading
      ) {
        this.setState({ itemList: this.props.itemsList.items });
      }
    }

    if (
      prevProps.suppliersByOrganizationList !==
      this.props.suppliersByOrganizationList
    ) {
      this.setState({
        supplierLoading: this.props.suppliersByOrganizationList.loading,
      });
      if (
        this.props.suppliersByOrganizationList.suppliers !== '' &&
        !this.props.suppliersByOrganizationList.error &&
        !this.props.suppliersByOrganizationList.loading
      ) {
        this.setState({
          suppliersList: this.props.suppliersByOrganizationList.suppliers,
        });
      }
    }

    if (prevProps.downloadedInventory !== this.props.downloadedInventory) {
      this.setState({ exportLoading: this.props.downloadedInventory.loading });
    }
  }

  handleChange = (name) => (event) => {
    if (event.target.value !== '') {
      this.setState({ [name]: event.target.value });
      this.args[name] = event.target.value;
    } else {
      this.setState({ [name]: '' });
      delete this.args[name];
    }
  };

  handleChangeSelect = (name) => (event) => {
    if (event) {
      this.setState({ [name]: event.value });
      this.args[name] = event.value;
    } else {
      this.setState({ [name]: null });
      delete this.args[name];
    }
  };

  handleClose = () => this.props.handleClose();

  handleSubmit = () => {
    this.props.exportInventoryRequest(this.props.idProject, this.args);
  };

  handleChangeSupplierValue = (value) => {
    if (value !== '') {
      this.props.getSuppliersByOrganizationRequest(
        this.props.idOrganization.id,
        {
          'company.name|contain': value,
        }
      );
    }
    this.setState({ supplier: value });
  };

  render() {
    const {
      posLoading,
      itemLoading,
      exportLoading,
      posList,
      itemList,
      supplier,
      suppliersList,
      supplierLoading,
    } = this.state;

    return (
      <div>
        <div className="col-12">
          <div className="information">
            <div className="col-12 col-md-4">
              <h4>
                <IntlMessages id={'inventory.modal.label.pos.name'} />
              </h4>
            </div>
            <div className="col-12 col-md-6">
              <Select
                id="type"
                fullWidth
                isClearable
                isLoading={posLoading}
                isDisabled={posLoading}
                onChange={this.handleChangeSelect('posId')}
                options={
                  posList &&
                  posList.map((data) => {
                    return {
                      value: data.id,
                      label: data.name,
                    };
                  })
                }
              />
            </div>
          </div>

          <div className="information">
            <div className="col-12 col-md-4">
              <h4>
                <IntlMessages id={'inventory.modal.label.product.name'} />
              </h4>
            </div>
            <div className="col-12 col-md-6">
              <Select
                fullWidth
                isClearable
                onChange={this.handleChangeSelect('productId')}
                isLoading={itemLoading}
                isDisabled={itemLoading}
                options={
                  itemList &&
                  itemList.map((data) => {
                    return {
                      value: data.id,
                      label: data.name,
                    };
                  })
                }
              />
            </div>
          </div>

          <div className="information">
            <div className="col-12 col-md-4">
              <h4>
                <IntlMessages id={'inventory.modal.label.supplier.name'} />
              </h4>
            </div>
            <div className="col-12 col-md-6">
              <SearchSelect
                handleChangeValue={this.handleChangeSupplierValue}
                changeSelect={this.handleChangeSelect('supplierId')}
                inputValue={supplier}
                isLoading={supplierLoading}
                options={
                  suppliersList &&
                  suppliersList.map((data) => ({
                    value: data.id,
                    label: data.company.name,
                  }))
                }
              />
            </div>
          </div>

          <div className="information">
            <div className="col-12 col-md-4">
              <h4>
                <IntlMessages id={'inventory.modal.label.status'} />
              </h4>
            </div>
            <div className="col-12 col-md-6">
              <Select
                fullWidth
                isClearable
                onChange={this.handleChangeSelect('status')}
                options={
                  stockStatus &&
                  stockStatus.map((data) => {
                    return {
                      value: data.value,
                      label: data.label,
                    };
                  })
                }
              />
            </div>
          </div>

          <div className="information">
            <div className="col-12 col-md-4">
              <h4>
                <IntlMessages id={'inventory.modal.label.sku'} />
              </h4>
            </div>
            <div className="col-12 col-md-6">
              <TextField
                type="string"
                variant="outlined"
                size="small"
                fullWidth
                onChange={this.handleChange('sku')}
              />
            </div>
          </div>

          <div className="d-flex justify-content-end mt-4">
            {/* Bouton a montrer quand ce sera en modal */}
            {/* <Button onClick={this.handleClose} color="primary">
              <IntlMessages id="inventory.modal.button.cancel" />
            </Button> */}

            <Button
              id="save"
              variant="outlined"
              onClick={this.handleSubmit}
              color="primary"
              endIcon={exportLoading && <CircularProgress size={20} />}
              disabled={exportLoading}
            >
              <IntlMessages id="inventory.modal.button.export" />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  idOrganization: state.sdk.project.projectDetails.organisation,
  itemsList: state.sdk.items.itemsList,
  suppliersByOrganizationList: state.sdk.suppliers.suppliersByOrganizationList,
  downloadedInventory: state.sdk.inventory.downloadedInventory,
  posList: state.sdk.pos.posList,
});

export default connect(mapStateToProps, {
  getSuppliersByOrganizationRequest,
  getPosByProjectRequest,
  getItemsByProjectRequest,
  exportInventoryRequest,
})(InventoryExportModalContent);
