import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  SelectionState,
  CustomPaging,
  SearchState,
  FilteringState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableColumnVisibility,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import { NotificationManager } from 'react-notifications';
import ColumnCell from '../../../../components/Table/Columns/ColumnCell';
import NoData from '../../../../components/Table/NoData/NoData';
import ItemPosCell from '../../../../components/Table/Cells/ItemPos/ItemPosCell';
import {
  columnsItemPos,
  tableColumnExtensionsItemPos,
} from '../../../../components/Table/Columns/ShowColumns';
import { Loading } from '../../../../config/components.config';
import IntlMessages from '../../../../util/IntlMessages';
import {
  getItemPosByProjectRequest,
  setGlobalError,
} from '../../../../config/actions.config';
import FilterCell from '../../../../components/Table/Cells/ItemPos/FiltersCell';

class ItemPosTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pageSizes: [10, 20, 50, 100, 0],
      currentPage: 0,
      currentPageSize: 20,
      filters: [],
    };
    this.params = {
      limit: this.state.currentPageSize,
      page: this.state.currentPage + 1,
    };
  }

  componentDidMount() {
    this.props.getItemPosByProjectRequest(this.props.idProject, this.params);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.itemPosList !== this.props.itemPosList) {
      if (this.props.itemPosList.loading) {
        this.setState({ loading: true });
      }
      if (
        this.props.itemPosList.itemPos !== '' &&
        !this.props.itemPosList.loading &&
        !this.props.itemPosList.error
      ) {
        this.setState({
          loading: false,
          objectCount: this.props.itemPosList.meta.object_count,
        });
      }
    }

    if (prevProps.updatedItemPos !== this.props.updatedItemPos) {
      if (
        this.props.updatedItemPos.itemPos !== '' &&
        !this.props.updatedItemPos.loading &&
        !this.props.updatedItemPos.error
      ) {
        NotificationManager.success(
          <IntlMessages id='itemPos.success.message.updated' />
        );
        this.props.getItemPosByProjectRequest(this.props.idProject, this.params);
      }
    }

    if (prevProps.newItemPosStock !== this.props.newItemPosStock) {
      if (
        this.props.newItemPosStock.itemPos !== '' &&
        !this.props.newItemPosStock.loading &&
        !this.props.newItemPosStock.error
      ) {
        NotificationManager.success(
          <IntlMessages id='itemPos.success.message.updated' />
        );
        this.props.getItemPosByProjectRequest(this.props.idProject, this.params);
      }
    }

    if (prevProps.newItemTransfert !== this.props.newItemTransfert) {
      if (
        this.props.newItemTransfert.itemTransfert !== '' &&
        !this.props.newItemTransfert.loading &&
        !this.props.newItemTransfert.error
      ) {
        NotificationManager.success(
          <IntlMessages id='itemPos.success.message.updated' />
        );
        this.props.getItemPosByProjectRequest(this.props.idProject, this.params);
      }
    }

    if (prevProps.deletedItemPos !== this.props.deletedItemPos) {
      if (
        this.props.deletedItemPos.itemPos !== '' &&
        !this.props.deletedItemPos.loading &&
        !this.props.deletedItemPos.error
      ) {
        NotificationManager.success(
          <IntlMessages id='itemPos.success.message.deleted' />
        );
        this.props.getItemPosByProjectRequest(this.props.idProject, this.params);
      }
    }

    if (prevProps.globalError !== this.props.globalError) {
      if (this.props.globalError === true) {
        NotificationManager.error(
          <IntlMessages id='appModule.message.error' />
        );
        this.props.setGlobalError(false);
      }
    }
  }

  setCurrentPage = (page) => {
    this.setState({ currentPage: page }, () => {
      this.props.getItemPosByProjectRequest(this.props.idProject, {
        limit: this.state.currentPageSize,
        page: page + 1,
      });
    });
  };

  setPageSize = (pageSize) => {
    this.setState({ currentPageSize: pageSize }, () => {
      this.props.getItemPosByProjectRequest(this.props.idProject, {
        limit: pageSize,
        page: 1,
      });
    });
  };

  onFilter = (filter) => {
    this.setState({ filters: filter });
    filter.forEach((data) => {
      if (data.value && data.value !== '' && data.value !== 'all') {
        if (data.columnName === 'item') {
          this.params['item.name'] = {};
          this.params['item.name'].operation = 'contain';
          this.params['item.name'].value = data.value;
        } else if (data.columnName === 'pos') {
          this.params['pos.name'] = {};
          this.params['pos.name'].operation = 'contain';
          this.params['pos.name'].value = data.value;
        } else {
          this.params[data.columnName] = {};
          this.params[data.columnName].operation = 'contain';
          this.params[data.columnName].value = data.value;
        }
      } else {
        if (data.columnName === 'item') {
          delete this.params['item.name'];
        } else if (data.columnName === 'pos') {
          delete this.params['pos.name'];
        } else {
          delete this.params[data.columnName];
        }
      }
    });

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.getItemPosByProjectRequest(this.props.idProject, this.params);
    }, 200);
  };

  render() {
    const {
      loading,
      currentPageSize,
      currentPage,
      pageSizes,
      objectCount,
      filters,
    } = this.state;
    const { itemPosList } = this.props;

    const row = [];
    if (itemPosList && itemPosList.itemPos) {
      itemPosList.itemPos.forEach((data, i) => {
        row[i] = {
          itemPos: data,
          item: data.item,
          pos: data.pos,
          reorderLevel: data.reorderLevel ? data.reorderLevel : 0,
          quantityInStock: data.quantityInStock ? data.quantityInStock : 0,
          quantityInOrder: data.quantityInOrder ? data.quantityInOrder : 0,
          quantityInDelivery: data.quantityInDelivery ? data.quantityInDelivery : 0,
          warehouse: data.warehouse,
        };
      });
    }

    return (
      <div>
        <Grid rows={row} columns={columnsItemPos}>
          <SelectionState />
          <PagingState
            pageSize={currentPageSize}
            currentPage={currentPage}
            onCurrentPageChange={this.setCurrentPage}
            onPageSizeChange={this.setPageSize}
          />
          <SortingState />
          <IntegratedSorting />
          <SearchState defaultValue='' />
          <FilteringState onFiltersChange={this.onFilter} filters={filters} />
          <Table
            columnExtensions={tableColumnExtensionsItemPos}
            cellComponent={ItemPosCell}
            noDataCellComponent={NoData}
          />
          <CustomPaging totalCount={objectCount} />
          <TableColumnVisibility />
          <TableHeaderRow cellComponent={ColumnCell} />
          <TableFilterRow cellComponent={FilterCell} />
          <PagingPanel pageSizes={pageSizes} />
        </Grid>
        {loading && <Loading />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  itemPosList: state.sdk.itemPos.itemPosList,
  updatedItemPos: state.sdk.itemPos.updatedItemPos,
  deletedItemPos: state.sdk.itemPos.deletedItemPos,
  newItemPosStock: state.sdk.itemPos.newItemPosStock,
  newItemTransfert: state.sdk.items.newItemTransfert,
  globalError: state.sdk.auth.globalError,
});

export default connect(mapStateToProps, {
  getItemPosByProjectRequest,
  setGlobalError,
})(ItemPosTable);
