import React from 'react';
import IntlMessages from '../../../util/IntlMessages';

const statusWidth = 150;
const buttonWidth = 200;

// -----------------Dashboard

export const columnsDashboard = [
  { name: 'articles', title: 'Articles' },
  { name: 'year1', title: '12 juillet 2018 - 16 juillet 2018' },
  { name: 'year2', title: '12 juillet 2019 - 16 juillet 2019' },
];

export const tableColumnExtensionsDashboard = [
  { columnName: 'articles' },
  { columnName: 'year1' },
  { columnName: 'year2' },
];

// -----------------Items

export const columnsItems = [
  { name: 'name', title: <IntlMessages id="items.table.column.name" /> },
  {
    name: 'defaultSupplier',
    title: <IntlMessages id="items.table.column.defaultSupplier" />,
  },
  {
    name: 'buttons',
    title: <IntlMessages id="items.table.column.actions" />,
  },
];

export const tableColumnExtensionsItems = [
  { columnName: 'name' },
  { columnName: 'defaultSupplier' },
  { columnName: 'buttons', align: 'right', width: buttonWidth },
];

// -----------------Pos

export const tableColumnExtensionsPos = [
  { columnName: 'name' },
  { columnName: 'posCategory' },
  { columnName: 'warehouse' },
  { columnName: 'buttons', align: 'right', width: buttonWidth },
];

// -----------------Suppliers

export const tableColumnExtensionsSuppliers = [
  { columnName: 'name' },
  { columnName: 'reference', width: 70 },
  { columnName: 'email', width: 200 },
  { columnName: 'phone' },
  { columnName: 'address', width: 200 },
  { columnName: 'vat' },
  { columnName: 'buttons', align: 'right', width: buttonWidth },
];

// -----------------ItemPos

export const columnsItemPos = [
  { name: 'item', title: <IntlMessages id="itemPos.table.column.item" /> },
  { name: 'pos', title: <IntlMessages id="itemPos.table.column.pos" /> },
  { name: 'reorderLevel', title: <IntlMessages id="itemPos.table.column.qtyAlert" /> },
  { name: 'quantityInStock', title: <IntlMessages id="itemPos.table.column.qtyNow" /> },
  { name: 'quantityInOrder', title: <IntlMessages id="itemPos.table.column.qtyCommand" /> },
  { name: 'quantityInDelivery', title: <IntlMessages id="itemPos.table.column.qtyDelivery" /> },
  { name: 'buttons', title: <IntlMessages id="itemPos.table.column.actions" /> },
];

export const tableColumnExtensionsItemPos = [
  { columnName: 'reorderLevel', align: 'center' },
  { columnName: 'reorderQuantity', align: 'center' },
  { columnName: 'quantityInStock', align: 'center' },
  { columnName: 'quantityInOrder', align: 'center' },
  { columnName: 'quantityInDelivery', align: 'center' },
  { columnName: 'buttons', align: 'right', width: buttonWidth },
];
// -----------------Movements

export const columnsMovements = [
  { name: 'datetime', title: <IntlMessages id="movements.table.datetime" /> },
  { name: 'item', title: <IntlMessages id="movements.table.item" /> },
  { name: 'pos', title: <IntlMessages id="movements.table.pos" /> },
  {
    name: 'stockStatus',
    title: <IntlMessages id="movements.table.stockStatus" />,
  },
  { name: 'type', title: <IntlMessages id="movements.table.type" /> },
  {
    name: 'quantityBefore',
    title: <IntlMessages id="movements.table.quantityBefore" />,
  },
  { name: 'quantity', title: <IntlMessages id="movements.table.quantity" /> },
  {
    name: 'quantityAfter',
    title: <IntlMessages id="movements.table.quantityAfter" />,
  },
  { name: 'buttons', title: <IntlMessages id="movements.table.actions" /> },
];

export const tableColumnExtensionsMovements = [
  { columnName: 'datetime' },
  { columnName: 'item' },
  { columnName: 'pos' },
  { columnName: 'stockStatus', align: 'center', width: statusWidth },
  { columnName: 'type', align: 'center' },
  { columnName: 'quantityBefore', align: 'center', width: 100 },
  { columnName: 'quantity', align: 'center', width: 100 },
  { columnName: 'quantityAfter', align: 'center', width: 100 },
  { columnName: 'buttons', align: 'right', width: 100 },
];

// -----------------Orders

export const columnsOrders = [
  { name: 'createdAt', title: <IntlMessages id="appModule.date" /> },
  {
    name: 'reference',
    title: <IntlMessages id="orders.supplier.table.reference" />,
  },
  {
    name: 'requestorPos',
    title: <IntlMessages id="orders.supplier.table.requestorPos" />,
  },
  {
    name: 'supplier',
    title: <IntlMessages id="orders.supplier.table.supplier" />,
  },
  {
    name: 'vendorPos',
    title: <IntlMessages id="orders.supplier.table.vendorPos" />,
  },
  {
    name: 'status',
    title: <IntlMessages id="orders.supplier.table.status" />,
  },
  {
    name: 'responsibleUserProfile',
    title: <IntlMessages id="orders.supplier.table.responsibleUserProfile" />,
  },
  {
    name: 'totalWithoutVat',
    title: <IntlMessages id="orders.supplier.table.priceWithoutVat" />,
  },
  {
    name: 'action',
    title: <IntlMessages id="orders.supplier.table.action" />,
  },
];

export const tableColumnExtensionsOrders = [
  { columnName: 'totalWithoutVat', align: 'center' },
  { columnName: 'status', align: 'center', width: statusWidth },
  { columnName: 'action', align: 'right', width: 100 },
];

// -----------------OrdersDetails

export const columnsOrdersDetail = [
  { name: 'item', title: <IntlMessages id="orders.detail.table.item" /> },
  {
    name: 'packUnitName',
    title: <IntlMessages id="orders.detail.table.packUnitName" />,
  },
  {
    name: 'priceWithoutVat',
    title: <IntlMessages id="orders.detail.table.priceWithoutVat" />,
  },
  {
    name: 'quantity',
    title: <IntlMessages id="orders.detail.table.quantity" />,
  },
  {
    name: 'quantityAlreadyDelivered',
    title: <IntlMessages id="orders.detail.table.quantityAlreadyDelivered" />,
  },
  {
    name: 'vatrate',
    title: <IntlMessages id="orders.detail.table.vatrate" />,
  },
  {
    name: 'totalWithoutVat',
    title: <IntlMessages id="orders.detail.table.totalWithoutVat" />,
  },
];

export const tableColumnExtensionsOrdersDetail = [
  { columnName: 'priceWithoutVat', align: 'center' },
  { columnName: 'quantity', align: 'center' },
  { columnName: 'totalWithoutVat', align: 'center' },
  { columnName: 'packUnitName', align: 'center' },
  { columnName: 'vatrate', align: 'center' },
];

// -----------------OrderDelivery

export const columnsOrderDelivery = [
  {
    name: 'qtyReceived',
    title: <IntlMessages id="orders.detail.table.quantityReceived" />,
  },
  {
    name: 'quantity',
    title: <IntlMessages id="orders.detail.table.quantity" />,
  },
  {
    name: 'quantityAlreadyDelivered',
    title: <IntlMessages id='itemPos.table.column.qtyDelivery' />,
  },
  { name: 'item', title: <IntlMessages id="orders.detail.table.item" /> },
  {
    name: 'totalWithoutVat',
    title: <IntlMessages id="orders.detail.table.totalWithoutVat" />,
  },
];

export const tableColumnExtensionsOrderDelivery = [
  { columnName: 'totalWithoutVat', align: 'right' },
  { columnName: 'qtyReceived', align: 'center' },
  { columnName: 'stock', align: 'center' },
  { columnName: 'item', align: 'center' },
  { columnName: 'quantityAlreadyDelivered', align: 'center' },
  { columnName: 'quantity', align: 'center' },
];
