import React, { Component } from 'react';
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { getUserSelfDetailsRequest } from '../../config/actions.config';
import './index.css';

class PersonalContentSide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      userDetails: null,
    };
  }

  componentDidMount() {
    this.setState({ fullName: this.props.userSelfDetails.details.fullName });
  }

  render() {
    const { fullName } = this.state;

    var picture = '';

    if (this.props.userSelfDetails.details) {
      if (this.props.userSelfDetails.details.picture) {
        picture = this.props.userSelfDetails.details.picture.normal;
      }
    }

    return (
      <div className='col-12 asideProfile'>
        <Grid container justify='center' alignItems='center'>
          <Avatar alt='image speaker' src={picture} className='avatarProfile' />
        </Grid>
        <h2>{fullName}</h2>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  userSelfDetails: state.sdk.user.userSelfDetails,
});

export default connect(mapStateToProps, { getUserSelfDetailsRequest })(
  PersonalContentSide
);
