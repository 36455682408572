import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import IntlMessages from '../../../../../util/IntlMessages';
import {
  UnderlineTitle,
  Loading,
} from '../../../../../config/components.config';
import {
  getAllUsersByProjectRequest,
  getPosByProjectRequest,
} from '../../../../../config/actions.config';
import '../../../index.css';
import SearchSelect from '../../../../../components/Select/SearchSelect/SearchSelect';

class InternOrderRequestor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      usersList: null,
      responsibleUserProfile: null,
      posList: null,
      requestorPos: null,
      valueUserReseach: '',
    };
  }

  componentDidMount() {
    this.props.getAllUsersByProjectRequest(this.props.idProject);
    this.props.getPosByProjectRequest(this.props.idProject);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.usersList !== this.props.usersList) {
      if (this.props.usersList.loading) {
        this.setState({ loading: true });
      }
      if (
        this.props.usersList.users !== '' &&
        !this.props.usersList.loading &&
        !this.props.usersList.error
      ) {
        this.setState({
          loading: false,
          usersList: this.props.usersList.users,
        });
      }
    }

    if (prevProps.posList !== this.props.posList) {
      if (this.props.posList.loading) {
        this.setState({ loading: true });
      }
      if (
        this.props.posList.pos !== '' &&
        !this.props.posList.loading &&
        !this.props.posList.error
      ) {
        this.setState({
          loading: false,
          posList: this.props.posList.pos,
        });
      }
    }
  }

  changeSelect = (name) => (event) => {
    this.setState({ [name]: event });
    this.props.handeChange(name, event.value);
  };

  handleChangeUserValue = (value) => {
    this.props.getAllUsersByProjectRequest(this.props.idProject, {
      'fullName|contain': value,
    });
    this.setState({ valueUserReseach: value });
  };

  render() {
    return (
      <>
        <div className="jr-card fullWidth">
          <UnderlineTitle
            title={<IntlMessages id="orders.detail.card.requestor.title" />}
          />

          <div className="information editDetailCardContainer">
            <div className="col-3 mr-2 p-0">
              <b>
                <IntlMessages id="orders.detail.card.requestor.responsible" />:
              </b>
            </div>

            <div className="col-8 p-0">
              <SearchSelect
                handleChangeValue={this.handleChangeUserValue}
                changeSelect={this.changeSelect('responsibleUserProfile')}
                inputValue={this.state.valueUserReseach}
                isLoading={this.state.loading}
                options={
                  this.state.usersList &&
                  this.state.usersList.map((data) => ({
                    value: data.id,
                    label: data.fullName,
                  }))
                }
              />
            </div>
          </div>

          <div
            className="information"
            style={{ justifyContent: 'space-between' }}
          >
            <div className="col-3 mr-2 p-0">
              <b>
                <IntlMessages id="itemPos.modal.pos" />:
              </b>
            </div>

            <div className="col-8 p-0">
              <Select
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
                fullWidth
                hideSelectedOptions
                value={this.state.pos}
                onChange={this.changeSelect('requestorPos')}
                options={
                  this.state.posList &&
                  this.state.posList.map((data) => ({
                    value: data.id,
                    label: data.name,
                  }))
                }
              />
            </div>
          </div>
          {this.state.loading && <Loading />}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  usersList: state.sdk.user.usersList,
  posList: state.sdk.pos.posList,
  locale: state.sdk.settings.locale.locale,
});

export default connect(mapStateToProps, {
  getAllUsersByProjectRequest,
  getPosByProjectRequest,
})(InternOrderRequestor);
