import React, { Component } from 'react';
import { connect } from 'react-redux';
import IntlMessages from '../../../../util/IntlMessages';
import { createPosDeliverydocumentRequest } from '../../../../config/actions.config';
import Button from '@material-ui/core/Button';
import { Redirect } from 'react-router';
import '../../index.css';
import InternDeliveryVendor from './DetailCards/InternDeliveryVendor';
import InternDeliveryRequestor from './DetailCards/InternDeliveryRequestor';

class InternDeliveryCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      deliveryDocumentId: null,
      deliverydocument: {
        requestorPos: null,
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.newPosDeliverydocument !== this.props.newPosDeliverydocument
    ) {
      if (
        this.props.newPosDeliverydocument.deliveryDocument !== '' &&
        !this.props.newPosDeliverydocument.loading &&
        !this.props.newPosDeliverydocument.error
      ) {
        this.setState({
          redirect: true,
          deliveryDocumentId: this.props.newPosDeliverydocument.deliveryDocument.id,
        });
      }
    }
  }

  handeChange = (name, value) => {
    if (name === 'vendorPos') {
      this.setState({ [name]: value });
    } else {
      let deliverydocument = this.state.deliverydocument;
      deliverydocument[name] = value;

      this.setState({ deliverydocument: deliverydocument });
    }
  };

  handleSubmit = () => {
    this.props.createPosDeliverydocumentRequest(
      this.state.vendorPos,
      this.state.deliverydocument
    );
  };

  render() {
    if (this.state.redirect) {
      return (
        <Redirect to={'show-intern-delivery/' + this.state.deliveryDocumentId} />
      );
    } else {
      return (
        <div className='app-wrapper div-wrapper-title'>
          <div className='row headerAlign'>
            <div className='col-12 page-title'>
              <IntlMessages id='delivery.intern.create.title' />
            </div>
          </div>
          <div>
            <div className='row'>
              <div className='col-12 col-md-6 detailCard'>
                <InternDeliveryVendor handeChange={this.handeChange} />
              </div>
              <div className='col-12 col-md-6 detailCard'>
                <InternDeliveryRequestor handeChange={this.handeChange} />
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <div className='d-flex justify-content-end'>
                  <Button
                    disabled={
                      !(
                        this.state.vendorPos &&
                        this.state.deliverydocument.requestorPos
                      )
                    }
                    variant='outlined'
                    onClick={this.handleSubmit}
                    color='primary'
                  >
                    <IntlMessages id='items.modal.button.save' />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  newPosDeliverydocument: state.sdk.posDocuments.newPosDeliverydocument
});

export default connect(mapStateToProps, {
  createPosDeliverydocumentRequest,
})(InternDeliveryCreate);
