import React, { Component } from 'react';
import { connect } from 'react-redux';
import IntlMessages from '../../../util/IntlMessages';
import {
  getSupplierOrderDocumentRequest,
  createDeliveryDocumentsSupplierRequest,
  setGlobalError,
} from '../../../config/actions.config';
import DetailOrderSupplier from '../../Orders/DetailCards/DetailOrderSupplier';
import DetailOrderRequestor from '../../Orders/DetailCards/DetailOrderRequestor';
import { NotificationManager } from 'react-notifications';
import DetailsOrderDeliveryItem from './DetailsOrderDeliveryItem/DetailsOrderDeliveryItem';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

class OrderNoteDelivery extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      orderDocument: null,
    };
    this.itemList = [];
  }

  componentDidMount() {
    this.props.getSupplierOrderDocumentRequest(this.props.match.params.idOrder);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.supplierOrderDocument !== this.props.supplierOrderDocument) {
      if (this.props.supplierOrderDocument.loading) {
        this.setState({ loading: true });
      }
      if (
        !this.props.supplierOrderDocument.loading &&
        !this.props.supplierOrderDocument.error &&
        this.props.supplierOrderDocument.orderDocument !== ''
      ) {
        this.setState({
          loading: false,
          orderDocument: this.props.supplierOrderDocument.orderDocument,
        });
      }
    }

    if (prevProps.newAddress !== this.props.newAddress) {
      if (!this.props.newAddress.loading && !this.props.newAddress.error) {
        if (!this.state.isResponsibleUpdated) {
          this.props.getSupplierOrderDocumentRequest(
            this.props.match.params.idOrder
          );

          NotificationManager.success(
            <IntlMessages id="orders.detail.message.success.updated" />
          );
        }
      }
    }

    if (prevProps.newDeliveryDocument !== this.props.newDeliveryDocument) {
      if (
        !this.props.newDeliveryDocument.loading &&
        !this.props.newDeliveryDocument.error
      ) {
        NotificationManager.success(
          <IntlMessages id="orders.detail.message.success.updated" />
        );
        this.props.history.push(
          '/app/show-supplier-order/' + this.props.match.params.idOrder
        );
      }
    }

    if (prevProps.globalError !== this.props.globalError) {
      if (this.props.globalError === true) {
        NotificationManager.error(
          <IntlMessages id="appModule.message.error" />
        );
        this.props.setGlobalError(false);
      }
    }
  }

  getCountry = (code) => {
    const regionName = new Intl.DisplayNames([this.props.locale], {
      type: 'region',
    });
    if (code) {
      const countryCode = code.substring(0, 2);
      return regionName.of(countryCode);
    }
  };

  handleCreationDeliveryNote = () => {
    this.props.createDeliveryDocumentsSupplierRequest(
      this.props.match.params.idOrder,
      { documentLines: this.formatListDelivery() }
    );
  };

  formatListDelivery = () => {
    const listFormated = [];
    this.itemList.forEach((item) => {
      if (item.qtyReceived !== 0) {
        listFormated.push({
          quantity: item.qtyReceived,
          item: item.itemData.id,
          vatRate: item.vatrate?.id,
        });
      }
    });
    return listFormated;
  };

  handleChangeQuantityDelivered = (rows) => {
    this.itemList = rows;
  };

  render() {
    const { orderDocument, loading } = this.state;

    return (
      <div className="app-wrapper div-wrapper-title">
        <div className="row headerAlign">
          <div className="col-8 page-title">
            <IntlMessages id="delivery.supplier.creationOrderDeliveryNote.title" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 detailCard">
            <DetailOrderSupplier
              supplier={orderDocument && orderDocument.supplier}
              loading={loading}
              getCountry={this.getCountry}
            />
          </div>
          <div className="col-12 col-md-6 detailCard">
            <DetailOrderRequestor
              supplier
              requestorPos={orderDocument && orderDocument.requestorPos}
              responsibleUserProfile={
                orderDocument && orderDocument.responsibleUserProfile
              }
              billingAddress={orderDocument && orderDocument.billingAddress}
              shippingAddress={orderDocument && orderDocument.shippingAddress}
              loading={loading}
              getCountry={this.getCountry}
              idOrder={this.props.match.params.idOrder}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <DetailsOrderDeliveryItem
              supplier
              id={orderDocument && orderDocument.id}
              vendor={orderDocument && orderDocument.supplier}
              totalWithoutVat={orderDocument && orderDocument.totalWithoutVat}
              totalWithVat={orderDocument && orderDocument.totalWithVat}
              handleChangeQuantityDelivered={this.handleChangeQuantityDelivered}
              refresh={() =>
                this.props.getSupplierOrderDocumentRequest(
                  this.props.match.params.idOrder
                )
              }
              loading={loading}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="jr-card" align="center">
              <Link
                to={
                  '/app/show-supplier-order/' + this.props.match.params.idOrder
                }
              >
                <Button variant="outlined" className="cancelButton mr-2">
                  <IntlMessages id="delivery.button.cancelCreationNote" />
                </Button>
              </Link>
              <Button
                variant="outlined"
                onClick={this.handleCreationDeliveryNote}
                color="primary"
              >
                <IntlMessages id="delivery.button.creationNote" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  supplierOrderDocument: state.sdk.suppliers.supplierOrderDocument,
  newAddress: state.sdk.addresses.newAddress,
  newDeliveryDocument: state.sdk.suppliers.newDeliveryDocumentSupplier,
  locale: state.sdk.settings.locale.locale,
});

export default connect(mapStateToProps, {
  createDeliveryDocumentsSupplierRequest,
  getSupplierOrderDocumentRequest,
  setGlobalError,
})(OrderNoteDelivery);
