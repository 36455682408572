import React, { Component } from 'react';
import IntlMessages from '../../../util/IntlMessages';
import { UnderlineTitle, Loading } from '../../../config/components.config';
import '../index.css';

class DetailDeliverySupplier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditInfo: false,
    };
  }

  render() {
    return (
      <>
        <div className='jr-card fullWidth'>
          <UnderlineTitle
            title={<IntlMessages id='orders.detail.card.supplier.title' />}
          ></UnderlineTitle>

          {this.props.supplier ? (
            !this.state.showEditInfo ? (
              <>
                <div className={'information'}>
                  <div className='mr-2'>
                    <b>
                      <IntlMessages id='appModule.last.name' />:
                    </b>
                  </div>
                  <div>{this.props.supplier.company.name}</div>
                </div>

                <div className={'information'}>
                  <div className='mr-2'>
                    <b>
                      <IntlMessages id='appModule.email' />:
                    </b>
                  </div>
                  <div>{this.props.supplier.company.email}</div>
                </div>

                <div className={'information'}>
                  <div className='mr-2'>
                    <b>
                      <IntlMessages id='suppliers.table.vat' />:
                    </b>
                  </div>
                  <div>{this.props.supplier.company.vatNumber}</div>
                </div>

                <div className='mt-3'>
                  <div className='row'>
                    {this.props.supplier.company.address[0] && (
                      <div className='col-6'>
                        <div>
                          <b>
                            <IntlMessages id='appModule.adress' />:
                          </b>
                        </div>
                        <div>
                          <div>
                            {this.props.supplier.company.address[0].street}{' '}
                            {
                              this.props.supplier.company.address[0]
                                .streetNumber
                            }
                          </div>
                          <div>
                            {this.props.supplier.company.address[0].zipCode}{' '}
                            {this.props.supplier.company.address[0].city}
                            {', '}
                            {this.props.getCountry(
                              this.props.supplier.company.address[0].country,
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div>
                  <IntlMessages id='delivery.detail.supplier.edit' />
                </div>
              </>
            )
          ) : null}

          {this.props.loading && <Loading />}
        </div>
      </>
    );
  }
}

export default DetailDeliverySupplier;
