import React, { Component } from 'react';
import { Modal } from '../../../../../config/components.config';
import IntlMessages from '../../../../../util/IntlMessages';
import MovementModalViewContent from './MovementModalViewContent';

class MovementModalView extends Component {
  handleClose = () => this.props.close();

  render() {
    return (
      <Modal
        title={<IntlMessages id={'movements.modal.view.title'} />}
        width='md'
        open={this.props.open}
        close={this.handleClose}
      >
        <MovementModalViewContent
          handleClose={this.handleClose}
          idMovement={this.props.idMovement}
        />
      </Modal>
    );
  }
}

export default MovementModalView;
