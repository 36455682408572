// Example
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
// import AccommodationAssemblies from './accommodation-assemblies.reducer';

const reducers = (history) =>
  combineReducers({
    // accommodationAssemblies: AccommodationAssemblies,
    router: connectRouter(history),
  });

export default reducers;
