import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from '../../../../../util/IntlMessages';
import { putItemPosRequest } from '../../../../../config/actions.config';
import { NotificationManager } from 'react-notifications';
import { UncontrolledPopover } from 'reactstrap';

class ItemPosModalEditContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reorderLevel: '',
      reorderQuantity: '',
      targetStockLevel: '',
      maximumStockLevel: '',
      globalLocationNumber: '',
      stockLocationNumber: '',
    };
    this.editItemPos = {};
  }

  componentDidMount() {
    if (this.props.itemPos) {
      this.setState({
        reorderLevel: this.props.itemPos.reorderLevel
          ? this.props.itemPos.reorderLevel
          : 0,
        reorderQuantity: this.props.itemPos.reorderQuantity
          ? this.props.itemPos.reorderQuantity
          : 0,
        targetStockLevel: this.props.itemPos.targetStockLevel
          ? this.props.itemPos.targetStockLevel
          : 0,
        maximumStockLevel: this.props.itemPos.maximumStockLevel
          ? this.props.itemPos.maximumStockLevel
          : 0,
        globalLocationNumber: this.props.itemPos.globalLocationNumber
          ? this.props.itemPos.globalLocationNumber
          : '',
        stockLocationNumber: this.props.itemPos.stockLocationNumber
          ? this.props.itemPos.stockLocationNumber
          : '',
      });
      this.editItemPos.id = this.props.itemPos.id;
    }
  }

  handleChange = (name) => (event) => {
    console.log(event.target.value.length);
    if (name === 'globalLocationNumber' && event.target.value.length <= 13) {
      this.setState({ [name]: event.target.value });
      this.editItemPos[name] = parseInt(event.target.value, 10);
    } else if (name !== 'globalLocationNumber') {
      this.setState({ [name]: event.target.value });
      this.editItemPos[name] = parseInt(event.target.value, 10);
    }
  };

  handleClose = () => {
    this.props.handleClose();
  };

  handLeSubmit = () => {
    if (Object.keys(this.editItemPos).length !== 1) {
      const arrayValue = Object.values(this.editItemPos);

      if (!arrayValue.some((v) => v < 0)) {
        this.props.putItemPosRequest(this.editItemPos);
        this.props.handleClose();
      } else {
        NotificationManager.warning(
          <IntlMessages id="itemPos.warning.message.negative.add" />
        );
      }
    } else {
      this.props.handleClose();
    }
  };

  render() {
    const {
      reorderLevel,
      reorderQuantity,
      targetStockLevel,
      maximumStockLevel,
      globalLocationNumber,
      stockLocationNumber,
    } = this.state;

    return (
      <div>
        <div className="col-12">
          <div className="information">
            <div className="col-12 col-md-4">
              <h4>
                <IntlMessages id={'itemPos.modal.reorderLevel'} />
                <i
                  className="zmdi zmdi-info infoIcon"
                  id={'Popoverbutton-reorderLevel'}
                  onMouseEnter={() =>
                    this.setState({ popoverReorderLevel: true })
                  }
                  onMouseLeave={() =>
                    this.setState({ popoverReorderLevel: false })
                  }
                />

                <UncontrolledPopover
                  placement="top"
                  isOpen={this.state.popoverReorderLevel}
                  target={'Popoverbutton-reorderLevel'}
                  className="UncontrolledPopover"
                >
                  <IntlMessages id={'itemPos.modal.reorderLevel.popover'} />
                </UncontrolledPopover>
              </h4>
            </div>
            <div className="col-12 col-md-6">
              <TextField
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                variant="outlined"
                size="small"
                fullWidth
                value={reorderLevel}
                onChange={this.handleChange('reorderLevel')}
              />
            </div>
          </div>

          <div className="information">
            <div className="col-12 col-md-4">
              <h4>
                <IntlMessages id={'itemPos.modal.reorderQuantity'} />
                <i
                  className="zmdi zmdi-info infoIcon"
                  id={'Popoverbutton-reorderQuantity'}
                  onMouseEnter={() =>
                    this.setState({ popoverReorderQuantity: true })
                  }
                  onMouseLeave={() =>
                    this.setState({ popoverReorderQuantity: false })
                  }
                />

                <UncontrolledPopover
                  placement="top"
                  isOpen={this.state.popoverReorderQuantity}
                  target={'Popoverbutton-reorderQuantity'}
                  className="UncontrolledPopover"
                >
                  <IntlMessages id={'itemPos.modal.reorderQuantity.popover'} />
                </UncontrolledPopover>
              </h4>
            </div>
            <div className="col-12 col-md-6">
              <TextField
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                variant="outlined"
                size="small"
                fullWidth
                value={reorderQuantity}
                onChange={this.handleChange('reorderQuantity')}
              />
            </div>
          </div>

          <div className="information">
            <div className="col-12 col-md-4">
              <h4>
                <IntlMessages id={'itemPos.modal.targetStockLevel'} />
                <i
                  className="zmdi zmdi-info infoIcon"
                  id={'Popoverbutton-targetStockLevel'}
                  onMouseEnter={() =>
                    this.setState({ popoverTargetStockLevel: true })
                  }
                  onMouseLeave={() =>
                    this.setState({ popoverTargetStockLevel: false })
                  }
                />

                <UncontrolledPopover
                  placement="top"
                  isOpen={this.state.popoverTargetStockLevel}
                  target={'Popoverbutton-targetStockLevel'}
                  className="UncontrolledPopover"
                >
                  <IntlMessages id={'itemPos.modal.targetStockLevel.popover'} />
                </UncontrolledPopover>
              </h4>
            </div>
            <div className="col-12 col-md-6">
              <TextField
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                variant="outlined"
                size="small"
                fullWidth
                value={targetStockLevel}
                onChange={this.handleChange('targetStockLevel')}
              />
            </div>
          </div>

          <div className="information">
            <div className="col-12 col-md-4">
              <h4>
                <IntlMessages id={'itemPos.modal.maximumStockLevel'} />
                <i
                  className="zmdi zmdi-info infoIcon"
                  id={'Popoverbutton-maximumStockLevel'}
                  onMouseEnter={() =>
                    this.setState({ popoverMaximumStockLevel: true })
                  }
                  onMouseLeave={() =>
                    this.setState({ popoverMaximumStockLevel: false })
                  }
                />

                <UncontrolledPopover
                  placement="top"
                  isOpen={this.state.popoverMaximumStockLevel}
                  target={'Popoverbutton-maximumStockLevel'}
                  className="UncontrolledPopover"
                >
                  <IntlMessages
                    id={'itemPos.modal.maximumStockLevel.popover'}
                  />
                </UncontrolledPopover>
              </h4>
            </div>
            <div className="col-12 col-md-6">
              <TextField
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                variant="outlined"
                size="small"
                fullWidth
                value={maximumStockLevel}
                onChange={this.handleChange('maximumStockLevel')}
              />
            </div>
          </div>

          <div className="information">
            <div className="col-12 col-md-4">
              <h4>
                <IntlMessages id={'itemPos.modal.globalLocationNumber'} />
                <i
                  className="zmdi zmdi-info infoIcon"
                  id={'Popoverbutton-globalLocationNumber'}
                  onMouseEnter={() =>
                    this.setState({ popoverGlobalLocationNumber: true })
                  }
                  onMouseLeave={() =>
                    this.setState({ popoverGlobalLocationNumber: false })
                  }
                />
                <UncontrolledPopover
                  placement="top"
                  isOpen={this.state.popoverGlobalLocationNumber}
                  target={'Popoverbutton-globalLocationNumber'}
                  className="UncontrolledPopover"
                >
                  <IntlMessages
                    id={'itemPos.modal.globalLocationNumber.popover'}
                  />
                </UncontrolledPopover>
              </h4>
            </div>
            <div className="col-12 col-md-6">
              <TextField
                type="text"
                InputProps={{ inputProps: { min: 0 } }}
                variant="outlined"
                size="small"
                fullWidth
                value={globalLocationNumber}
                onChange={this.handleChange('globalLocationNumber')}
              />
            </div>
          </div>

          <div className="information">
            <div className="col-12 col-md-4">
              <h4>
                <IntlMessages id={'itemPos.modal.stockLocationNumber'} />
                <i
                  className="zmdi zmdi-info infoIcon"
                  id={'Popoverbutton-stockLocationNumber'}
                  onMouseEnter={() =>
                    this.setState({ popoverStockLocationNumber: true })
                  }
                  onMouseLeave={() =>
                    this.setState({ popoverStockLocationNumber: false })
                  }
                />
                <UncontrolledPopover
                  placement="top"
                  isOpen={this.state.popoverStockLocationNumber}
                  target={'Popoverbutton-stockLocationNumber'}
                  className="UncontrolledPopover"
                >
                  <IntlMessages
                    id={'itemPos.modal.stockLocationNumber.popover'}
                  />
                </UncontrolledPopover>
              </h4>
            </div>
            <div className="col-12 col-md-6">
              <TextField
                type="text"
                InputProps={{ inputProps: { min: 0 } }}
                variant="outlined"
                size="small"
                fullWidth
                value={stockLocationNumber}
                onChange={this.handleChange('stockLocationNumber')}
              />
            </div>
          </div>

          <div className="d-flex justify-content-end my-4">
            <Button onClick={this.handleClose} color="primary">
              <IntlMessages id="appModule.cancel" />
            </Button>
            <Button
              variant="outlined"
              onClick={this.handLeSubmit}
              color="primary"
            >
              <IntlMessages id="appModule.save" />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
});

export default connect(mapStateToProps, { putItemPosRequest })(
  ItemPosModalEditContent
);
