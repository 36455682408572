import React, { Component } from 'react';
import makeAnimated from 'react-select/animated';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import AddIcon from '@material-ui/icons/Add';
import IntlMessages from '../../../util/IntlMessages';
import '../index.css';

const animatedComponents = makeAnimated();

class AddSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: '',
      array: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { listItems, listItemsSelected } = this.props;
    let arraySelected = [];
    if (prevProps !== this.props) {
      if (listItemsSelected.length !== 0) {
        listItemsSelected.forEach((data) => {
          listItems.forEach((element) => {
            if (element.id === data) {
              arraySelected.push({
                label: element[this.props.labelSelect],
                value: element.id,
              });
              this.setState({ array: arraySelected });
            }
          });
        });
      } else {
        this.setState({ array: [] });
      }
    }
  }

  handleChange = (selectedOption) => {
    this.props.handleSelectedItems(selectedOption);
  };

  createText = (text) => {
    return (
      <div className="createTextContainer">
        <div className="createText">
          <IntlMessages id="appModule.create" /> `&quot;`{text}`&quot;`
        </div>
        <AddIcon />
      </div>
    );
  };

  render() {
    const { listItems } = this.props;

    return (
      <div className="row">
        <div className="col-12">
          {this.props.creatable ? (
            <CreatableSelect
              closeMenuOnSelect={false}
              components={animatedComponents}
              value={this.state.array}
              isMulti
              onCreateOption={(text) => this.props.onClickAction(text)}
              formatCreateLabel={(text) => this.createText(text)}
              styles={{
                option: (styles, { data }) => {
                  return {
                    ...styles,
                    backgroundColor: data.__isNew__
                      ? 'rgba(240,240,240,1)'
                      : 'white',
                  };
                },
              }}
              options={
                listItems &&
                listItems.map((data) => {
                  return {
                    value: data.id,
                    label: data[this.props.labelSelect],
                  };
                })
              }
              onChange={this.handleChange}
            />
          ) : (
            <Select
              id={this.props.id ? this.props.id : ''}
              closeMenuOnSelect={false}
              components={animatedComponents}
              value={this.state.array}
              isMulti
              formatCreateLabel={(text) => this.createText(text)}
              styles={{
                option: (styles, { data }) => {
                  return {
                    ...styles,
                    backgroundColor: data.__isNew__
                      ? 'rgba(240,240,240,1)'
                      : 'white',
                    cursor: data.value === this.props.hideItem && 'not-allowed',
                    pointerEvents: data.value === this.props.hideItem && 'none',
                    color: data.value === this.props.hideItem && '#ccc',
                  };
                },
              }}
              options={
                listItems &&
                listItems.map((data) => {
                  return {
                    value: data.id,
                    label: data[this.props.labelSelect],
                    disable: true,
                  };
                })
              }
              onChange={this.handleChange}
            />
          )}
        </div>
      </div>
    );
  }
}

export default AddSelect;
