import React, { Component } from 'react';
import { connect } from 'react-redux';
import ColumnCell from '../../../../components/Table/Columns/ColumnCell';
import NoData from '../../../../components/Table/NoData/NoData';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  SelectionState,
  CustomPaging,
  SearchState,
  FilteringState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableColumnVisibility,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import { Loading } from '../../../../config/components.config';
import {
  getSuppliersOrderDocumentsbyProjectRequest,
  setGlobalError,
} from '../../../../config/actions.config';
import { NotificationManager } from 'react-notifications';
import IntlMessages from '../../../../util/IntlMessages';
import FilterCell from '../../../../components/Table/Cells/Orders/FiltersCell';
import OrdersCell from '../../../../components/Table/Cells/Orders/OrdersCell';
import {
  columnsOrders,
  tableColumnExtensionsOrders,
} from '../../../../components/Table/Columns/ShowColumns';

class SupplierOrdersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pageSizes: [10, 20, 50, 100, 0],
      filters: [],
    };
    this.parameters = {};
  }

  componentDidMount() {
    if (!localStorage.getItem('paramsOrders')) {
      localStorage.setItem(
        'paramsOrders',
        JSON.stringify({
          limit: 20,
          page: 1,
          order: 'id:DESC',
        }),
      );
    }
    this.parameters = JSON.parse(localStorage.getItem('paramsOrders'));

    this.props.getSuppliersOrderDocumentsbyProjectRequest(
      this.props.idProject,
      this.parameters,
    );
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.supplierOrderDocumentsList !==
      this.props.supplierOrderDocumentsList
    ) {
      if (this.props.supplierOrderDocumentsList.loading) {
        this.setState({ loading: true });
      }
      if (
        this.props.supplierOrderDocumentsList.OrderDocumentsList !== '' &&
        !this.props.supplierOrderDocumentsList.loading &&
        !this.props.supplierOrderDocumentsList.error
      ) {
        this.setState({
          loading: false,
          objectCount: this.props.supplierOrderDocumentsList.meta.object_count,
        });
      }
    }
    if (prevProps.updatedDocumentStatus !== this.props.updatedDocumentStatus) {
      if (this.props.updatedDocumentStatus.loading) {
        this.setState({ loading: true });
      }
      if (
        this.props.updatedDocumentStatus.document !== '' &&
        !this.props.updatedDocumentStatus.loading &&
        !this.props.updatedDocumentStatus.error
      ) {
        this.props.getSuppliersOrderDocumentsbyProjectRequest(
          this.props.idProject,
          this.parameters,
        );
      }
    }
    if (prevProps.globalError !== this.props.globalError) {
      if (this.props.globalError === true) {
        NotificationManager.error(
          <IntlMessages id='appModule.message.error' />
        );
        this.setState({ loading: false });
        this.props.setGlobalError(false);
      }
    }
  }

  setCurrentPage = (page) => {
    this.parameters = JSON.parse(localStorage.getItem('paramsOrders'));
    this.parameters.page = page + 1;

    this.props.getSuppliersOrderDocumentsbyProjectRequest(
      this.props.idProject,
      this.parameters,
    );
    localStorage.setItem('paramsOrders', JSON.stringify(this.parameters));
  };

  setPageSize = (pageSize) => {
    this.parameters = JSON.parse(localStorage.getItem('paramsOrders'));
    this.parameters.limit = pageSize;

    this.props.getSuppliersOrderDocumentsbyProjectRequest(
      this.props.idProject,
      this.parameters,
    );
    localStorage.setItem('paramsOrders', JSON.stringify(this.parameters));
  };

  onFilter = (filter) => {
    this.parameters = JSON.parse(localStorage.getItem('paramsOrders'));
    this.parameters.page = 1;
    this.setState({ filters: filter });
    filter.forEach((data) => {
      if (
        data.value &&
        data.value !== '' &&
        data.value !== 'all' &&
        data.value !== null
      ) {
        if (data.columnName === 'supplier') {
          this.parameters['supplier.company.name'] = {};
          this.parameters['supplier.company.name'].operation = 'contain';
          this.parameters['supplier.company.name'].value = data.value;
        } else if (data.columnName === 'responsibleUserProfile') {
          this.parameters['responsibleUserProfile.fullName'] = {};
          this.parameters['responsibleUserProfile.fullName'].operation =
            'contain';
          this.parameters['responsibleUserProfile.fullName'].value = data.value;
        } else if (data.columnName === 'requestorPos') {
          this.parameters['requestorPos.name'] = {};
          this.parameters['requestorPos.name'].operation = 'contain';
          this.parameters['requestorPos.name'].value = data.value;
        } else if (data.columnName === 'createdAt' && data.value !== null) {
          this.parameters[data.columnName] = [];
          this.parameters[data.columnName].push({
            operation: 'gt',
            value: data.values.dateStart,
          });
          this.parameters[data.columnName].push({
            operation: 'lt',
            value: data.values.dateEnd,
          });
        } else {
          this.parameters[data.columnName] = {};
          this.parameters[data.columnName].operation = 'contain';
          this.parameters[data.columnName].value = data.value;
        }
      } else {
        if (data.columnName === 'supplier') {
          delete this.parameters['supplier.company.name'];
        } else if (data.columnName === 'requestorPos') {
          delete this.parameters['requestorPos.name'];
        } else if (data.columnName === 'responsibleUserProfile') {
          delete this.parameters['responsibleUserProfile.fullName'];
        } else {
          delete this.parameters[data.columnName];
        }
      }
    });

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.getSuppliersOrderDocumentsbyProjectRequest(
        this.props.idProject,
        this.parameters,
      );
    }, 200);

    localStorage.setItem('paramsOrders', JSON.stringify(this.parameters));
  };

  render() {
    const {
      loading,
      currentPageSize,
      currentPage,
      pageSizes,
      objectCount,
      filters,
    } = this.state;

    const { supplierOrderDocumentsList } = this.props;

    const row = [];
    if (
      supplierOrderDocumentsList &&
      supplierOrderDocumentsList.OrderDocumentsList
    ) {
      supplierOrderDocumentsList.OrderDocumentsList.forEach((data, i) => {
        row[i] = {
          id: data.id,
          orderType: 'supplierOrder',
          createdAt: data.createdAt,
          reference: data.reference,
          requestorPos: data.requestorPos?.name,
          supplier: data.supplier?.company.name,
          responsibleUserProfile: data.responsibleUserProfile?.fullName,
          status: data.status,
          totalWithoutVat: data.totalWithoutVat,
          locale: this.props.locale,
          timezone: this.props.timezone,
          currency:
            this.props.projectDetails && this.props.projectDetails !== ''
              ? this.props.projectDetails.projectFinance.defaultCurrency.isoCode
              : null,
        };
      });
    }

    return (
      <div>
        <Grid rows={row} columns={columnsOrders}>
          <SelectionState />
          <PagingState
            pageSize={currentPageSize}
            currentPage={currentPage}
            onCurrentPageChange={this.setCurrentPage}
            onPageSizeChange={this.setPageSize}
          />
          <SortingState />
          <IntegratedSorting />
          <SearchState defaultValue='' />
          <FilteringState onFiltersChange={this.onFilter} filters={filters} />
          <Table
            columnExtensions={tableColumnExtensionsOrders}
            cellComponent={(props) => <OrdersCell cell={props} />}
            noDataCellComponent={NoData}
          />
          <CustomPaging totalCount={objectCount} />
          <TableColumnVisibility hiddenColumnNames={['vendorPos']} />
          <TableHeaderRow cellComponent={ColumnCell} />
          <TableFilterRow cellComponent={FilterCell} />
          <PagingPanel pageSizes={pageSizes} />
        </Grid>
        {loading && <Loading />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  supplierOrderDocumentsList: state.sdk.suppliers.supplierOrderDocumentsList,
  locale: state.sdk.settings.locale.locale,
  timezone: state.sdk.project.projectDetails.timezone,
  projectDetails: state.sdk.project.projectDetails,
  globalError: state.sdk.auth.globalError,
  updatedDocumentStatus: state.sdk.documents.updatedDocumentStatus,
});

export default connect(mapStateToProps, {
  getSuppliersOrderDocumentsbyProjectRequest,
  setGlobalError,
})(SupplierOrdersTable);
