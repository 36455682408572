import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import '../rows.css';

const getStatusDelivery = (row) => {
  let status = 'deliveryAlmostCompleted';
  const qtyAlreadyDelivered = row.quantityAlreadyDelivered
    ? row.quantityAlreadyDelivered
    : 0;
  if (row.qtyReceived) {
    if (
      row.qtyReceived &&
      row.qtyReceived + qtyAlreadyDelivered === row.quantity
    ) {
      status = 'deliveryCompleted';
    } else if (
      row.qtyReceived &&
      row.qtyReceived + qtyAlreadyDelivered > row.quantity
    ) {
      status = 'deliveryOverCompleted';
    } else if (
      qtyAlreadyDelivered &&
      row.qtyReceived + qtyAlreadyDelivered < row.quantity
    ) {
      status = 'deliveryAlmostCompleted';
    }
  } else {
    if (row.quantity === qtyAlreadyDelivered) {
      status = 'deliveryCompleted';
    } else if (row.quantity > qtyAlreadyDelivered) {
      status = 'deliveryAlmostCompleted';
    } else if (row.quantity < qtyAlreadyDelivered) {
      status = 'deliveryOverCompleted';
    }
  }

  return status;
};

const OrderItemRows = ({ row, ...restProps }) => {
  return <Table.Row {...restProps} className={getStatusDelivery(row)} />;
};

export default OrderItemRows;
