import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { connect } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link, NavLink } from 'react-router-dom';
import { userSignOut } from '../../config/actions.config';
// import LinearProgress from '@material-ui/core/LinearProgress';
import logoAudience from '../../assets/images/logo-audience-dark.png';
import avatarUnknown from '../../assets/images/avatarEmisys.png';
import IntlMessages from '../../util/IntlMessages';

class UserInfo extends React.Component {
  state = {
    anchorEl: null,
    open: false,
    dropDownOpen: false,
  };

  handleClick = (event) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  toggle = () => {
    this.setState({
      dropDownOpen: !this.state.dropDownOpen,
    });
  };

  render() {
    let fullName;
    let email;
    let picturePath = null;
    if (
      this.props.user.userSelfDetails.details !== undefined &&
      this.props.user.userSelfDetails.details !== ''
    ) {
      fullName = this.props.user.userSelfDetails.details.fullName;
      email = this.props.user.userSelfDetails.details.email;
      if (this.props.user.userSelfDetails.details.picture !== undefined) {
        picturePath = this.props.user.userSelfDetails.details.picture.original;
      }
    }

    return (
      <div>
        <div className='background-logo'>
          <Link className='app-logo' to='/app/index'>
            <img
              className='logo-byemisys'
              src={logoAudience}
              alt='logo by emisys'
            />
          </Link>
        </div>
        <div className='user-profile d-flex flex-row align-items-center'>
          <Avatar
            alt='...'
            src={picturePath !== null ? picturePath : avatarUnknown}
            className='user-avatar'
          />
          <div className='user-detail'>
            <span className='sidebar-welcome'>
              <IntlMessages id='sidenav.welcome' />
            </span>
            <h4 className='user-name' onClick={this.handleClick}>
              {fullName !== ' ' ? fullName : email}{' '}
              <i className='zmdi zmdi-caret-down zmdi-hc-fw align-middle' />
            </h4>
          </div>
          <Menu
            className='user-info'
            id='simple-menu'
            anchorEl={this.state.anchorEl}
            open={this.state.open}
            onClose={this.handleRequestClose}
            PaperProps={{
              style: {
                width: 220,
                paddingTop: 0,
                paddingBottom: 0,
              },
            }}
          >
            <MenuItem onClick={this.handleRequestClose}>
              <NavLink to='/app/profile'>
                {' '}
                <IntlMessages id='appModule.myProfile' />
              </NavLink>
            </MenuItem>
            <MenuItem
              onClick={() => {
                localStorage.removeItem('paramsItems');
                localStorage.removeItem('paramsPos');
                localStorage.removeItem('paramsSupplier');
                localStorage.removeItem('paramsOrders');
                localStorage.removeItem('paramsOrdersIntern');
                this.props.userSignOut();
              }}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <IntlMessages id='appModule.logOut' />
              <i className='zmdi zmdi-sign-in icon-alert zmdi-hc-fw' />
            </MenuItem>
          </Menu>
        </div>
        {/* <NavLink to='/app/index' style={{ textDecoration: 'none' }}>
          <div className='row short-infos'>
            <div align='center' className='col-12 progress-box'>
              <p className='sidenav-label-title label-progress-box'>
                example
              </p>
              <LinearProgress
                variant='determinate'
                value={50}
                className='progressionBar'
              />
              <div className='progressbar-label'>50% - 3 sur 6 actions</div>
            </div>
          </div>
        </NavLink> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.sdk.settings,
  user: state.sdk.user,
});
export default connect(mapStateToProps, { userSignOut })(UserInfo);
