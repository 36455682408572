import React, { Component } from 'react';
import { connect } from 'react-redux';
import ItemSupplierModalEdit from '../../app/Suppliers/ItemSupplier/ItemSupplierModal/ItemSupplierModalEdit/ItemSupplierModalEdit';
import SweetAlert from 'react-bootstrap-sweetalert';
import IntlMessages from '../../util/IntlMessages';
import { ActionButton } from '../../config/components.config';
import { deleteItemSupplierRequest } from '../../config/actions.config';

class ButtonsItemSupplier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalEditionItemSupplier: false,
      isClosed: false,
    };
  }
  handleDelete = () => {
    this.setState({
      isClosed: true,
    });
  };

  render() {
    const { openModalEditionItemSupplier } = this.state;
    return (
      <div className={'btn-action-container'}>
        <ActionButton
          icon={'edit'}
          onClick={() => this.setState({ openModalEditionItemSupplier: true })}
        />
        <ActionButton icon={'delete'} onClick={this.handleDelete} />
        <ItemSupplierModalEdit
          itemSupplier={this.props.itemSupplier}
          open={openModalEditionItemSupplier}
          close={() => this.setState({ openModalEditionItemSupplier: false })}
        />

        <SweetAlert
          show={this.state.isClosed}
          style={{ whiteSpace: 'normal' }}
          warning
          showCancel
          confirmBtnText={<IntlMessages id='appModule.yes' />}
          cancelBtnText={<IntlMessages id='appModule.cancel' />}
          confirmBtnBsStyle='danger'
          title={<IntlMessages id='appModule.modal.delete.confirm.message' />}
          titleBsStyle='default'
          onConfirm={() => {
            this.props.deleteItemSupplierRequest(this.props.itemSupplier.id);
            this.setState({ isClosed: false });
          }}
          onCancel={() => {
            this.setState({ isClosed: false });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
});

export default connect(mapStateToProps, {
  deleteItemSupplierRequest,
})(ButtonsItemSupplier);
