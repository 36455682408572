import saMessages from '../locales/de/de.json';
import deMessages from "../locales/de/global_de.json";

const result = {};
let key;

for (key in deMessages) {
  if (Object.prototype.hasOwnProperty.call(deMessages, key)) {
    result[key] = deMessages[key];
  }
}

for (key in saMessages) {
  if (Object.prototype.hasOwnProperty.call(saMessages, key)) {
    result[key] = saMessages[key];
  }
}

const enLang = {
  messages: {
    ...result,
  },
  locale: "de",
};
export default enLang;
