import React from 'react';
import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import { Close } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../../../util/IntlMessages';
import '../cell.css';

const FilterCell = ({ column, filter, onFilter, ...restProps }) => {
  const filterValue = filter && filter.value;
  const onChange = (e) =>
    onFilter({ columnName: column.name, value: e.target.value });

  const handleReset = () => {
    onFilter({ columnName: column.name, value: '' });
  };

  let content;
  if (
    column.name === 'name' ||
    column.name === 'category' ||
    column.name === 'price' ||
    column.name === 'defaultSupplier'
  ) {
    let defaultValue = '';
    if (column.name === 'defaultSupplier') {
      defaultValue = JSON.parse(localStorage.getItem('paramsItems'))
        ? JSON.parse(localStorage.getItem('paramsItems'))[
            'itemSupplier.supplier.supplierReference'
          ]
          ? JSON.parse(localStorage.getItem('paramsItems'))[
              'itemSupplier.supplier.supplierReference'
            ].value
          : ''
        : '';
    } else {
      defaultValue = JSON.parse(localStorage.getItem('paramsItems'))
        ? JSON.parse(localStorage.getItem('paramsItems'))[column.name]
          ? JSON.parse(localStorage.getItem('paramsItems'))[column.name].value
          : ''
        : '';
    }

    content = (
      <TableFilterRow.Cell
        {...restProps}
        className="tableFilterRow"
        column={column}
      >
        <TextField
          label={<IntlMessages id="appModule.filter" />}
          value={defaultValue}
          onChange={onChange}
          InputProps={{
            endAdornment:
              filterValue || defaultValue ? (
                <IconButton onClick={handleReset} className="p-0 m-0">
                  <Close />
                </IconButton>
              ) : (
                <IconButton />
              ),
          }}
        />
      </TableFilterRow.Cell>
    );
  } else {
    content = (
      <TableFilterRow.Cell
        {...restProps}
        className="tableFilterRow"
        column={column}
      />
    );
  }
  return content;
};

export default FilterCell;
