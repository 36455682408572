import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { NotificationManager } from 'react-notifications';
import { Link } from 'react-router-dom';
import { UnderlineTitle } from '../../config/components.config';
import IntlMessages from '../../util/IntlMessages';
import {
  updateDocumentStatusRequest,
  setGlobalError,
} from '../../config/actions.config';

class ButtonsChangeStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: '',
      id: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (prevProps.status !== this.props.status) {
        this.setState({
          status: this.props.status,
          id: this.props.id,
        });
      }
    }

    if (prevProps.globalError !== this.props.globalError) {
      if (this.props.globalError === true) {
        NotificationManager.error(
          <IntlMessages id="appModule.message.error" />
        );
        this.props.setGlobalError(false);
      }
    }
  }

  handlePutStatus = (newStatus) => {
    this.props.updateDocumentStatusRequest(this.state.id, newStatus);
  };

  render() {
    const { status } = this.state;
    return (
      <div className="jr-card fullWidth">
        <UnderlineTitle
          title={<IntlMessages id="orders.detail.status.title" />}
        >
          {status === 'created' && (
            <div style={{ color: 'green' }}>
              <IntlMessages id="orders.detail.status.title.created" />
            </div>
          )}
          {status === 'validated' && (
            <div style={{ color: 'green' }}>
              <IntlMessages id="orders.detail.status.title.validated" />
            </div>
          )}
          {status === 'sended' && (
            <div style={{ color: 'green' }}>
              <IntlMessages id="orders.detail.status.title.sended" />
            </div>
          )}
          {status === 'delivered' && (
            <div style={{ color: 'green' }}>
              <IntlMessages id="orders.detail.status.title.delivered" />
            </div>
          )}
          {status === 'canceled' && (
            <div style={{ color: 'red' }}>
              <IntlMessages id="orders.detail.status.title.canceled" />
            </div>
          )}
        </UnderlineTitle>
        <div className="d-flex justify-content-center my-3">
          {status !== 'canceled' && status !== 'delivered' && (
            <Button
              variant="outlined"
              className="mr-2 cancelButton"
              onClick={() => this.handlePutStatus('cancel')}
            >
              <IntlMessages id="orders.detail.button.cancel" />
            </Button>
          )}

          {status === 'created' && (
            <Button
              variant="outlined"
              onClick={() => this.handlePutStatus('validate')}
              color="primary"
            >
              <IntlMessages id="orders.detail.button.validate" />
            </Button>
          )}
          {status === 'validated' && (
            <Button
              variant="outlined"
              onClick={() => this.handlePutStatus('send')}
              color="primary"
            >
              <IntlMessages id="orders.detail.button.send" />
            </Button>
          )}
          {status === 'sended' || status === 'backorder' ? (
            <Link
              to={
                '/app/show-supplier-order/' +
                this.props.idOrder +
                '/creation-delivery-note'
              }
            >
              <Button
                variant="outlined"
                onClick={this.handleCreationDeliveryNote}
                color="primary"
              >
                <IntlMessages id="stock.status.button.createDeliveryNote" />
              </Button>
            </Link>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
});

export default connect(mapStateToProps, {
  updateDocumentStatusRequest,
  setGlobalError,
})(ButtonsChangeStatus);
