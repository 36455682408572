import React from 'react';
import 'react-notifications/lib/notifications.css';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import jwtDecode from 'jwt-decode';
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from '../config/constants.config';
import { isIOS, isMobile } from 'react-device-detect';
import {
  getUserSelfDetailsRequest,
  userSignOut,
  getProjectsSelfRequest,
  setUserCurrentProjectRequest,
  getUserProjectByProjectRequest,
  postErrorInSlackRequest,
  setForbidden,
} from '../config/actions.config';
import { ErrorFallback } from '../config/components.config';
import TopNav from '../components/TopNav';
import Sidebar from '../containers/SideNav/index';
import Header from '../components/Header/index';
import ModalChoiceCompanies from './Companies/ModalChoiceCompanies';
import { NotificationContainer } from 'react-notifications';
import SweetAlert from 'react-bootstrap-sweetalert';
import IntlMessages from './../util/IntlMessages';
import { ErrorBoundary } from 'react-error-boundary';
import { version, name as projectName } from '../../package.json';

import Home from './Home';
import Account from './Account';
import Items from './Items';
import ItemDetails from './Items/ItemDetails/ItemDetails';
import Pos from './Pos';
import PosDetail from './Pos/PosDetail/PosDetail';
import Suppliers from './Suppliers';
import SuppliersDetail from './Suppliers/SuppliersDetail/SuppliersDetail';
import Movements from './Stock/Movements';
import ItemPos from './Stock/ItemPos';
import OutOfOrder from './Stock/OutOfOrder';
import Inventory from './Stock/Inventory';
import InternOrders from './Orders/InternOrders';
import InternOrderDetails from './Orders/InternOrders/InternOrderDetails/InternOrderDetails';
import SupplierOrders from './Orders/SupplierOrders';
import OrderNoteDelivery from './Delivery/OrderNoteDelivery';
import SupplierOrderDetails from './Orders/SupplierOrders/SupplierOrderDetails/SupplierOrderDetails';
import InternDelivery from './Delivery/InternDelivery';
import SupplierDelivery from './Delivery/SupplierDelivery';
import SupplierOrderCreate from './Orders/SupplierOrders/SupplierOrderCreate/SupplierOrderCreate';
import InternOrderCreate from './Orders/InternOrders/InternOrderCreate/InternOrderCreate';
import SupplierDeliveryCreate from './Delivery/SupplierDelivery/SupplierDeliveryCreate/SupplierDeliveryCreate';
import InterDeliveryCreate from './Delivery/InternDelivery/InternDeliveryCreate/InterDeliveryCreate';
import SupplierDeliveryDetails from './Delivery/SupplierDelivery/SupplierDeliveryDetails/SupplierDeliveryDetails';
import InternDeliveryDetails from './Delivery/InternDelivery/InternDeliveryDetails/InternDeliveryDetails';

import Error404 from '../components/Error404';

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      openModalChoiceCompanies: false,
      open403Modal: false,
    };
  }

  componentDidMount() {
    this.props.getProjectsSelfRequest();
    if (this.props.idProject !== 'null' || this.props.idProject !== null) {
      this.props.getUserSelfDetailsRequest(this.props.idProject);
    }
    this.checkToken();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.projectsSelf !== this.props.projectsSelf) {
      if (this.props.projectsSelf.projects) {
        if (this.props.idProject === 'null' || this.props.idProject === null) {
          const id_project = localStorage.getItem('id_project');
          const name_project = localStorage.getItem('name_project');
          if (
            id_project === 'null' ||
            name_project === 'null' ||
            id_project === null ||
            name_project === null
          ) {
            localStorage.setItem(
              'id_project',
              this.props.projectsSelf.projects[0].project.id
            );
            localStorage.setItem(
              'name_project',
              this.props.projectsSelf.projects[0].project.name
            );
            this.props.setUserCurrentProjectRequest(
              this.props.projectsSelf.projects[0].project.id,
              this.props.projectsSelf.projects[0].project.name
            );
          } else if (id_project && name_project) {
            this.props.setUserCurrentProjectRequest(id_project, name_project);
          }
        }
      }
    }

    if (prevProps.idProject !== this.props.idProject) {
      if (this.props.idProject) {
        this.props.getUserSelfDetailsRequest(this.props.idProject);
      }
    }

    if (prevProps.user !== this.props.user) {
      if (this.props.user.details && this.props.user.details !== '') {
        this.props.getUserProjectByProjectRequest(
          this.props.idProject,
          this.props.user.details.id
        );
      }
    }

    if (prevProps.authUser !== this.props.authUser) {
      this.checkToken();
    }

    if (prevProps.forbidden !== this.props.forbidden) {
      if (this.props.forbidden === true) {
        this.setState({ open403Modal: true });
      } else {
        this.setState({ open403Modal: false });
      }
    }
  }

  setEnv = (env) => {
    localStorage.setItem('env', env);
  };

  handleClose = () => {
    this.setState({ openModalChoiceCompanies: false });
  };

  checkToken = () => {
    const token = localStorage.getItem('token');
    if (token !== null && token !== undefined) {
      const tokenExpiration = jwtDecode(token).exp;
      if (
        tokenExpiration &&
        moment(tokenExpiration * 1000) - moment(Date.now()) < 3600
      ) {
        this.props.userSignOut();
      }
    }

    if (token === null) {
      this.props.userSignOut();
    }
  };

  postErrorInSlack = (error) => {
    if (process.env.REACT_APP_ENV === 'prod') {
      this.props.postErrorInSlackRequest({
        application: projectName,
        version: version,
        fields: {
          project: this.props.idProject,
          user: {
            email: this.props.user.details?.email,
            username: this.props.user.details?.fullName,
          },
          link: window.location.href,
          device: window.clientInformation.appVersion,
        },
        error: error.message,
        callstack: error.stack,
      });
    }
  };

  render() {
    this.setEnv('test');

    const { match, drawerType, navigationStyle, horizontalNavPosition } =
      this.props;

    const { openModalChoiceCompanies } = this.state;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? 'fixed-drawer'
      : drawerType.includes(COLLAPSED_DRAWER)
      ? 'collapsible-drawer'
      : 'mini-drawer';

    // set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height');
    } else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height');
    }

    return (
      <ErrorBoundary
        FallbackComponent={(error) => (
          <ErrorFallback
            error={error.error}
            title={<IntlMessages id="appModule.message.error" />}
            back={<IntlMessages id="extraPages.goHome" />}
          />
        )}
        onError={(error) => this.postErrorInSlack(error)}
      >
        <div className={`app-container ${drawerStyle}`}>
          <Sidebar />
          <ModalChoiceCompanies
            open={openModalChoiceCompanies}
            close={this.handleClose}
          />
          <div className="app-main-container">
            <div
              className={`app-header ${
                navigationStyle === HORIZONTAL_NAVIGATION
                  ? 'app-header-horizontal'
                  : ''
              }`}
            >
              {navigationStyle === HORIZONTAL_NAVIGATION &&
                horizontalNavPosition === ABOVE_THE_HEADER && (
                  <TopNav styleName="app-top-header" />
                )}
              <Header />
              {navigationStyle === HORIZONTAL_NAVIGATION &&
                horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
            </div>

            <main className="app-main-content-wrapper">
              <div className="app-main-content">
                <Switch>
                  <Route path={`${match.url}/index`} component={Home} />
                  <Route path={`${match.url}/profile`} component={Account} />
                  <Route path={`${match.url}/items`} component={Items} />
                  <Route
                    path={`${match.url}/show-item/:idItem`}
                    component={ItemDetails}
                  />
                  <Route path={`${match.url}/pos`} component={Pos} />
                  <Route
                    path={`${match.url}/show-pos/:idPos`}
                    component={PosDetail}
                  />
                  <Route
                    path={`${match.url}/suppliers`}
                    component={Suppliers}
                  />
                  <Route
                    path={`${match.url}/show-supplier/:idSupplier`}
                    component={SuppliersDetail}
                  />
                  <Route
                    path={`${match.url}/movements`}
                    component={Movements}
                  />
                  <Route path={`${match.url}/stock`} component={ItemPos} />
                  <Route
                    path={`${match.url}/out-of-order`}
                    component={OutOfOrder}
                  />
                  <Route
                    path={`${match.url}/inventory`}
                    component={Inventory}
                  />
                  <Route
                    path={`${match.url}/orders-intern`}
                    component={InternOrders}
                  />
                  <Route
                    path={`${match.url}/show-intern-order/:idOrder`}
                    component={InternOrderDetails}
                  />
                  <Route
                    path={`${match.url}/orders-suppliers`}
                    component={SupplierOrders}
                  />
                  <Route
                    path={`${match.url}/show-supplier-order/:idOrder/creation-delivery-note`}
                    component={OrderNoteDelivery}
                  />
                  <Route
                    path={`${match.url}/show-supplier-order/:idOrder`}
                    component={SupplierOrderDetails}
                  />
                  <Route
                    path={`${match.url}/delivery-internal`}
                    component={InternDelivery}
                  />
                  <Route
                    path={`${match.url}/delivery-suppliers`}
                    component={SupplierDelivery}
                  />
                  <Route
                    path={`${match.url}/create-supplier-order`}
                    component={SupplierOrderCreate}
                  />
                  <Route
                    path={`${match.url}/create-intern-order`}
                    component={InternOrderCreate}
                  />
                  <Route
                    path={`${match.url}/create-supplier-delivery`}
                    component={SupplierDeliveryCreate}
                  />
                  <Route
                    path={`${match.url}/create-intern-delivery`}
                    component={InterDeliveryCreate}
                  />
                  <Route
                    path={`${match.url}/show-supplier-delivery/:idDelivery`}
                    component={SupplierDeliveryDetails}
                  />
                  <Route
                    path={`${match.url}/show-intern-delivery/:idDelivery`}
                    component={InternDeliveryDetails}
                  />
                  <Route component={Error404} />
                </Switch>
              </div>
              <NotificationContainer style={{ color: 'yellow' }} />
            </main>
          </div>
          <SweetAlert
            show={this.state.open403Modal}
            style={{ whiteSpace: 'normal' }}
            warning
            confirmBtnText={<IntlMessages id="button.ok" />}
            confirmBtnBsStyle="danger"
            title={<IntlMessages id="error.403.message" />}
            titleBsStyle="default"
            onConfirm={() => {
              this.props.setForbidden(false);
              this.setState({
                open403Modal: false,
              });
            }}
            onCancel={() => {
              this.props.setForbidden(false);
              this.setState({ open403Modal: false });
            }}
          />
        </div>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = state.sdk.settings;
  return {
    drawerType,
    navigationStyle,
    horizontalNavPosition,
    user: state.sdk.user.userSelfDetails,
    actionStatus: state.sdk.actionStatus.alert,
    idProject: state.sdk.user.currentProject.idCurrentProject,
    projectsSelf: state.sdk.project.projectsSelf,
    userProjectByProject: state.sdk.user.userProjectByProject,
    forbidden: state.sdk.auth.forbidden,
    authUser: state.sdk.auth.authUser,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    getUserSelfDetailsRequest,
    userSignOut,
    getProjectsSelfRequest,
    setUserCurrentProjectRequest,
    getUserProjectByProjectRequest,
    postErrorInSlackRequest,
    setForbidden,
  })(App)
);
