import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import IntlMessages from '../../../../util/IntlMessages';
import { ShoppingBasket } from '@material-ui/icons';
import { FaArrowRight } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import '../cell.css';

const PosOrdersCell = (cell) => {
  let content = cell.value;
  if (cell.column.name === 'reference') {
    content = (
      <div className='spaceBetween'>
        <div className='alignItemsCenter'>
          <ShoppingBasket className='mr-2' />
          {content === null ? (
            <div className='noDataText'>
              <IntlMessages id='orders.supplier.no.reference' />
            </div>
          ) : (
            content
          )}
        </div>
        <div>
          <NavLink to={'../show-intern-order/' + cell.row.id}>
            <FaArrowRight className='ArrowIcons' />
          </NavLink>
        </div>
      </div>
    );
  }
  return <Table.Cell style={cell.style}>{content}</Table.Cell>;
};

export default PosOrdersCell;
