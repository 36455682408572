import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { FaTruck, FaArrowRight } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { formatMoney } from '../../../../config/services.config';
import Checkbox from '@material-ui/core/Checkbox';
import { updateItemSupplierRequest } from '../../../../config/actions.config';
import '../cell.css';

class ItemSuppliersCell extends Component {
  handleChange = (itemSupplier) => {
    this.props.updateItemSupplierRequest(itemSupplier.id, {
      default: !itemSupplier.default,
    });
  };

  render() {
    const { cell } = this.props;

    let content = cell.value;

    if (cell.column.name === 'supplier') {
      content = (
        <div
          className='row alignItemsCenter'
        >
          <div
            className='col-10 spaceBetweenCenter'
          >
            <div className='alignItemsCenter'>
              <Checkbox
                className='p-0'
                checked={cell.row.default}
                onClick={() => this.handleChange(cell.row.data)}
              />
              <FaTruck className='mx-1' />
              <span>{cell.row.supplier}</span>
            </div>
            <span>
              {formatMoney(
                cell.row.currency?.isoCode,
                cell.row.locale,
                cell.row.purchasePriceExclTax
              )}
              {cell.row.packUnitName &&
                cell.row.packUnitName !== '' &&
                '/' + cell.row.packUnitName}
            </span>
          </div>
          <div className='col-2'>
            <NavLink to={'/app/show-supplier/' + cell.row.data.supplier.id}>
              <FaArrowRight className='ArrowIcons' />
            </NavLink>
          </div>
        </div>
      );
    }
    return <Table.Cell style={cell.style}>{content}</Table.Cell>;
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
});

export default connect(mapStateToProps, {
  updateItemSupplierRequest,
})(ItemSuppliersCell);
