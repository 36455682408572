import React from 'react';
import { connect } from 'react-redux';
import IntlMessages from '../../../util/IntlMessages';
import MovementsTable from './MovementsTable';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import { RiArrowRightSLine } from 'react-icons/ri';
import { formatDatePicker } from '../../../config/services.config';
import '../index.css';

class Movements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDateStart: null,
      selectedDateEnd: null,
    };
  }

  handleChangeDate(date, name) {
    if (name === 'selectedDateStart') {
      let dateStart = new Date(date);
      dateStart = new Date(
        dateStart.getFullYear(),
        dateStart.getMonth(),
        dateStart.getDate(),
        0,
        0,
        0
      );
      this.setState({ selectedDateStart: formatDatePicker(dateStart) });
    }

    if (name === 'selectedDateEnd') {
      let dateEnd = new Date(date);
      dateEnd = new Date(
        dateEnd.getFullYear(),
        dateEnd.getMonth(),
        dateEnd.getDate(),
        23,
        59,
        59
      );
      this.setState({ selectedDateEnd: formatDatePicker(dateEnd) });
    }
  }

  render() {
    return (
      <div className='app-wrapper div-wrapper-title'>
        <div className='row headerAlign'>
          <div className='col-lg-8 col-md-8 page-title'>
            <IntlMessages id='movements.title' />
          </div>
          <div className='col-lg-4 col-md-4 headerFilter'>
            <div className='filterContainer'>
              <div className='mb-1'>
                <IntlMessages id='movements.filter.period' />
              </div>
              <div className='rangeDates'>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div>
                    <DatePicker
                      label={<IntlMessages id='appModule.start.date' />}
                      format='dd/MM/yyyy'
                      value={this.state.selectedDateStart}
                      onChange={(date) =>
                        this.handleChangeDate(date, 'selectedDateStart')
                      }
                    />
                  </div>
                  <div className='iconFilter'>
                    <RiArrowRightSLine />
                  </div>
                  <div>
                    <DatePicker
                      label={<IntlMessages id='appModule.end.date' />}
                      format='dd/MM/yyyy'
                      value={this.state.selectedDateEnd}
                      onChange={(date) =>
                        this.handleChangeDate(date, 'selectedDateEnd')
                      }
                    />
                  </div>
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='jr-card'>
              <MovementsTable
                selectedDateStart={this.state.selectedDateStart}
                selectedDateEnd={this.state.selectedDateEnd}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.sdk.auth,
  idProject: state.sdk.user.currentProject.idCurrentProject,
  projectDetails: state.sdk.project.projectDetails,
});

export default connect(mapStateToProps)(Movements);
