import React, { Component } from 'react';
import { connect } from 'react-redux';
import IntlMessages from '../../../../util/IntlMessages';
import {
  getDocumentByIdRequest,
  updateSupplierDeliveryDocumentRequest,
  setGlobalError,
} from '../../../../config/actions.config';
import DetailDeliverySupplier from '../../DetailCards/DetailDeliverySupplier';
import DetailDeliveryRequestor from '../../DetailCards/DetailDeliveryRequestor';
import DetailDeliveryItems from '../../DetailCards/DetailDeliveryItems';
import { NotificationManager } from 'react-notifications';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Close } from '@material-ui/icons';
import ButtonsChangeStatus from '../../../../components/Buttons/ButtonsChangeStatus';
import '../../index.css';

class SupplierDeliveryDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      deliveryDocument: null,
      editReference: false,
      reference: '',
      isResponsibleUpdated: false,
    };
    this.updateRef = null;
  }

  componentDidMount() {
    this.props.getDocumentByIdRequest(this.props.match.params.idDelivery);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.documentList !== this.props.documentList) {
      if (this.props.documentList.loading) {
        this.setState({ loading: true });
      }
      if (
        !this.props.documentList.loading &&
        !this.props.documentList.error &&
        this.props.documentList.document !== ''
      ) {
        this.setState({
          loading: false,
          deliveryDocument: this.props.documentList.document,
        });
        if (this.props.documentList.document.reference) {
          this.setState({
            editReference: false,
            reference: this.props.documentList.document.reference,
          });
        } else {
          this.setState({ editReference: true });
        }
      }
    }

    if (
      prevProps.updatedSupplierDeliveryDocument !==
      this.props.updatedSupplierDeliveryDocument
    ) {
      if (
        !this.props.updatedSupplierDeliveryDocument.loading &&
        !this.props.updatedSupplierDeliveryDocument.error
      ) {
        this.setState({ isResponsibleUpdated: true });
        this.props.getDocumentByIdRequest(this.props.match.params.idDelivery);
        NotificationManager.success(
          <IntlMessages id='orders.detail.message.success.updated' />,
        );
      }
    }

    if (prevProps.updatedDocumentStatus !== this.props.updatedDocumentStatus) {
      if (this.props.updatedDocumentStatus.loading) {
        this.setState({ loading: true });
      }
      if (
        this.props.updatedDocumentStatus.document !== '' &&
        !this.props.updatedDocumentStatus.loading &&
        !this.props.updatedDocumentStatus.error
      ) {
        this.props.getDocumentByIdRequest(this.props.match.params.idDelivery);
        NotificationManager.success(
          <IntlMessages id='orders.detail.message.success.updated' />,
        );
      }
    }

    if (prevProps.newAddress !== this.props.newAddress) {
      if (!this.props.newAddress.loading && !this.props.newAddress.error) {
        if (!this.state.isResponsibleUpdated) {
          this.props.getSupplierOrderDocumentRequest(
            this.props.match.params.idOrder,
          );

          NotificationManager.success(
            <IntlMessages id='orders.detail.message.success.updated' />,
          );
        }
      }
    }

    if (prevProps.globalError !== this.props.globalError) {
      if (this.props.globalError === true) {
        NotificationManager.error(
          <IntlMessages id='appModule.message.error' />,
        );
        this.props.setGlobalError(false);
      }
    }
  }

  getCountry = (code) => {
    const regionName = new Intl.DisplayNames([this.props.locale], {
      type: 'region',
    });
    if (code) {
      let countreCode = code.substring(0, 2);
      return regionName.of(countreCode);
    }
  };

  handleChangeReference = (event) => {
    this.setState({ reference: event.target.value });

    this.updateRef = { reference: event.target.value };
  };

  render() {
    const { loading } = this.state;

    return (
      <div className='app-wrapper div-wrapper-title'>
        <div className='row headerAlign'>
          <div className='col-8 page-title'>
            <IntlMessages id='delivery.supplier.detail.title' />
          </div>

          <div className='col-4'>
            {this.state.deliveryDocument && (
              <div className='orderDocumentRef'>
                <div className='mr-1'>
                  <IntlMessages id='orders.detail.order.number' />
                </div>
                {!this.state.editReference ? (
                  <div onClick={() => this.setState({ editReference: true })}>
                    {this.state.deliveryDocument.reference}
                  </div>
                ) : (
                  <TextField
                    margin='normal'
                    value={this.state.reference}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            className='p-0'
                            disabled={
                              !this.props.documentList.document.reference
                            }
                            onClick={() => {
                              this.setState({
                                editReference: false,
                                reference: this.props.documentList.document
                                  .reference,
                              });
                              this.updateRef = null;
                            }}
                          >
                            <Close />
                          </IconButton>
                          <IconButton
                            className='p-0'
                            disabled={
                              this.state.reference === ''
                                ? true
                                : !this.updateRef
                                ? true
                                : false
                            }
                            onClick={() => {
                              this.props.updateSupplierDeliveryDocumentRequest(
                                this.props.match.params.idDelivery,
                                this.updateRef,
                              );
                              this.setState({ editReference: false });
                            }}
                          >
                            <Check />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={this.handleChangeReference}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <div>
          <div className='row'>
            <div className='col-12 col-md-6 detailCard'>
              <DetailDeliverySupplier
                supplier={
                  this.state.deliveryDocument &&
                  this.state.deliveryDocument.supplier
                }
                loading={loading}
                getCountry={this.getCountry}
              />
            </div>
            <div className='col-12 col-md-6 detailCard'>
              <DetailDeliveryRequestor
                supplier
                requestorPos={
                  this.state.deliveryDocument &&
                  this.state.deliveryDocument.requestorPos
                }
                responsibleUserProfile={
                  this.state.deliveryDocument &&
                  this.state.deliveryDocument.responsibleUserProfile
                }
                billingAddress={
                  this.state.deliveryDocument &&
                  this.state.deliveryDocument.billingAddress
                }
                shippingAddress={
                  this.state.deliveryDocument &&
                  this.state.deliveryDocument.shippingAddress
                }
                loading={loading}
                getCountry={this.getCountry}
                idDelivery={this.props.match.params.idDelivery}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
              <DetailDeliveryItems
                supplier
                id={
                  this.state.deliveryDocument && this.state.deliveryDocument.id
                }
                vendor={
                  this.state.deliveryDocument &&
                  this.state.deliveryDocument.supplier
                }
                totalWithoutVat={
                  this.state.deliveryDocument &&
                  this.state.deliveryDocument.totalWithoutVat
                }
                totalWithVat={
                  this.state.deliveryDocument &&
                  this.state.deliveryDocument.totalWithVat
                }
                refresh={() =>
                  this.props.getDocumentByIdRequest(
                    this.props.match.params.idDelivery,
                  )
                }
                loading={loading}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <ButtonsChangeStatus
                id={this.state.deliveryDocument && this.state.deliveryDocument.id}
                status={
                  this.state.deliveryDocument && this.state.deliveryDocument.status
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  updatedSupplierDeliveryDocument:
    state.sdk.suppliers.updatedSupplierDeliveryDocument,
  newAddress: state.sdk.addresses.newAddress,
  locale: state.sdk.settings.locale.locale,
  documentList: state.sdk.documents.documentList,
  updatedDocumentStatus: state.sdk.documents.updatedDocumentStatus,
});

export default connect(mapStateToProps, {
  getDocumentByIdRequest,
  updateSupplierDeliveryDocumentRequest,
  setGlobalError,
})(SupplierDeliveryDetails);
