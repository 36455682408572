import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from '../../../../../util/IntlMessages';
import Select from 'react-select';
import {
  postStockinItemPosRequest,
  getItemPosByProjectRequest,
  getPosByProjectRequest,
  transfertItemRequest,
} from '../../../../../config/actions.config';
import { NotificationManager } from 'react-notifications';
import stockStatus from '../../../../../services/stockStatus';
import '../../../index.css';
import { CircularProgress } from '@material-ui/core';
import {
  Add,
  Remove,
  SyncAlt,
  BrokenImage,
  Build,
  LocalShipping,
  PanTool,
  NotListedLocation,
} from '@material-ui/icons';

const actionsList = [
  {
    value: 0,
    label: <IntlMessages id="movements.stock.action.add" />,
    icon: <Add className="icon-shortcut-movement" />,
    status: 'stock',
  },
  {
    value: 1,
    label: <IntlMessages id="movements.stock.action.subtract" />,
    icon: <Remove className="icon-shortcut-movement" />,
    status: 'stock',
  },
  {
    value: 2,
    label: <IntlMessages id="movements.stock.action.transfert" />,
    icon: <SyncAlt className="icon-shortcut-movement" />,
    status: 'stock',
  },
  {
    value: 3,
    label: <IntlMessages id="movements.stock.action.broken" />,
    icon: <BrokenImage className="icon-shortcut-movement" />,
    status: 'broken',
  },
  {
    value: 4,
    label: <IntlMessages id="movements.stock.action.lost" />,
    icon: <NotListedLocation className="icon-shortcut-movement" />,
    status: 'lost',
  },
  {
    value: 5,
    label: <IntlMessages id="movements.stock.action.stolen" />,
    icon: <PanTool className="icon-shortcut-movement" />,
    status: 'stolen',
  },
  {
    value: 6,
    label: <IntlMessages id="movements.stock.action.repair" />,
    icon: <Build className="icon-shortcut-movement" />,
    status: 'repair',
  },
  {
    value: 7,
    label: <IntlMessages id="movements.stock.action.returned" />,
    icon: <LocalShipping className="icon-shortcut-movement-small" />,
    status: 'returned',
  },
];

class ItemPosModalQuantityContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      action: actionsList[0],
      quantity: '',
      fromStatus: stockStatus[0],
      toStatus: '',
      toPos: '',
      comment: '',
      posList: [],
      posListLoading: false,
      confirmLoading: false,
      actionSelected: 0,
    };
    this.newStock = {
      stockStatus: stockStatus[0].value,
    };
    this.transfertStock = {
      fromStatus: stockStatus[0].value,
    };
    this.params = { limit: 5 };
  }

  componentDidMount() {
    this.transfertStock['fromPos'] = this.props.pos.id;
    this.props.getPosByProjectRequest(this.props.idProject, this.params);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.posList !== this.props.posList) {
      this.setState({ posListLoading: this.props.posList.loading });
      if (
        this.props.posList.pos !== '' &&
        !this.props.posList.error &&
        !this.props.posList.loading
      ) {
        this.setState({ posList: this.props.posList.pos });
      }
    }

    if (prevProps.newItemPosStock !== this.props.newItemPosStock) {
      this.setState({ confirmLoading: this.props.newItemPosStock.loading });
      if (
        this.props.newItemPosStock.itemPos !== '' &&
        !this.props.newItemPosStock.loading &&
        !this.props.newItemPosStock.error
      ) {
        this.props.handleClose();
      }
    }

    if (prevProps.newItemTransfert !== this.props.newItemTransfert) {
      this.setState({ confirmLoading: this.props.newItemTransfert.loading });
      if (
        this.props.newItemTransfert.itemTransfert !== '' &&
        !this.props.newItemTransfert.loading &&
        !this.props.newItemTransfert.error
      ) {
        this.props.handleClose();
      }
    }
  }

  handleChange = (name) => (event) => {
    if (name === 'quantity') {
      const onlyNums = event.target.value.replace(/[^0-9]/g, '');
      this.setState({ [name]: onlyNums });
      this.newStock[name] = parseInt(onlyNums, 10);
      this.transfertStock[name] = parseInt(onlyNums, 10);
    } else {
      this.setState({ [name]: event.target.value });
      this.newStock[name] = event.target.value;
    }
  };

  handleChangeSelect = (name) => (event) => {
    if (name === 'action') {
      this.setState({ [name]: event });
    } else if (name === 'stockStatus') {
      this.setState({ status: event });
      this.newStock[name] = event.value;
    }
  };

  handleChangeSelectDestination = (name) => (event) => {
    if (event !== null) {
      this.setState({ [name]: event });
      this.transfertStock[name] = event.value;
    } else {
      this.setState({ [name]: [] });
      this.transfertStock[name] = null;
    }
  };

  handleChangePos = (text) => {
    if (text.length !== 0) {
      this.params['name'] = {};
      this.params['name'].operation = 'contain';
      this.params['name'].value = text;
    } else {
      delete this.params['name'];
      this.setState({ posList: [] });
    }

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.getPosByProjectRequest(this.props.idProject, this.params);
    }, 100);
  };

  handleSubmit = () => {
    if (this.state.quantity !== '') {
      if (this.state.action.value === 0) {
        this.newStock.quantity = Math.abs(this.newStock.quantity);

        this.props.postStockinItemPosRequest(
          this.props.itemPosId,
          this.newStock
        );
      }

      if (this.state.action.value === 1) {
        this.newStock.quantity = -Math.abs(this.newStock.quantity);

        this.props.postStockinItemPosRequest(
          this.props.itemPosId,
          this.newStock
        );
      }

      if (this.state.action.value === 2) {
        if (this.transfertStock.toStatus && this.transfertStock.toPos) {
          this.props.transfertItemRequest(
            this.props.item.id,
            this.transfertStock
          );
        } else {
          NotificationManager.warning(
            <IntlMessages id="itemPos.warning.message.empty" />
          );
        }
      }

      if (this.state.action.value > 2) {
        this.transfertStock.toStatus = this.state.action.status;
        this.transfertStock.fromStatus = 'stock';
        this.transfertStock.toPos = this.props.pos.id;
        this.props.transfertItemRequest(
          this.props.item.id,
          this.transfertStock
        );
      }
    } else {
      NotificationManager.warning(
        <IntlMessages id="itemPos.warning.message.empty" />
      );
    }
  };

  handleClose = () => this.props.handleClose();

  handleChangeAction = (action) => {
    this.setState({ actionSelected: action.value, action: action });
  };

  render() {
    const {
      quantity,
      action,
      fromStatus,
      toStatus,
      toPos,
      comment,
      posListLoading,
      confirmLoading,
      actionSelected,
    } = this.state;

    return (
      <div>
        <div className="container-actions">
          {actionsList.map((action, index) => {
            return (
              <div
                key={index}
                className={
                  'action' +
                  (actionSelected === action.value ? ' selected' : '')
                }
                onClick={() => this.handleChangeAction(action)}
              >
                <div>{action.icon}</div>
                <div>{action.label}</div>
              </div>
            );
          })}
        </div>
        <div className="col-12">
          {action.value !== 2 && (
            <>
              <div className="information">
                <div className="col-12 col-md-4">
                  <h4>
                    <IntlMessages id={'itemPos.modal.pos'} />
                  </h4>
                </div>
                <div className="col-12 col-md-6">
                  <TextField
                    fullWidth
                    disabled
                    value={this.props.pos.name}
                    variant="outlined"
                    size="small"
                  />
                </div>
              </div>
            </>
          )}

          {action.value === 2 && (
            <>
              <div className="col-12 subtitleModal">
                <IntlMessages id={'itemPos.modal.source'} />
              </div>
              <div className="information m-0">
                <div className="col-12 col-md-4">
                  <h4>
                    <IntlMessages id={'itemPos.modal.status'} />
                  </h4>
                </div>
                <div className="col-12 col-md-6">
                  <Select
                    fullWidth
                    value={fromStatus}
                    onChange={this.handleChangeSelectDestination('fromStatus')}
                    options={stockStatus}
                    maxMenuHeight={180}
                  />
                </div>
              </div>

              <div className="information">
                <div className="col-12 col-md-4">
                  <h4>
                    <IntlMessages id={'itemPos.modal.pos'} />
                  </h4>
                </div>
                <div className="col-12 col-md-6">
                  <TextField
                    fullWidth
                    disabled
                    value={this.props.pos.name}
                    variant="outlined"
                    size="small"
                  />
                </div>
              </div>
              <div className="col-12 subtitleModal">
                <IntlMessages id={'itemPos.modal.destination'} />
              </div>
              <div className="information m-0">
                <div className="col-12 col-md-4">
                  <h4>
                    <IntlMessages id={'itemPos.modal.status'} />
                  </h4>
                </div>
                <div className="col-12 col-md-6">
                  <Select
                    fullWidth
                    value={toStatus}
                    onChange={this.handleChangeSelectDestination('toStatus')}
                    options={stockStatus}
                    maxMenuHeight={180}
                  />
                </div>
              </div>

              <div className="information">
                <div className="col-12 col-md-4">
                  <h4>
                    <IntlMessages id={'itemPos.modal.pos'} />
                  </h4>
                </div>
                <div className="col-12 col-md-6">
                  <Select
                    fullWidth
                    isClearable
                    value={toPos}
                    onChange={this.handleChangeSelectDestination('toPos')}
                    onInputChange={(text) => this.handleChangePos(text)}
                    isLoading={posListLoading}
                    options={
                      this.state.posList &&
                      this.state.posList.map((data) => {
                        return {
                          value: data.id,
                          label: data.name,
                        };
                      })
                    }
                  />
                </div>
              </div>
            </>
          )}

          <div className="information">
            <div className="col-12 col-md-4">
              <h4>
                <IntlMessages id={'itemPos.modal.quantity'} />
              </h4>
            </div>
            <div className="col-12 col-md-6">
              <TextField
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                variant="outlined"
                size="small"
                fullWidth
                value={quantity}
                onChange={this.handleChange('quantity')}
              />
            </div>
          </div>

          <div className="information">
            <div className="col-12 col-md-4">
              <h4>
                <IntlMessages id={'itemPos.modal.description'} />
              </h4>
            </div>
            <div className="col-12 col-md-6">
              <TextField
                id="outlined-multiline-static"
                multiline
                rows="4"
                fullWidth
                value={comment}
                onChange={this.handleChange('comment')}
                variant="outlined"
              />
            </div>
          </div>

          <div className="d-flex justify-content-end my-4">
            <Button className="cancel-movement" onClick={this.handleClose} color="primary">
              <IntlMessages id="appModule.cancel" />
            </Button>
            <Button
              className="submit-movement"
              variant="outlined"
              onClick={this.handleSubmit}
              color="primary"
              endIcon={
                confirmLoading && <CircularProgress size={20} disabled />
              }
              disabled={confirmLoading}
            >
              <IntlMessages id="appModule.save" />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  posList: state.sdk.pos.posList,
  newItemPosStock: state.sdk.itemPos.newItemPosStock,
  newItemTransfert: state.sdk.items.newItemTransfert,
});

export default connect(mapStateToProps, {
  postStockinItemPosRequest,
  getItemPosByProjectRequest,
  getPosByProjectRequest,
  transfertItemRequest,
})(ItemPosModalQuantityContent);
