import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import IntlMessages from '../../../../util/IntlMessages';
import ButtonsItemPos from '../../../Buttons/ButtonsItemPos';
import { Warning } from '@material-ui/icons';
import '../cell.css';

const ItemPosCell = (cell) => {
  let content = cell.value;

  if (cell.column.name === 'pos' || cell.column.name === 'item') {
    if (cell.row[cell.column.name]) {
      content = <div align="left">{cell.row[cell.column.name].name}</div>;
    } else {
      content = (
        <div align="left" className="noDataText">
          <IntlMessages id="pos.table.no.name" />{' '}
        </div>
      );
    }
  } else if (cell.column.name === 'buttons') {
    content = (
      <ButtonsItemPos
        pos={cell.row.pos}
        item={cell.row.item}
        itemPos={cell.row.itemPos}
      />
    );
  } else if (cell.column.name === 'quantityInStock') {
    if (cell.row.reorderLevel > cell.row.quantityInStock) {
      content = (
        <div className="warningContainer">
          <Warning style={{ fontSize: '1em' }} />
          {content}
        </div>
      );
    } else {
      content = <div align="center">{content}</div>;
    }
  } else {
    content = <div align="center">{content}</div>;
  }

  return <Table.Cell style={cell.style}>{content}</Table.Cell>;
};

export default ItemPosCell;
