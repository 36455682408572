import React from 'react';
import { connect } from 'react-redux';
import PersonalContentSide from './PersonalContentSide';
import IntlMessages from '../../util/IntlMessages';
import PersonalContent from './PersonalContent';
import { Loading } from '../../config/components.config';
import { NotificationManager } from 'react-notifications';
import { setGlobalError } from '../../config/actions.config';
import './index.css';

class PersonalAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userUpdate !== this.props.userUpdate) {
      if (this.props.userUpdate.loading) {
        this.setState({ loading: true });
      }
      if (!this.props.userUpdate.loading) {
        this.setState({ loading: false });
      }
      if (
        this.props.userUpdate.user !== '' &&
        !this.props.userUpdate.loading &&
        !this.props.userUpdate.error
      ) {
        NotificationManager.success(
          <IntlMessages id='account.personal.message.success.updated' />
        );
      }
    }

    if (prevProps.globalError !== this.props.globalError) {
      if (this.props.globalError === true) {
        NotificationManager.error(
          <IntlMessages id='appModule.message.error' />
        );
        this.props.setGlobalError(false);
      }
    }
  }

  render() {
    return (
      <div className='app-wrapper div-wrapper-title'>
        <div className='row'>
          <div className='col-12 page-title'>
            <IntlMessages id='appModule.myProfile' />
          </div>
        </div>
        <div className='col-12'>
          <div className='row jr-card p-0'>
            <div className='col-12 col-lg-3 asideContent'>
              <PersonalContentSide />
            </div>
            <div className='col-12 col-lg-9 infoContent'>
              <PersonalContent userDetails={this.props.userSelfDetails} />
            </div>
          </div>
        </div>
        {this.state.loading && <Loading />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  userUpdate: state.sdk.user.userUpdate,
  globalError: state.sdk.auth.globalError,
});

export default connect(mapStateToProps, { setGlobalError })(PersonalAccount);
