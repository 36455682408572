import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import ButtonsItems from '../../../Buttons/ButtonsItems';
import IntlMessages from '../../../../util/IntlMessages';
import '../cell.css';

export const ItemsCell = (cell) => <ObjectsCellConst cell={cell} />;

class ObjectsCellConst extends Component {
  render() {
    const { cell } = this.props;
    let content = cell.value;

    if (cell.column.name === 'buttons') {
      content = <ButtonsItems item={cell.row.item} />;
    } else if (cell.column.name === 'name') {
      content = (
        <div className='nameCell'>
          {cell.row.name}
        </div>
      );
    } else if (cell.column.name === 'defaultSupplier') {
      if (
        cell.row.defaultSupplier === '' ||
        cell.row.defaultSupplier === null
      ) {
        content = (
          <div className='noDataText'>
            <IntlMessages id='item.table.no.supplier' />{' '}
          </div>
        );
      }
    }
    return <Table.Cell style={cell.style}>{content}</Table.Cell>;
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
});

export default connect(mapStateToProps)(ItemsCell);
