import React, { Component } from 'react';
import { Modal } from '../../../../config/components.config';
import IntlMessages from '../../../../util/IntlMessages';
import ReOrderModalContent from './ReOrderModalContent';

class ReOrderModal extends Component {
  handleClose = () => this.props.close();

  render() {
    return (
      <Modal
        title={<IntlMessages id={'pos.modal.creation.title'} />}
        width='md'
        open={this.props.open}
        close={this.handleClose}
      >
        <ReOrderModalContent
          selectedRows={this.props.selectedRows}
          handleClose={this.handleClose}
        />
      </Modal>
    );
  }
}

export default ReOrderModal;
