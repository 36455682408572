import React, { Component } from 'react';
import { Modal } from '../../../../../config/components.config';
import ItemPosModalQuantityContent from './ItemPosModalQuantityContent';
import { injectIntl } from 'react-intl';

class ItemPosModalQuantity extends Component {
  handleClose = () => this.props.close();

  render() {
    let itemName = this.props.item !== null ? this.props.item.name : '';
    return (
      <Modal
        title={
          this.props.intl.formatMessage({ id: 'itemPos.modal.quatity.title' }) +
          ' :: ' +
          itemName
        }
        width='md'
        open={this.props.open}
        close={this.handleClose}
      >
        <ItemPosModalQuantityContent
          handleClose={this.handleClose}
          itemPosId={this.props.itemPosId}
          item={this.props.item}
          pos={this.props.pos}
        />
      </Modal>
    );
  }
}

export default injectIntl(ItemPosModalQuantity);
