import React, { Component } from 'react';
import { connect } from 'react-redux';
import IntlMessages from '../../../../util/IntlMessages';
import { Grid, Table } from '@devexpress/dx-react-grid-material-ui';
import { UnderlineTitle } from '../../../../config/components.config';
import NoData from '../../../../components/Table/NoData/NoData';
import { getDeliveryDocumentsSupplierRequest } from '../../../../config/actions.config';
import movementsSupplierCell from '../../../../components/Table/Cells/Movements/movementsSupplierCell';

const columns = [{ name: 'delivery' }];

class SuppliersDelivery extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.params = {
      limit: 10,
    };
  }
  componentDidMount() {
    this.props.getDeliveryDocumentsSupplierRequest(this.props.id, this.params);
  }

  render() {
    const { deliveryDocumentsSupplierList } = this.props;
    const row = [];
    if (deliveryDocumentsSupplierList && deliveryDocumentsSupplierList !== '') {
      deliveryDocumentsSupplierList.deliveryDocuments.forEach((data, i) => {
        row[i] = {
          id: data.id,
          name: data.name
        };
      });
    }
    return (
      <>
        <div className='jr-card'>
          <UnderlineTitle
            title={<IntlMessages id='supplier.detail.card.delivery.title' />}
          />
          <div className='containerTable'>
            <Grid rows={row} columns={columns}>
              <Table
                noDataCellComponent={NoData}
                cellComponent={movementsSupplierCell}
              />
            </Grid>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  deliveryDocumentsSupplierList: state.sdk.suppliers.deliveryDocumentsSupplierList,
});

export default connect(mapStateToProps, {
  getDeliveryDocumentsSupplierRequest,
})(SuppliersDelivery);
