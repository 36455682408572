import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableInlineCellEditing,
} from '@devexpress/dx-react-grid-material-ui';
import { EditingState } from '@devexpress/dx-react-grid';
import { NotificationManager } from 'react-notifications';
import IntlMessages from '../../../../util/IntlMessages';
import { UnderlineTitle, Loading } from '../../../../config/components.config';
import { formatMoney } from '../../../../config/services.config';
import ColumnCell from '../../../../components/Table/Columns/ColumnCell';
import OrderItemRows from '../../../../components/Table/Rows/OrderItem/OrderItemRows';
import {
  columnsOrderDelivery,
  tableColumnExtensionsOrderDelivery,
} from '../../../../components/Table/Columns/ShowColumns';
import {
  getDocumentsLinesRequest,
  postDocumentsLinesRequest,
  deleteDocumentsLineRequest,
  editDocumentsLineRequest,
  setGlobalError,
} from '../../../../config/actions.config';
import NoData from '../../../../components/Table/NoData/NoData';
import EditIcon from '@material-ui/icons/Edit';
import './DetailsOrderDeliveryItem.css';

const getRowId = (row) => row.id;

const FocusableCell = ({ onClick, ...restProps }) => {
  let cell;

  if (restProps.column.name === 'qtyReceived') {
    cell = (
      <Table.Cell
        {...restProps}
        tabIndex={0}
        className="quantityReceivedCell"
        onFocus={onClick}
      >
        <div className="editQuantityReceivedContainer">
          <div className="quantityReceivedFakeInput">
            {restProps.row.qtyReceived}
          </div>
          <div className="quantityReceivedEditIcon">
            <EditIcon />
          </div>
        </div>
      </Table.Cell>
    );
  } else if (restProps.column.name === 'totalWithoutVat') {
    cell = (
      <Table.Cell style={{ textAlign: 'right' }}>
        {formatMoney(
          restProps.row.currency?.isoCode,
          restProps.row.locale,
          restProps.row[restProps.column.name]
        )}
      </Table.Cell>
    );
  } else if (restProps.column.name === 'quantityAlreadyDelivered') {
    cell = (
      <Table.Cell style={{ textAlign: 'center' }}>
        <div>{restProps.row[restProps.column.name]}</div>
      </Table.Cell>
    );
  } else {
    cell = (
      <Table.Cell style={{ textAlign: 'center' }}>
        {restProps.row[restProps.column.name]}
      </Table.Cell>
    );
  }
  return cell;
};

class DetailsOrderDeliveryItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      objectCount: 0,
      rows: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      if (!this.props.loading) {
        this.props.getDocumentsLinesRequest(this.props.id);
      }
    }

    if (prevProps.documentsLinesList !== this.props.documentsLinesList) {
      if (this.props.documentsLinesList.loading) {
        this.setState({ loading: true });
      }
      if (
        !this.props.documentsLinesList.loading &&
        !this.props.documentsLinesList.error
      ) {
        const { documentsLinesList } = this.props;
        const rows = [];
        if (documentsLinesList && documentsLinesList.documentsLines) {
          documentsLinesList.documentsLines.forEach((data, i) => {
            rows[i] = {
              id: data.id,
              itemData: data.item,
              item: data.item?.name,
              priceWithoutVat: data.priceWithoutVat,
              quantity: data.quantity,
              quantityAlreadyDelivered: data.quantityAlreadyDelivered,
              totalWithoutVat: data.totalWithoutVat,
              packUnitName: data.packUnitName,
              vatrate: data.vatrate,
              locale: this.props.locale,
              qtyReceived: 0,
              currency:
                this.props.projectDetails && this.props.projectDetails !== ''
                  ? this.props.projectDetails.projectFinance.defaultCurrency
                  : null,
            };
          });
        }
        this.setState({
          loading: false,
          objectCount: this.props.documentsLinesList.meta.object_count,
          rows,
        });
      }
    }

    if (prevProps.globalError !== this.props.globalError) {
      if (this.props.globalError === true) {
        NotificationManager.error(
          <IntlMessages id="appModule.message.error" />
        );
        this.props.setGlobalError(false);
      }
    }
  }

  commitChanges = ({ changed }) => {
    let changedRows;
    if (changed) {
      changedRows = this.state.rows.map((row) => {
        if (changed[row.id]) {
          const floatQtyReceived = parseFloat(changed[row.id].qtyReceived);
          changed[row.id].qtyReceived = floatQtyReceived || 0;
          return { ...row, ...changed[row.id] };
        }
        return row;
      });
    }
    this.props.handleChangeQuantityDelivered(changedRows);
    this.setState({ rows: changedRows });
  };

  render() {
    const { rows, loading, objectCount } = this.state;
    const { projectDetails, totalWithVat, locale, totalWithoutVat } =
      this.props;

    return (
      <>
        <div className="jr-card fullWidth">
          <UnderlineTitle
            title={<IntlMessages id="orders.detail.card.items.title" />}
          />

          <Grid rows={rows} columns={columnsOrderDelivery} getRowId={getRowId}>
            <EditingState onCommitChanges={this.commitChanges} />
            <Table
              columnExtensions={tableColumnExtensionsOrderDelivery}
              cellComponent={FocusableCell}
              rowComponent={OrderItemRows}
              noDataCellComponent={NoData}
            />
            <TableHeaderRow cellComponent={ColumnCell} />
            <TableInlineCellEditing
              startEditAction="click"
              selectTextOnEditStart
            />
          </Grid>

          {!loading && objectCount !== 0 && (
            <div className="priceContainer">
              <div style={{ width: '40%' }}>
                <div className="priceItem">
                  <span>
                    <b>
                      <IntlMessages id="orders.detail.table.subtotal" />
                    </b>
                  </span>
                  <div>
                    {formatMoney(
                      projectDetails &&
                        projectDetails.projectFinance.defaultCurrency.isoCode,
                      locale,
                      totalWithoutVat
                    )}
                  </div>
                </div>
                <div className="priceItem">
                  <span>
                    <b>
                      <IntlMessages id="orders.detail.table.vatrate" />
                    </b>
                  </span>
                  <div>
                    {formatMoney(
                      projectDetails &&
                        projectDetails.projectFinance.defaultCurrency.isoCode,
                      locale,
                      totalWithVat - totalWithoutVat
                    )}
                  </div>
                </div>
                <div className="priceItem">
                  <span>
                    <b>
                      <IntlMessages id="orders.detail.table.totalWithVat" />
                    </b>
                  </span>
                  <div>
                    {formatMoney(
                      projectDetails &&
                        projectDetails.projectFinance.defaultCurrency.isoCode,
                      locale,
                      totalWithVat
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {loading && <Loading />}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.user.currentProject.idCurrentProject,
  documentsLinesList: state.documents.documentsLinesList,
  newDocumentsLines: state.documents.newDocumentsLines,
  updatedDocumentsLine: state.documents.updatedDocumentsLine,
  deletedDocumentsLine: state.documents.deletedDocumentsLine,
  locale: state.settings.locale.locale,
  projectDetails: state.project.projectDetails,
  globalError: state.auth.globalError,
});

export default connect(mapStateToProps, {
  getDocumentsLinesRequest,
  postDocumentsLinesRequest,
  deleteDocumentsLineRequest,
  editDocumentsLineRequest,
  setGlobalError,
})(DetailsOrderDeliveryItem);
