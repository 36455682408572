import React from 'react';
import { connect } from 'react-redux';
import { showAuthLoader, userSignIn } from '../config/actions.config';
import { Login } from '../config/components.config';
import IntlMessages from '../util/IntlMessages';

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      errorMessage: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.alertMessage !== prevProps.alertMessage) {
      this.setState({ errorMessage: this.props.alertMessage });
    }
    if (this.props.authUser !== null && this.props.authUser !== '') {
      this.props.history.push('/');
    }
  }

  keyPress = (e) => {
    const { email, password } = this.state;
    if (e.key === 'Enter') {
      this.props.showAuthLoader();
      this.props.userSignIn({ email, password });
    }
  };

  handleChange = (event, name) => {
    this.setState({ [name]: event.target.value, errorMessage: '' });
  };

  handleSubmit = (email, password) => {
    this.props.showAuthLoader();
    this.props.userSignIn({ email, password });
  };

  render() {
    const { email, password, errorMessage } = this.state;
    const { loader } = this.props;
    return (
      <Login
        title={<IntlMessages id="signIn.identify" />}
        emailLabel={<IntlMessages id="appModule.email" />}
        email={email}
        handleEmail={(event) => this.handleChange(event, 'email')}
        passwordLabel={<IntlMessages id="appModule.password" />}
        password={password}
        handlePassword={(event) => this.handleChange(event, 'password')}
        errorMessage={errorMessage}
        connexionLabel={<IntlMessages id="appModule.connection" />}
        loader={loader}
        onKeyPress={this.keyPress}
        handleConfirm={() => {
          this.props.showAuthLoader();
          this.props.userSignIn({ email, password });
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { loader, alertMessage, authUser } = state.sdk.auth;
  return {
    loader,
    alertMessage,
    authUser,
  };
};

export default connect(mapStateToProps, {
  userSignIn,
  showAuthLoader,
})(SignIn);
