import React, { Component } from 'react';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  SelectionState,
  CustomPaging,
  SearchState,
  FilteringState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableColumnVisibility,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import {
  getItemsByProjectRequest,
  setGlobalError,
} from '../../../config/actions.config';
import NoData from '../../../components/Table/NoData/NoData';
import ColumnCell from '../../../components/Table/Columns/ColumnCell';
import ItemsCell from '../../../components/Table/Cells/Items/ItemsCell';
import {
  tableColumnExtensionsItems,
  columnsItems,
} from '../../../components/Table/Columns/ShowColumns';
import { Loading } from '../../../config/components.config';
import IntlMessages from '../../../util/IntlMessages';
import FilterCell from '../../../components/Table/Cells/Items/FiltersCell';

class ItemsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pageSizes: [10, 20, 50, 100],
      filters: [],
    };
    this.parameters = {};
  }

  componentDidMount() {
    if (!localStorage.getItem('paramsItems')) {
      localStorage.setItem(
        'paramsItems',
        JSON.stringify({
          limit: 20,
          page: 1,
        })
      );
    }
    this.parameters = JSON.parse(localStorage.getItem('paramsItems'));

    this.props.getItemsByProjectRequest(this.props.idProject, this.parameters);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.itemsList !== this.props.itemsList) {
      if (this.props.itemsList.loading) {
        this.setState({ loading: true });
      }
      if (
        this.props.itemsList.items !== '' &&
        !this.props.itemsList.error &&
        !this.props.itemsList.loading
      ) {
        this.setState({
          loading: false,
          objectCount: this.props.itemsList.meta.object_count,
        });
      }
    }

    if (this.props.deletedItem !== prevProps.deletedItem) {
      if (!this.props.deletedItem.loading && !this.props.deletedItem.error) {
        this.props.getItemsByProjectRequest(
          this.props.idProject,
          JSON.parse(localStorage.getItem('paramsItems'))
        );
        NotificationManager.success(
          <IntlMessages id='item.success.message.deleted' />
        );
      }
    }

    if (this.props.newItemTranslations !== prevProps.newItemTranslations) {
      if (
        this.props.newItemTranslations &&
        this.props.newItemTranslations.itemTranslations !== '' &&
        !this.props.newItemTranslations.loading &&
        !this.props.newItemTranslations.error
      ) {
        this.props.getItemsByProjectRequest(
          this.props.idProject,
          JSON.parse(localStorage.getItem('paramsItems'))
        );
        NotificationManager.success(
          <IntlMessages id='item.success.message.added' />
        );
      }
    }

    if (
      this.props.updatedItemTranslations !== prevProps.updatedItemTranslations
    ) {
      if (
        this.props.updatedItemTranslations &&
        this.props.updatedItemTranslations.itemTranslations !== '' &&
        !this.props.updatedItemTranslations.loading &&
        !this.props.updatedItemTranslations.error
      ) {
        this.props.getItemsByProjectRequest(
          this.props.idProject,
          JSON.parse(localStorage.getItem('paramsItems'))
        );

        NotificationManager.success(
          <IntlMessages id='item.success.message.updated' />
        );
      }
    }

    if (prevProps.globalError !== this.props.globalError) {
      if (this.props.globalError === true) {
        NotificationManager.error(
          <IntlMessages id='appModule.message.error' />
        );
        this.props.setGlobalError(false);
      }
    }
  }

  setCurrentPage = (page) => {
    this.parameters = JSON.parse(localStorage.getItem('paramsItems'));
    this.parameters.page = page + 1;

    this.props.getItemsByProjectRequest(this.props.idProject, this.parameters);
    localStorage.setItem('paramsItems', JSON.stringify(this.parameters));
  };

  setPageSize = (pageSize) => {
    this.parameters = JSON.parse(localStorage.getItem('paramsItems'));
    this.parameters.limit = pageSize;

    this.props.getItemsByProjectRequest(this.props.idProject, this.parameters);
    localStorage.setItem('paramsItems', JSON.stringify(this.parameters));
  };

  onFilter = (filter) => {
    this.parameters = JSON.parse(localStorage.getItem('paramsItems'));
    this.parameters.page = 1;
    this.setState({ filters: filter });
    filter.forEach((data) => {
      if (data.value && data.value !== '' && data.value !== 'all') {
        if (data.columnName === 'defaultSupplier') {
          this.parameters['itemSupplier.supplier.supplierReference'] = {};
          this.parameters['itemSupplier.supplier.supplierReference'].operation =
            'contain';
          this.parameters['itemSupplier.supplier.supplierReference'].value =
            data.value;
        } else {
          this.parameters[data.columnName] = {};
          this.parameters[data.columnName].operation = 'contain';
          this.parameters[data.columnName].value = data.value;
        }
      } else {
        if (data.columnName === 'defaultSupplier') {
          delete this.parameters['itemSupplier.supplier.supplierReference'];
        } else {
          delete this.parameters[data.columnName];
        }
      }
    });

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.getItemsByProjectRequest(
        this.props.idProject,
        this.parameters
      );
    }, 200);

    localStorage.setItem('paramsItems', JSON.stringify(this.parameters));
  };

  render() {
    const { loading, pageSizes, objectCount, filters } = this.state;
    const { itemsList } = this.props;
    const rows = [];

    if (itemsList && itemsList.items) {
      itemsList.items.forEach((data) => {
        if (data.stockManagement) {
          rows.push({
            item: data,
            name: data.name,
            defaultSupplier: data.defaultSupplier
              ? data.defaultSupplier.supplierReference
              : '',
            defaultSupplierId: data.defaultSupplier
              ? data.defaultSupplier.id
              : '',
          });
          console.log(rows);
        }
      });
    }
    return (
      <div>
        <Grid rows={rows} columns={columnsItems}>
          <SelectionState />
          <PagingState
            pageSize={
              JSON.parse(localStorage.getItem('paramsItems')) &&
              JSON.parse(localStorage.getItem('paramsItems'))['limit']
                ? JSON.parse(localStorage.getItem('paramsItems'))['limit']
                : 20
            }
            currentPage={
              JSON.parse(localStorage.getItem('paramsItems')) &&
              JSON.parse(localStorage.getItem('paramsItems'))['page']
                ? JSON.parse(localStorage.getItem('paramsItems'))['page'] - 1
                : 1
            }
            onCurrentPageChange={this.setCurrentPage}
            onPageSizeChange={this.setPageSize}
          />
          <SortingState />
          <IntegratedSorting />
          <SearchState defaultValue='' />
          <FilteringState onFiltersChange={this.onFilter} filters={filters} />
          <Table
            columnExtensions={tableColumnExtensionsItems}
            cellComponent={ItemsCell}
            noDataCellComponent={NoData}
          />
          <CustomPaging totalCount={objectCount} />
          <TableColumnVisibility />
          <TableHeaderRow cellComponent={ColumnCell} />
          <TableFilterRow cellComponent={FilterCell} />
          <PagingPanel pageSizes={pageSizes} />
        </Grid>
        {loading && <Loading />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  itemsList: state.sdk.items.itemsList,
  locale: state.sdk.settings.locale.locale,
  projectDetails: state.sdk.project.projectDetails,
  projectFinance: state.sdk.project.projectDetails.projectFinance,
  globalError: state.sdk.auth.globalError,
  deletedItem: state.sdk.items.deletedItem,
  newItemTranslations: state.sdk.items.newItemTranslations,
  updatedItemTranslations: state.sdk.items.updatedItemTranslations,
});

export default connect(mapStateToProps, {
  getItemsByProjectRequest,
  setGlobalError,
})(ItemsTable);
