export const styles = {
    rootAccordion: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      margin: '0 !important',
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    rootSummary: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    contentSummary: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    rootDetails: {
      display: 'block',
    },
  };