import saMessages from '../locales/fr/fr.json';
import frMessages from "../locales/fr/global_fr.json";

const result = {};
let key;

for (key in frMessages) {
  if (Object.prototype.hasOwnProperty.call(frMessages, key)) {
    result[key] = frMessages[key];
  }
}

for (key in saMessages) {
  if (Object.prototype.hasOwnProperty.call(saMessages, key)) {
    result[key] = saMessages[key];
  }
}

const frLang = {
  messages: {
    ...result,
  },
  locale: "fr",
};
export default frLang;
