import React, { Component } from 'react';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import ItemModalEdition from '../../app/Items/ItemModal/ItemModalEdit/ItemModalEdition';
import IntlMessages from '../../util/IntlMessages';
import { deleteItemRequest, getItemRequest } from '../../config/actions.config';
import { ActionButton } from '../../config/components.config';
import { NavLink } from 'react-router-dom';
import './Buttons.css';

class ButtonsItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalEditionItem: false,
      isOpen: false,
    };
  }

  handleOpenModal = () => {
    const { item } = this.props;
    this.props.getItemRequest(item.id);
    this.setState({ openModalEditionItem: true });
  };

  handleDelete = () => {
    this.setState({
      isOpen: true,
    });
  };

  render() {
    const { openModalEditionItem, isOpen } = this.state;
    const { item } = this.props;

    return (
      <div className={'btn-action-container'}>
        <NavLink
          to={'show-item/' + item.id}
          className='itemDetails'
          id={item.id}
        >
          <ActionButton icon='view' />
        </NavLink>
        <ActionButton
          icon='edit'
          className='editItem'
          id={item.id}
          onClick={this.handleOpenModal}
        />
        <ActionButton
          icon='delete'
          onClick={this.handleDelete}
          id={item.id}
          className='deleteItem'
        />
        <ItemModalEdition
          item={item}
          open={openModalEditionItem}
          close={() => this.setState({ openModalEditionItem: false })}
        />
        <SweetAlert
          show={isOpen}
          style={{ whiteSpace: 'normal' }}
          warning
          showCancel
          confirmBtnText={<IntlMessages id='appModule.yes' />}
          cancelBtnText={<IntlMessages id='appModule.cancel' />}
          confirmBtnBsStyle='danger'
          title={<IntlMessages id='appModule.modal.delete.confirm.message' />}
          titleBsStyle='default'
          confirmBtnCssClass='confirmDeleteItem'
          onConfirm={() => {
            this.props.deleteItemRequest(item.id);
            this.setState({ isOpen: false });
          }}
          onCancel={() => {
            this.setState({ isOpen: false });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
});

export default connect(mapStateToProps, {
  deleteItemRequest,
  getItemRequest,
})(ButtonsItems);
