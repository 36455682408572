import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from '../../../../util/IntlMessages';
import FormControl from '@material-ui/core/FormControl';
import {
  getProjectDetailsRequest,
  postCompanyByOrganizationRequest,
  postSuppliersByOrganizationRequest,
  postAddressCompanyRequest,
} from '../../../../config/actions.config';
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import '../../index.css';
import { UnderlineTitle } from '../../../../config/components.config';
import { isVatValid, isEmailValid } from '../../../../config/services.config';
const countries = require('i18n-iso-countries');

class SuppliersModalCreationContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      reference: '',
      email: '',
      street: '',
      streetNumber: '',
      city: '',
      zipCode: '',
      vatNumber: '',
      phoneNumber: null,
      country: '',
      defaultCountry: '',
    };
    this.allCountry = null;
    this.supplierCompany = {
      name: '',
      email: '',
      vatNumber: '',
      reference: '',
    };
    this.supplierAddress = {
      street: '',
      streetNumber: '',
      city: '',
      zipCode: '',
      phoneNumber: null,
      country: '',
    };
    this.supplier = {
      supplierReference: '',
      company: null,
      defaultSupplier: false,
    };
  }

  componentDidMount() {
    if (this.props.project.projectDetails) {
      this.setState({
        defaultCountry: this.props.project.projectDetails.defaultCountry,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.newCompany !== this.props.newCompany) {
      if (
        this.props.newCompany.company !== '' &&
        !this.props.newCompany.loading &&
        !this.props.newCompany.error
      ) {
        this.props.postAddressCompanyRequest(
          this.props.newCompany.company.id,
          this.supplierAddress,
        );
        this.supplier['company'] = this.props.newCompany.company.id;
        this.props.postSuppliersByOrganizationRequest(
          this.props.idOrganization,
          this.supplier,
        );
        this.props.handleClose();
      }
    }
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
    this.supplierCompany[name] = event.target.value;
    if (name === 'reference') {
      this.supplier['supplierReference'] = event.target.value;
    }
  };

  handleChangeAddress = (name) => (event) => {
    if (name === 'phoneNumber') {
      this.setState({ [name]: event });
      this.supplierAddress[name] = '+' + event;
    } else {
      this.setState({ [name]: event.target.value });
      this.supplierAddress[name] = event.target.value;
    }
  };

  getCountry = (code) => {
    const regionName = new Intl.DisplayNames([this.props.locale], {
      type: 'region',
    });
    if (code) {
      const countryCode = code.substring(0, 2);
      if (isVatValid(this.state.vatNumber)) {
        return (
          <div
            style={{ color: 'grey', fontStyle: 'italic', fontWeight: '300' }}
          >
            {regionName.of(countryCode)}
          </div>
        );
      } else {
        return null;
      }
    }
  };

  handleChangeAddressCountry = (event) => {
    var countryCode = countries.getAlpha2Code(event.value, this.props.locale);
    this.supplierAddress['country'] = countryCode;
    this.setState({ country: event.value });
  };

  handleClose = () => {
    this.props.handleClose();
  };

  handLeSubmit = () => {
    if (this.supplierCompany.name === '') {
      NotificationManager.warning(
        <IntlMessages id='supplier.warning.message.name' />,
      );
    } else if (this.supplierCompany.reference === '') {
      NotificationManager.warning(
        <IntlMessages id='supplier.warning.message.reference' />,
      );
    } else if (this.supplierAddress.street === '') {
      NotificationManager.warning(
        <IntlMessages id='supplier.warning.message.address' />,
      );
    } else {
      this.props.postCompanyByOrganizationRequest(
        this.props.idOrganization,
        this.supplierCompany,
      );
    }
  };

  render() {
    const {
      name,
      reference,
      phoneNumber,
      email,
      street,
      streetNumber,
      city,
      vatNumber,
      zipCode,
      defaultCountry,
    } = this.state;

    countries.registerLocale(
      require(`i18n-iso-countries/langs/${
        this.props.locale === 'fr_CA' ? 'fr' : this.props.locale
      }.json`)
    );

    this.allCountry = countries.getNames(this.props.locale, {
      select: 'official',
    });

    return (
      <div>
        <div className='col-12'>
          <UnderlineTitle
            title={<IntlMessages id='suppliers.project.title.general.infos' />}
          />
          <div className='row mb-4'>
            <div className='col-6 col-lg-6'>
              <TextField
                id='name'
                variant='outlined'
                margin='normal'
                size='small'
                label={<IntlMessages id='suppliers.modal.name' />}
                fullWidth
                value={name}
                onChange={this.handleChange('name')}
              />
            </div>

            <div className='col-6 col-lg-6'>
              <TextField
                id='reference'
                variant='outlined'
                margin='normal'
                label={<IntlMessages id='suppliers.modal.reference' />}
                size='small'
                fullWidth
                value={reference}
                onChange={this.handleChange('reference')}
              />
            </div>

            <div className='col-12 col-lg-6'>
              <TextField
                error={
                  email !== '' ? (isEmailValid(email) ? false : true) : false
                }
                id='email'
                variant='outlined'
                margin='normal'
                size='small'
                label={<IntlMessages id='suppliers.modal.email' />}
                fullWidth
                value={email}
                onChange={this.handleChange('email')}
              />
            </div>

            <div className='col-6 col-lg-6'>
              <TextField
                id='vatNumber'
                error={
                  vatNumber && vatNumber !== ''
                    ? isVatValid(vatNumber)
                      ? false
                      : true
                    : false
                }
                label={<IntlMessages id='suppliers.modal.vat' />}
                style={{ zIndex: 0 }}
                fullWidth
                variant='outlined'
                size='small'
                margin='normal'
                value={vatNumber}
                onChange={this.handleChange('vatNumber')}
                InputProps={{
                  endAdornment: vatNumber && this.getCountry(vatNumber),
                }}
              />
            </div>
          </div>

          <UnderlineTitle
            title={<IntlMessages id='suppliers.project.title.adress' />}
          />

          <div className='row'>
            <div className='col-lg-8'>
              <TextField
                id='addressRoad'
                margin='normal'
                size='small'
                fullWidth
                label={<IntlMessages id='appModule.adress' />}
                variant='outlined'
                value={street}
                onChange={this.handleChangeAddress('street')}
              />
            </div>
            <div className='col-lg-2'>
              <TextField
                id='addressNumber'
                margin='normal'
                size='small'
                fullWidth
                label={
                  <IntlMessages id='appModule.houseNumber' />
                }
                variant='outlined'
                value={streetNumber}
                onChange={this.handleChangeAddress('streetNumber')}
              />
            </div>
            <div className='col-lg-6'>
              <TextField
                id='addressCity'
                margin='normal'
                size='small'
                fullWidth
                label={<IntlMessages id='appModule.city' />}
                variant='outlined'
                value={city}
                onChange={this.handleChangeAddress('city')}
              />
            </div>
            <div className='col-lg-3'>
              <TextField
                id='addressZipCode'
                margin='normal'
                size='small'
                fullWidth
                label={<IntlMessages id='appModule.zipCode' />}
                variant='outlined'
                value={zipCode}
                onChange={this.handleChangeAddress('zipCode')}
              />
            </div>

            <div className='col-lg-4 my-2'>
              <FormControl style={{ width: '100%', marginTop: 2 }}>
                <Select
                  id='type'
                  fullWidth
                  placeholder={
                    <IntlMessages id='appModule.country' />
                  }
                  value={countries.getName(
                    this.state.country,
                    this.props.locale,
                    {
                      select: 'official',
                    },
                  )}
                  onChange={this.handleChangeAddressCountry}
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: 40,
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                  options={
                    this.allCountry &&
                    Object.keys(this.allCountry).map((data) => {
                      return {
                        value: this.allCountry[data],
                        label: this.allCountry[data],
                      };
                    })
                  }
                />
              </FormControl>
            </div>
            <div className='col-lg-4'>
              {defaultCountry && (
                <PhoneInput
                  id='phoneInput'
                  country={defaultCountry.toLowerCase()}
                  value={phoneNumber}
                  inputStyle={{
                    marginTop: '10px',
                    padding: '7px 10px 7px 58px',
                    width: '100%',
                  }}
                  onChange={this.handleChangeAddress('phoneNumber')}
                />
              )}
            </div>
          </div>

          <div className='d-flex justify-content-end my-4'>
            <Button onClick={this.handleClose} color='primary'>
              <IntlMessages id='appModule.cancel' />
            </Button>
            <Button
              id='save'
              variant='outlined'
              onClick={this.handLeSubmit}
              color='primary'
            >
              <IntlMessages id='appModule.save' />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  idOrganization: state.sdk.project.projectDetails.organisation.id,
  locale: state.sdk.settings.locale.locale,
  project: state.sdk.project,
  newCompany: state.sdk.organizations.newCompany,
});

export default connect(mapStateToProps, {
  getProjectDetailsRequest,
  postCompanyByOrganizationRequest,
  postAddressCompanyRequest,
  postSuppliersByOrganizationRequest,
})(SuppliersModalCreationContent);
