import React from 'react';
import { connect } from 'react-redux';
import IntlMessages from '../../../util/IntlMessages';
import InternDeliveryTable from './InternDeliveryTable/index';
import '../index.css';

class InternDelivery extends React.Component {
  render() {
    return (
      <div className='app-wrapper div-wrapper-title'>
        <div className='row headerAlign'>
          <div className='col-lg-9 col-md-9 col-sm-7 page-title'>
            <IntlMessages id='delivery.intern.title' />
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='jr-card'>
              <InternDeliveryTable />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
});

export default connect(mapStateToProps)(InternDelivery);
