import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  UnderlineTitle,
  Loading,
  ActionButton,
} from '../../../../config/components.config';
import IntlMessages from '../../../../util/IntlMessages';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ItemModalEdition from '../../ItemModal/ItemModalEdit/ItemModalEdition';
import { getItemRequest } from '../../../../config/actions.config';
import '../../index.css';

class ItemInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalItem: false,
    };
  }

  handleOpenModal = () => {
    const { item } = this.props;
    this.props.getItemRequest(item.id);
    this.setState({ openModalItem: true });
  };

  render() {
    const { item } = this.props;

    return (
      <>
        <div className='jr-card fullWidth'>
          <UnderlineTitle
            title={<IntlMessages id='item.details.card.info.title' />}
          >
            <ActionButton
              icon={'edit'}
              onClick={this.handleOpenModal}
              className='editItemDetail'
            />
          </UnderlineTitle>
          {item ? (
            <>
              <div className='information'>
                <div className='mr-2'>
                  <b>
                    <IntlMessages id='item.details.card.info.label.name' />:
                  </b>
                </div>
                <div className="itemDetailName">{item.name}</div>
              </div>

              <div className='information'>
                <div className='mr-2'>
                  <b>
                    <IntlMessages id='item.details.card.info.label.defaultSupplier' />
                    :
                  </b>
                </div>
                <div>
                  {item.defaultSupplier
                    ? item.defaultSupplier.supplierReference
                    : ''}
                </div>
              </div>

              <div className='information'>
                <div className='mr-2'>
                  <b>
                    <IntlMessages id='item.details.card.info.label.stockManagement' />
                    :
                  </b>
                </div>
                <div>
                  {item.stockManagement ? (
                    <CheckCircleIcon style={{ color: 'green' }} />
                  ) : (
                    <CancelIcon style={{ color: 'red' }} />
                  )}
                </div>
              </div>
              <ItemModalEdition
                item={item}
                open={this.state.openModalItem}
                close={() => this.setState({ openModalItem: false })}
              />
            </>
          ) : (
            <Loading />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
});

export default connect(mapStateToProps, { getItemRequest })(ItemInfo);
