import React, { Component } from 'react';
import { connect } from 'react-redux';
import IntlMessages from '../../../../util/IntlMessages';
import {
  getSupplierOrderDocumentRequest,
  updateSupplierOrderDocumentRequest,
  setGlobalError,
} from '../../../../config/actions.config';
import DetailOrderSupplier from '../../DetailCards/DetailOrderSupplier';
import DetailOrderRequestor from '../../DetailCards/DetailOrderRequestor';
import DetailOrderItems from '../../DetailCards/DetailOrderItems';
import { NotificationManager } from 'react-notifications';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Close } from '@material-ui/icons';
import ButtonsChangeStatus from '../../../../components/Buttons/ButtonsChangeStatus';
import '../../index.css';

class SupplierOrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      orderDocument: null,
      editReference: false,
      reference: '',
      isResponsibleUpdated: false,
    };
    this.updateRef = null;
  }

  componentDidMount() {
    this.props.getSupplierOrderDocumentRequest(this.props.match.params.idOrder);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.supplierOrderDocument !== this.props.supplierOrderDocument) {
      if (this.props.supplierOrderDocument.loading) {
        this.setState({ loading: true });
      }
      if (
        !this.props.supplierOrderDocument.loading &&
        !this.props.supplierOrderDocument.error &&
        this.props.supplierOrderDocument.orderDocument !== ''
      ) {
        this.setState({
          loading: false,
          orderDocument: this.props.supplierOrderDocument.orderDocument,
        });
        if (this.props.supplierOrderDocument.orderDocument.reference) {
          this.setState({
            editReference: false,
            reference: this.props.supplierOrderDocument.orderDocument.reference,
          });
        } else {
          this.setState({ editReference: true });
        }
      }
    }

    if (
      prevProps.updatedSupplierOrderDocument !==
      this.props.updatedSupplierOrderDocument
    ) {
      if (
        !this.props.updatedSupplierOrderDocument.loading &&
        !this.props.updatedSupplierOrderDocument.error
      ) {
        this.setState({ isResponsibleUpdated: true });
        this.props.getSupplierOrderDocumentRequest(
          this.props.match.params.idOrder
        );
        NotificationManager.success(
          <IntlMessages id="orders.detail.message.success.updated" />
        );
      }
    }

    if (prevProps.updatedDocumentStatus !== this.props.updatedDocumentStatus) {
      if (this.props.updatedDocumentStatus.loading) {
        this.setState({ loading: true });
      }
      if (
        this.props.updatedDocumentStatus.document !== '' &&
        !this.props.updatedDocumentStatus.loading &&
        !this.props.updatedDocumentStatus.error
      ) {
        this.props.getSupplierOrderDocumentRequest(
          this.props.match.params.idOrder
        );
        NotificationManager.success(
          <IntlMessages id="orders.detail.message.success.updated" />
        );
      }
    }

    if (prevProps.newAddress !== this.props.newAddress) {
      if (!this.props.newAddress.loading && !this.props.newAddress.error) {
        if (!this.state.isResponsibleUpdated) {
          this.props.getSupplierOrderDocumentRequest(
            this.props.match.params.idOrder
          );

          NotificationManager.success(
            <IntlMessages id="orders.detail.message.success.updated" />
          );
        }
      }
    }

    if (prevProps.globalError !== this.props.globalError) {
      if (this.props.globalError === true) {
        NotificationManager.error(
          <IntlMessages id="appModule.message.error" />
        );
        this.props.setGlobalError(false);
      }
    }
  }

  getCountry = (code) => {
    const regionName = new Intl.DisplayNames(
      [this.props.locale.replace('_', '-')],
      {
        type: 'region',
      }
    );
    if (code) {
      const countryCode = code.substring(0, 2);
      return regionName.of(countryCode);
    }
  };

  handleChangeReference = (event) => {
    this.setState({ reference: event.target.value });

    this.updateRef = { reference: event.target.value };
  };

  render() {
    const { loading } = this.state;

    return (
      <div className="app-wrapper div-wrapper-title">
        <div className="row headerAlign">
          <div className="col-8 page-title">
            <IntlMessages id="orders.supplier.detail.title" />
          </div>

          <div className="col-4">
            {this.state.orderDocument && (
              <div className="orderDocumentRef">
                <div className="mr-1">
                  <IntlMessages id="orders.detail.order.number" />
                </div>
                {!this.state.editReference ? (
                  <div onClick={() => this.setState({ editReference: true })}>
                    {this.state.orderDocument.reference}
                  </div>
                ) : (
                  <TextField
                    margin="normal"
                    value={this.state.reference}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            className="p-0"
                            disabled={
                              !this.props.supplierOrderDocument.orderDocument
                                .reference
                            }
                            onClick={() => {
                              this.setState({
                                editReference: false,
                                reference:
                                  this.props.supplierOrderDocument.orderDocument
                                    .reference,
                              });
                              this.updateRef = null;
                            }}
                          >
                            <Close />
                          </IconButton>
                          <IconButton
                            className="p-0"
                            disabled={
                              this.state.reference === ''
                                ? true
                                : !this.updateRef
                                ? true
                                : false
                            }
                            onClick={() => {
                              this.props.updateSupplierOrderDocumentRequest(
                                this.props.match.params.idOrder,
                                this.updateRef
                              );
                              this.setState({ editReference: false });
                            }}
                          >
                            <Check />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={this.handleChangeReference}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-12 col-md-6 detailCard">
              <DetailOrderSupplier
                supplier={
                  this.state.orderDocument && this.state.orderDocument.supplier
                }
                loading={loading}
                getCountry={this.getCountry}
              />
            </div>
            <div className="col-12 col-md-6 detailCard">
              <DetailOrderRequestor
                supplier
                requestorPos={
                  this.state.orderDocument &&
                  this.state.orderDocument.requestorPos
                }
                responsibleUserProfile={
                  this.state.orderDocument &&
                  this.state.orderDocument.responsibleUserProfile
                }
                billingAddress={
                  this.state.orderDocument &&
                  this.state.orderDocument.billingAddress
                }
                shippingAddress={
                  this.state.orderDocument &&
                  this.state.orderDocument.shippingAddress
                }
                loading={loading}
                getCountry={this.getCountry}
                idOrder={this.props.match.params.idOrder}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <DetailOrderItems
                supplier
                id={this.state.orderDocument && this.state.orderDocument.id}
                vendor={
                  this.state.orderDocument && this.state.orderDocument.supplier
                }
                totalWithoutVat={
                  this.state.orderDocument &&
                  this.state.orderDocument.totalWithoutVat
                }
                totalWithVat={
                  this.state.orderDocument &&
                  this.state.orderDocument.totalWithVat
                }
                refresh={() =>
                  this.props.getSupplierOrderDocumentRequest(
                    this.props.match.params.idOrder
                  )
                }
                loading={loading}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <ButtonsChangeStatus
                id={this.state.orderDocument && this.state.orderDocument.id}
                status={
                  this.state.orderDocument && this.state.orderDocument.status
                }
                idOrder={this.props.match.params.idOrder}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  supplierOrderDocument: state.sdk.suppliers.supplierOrderDocument,
  updatedSupplierOrderDocument: state.sdk.suppliers.updatedSupplierOrderDocument,
  newAddress: state.sdk.addresses.newAddress,
  locale: state.sdk.settings.locale.locale,
  updatedDocumentStatus: state.sdk.documents.updatedDocumentStatus,
});

export default connect(mapStateToProps, {
  getSupplierOrderDocumentRequest,
  updateSupplierOrderDocumentRequest,
  setGlobalError,
})(SupplierOrderDetails);
