import React, { Component } from 'react';
import { Modal } from '../../../../../config/components.config';
import IntlMessages from '../../../../../util/IntlMessages';
import InventoryImportModalContent from './InventoryImportModalContent';

class InventoryImportModal extends Component {
  handleClose = () => this.props.close();

  render() {
    return (
      <Modal
        id="import-inventory-modal"
        title={<IntlMessages id="inventory.modal.import.title" />}
        width="md"
        open={this.props.open}
        close={this.handleClose}
      >
        <InventoryImportModalContent
          handleClose={this.handleClose}
        />
      </Modal>
    );
  }
}

export default InventoryImportModal;
